
<div class='app'>
  <main class='project right'>
    <div id="user-profile" class="">
      <div class="content">
        <div class="row">
          <div class="col-sm-12">
            <div class="card-7">
              <!-- Nav tabs -->
              <ul class="nav nav-tabs" role="tablist">
                <li [ngClass]="activityTab?'active':''"><a href="javascript: void(0);" role="tab" data-toggle="tab" (click)="toggleTab('activity')">Activity</a></li>

                <li [ngClass]="taskTab?'active':''"><a href="javascript: void(0);" role="tab" data-toggle="tab" (click)="toggleTab('task')">Task</a></li>

                <li [ngClass]="noteTab?'active':''"><a href="javascript: void(0);" role="tab" data-toggle="tab" (click)="toggleTab('note')">Reminder</a></li>

                <li [ngClass]="leaveTab?'active':''"><a href="javascript: void(0);" role="tab" data-toggle="tab" (click)="toggleTab('leave')">Leave</a></li>

                <li [ngClass]="achivementTab?'active':''"><a href="javascript: void(0);" role="tab" data-toggle="tab" (click)="toggleTab('achivement')">Achivement<i class="fa-solid fa-hands-clapping"></i></a></li>
              </ul> 
              <!-- Tab panes -->
              <div class="tab-content">
                <div role="tabpanel" class="tab-pane active" id="activity" *ngIf="activityTab">
                  <br>

                <div *ngIf="announcements?.length > 0">
                  <div *ngFor="let announce of announcements">
                    <div id="contentArea">
                      <div class="notice-listing">
                        <h2 class="announce_title">{{announce.type}}</h2>
                        <div class="notice">
                          <div class="notice-content">
                            <p class="notice_p">{{announce.date|date}}</p>
                            <span>
                              <p>{{announce.description}}.</p>
                            </span>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <br>
                </div>
                  <h2 class="title recent_title">RECENT ACTIVITY </h2>
                  <!-- birthday -->
                    <!-- <ul>
                      <li *ngIf="birthDayBoy?.length > 0">
                        <div *ngIf="birthDayBoy?.length > 1">
                          <span id="main" class="container" *ngFor="let bb of birthDayBoy">
                            <h1 class="bdfont">Happy Birthday {{bb.name}}!</h1>
                          </span>
                        </div>
                    
                        <div *ngIf="birthDayBoy?.length == 1">
                          <span id="main" class="container" *ngFor="let bb of birthDayBoy">
                            <h1 class="bdfont">Happy Birthday {{bb.name}}!</h1>
                          </span>
                        </div>
                    
                        <div class="box sb3">On this joyous day, we come together as a team to celebrate the remarkable individuals that
                          each of you is. Your birthdays mark not only the passing of time but the growth, accomplishments, and shared
                          experiences that have made our collective journey richer.</div>
                        <p class="bkp">Team Bookyup.</p>
                      </li>
                    </ul> -->
                    <!--end  birthday -->

                    <!-- Tasks -->
                    <h3 class="title_context"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-list-task" viewBox="0 0 16 16">
                      <path fill-rule="evenodd" d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 3H2v1h1z"/>
                      <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z"/>
                      <path fill-rule="evenodd" d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5zM2 7h1v1H2zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm1 .5H2v1h1z"/>
                    </svg> Last Created Tasks</h3>
                    <div class="context_ul_main" *ngIf="empData.last_tasks?.length > 0">
                      <div class="context_li_main" *ngFor="let task of empData.last_tasks; index as i">
                        <h5><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-check2-circle" viewBox="0 0 16 16">
                          <path d="M2.5 8a5.5 5.5 0 0 1 8.25-4.764.5.5 0 0 0 .5-.866A6.5 6.5 0 1 0 14.5 8a.5.5 0 0 0-1 0 5.5 5.5 0 1 1-11 0"/>
                          <path d="M15.354 3.354a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z"/>
                        </svg>  <a class="" [routerLink]='["/manage-task/",encodeURIParam(task.t_id)]'> {{ task.task_name| titlecase}}</a> - {{task.created_at|date:'short'}}</h5>
                        <p class="description_p"><span>Description :</span> {{ task.task_description| titlecase}}</p>
                      </div>
                      <div class="stage">
                        <a href="javascript: void(0);" class="more" (click)="toggleTab('task')">See More</a>
                        <br>
                      </div>
                    </div>
                    <br>
                    <h3 class="title_context"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-week" viewBox="0 0 16 16">
                      <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5z"/>
                      <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5M1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4z"/>
                    </svg> Last Timesheet Entry</h3>
                    <ul>
                      <li>
                        <h6>
                          <a [routerLink]='["/work-reports/"]' class="more">{{empData.last_timesheet.entry_date|date}}</a>
                          <br>
                          <b> {{ empData.last_timesheet.comment | titlecase}} </b><br> 
                          <small> {{ convertToAmPm(empData.last_timesheet.start_time)}} - {{convertToAmPm(empData.last_timesheet.end_time) }}</small>
                        </h6>
                      </li>
                    </ul>

                    
                    <h3 class="title_context"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
                      <path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6"/>
                    </svg> Reminders</h3>
                    <ul>
                      <li>
                        <div *ngIf="empData.reminders?.length < 1; else reminder">
                          <h6 >
                            <b>No Reminder Today</b><br>
                          </h6>
                        </div>
                      </li>
                    </ul>

                    <ng-template #reminder>
                      <h6>
                        <b class="more"> Today at {{empData.reminders.reminder_time}}</b><br>
                        <small>{{empData.reminders.description | titlecase}}</small>
                      </h6>
                      <br>
                      <div class="stage">
                        <a href="javascript: void(0);" class="more" (click)="toggleTab('note')">See More</a>
                      </div>
                    </ng-template>

                    <h3 class="title_context"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-building-fill-add" viewBox="0 0 16 16">
                      <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0"/>
                      <path d="M2 1a1 1 0 0 1 1-1h10a1 1 0 0 1 1 1v7.256A4.5 4.5 0 0 0 12.5 8a4.5 4.5 0 0 0-3.59 1.787A.5.5 0 0 0 9 9.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .39-.187A4.5 4.5 0 0 0 8.027 12H6.5a.5.5 0 0 0-.5.5V16H3a1 1 0 0 1-1-1zm2 1.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m3 0v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5m3.5-.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zM4 5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M7.5 5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm2.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5h-1a.5.5 0 0 0-.5.5M4.5 8a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5z"/>
                    </svg> New Business Joined</h3>
                    <ul>
                      <li>
                        <div *ngIf="empData.business?.length < 1; else business">
                          <h6>
                            <b>No business joined recently</b><br>
                          </h6>
                        </div>
                      </li>
                    </ul>
                </div>

                <ng-template #business>
                  <h6>
                    <b>Today at {{empData.business.m_name | titlecase}}</b><br>
                    <small>{{empData.business.created_at | date}}</small>
                  </h6>
                  <br>
                  <div class="stage">
                    <a href="javascript: void(0);" [routerLink]='["/manage-module/",encodeURIParam(empData.business.m_id)]' class="more">See More</a>
                  </div>
                </ng-template>

                <!-- Tasks -->

                <div role="tabpanel" class="tab-pane active" id="taskTab" *ngIf="taskTab">
                  <h4 class="text-muted">Last 30 Days</h4>
                  <hr>
                  <br>  
                  
                  <div *ngFor="let task of taskList; index as i">
                    
                  <details id="smooth">
                    <summary>
                      <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#f2607d" class="bi bi-list-task" viewBox="0 0 16 16">
                        <path fill-rule="evenodd" d="M2 2.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5V3a.5.5 0 0 0-.5-.5zM3 3H2v1h1z"/>
                        <path d="M5 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5M5.5 7a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1zm0 4a.5.5 0 0 0 0 1h9a.5.5 0 0 0 0-1z"/>
                        <path fill-rule="evenodd" d="M1.5 7a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5H2a.5.5 0 0 1-.5-.5zM2 7h1v1H2zm0 3.5a.5.5 0 0 0-.5.5v1a.5.5 0 0 0 .5.5h1a.5.5 0 0 0 .5-.5v-1a.5.5 0 0 0-.5-.5zm1 .5H2v1h1z"/>
                      </svg>
                      
                      <div class="summary_div">
                        <h3 class="name1"><span>{{i+1}}.</span>{{task.task_name| titlecase}}</h3>
                        <p><strong>M : </strong> <span>{{task.module_name| titlecase}}</span></p>
                        <p class="Date">{{task.updated_at | date}}</p>
                        <div class="progress" style="width: 100px; border: 1px solid #f2607d;">
                          <div class="progress-bar" role="progressbar" [style.width]="((task.used_time/task.task_estimated_pd)*100).toFixed()+'%'" style="width: 100%;background-color:#f2607d !important;" ></div>
                        </div>
                        <p class="Time">                      
                          {{((task.used_time/task.task_estimated_pd)*100).toFixed()}}%
                        </p>
                      </div>
                    </summary>
                    <div class="content">
                      <div class="content_pogress">
                        <p><strong>Project:</strong></p>
                        <p><span>{{task.project_name| titlecase}}</span></p>
                      </div>
                      <div class="content_pogress">
                        <p><strong>Module:</strong></p>
                        <p>{{task.module_name| titlecase}}</p>
                      </div>
                      <div class="content_pogress">
                        <p><strong> Assigned Time:</strong></p>
                        <p>{{task.task_estimated_pd}} Hr</p>
                      </div>
                      <div class="content_pogress">
                        <p><strong> Consumed Time:</strong></p>
                        <p>{{task.used_time}} Hr</p>
                      </div>
                      <div class="content_pogress">
                        <p><strong> Assigned By:</strong></p>
                        <p>{{task.assign_by}}</p>
                      </div>
                      <div class="content_pogress">
                        <p><strong> View:</strong></p>
                        <p><a href="javascript: void(0);" [routerLink]='["/manage-task/",encodeURIParam(task.t_id)]'>Info</a></p>
                      </div>
                    </div>
                </details>
                  </div>
                </div>

                <div role="tabpanel" class="tab-pane active" id="nda" *ngIf="leaveTab">
                  <h4>Leave</h4>
                  <hr>
                  <ul *ngIf="activeLeveList?.length < 1">
                    <li>
                      <p>No pending Leave!</p>
                    </li>
                  </ul>

                  <div *ngFor="let leave of activeLeveList; index as i">
                    <details id="smooth">
                      <summary>
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-person-exclamation" viewBox="0 0 16 16">
                          <path d="M11 5a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4m.256 7a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
                          <path d="M16 12.5a3.5 3.5 0 1 1-7 0 3.5 3.5 0 0 1 7 0m-3.5-2a.5.5 0 0 0-.5.5v1.5a.5.5 0 0 0 1 0V11a.5.5 0 0 0-.5-.5m0 4a.5.5 0 1 0 0-1 .5.5 0 0 0 0 1"/>
                        </svg>
                  
                        <div class="summary_div">
                          <h3 class="name1"><span>{{i+1}}.</span>{{leave.start_date|date}} - {{leave.end_date|date}}</h3>
                          <p><strong>: </strong>Type : <span>{{leave.leave_type}}</span></p>
                          <p class="Date">{{leave.applied_status}}</p>
                        </div>
                      </summary>
                      <div class="content">
                        <div class="content_pogress">
                          <p><strong>Reason:</strong></p>
                          <p><span>{{leave.reason| titlecase}}</span></p>
                        </div>
                        <div class="content_pogress">
                          <p><strong>Apply Date:</strong></p>
                          <p>{{leave.created_at|date}}</p>
                        </div>
                        <div class="content_pogress">
                          <p><strong>Total Days:</strong></p>
                          <p>{{leave.total_day}}</p>
                        </div>
                        <div class="content_pogress">
                          <p><strong>Day Type:</strong></p>
                          <p>{{leave.day_type}}</p>
                        </div>
                        <div class="content_pogress">
                          <p><strong> View:</strong></p>
                          <p><a href="javascript: void(0);" [routerLink]="['/leave/leave-details/',leave.leave_req_id]">Info</a></p>
                        </div>
                      </div>
                    </details>
                  </div>
                </div>

                <div role="tabpanel" class="tab-pane active" id="customerinvoices" *ngIf="noticeTab">
                  <h4>Notice</h4>
                  <hr>
                  <p>No Announcement!</p>
                </div>

                <div role="tabpanel" class="tab-pane active customerinvoices" id="customerinvoices" *ngIf="noteTab">
                  <div class="listing-container">
                    <div class="inputs-container">
                      <span class="js-add-sauce">
                        <a (click)="open(demoModal)"><i class="icon icon-add"></i></a>
                      </span>
                    </div>
                  </div>
                  
                  <hr>
                  <br>
                  <ul *ngIf="getReminders?.length < 1">
                    <li>
                      <p>No Reminder Set!</p>
                    </li>
                  </ul>

                    <div *ngFor="let reminder of getReminders; index as i">
                      <details id="smooth">
                        <summary>
                          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-bell" viewBox="0 0 16 16">
                            <path
                              d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2M8 1.918l-.797.161A4 4 0 0 0 4 6c0 .628-.134 2.197-.459 3.742-.16.767-.376 1.566-.663 2.258h10.244c-.287-.692-.502-1.49-.663-2.258C12.134 8.197 12 6.628 12 6a4 4 0 0 0-3.203-3.92zM14.22 12c.223.447.481.801.78 1H1c.299-.199.557-.553.78-1C2.68 10.2 3 6.88 3 6c0-2.42 1.72-4.44 4.005-4.901a1 1 0 1 1 1.99 0A5 5 0 0 1 13 6c0 .88.32 4.2 1.22 6" />
                          </svg>
                    
                          <div class="summary_div" [ngClass]="reminder.reminder_date === today?'text-danger':''">
                            <h3 class="name1"><span>{{i+1}}.</span>{{reminder.title| titlecase}}</h3>
                            <p><strong>: </strong> <span>{{reminder.reminder_time}}</span></p>
                            <p class="Date">{{reminder.reminder_date === today?'Today':reminder.reminder_date| date}}</p>
                          </div>
                        </summary>
                        <div class="content">
                          <div class="content_pogress">
                            <p><strong>Description:</strong></p>
                            <p><span>{{reminder.description| titlecase}}</span></p>
                          </div>
                          <div class="content_pogress">
                            <p><strong>Status:</strong></p>
                            <p>{{reminderStatusMsg(reminder.status,reminder.reminder_date,reminder.title)}}</p>
                          </div>
                          <div class="content_pogress">
                            <p><strong>Time:</strong></p>
                            <p>{{reminder.reminder_time}}</p>
                          </div>
                          <div class="content_pogress">
                            <p><strong>Priority:</strong></p>
                            <p>{{reminder.priority}}</p>
                          </div>
                          <div class="content_pogress">
                            <p><strong>Action:</strong></p>
                            <p (click)="deleteRem(reminder.id)" tooltip="Delete?" class="text-danger"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                            </svg></p>
                          </div>
                        </div>
                      </details>
                    </div>
                </div>

                <div role="tabpanel" class="tab-pane active" id="customerinvoices" *ngIf="achivementTab">
                  <h4>My Achivements</h4>
                  <hr>
                  <p>No Achivement earned yet!</p>
                </div>

              </div>
            </div> 
          </div>
        </div>
      </div>
    </div>  
  </main>

  <aside class='task-details'>
    <!-- <div class="date-card-two">
      <div class="onldcls" onload="startTime()">
        <div id="clockdate">
          <div class="clockdate-wrapper">
            <div id="date" tooltip={{reminderTitle}} placement="bottom">{{today|date:'mediumDate'}} | {{reminderTitle?.length > 8?reminderTitle.substr(0,8)+'..':''}}</div>
          </div>
        </div>
      </div>
    </div>
    <br> -->
    <div class='tag-progress'>
      <div class='tag-progress'>
        <p>Attendance <span>{{attendance}}</span></p>
        <progress class="progress progress--copyright" tooltip="Upcomming feature" max="22" [value]='attendance'>{{attendance}}</progress>
      </div>
      <div class='tag-progress'>
        <p>Leave <span>{{empData.leaves}}/24</span></p>
        <progress class="progress progress--illustration" tooltip="Total approved Leaves And Total Leaves" max="24" [value]='empData.leaves'>{{empData.leaves}}</progress>
      </div>
      <div class='tag-progress'>
        <p>Task <span>{{taskStatistics.finished}}/{{taskStatistics.all}}</span></p>
        <progress class="progress progress--design" tooltip="Total completed tasks And Total tasks" [max]='taskStatistics.all' [value]='taskStatistics.finished'></progress>
      </div>
    </div>
    <br>
    <br>
    <br>
    <div class='task-activity' *ngIf="taskMessages?.length > 0">
        <h2><a href="javascript: void(0);" [routerLink]='["/manage-task/",encodeURIParam(t_id)]' class="more" >Task : {{TaskNameChat|titlecase}}</a></h2><br>
        <ul class="universal-ul">
          <div class="chatscrll" *ngFor="let chat of taskMessages">
            <li [ngClass]="chat.user_id === u_id?'odd-list':'even-list'">
              <div [ngClass]="chat.user_id === u_id?'inner-list':'inner-list-2'">
                <h6>{{chat.msg}}</h6>
                <p>{{chat.date|date:'short'}}</p>
                <!-- <img src="./img/pre.png" alt=""> -->
                <div class="userBox">
                  <div class="flex-shrink-0 ">
                    <a href="javascript: void(0);" class="userShape-Sm" tooltip={{chat.name}} placement="bottom"
                      data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" aria-label="hello"
                      [ngStyle]="{'background-color':'white'}">
                      {{convertToLetter(chat.name)}}
                    </a>
                  </div>
                </div>
              </div>
            </li>
          </div>
          <div class="stage">
            <a href="javascript: void(0);" [routerLink]='["/manage-task/",encodeURIParam(t_id)]' class="more" >Go to chat </a>
          </div>
        </ul>

    </div>
    <hr>
    <br>
  </aside>
</div>

<ng-template #demoModal let-modal>
  <div class="modal-header reminder-head">
    <h4 class="modal-title" id="modal-basic-title">Add Reminder</h4>
    <button type="button" class="btn-close reminder-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body reminder-body">
    <form [formGroup]="remimderForm" (ngSubmit)="remimderForm.valid && addEditReminder(remimderForm)" #fd="ngForm" [ngxFvControl]="fd">
      <!-- <div class="accordion add-employee" id="accordion-details"> -->
        <div class="card  grow ctm-border-radius border-0 shadow-none">
          <div class="card-body p-0">
            <div id="basic-one" class="ctm-padding collapse show" >
              <div class="row">
                <input type="hidden" class="form-control" id="r_id" #ngxFvFormControl formControlName="r_id">
                <div class="col-lg-12 form-group">
                  <ngx-fv-validate [control]="remimderForm.controls['title']">
                    <label #ngxFvName>
                      Title
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="las la-envelope-open"></i>
                      </div>
                      <input type="text" class="form-control" id="title" placeholder="Ex: My reminder" #ngxFvFormControl formControlName="title" required>
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                </div>
  
                <div class="col-lg-12 form-group">
                  <ngx-fv-validate [control]="remimderForm.controls['description']">
                    <label #ngxFvName>
                      Description
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="las la-envelope-open"></i>
                      </div>
                      <textarea class="form-control" id="description" placeholder="Description" #ngxFvFormControl
                        formControlName="description" required></textarea>
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                </div>
  
                <div class="col-lg-6 form-group">
                  <ngx-fv-validate [control]="remimderForm.controls['reminder_date']">
                    <label #ngxFvName>
                      Date
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text">
                        <i class="las la-calendar-alt"></i>
                      </div>
                      <input type="text" class="form-control date-pick" ngbDatepicker #dpStart="ngbDatepicker" id="reminder_date"
                        placeholder="YYYY-MM-DD" [minDate]="minDateLimit" name="dpStart"
                        (dateSelect)="onDateSelect($event)" (click)="dpStart.toggle()" #ngxFvFormControl
                        formControlName="reminder_date" readonly>
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                </div>
  
                <div class="col-lg-6 form-group">
                  <ngx-fv-validate [control]="remimderForm.controls['reminder_time']">
                    <label #ngxFvName>
                      Time
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="las la-envelope-open"></i>
                      </div>
                      <input type="time" class="form-control" id="reminder_time" placeholder="Ex: My reminder"
                        #ngxFvFormControl formControlName="reminder_time" required>
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                </div>
  
                <div class="col-lg-12 form-group">
                  <ngx-fv-validate [control]="remimderForm.controls['priority']">
                    <label #ngxFvName>
                      Priority
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="lar la-id-card"></i>
                      </div>
                      <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                        #ngxFvFormControl id="priority" formControlName="priority" required>
                        <option value="">Set Priority</option>
                        <option value="h">High</option>
                        <option value="m">Medium</option>
                        <option value="l">Low</option>
                      </select>
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                </div>
                <div class="input-group-save-button mb-3">
                  <button class="custom-btn btn-8 text-center" [disabled]="remimderForm.invalid" (click)="modal.dismiss('Cross click')" type="submit"><span>SET</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
      <!-- </div> -->
    </form>
  </div>
</ng-template>