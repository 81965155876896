import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leave-roster',
  templateUrl: './leave-roster.component.html',
  styleUrls: ['./leave-roster.component.scss']
})
export class LeaveRosterComponent implements OnInit {
  public status = false;
  constructor() { }

  ngOnInit(): void {
  }
  roaster(){
    this.status = true;
  }
}
