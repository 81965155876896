<div class="main-content" *ngIf="loadAllComponant">
<div class="ctm-border-radius  grow card">
  <div id="basic1" class="card-header d-flex align-center justify-content-between">
    <div class="card_title_left">
      <h4 class="card-title  mb-0 mt-0" *ngIf="projectList.length > 0; else title"> <b><i class="las la-info-circle"></i>
        {{projectList.length}} Projects Available </b></h4>
    <ng-template #title>
      <h4 class="card-title  mb-0 mt-2"> <b><i class="las la-info-circle"></i> No Project Available</b></h4>
    </ng-template>
    </div>
    <div class="card_right_ d-flex align-items-center justify-content-end">
      <div class="filter_contetn">
        <div class="filter_drop_down justify-content-between">
          <div class="form-outline">
            <input type="text" class="form-control" (keyup)="searchType($event)" placeholder="Search" aria-label="Search"
              [(ngModel)]="multiSearch" id="searchdata">
          </div>
          <button class="custom-btn btn-8 text-center" (click)="filter_status_btn()"><span><i class="las la-filter"></i>
              Filters</span></button>
        </div>
        <div class="drop_down_filter_list d-flex justify-content-end" [ngClass]="filter_status?'on':''">
          <div class="input-group mb-3">
            <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="false" placeholder="Project Name"
              id="p_name" [(ngModel)]="nameSearch" (change)="deSelect($event,'p_name')">
              <ng-option *ngFor="let name of nameFilterList" [value]="name">{{name}}</ng-option>
            </ng-select>
          </div>
          <div class="input-group mb-3">
            <ng-select [multiple]="false" [selectableGroup]="true" (change)="deSelect($event,'p_type')"
              [closeOnSelect]="false" [(ngModel)]="typeSearch" id="name" placeholder="Project Type">
              <ng-option *ngFor="let type of typeFilterList" [value]="type">{{type}}</ng-option>
            </ng-select>
          </div>
          <div class="input-group mb-3">
            <ng-select [multiple]="false" [selectableGroup]="true" (change)="deSelect($event,'p_stage')"
              [closeOnSelect]="false" [(ngModel)]="stageSearch" placeholder="Project Stage">
              <ng-option *ngFor="let stage of stageFilterList" [value]="stage">{{stage}}</ng-option>
            </ng-select>
          </div>
          <div class="input-group mb-3">
            <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true"
              (change)="deSelect($event,'p_status')" [(ngModel)]="statusSearch" id="name" placeholder="Project Status">
              <ng-option *ngFor="let status of statusFilterList" [value]="status">
                {{status}}
              </ng-option>
            </ng-select>
          </div>
          <div class="input-group mb-3">
            <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true"
              (change)="deSelect($event,'p_domain')" [(ngModel)]="domainSearch" id="domain" placeholder="Project Domain">
              <ng-option *ngFor="let domain of domainFilterList" [value]="domain">
                {{domain}}
              </ng-option>
            </ng-select>
          </div>
        </div>
      </div>
      <a routerLink="/create-project" class="custom-btn btn-8 text-center"><span><i class="las la-plus"></i> Add
        Project</span> </a>
    </div>
</div>
  <div class="card-body">
  
    <div class="table-back employee-office-table">
      <div class="table-responsive"
      infiniteScroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="500"
      (scrolled)="onScroll()"
      [scrollWindow]="false">
        <table class="table custom-table table-hover table-hover"
          *ngIf="projectList != undefined || projectList != null || projectList !=''">
          <thead>
            <tr>
              <th>SL_no</th>
              <th>Project Name</th>
              <th>Project Id</th>
              <th class="text-center">Project Type</th>
              <th class="text-center">Status</th>
              <th>Project Stage</th>
              <th>Project Domain</th>
              <!-- <th>Project Health</th> -->
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let projectLists of projectList | tableFilter:searchKey : selectedfield | tableSearch : objKey;index as i">
              <td>{{i+1}}</td>
              <td><a class="" [routerLink]="['/manage-project/',encodeURIParam(projectLists.p_id)]">{{projectLists.p_name}}</a></td>
              <td>{{projectLists.p_id}}</td>
              <td class="text-center"><span class="badge alert-primary">{{projectLists.p_type}}</span></td>
              <td class="text-center">
                <span class="badge alert-info"
                  *ngIf="projectLists.p_status=='active'">Active</span>
                <span class="badge alert-danger"
                  *ngIf="projectLists.p_status=='inactive'">Inactive</span>
                <span class="badge alert-warning"
                  *ngIf="projectLists.p_status=='stop'">Paused</span>
                <span class="badge alert-success"
                  *ngIf="projectLists.p_status=='finished'">Finished</span>
              </td>
              <td>{{projectLists.p_stage}}</td>
              <td>{{projectLists.p_domain}}</td>
              <!-- <td>
                <div class="health">
                  <span class="open"></span>
                  <span class="{{projectLists.health}} on "></span>
                  <span class="{{projectLists.health}} on"></span>
                  <span class="{{projectLists.health}} on"></span>
                  <span [ngClass]="getCustomCss(projectLists.p_health)"></span>
                  <span [ngClass]="getCustomCss(projectLists.p_health)"></span>
                  <span [ngClass]="getCustomCss(projectLists.p_health)"></span>
                </div>
              </td> -->
              <td><a class="" [routerLink]="['/manage-project/',encodeURIParam(projectLists.p_id)]"><span
                    class=" edit text-success text-center"><i class="las la-edit"></i></span></a></td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
</div>
<ngx-ui-loader></ngx-ui-loader>