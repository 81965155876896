import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EmployeesListComponent } from './employees-list/employees-list.component';
import { LeaveEmployeeComponent } from '../../pages/employees/leave-employee/leave-employee.component';
import { LeaveRosterComponent } from './leave-roster/leave-roster.component';
import { AddEmployeeComponent } from '../../pages/employees/add-employee/add-employee.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule , ReactiveFormsModule} from '@angular/forms'
import { NgxFormValidationsModule } from 'ngx-form-validations';
import { NgSelectModule } from "@ng-select/ng-select";
import { ShareModule } from '../../share/share.module';
import { SharedSearchModule } from '../../shared-search/shared-search.module';
import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from "ngx-ui-loader";
import { ManageProfileComponent } from './manage-profile/manage-profile.component';
import { EmployeesRoutingModule } from './employees-routing.module';
import { EmployeesDashboardComponent } from './employees-dashboard/employees-dashboard.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { WebcamModule } from 'ngx-webcam';
import { ViewAttendanceComponent } from './view-attendance/view-attendance.component';
import { UploadAttendanceComponent } from './upload-attendance/upload-attendance.component';
import { EditAttendanceComponent } from './edit-attendance/edit-attendance.component';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { AddAttendanceComponent } from './add-attendance/add-attendance.component';
import { AttendanceReportComponent } from './attendance-report/attendance-report.component';
import { NgDragDropModule } from 'ng-drag-drop';
import { HolidayComponent } from './holiday/holiday.component';
import { AttendanceDetailsComponent } from './attendance-details/attendance-details.component';
import { TooltipModule } from 'ng2-tooltip-directive';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "red",
  pbColor: "#6fff00",
  fgsColor: "#1cc028",
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.squareLoader
  , // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 2, // progress bar thickness
};
@NgModule({
  declarations: [
    EmployeesListComponent, 
    LeaveEmployeeComponent, 
    LeaveRosterComponent,
    AddEmployeeComponent,
    ManageProfileComponent,
    EmployeesDashboardComponent,
    AttendanceComponent,
    ViewAttendanceComponent,
    UploadAttendanceComponent,
    EditAttendanceComponent,
    MyDashboardComponent,
    AddAttendanceComponent,
    AttendanceReportComponent,
    HolidayComponent,
    AttendanceDetailsComponent,
    
    
  ],
  imports: [
    CommonModule,
    TooltipModule,
    InfiniteScrollModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgxFormValidationsModule,
    ShareModule,
    SharedSearchModule,
    NgSelectModule,
    IvyCarouselModule,
    WebcamModule,
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    EmployeesRoutingModule,
    NgDragDropModule.forRoot(),

  ]
})
export class EmployeesModule { }
