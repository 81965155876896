import { Component, OnInit} from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { ToastrService } from 'ngx-toastr';


@Component({
  selector: 'app-task',
  templateUrl: './task.component.html',
  styleUrls: ['./task.component.scss'],
})
export class TaskComponent implements OnInit {
  public showloader: boolean = false;
  public taskList = <any>[];
  public taskObjList = <any>[];
  public nextPage = <any>[];
  public error = false;
  public filter_status = false;
  public projectSearch: string = '';
  public filterData: any;
  public searchKey = '';
  public nameSearch: any;
  public typeSearch: any;
  public statusSearch: any;
  public selectedfield = '';
  public nameFilterList = <any>[];
  public typeFilterList = <any>[];
  public statusFilterList = <any>[];
  public objKey: any;
  public multiSearch: string = '';
  public loadAllComponant = false;
  public scrolling: any;
  public paginateData: any;
  public showEmp = true;
  public scrollList = true;
  public lastPage = true;
  public offset = <any>0;
  public limit = <any>15;
  public addDataToArray: any;
  public isTrue = true;
  public currentPosition = window.pageYOffset;
  public viewAll = '';
  public currentpage = 1;
  public totalpage = 1;
  
  constructor(
    public rest: RestService,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.getTasks();
  }

  /* This function is used to fetch task list*/
  getTasks(key = '') {
    let key1 = '';
    if(key !== '' || key !== undefined){
      key1 = key;
    }
    this.rest.getData({offset: this.offset, limit: this.limit,keywords:key1},'getalltask').subscribe({
      next: (res) => {
        this.loadAllComponant = true;
        if (res.state == '200') {
          this.taskList = [...this.taskList, ...res.data];
          this.createFilterData();
        } else {
          //this.toaster.error(res.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        this.toaster.error('Something wend wrong!');
        this.error = true;
      },
    });
  }

  findTask(key = '') {
    let key1 = '';
    if(key !== '' || key !== undefined){
      key1 = key;
    }
    this.rest.getData({keywords:key1}, 'getalltask').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.taskList = [];
          console.log(this.taskList);
          this.taskList = data.data;
          this.createFilterData();
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.loadAllComponant = true;
        //this.toaster.error('Something went wrong');
      },
    });
  }

  onScroll(){
    if(this.scrollList == true){
      this.limit   = 15;
      this.offset += 15;
      console.log("==",this.offset);
      this.getTasks();
    }
  }

  filter_status_btn() {
    this.filter_status = !this.filter_status;
  }

  deSelect(event: any, filedType: string) {
    this.selectedfield = filedType;
    if (filedType === 'task_name') {
      this.typeSearch = undefined;
      this.statusSearch = undefined;
      this.searchKey = this.nameSearch;
    } else if (filedType === 'task_type') {
      this.nameSearch = undefined;
      this.statusSearch = undefined;
      this.searchKey = this.typeSearch;
    } else if (filedType === 'task_status') {
      this.nameSearch = undefined;
      this.typeSearch = undefined;
      this.searchKey = this.statusSearch;
    }
  }
  
  createFilterData() {
    let tnameFilterList = [];
    let ttypeFilterList = [];
    let tstatusFilterList = [];
    for (let task of this.taskList) {
      tnameFilterList.push(task.task_name);
      ttypeFilterList.push(task.task_type);
      tstatusFilterList.push(task.task_status);
    }
    this.nameFilterList   = [...new Set(tnameFilterList)];
    this.typeFilterList   = [...new Set(ttypeFilterList)];
    this.statusFilterList = [...new Set(tstatusFilterList)];
  }

  searchType(event: any) {
    let searchkey = event.target.value;
    if (searchkey.length > 2) {
      this.scrollList = false;
      this.findTask(searchkey);
    } else if (searchkey.length == 0) {
      this.scrollList = true;
      this.taskList = [];
      this.getTasks();
    }
  }

  encodeURIParam(param:any){
    return param.replace('/','_');
  }
}