import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { saveAs } from 'file-saver';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';
@Injectable({
  providedIn: 'root',
})
export class PagesService {
  // private taskSubject = new Subject<any>();
  private dataTask = {};
  public fLetter: any;
  public stWord: any;
  constructor() {}
  setData(restdata: any) {
    this.dataTask = restdata;
  }
  getData() {
    return this.dataTask;
  }

  timeConvert(n: number) {
    let rhours = Math.floor(n / 60);
    let rminutes = Math.round((n / 60 - rhours) * 60);
    let timeStr =
      rhours > 0 ? (rhours == 1 ? rhours + ' h ' : rhours + ' h ') : '';
    timeStr += rminutes == 0 ? '' : rminutes + ' m';
    return timeStr;
  }

  formatSeconds(totalmins: number) {
    let mins = Math.abs(totalmins) % 60;
    let hours = Math.floor(Math.abs(totalmins) / 60);
    let days = Math.floor(hours / 24);
    let hourss = hours % 24;
    let timeStr = days > 0 ? (days == 1 ? days + ' d ' : days + ' d ') : '';
    timeStr +=
      hourss > 0 ? (hourss == 1 ? hourss + ' h ' : hourss + ' h ') : '';
    timeStr += mins == 0 ? '' : mins + ' m.';
    return timeStr;
  }

  notifyMe(...data: any) {
    let [title, message, iconIm, url] = data;
    if (Notification.permission !== 'granted') Notification.requestPermission();
    else {
      let notification = new Notification(title === undefined ? '' : title, {
        icon: 'https://iconscout.com/ms-icon-310x310.png',
        body: message === undefined ? '' : message,
      });
      notification.onclick = function () {
        window.open(url === undefined ? '' : url);
      };
    }
  }

  exportExcel(data: any, fileName = 'report') {
    import('xlsx').then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(data); // Sale Data
      const workbook = { Sheets: { data: worksheet }, SheetNames: ['data'] };
      const excelBuffer: any = xlsx.write(workbook, {
        bookType: 'xlsx',
        type: 'array',
      });
      this.saveAsExcelFile(excelBuffer, fileName);
    });
  }

  saveAsExcelFile(buffer: any, fileName: string): void {
    import('file-saver').then((FileSaver: any) => {
      let EXCEL_TYPE =
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
      let EXCEL_EXTENSION = '.xlsx';
      const data: Blob = new Blob([buffer], {
        type: EXCEL_TYPE,
      });
      saveAs(data, fileName + new Date().getTime() + EXCEL_EXTENSION);
    });
  }

  getRandomColor() {
    let letters = '0123456789ABCDEF';
    let color = '';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  loadMore(data: any, curLen = 0, limit = 3) {
    let len = curLen == 0 ? limit : curLen + limit;
    if (len > data.length) {
      len = data.length;
    }
    return data.slice(0, len);
  }

  showLess(data: any, curLen = 0, limit = 3) {
    if (curLen > limit) {
      let len = curLen - limit;
      return data.slice(0, len);
    }
  }

  openCloseTab(ref: any, ev: any) {
    return ref == ev ? 0 : ev;
  }

  removeSpace(data: string) {
    return data.replace(' ', '-');
  }

  dateConvert(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    const convertDate = year + '-' + month + '-' + day;
    return convertDate;
  }

  convertNameToColor(name: any, s = 50, l = 50) {
    let hash = 0;
    for (let i = 0; i < name.length; i++) {
      hash = name.charCodeAt(i) + ((hash << 5) - hash);
    }
    let h = hash % 360;
    return 'hsl(' + h + ', ' + s + '%, ' + l + '%)';
  }

  getFirstLetters(str: any) {
    let output = '';
    if (str.includes(' ')) {
      let breakStr = str.split(' ');
      breakStr = breakStr[breakStr.length - 1];
      output = str[0] + breakStr[0];
    } else {
      output = str[0] + str[1];
    }
    return output.toUpperCase();
  }

  showDonutChart(data: any) {
    return {
      series: data.seriesArr,
      chart: {
        type: 'donut',
        width: '650px',
      },
      labels: data.labelsArr,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: '150px',
            },
            legend: {
              position: 'bottom',
            },
          },
        },
      ],
    };
  }

  getFormattedDates() {
    const today = new Date();
    const yesterday = new Date(today);
    yesterday.setDate(today.getDate() - 1);

    const lastWeek = new Date(today);
    lastWeek.setDate(today.getDate() - 7);

    const lastMonth = new Date(today);
    lastMonth.setMonth(today.getMonth() - 1);

    const lastYearToday = new Date(today);
    lastYearToday.setFullYear(today.getFullYear() - 1);

    const lastYearYesterday = new Date(yesterday);
    lastYearYesterday.setFullYear(yesterday.getFullYear() - 1);

    const lastYearLastWeek = new Date(lastWeek);
    lastYearLastWeek.setFullYear(lastWeek.getFullYear() - 1);

    const lastYearLastMonth = new Date(lastMonth);
    lastYearLastMonth.setFullYear(lastMonth.getFullYear() - 1);

    const dateFormat = 'yyyy-MM-dd';
    const locale = 'en-US';

    const formattedToday = formatDate(today, dateFormat, locale);
    const formattedYesterday = formatDate(yesterday, dateFormat, locale);
    const formattedLastWeek = formatDate(lastWeek, dateFormat, locale);
    const formattedLastMonth = formatDate(lastMonth, dateFormat, locale);

    const formattedLastYearToday = formatDate(lastYearToday, dateFormat, locale);
    const formattedLastYearYesterday = formatDate(lastYearYesterday, dateFormat, locale);
    const formattedLastYearLastWeek = formatDate(lastYearLastWeek, dateFormat, locale);
    const formattedLastYearLastMonth = formatDate(lastYearLastMonth, dateFormat, locale);

    return {
      today: formattedToday,
      yesterday: formattedYesterday,
      lastWeek: formattedLastWeek,
      lastMonth: formattedLastMonth,
      lastYear: {
        today: formattedLastYearToday,
        yesterday: formattedLastYearYesterday,
        lastWeek: formattedLastYearLastWeek,
        lastMonth: formattedLastYearLastMonth,
      },
    };
  }
  
}
