<div class="main-content" *ngIf="loadAllComponant">
    <div class="ctm-border-radius  grow card" *ngIf="holidayList!=undefined || holidayList!=null">
        <div id="basic1" class="card-header d-flex align-center justify-content-between">
            <div class="card_title_left" *ngIf="userRoles.includes('Admin') || userRoles.includes('Manager')">
                <h4 class="card-title  mb-0 mt-0"> <b><i class="las la-info-circle"></i>Holiday List</b></h4>
                <ng-template #title>
                    <h4 class="card-title  mb-0 mt-0"> <b><i class="las la-info-circle"></i>No Holiday Data Available</b></h4>
                </ng-template>
            </div>
            <div class="card_right_ d-flex align-items-center justify-content-end">
                <div class="filter_contetn">
                    <div class="filter_drop_down ">
                        <div class="input-group">
                            <div class="form-outline">
                                <input type="text" class="form-control" placeholder="Search" aria-label="Search">
                            </div>
                        </div>
                        <button class="custom-btn btn-8 text-center" (click)="filter_status_btn()"><span><i
                                    class="las la-filter"></i> Filters</span></button>
                    </div>
                    <div class="drop_down_filter_list d-flex justify-content-end" [ngClass]="filter_status?'on':''">
        
                        <div class="input-group mb-3">
                            <input type="text" ngbDatepicker #dpStart="ngbDatepicker" name="dpStart" (click)="dpStart.toggle()"
                                class="form-control" placeholder="Select date">
                        </div>
                    </div>
                </div>
                <input type="file" (change)="uploadFile($event)" id="upload-input" class="d-none">
                <button class="custom-btn btn-8 text-center" (click)="upload()"><span><i class="las la-plus"></i>
                        Upload Holiday List</span> </button>
            </div>
        </div>
        <div class="card-body">
        
            <div class="table-back employee-office-table">
                <div class="table-responsive">
                    <table class="table custom-table table-hover table-hover" id="empolyeeId">
                        <thead>
                            <tr>
                                <th>SL_no</th>
                                <th>Country</th>
                                <th>Date</th>
                                <th>Name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <ng-container *ngIf="holidayList==undefined || holidayList==''; else dataTable">
                                <tr>
                                    <td class="text-center" colspan="4">No Data Found!!</td>
                                </tr>
                            </ng-container>
                            <ng-template #dataTable>
                                <tr *ngFor="let hday of holidayList; index as i">
                                    <td>{{i+1}}</td>
                                    <td>{{hday.country}}</td>
                                    <td>{{hday.event_date | date}}</td>
                                    <td>{{hday.event_name}}</td>
                                </tr>
                            </ng-template>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    </div>    
</div>
<ngx-ui-loader></ngx-ui-loader>