<div class="main-content" *ngIf="loadAllComponant">

  <div class="quicklink-sidebar-menu ctm-border-radius  bg-white  grow">
    <div class="ctm-border-radius  grow card"
      *ngIf="recurringTaskList != undefined || recurringTaskList != null || recurringTaskList !='' ">
      <div class="card-header align-center d-flex justify-content-between">

        <h4 class="card-title  mb-0 mt-2" *ngIf="recurringTaskList.length > 0;else title"> <b>
          <i class="las la-info-circle"></i>{{recurringTaskList.length}} Tasks Available </b></h4>
        <ng-template #title>
          <h4 class="card-title  mb-0 mt-2"> <b><i class="las la-info-circle"></i> No Project Available Right Now</b></h4>
        </ng-template>
        
        <div class="card_right_ d-flex align-items-center justify-content-end">
          <div class="filter_contetn">
            <div class="filter_drop_down align-center justify-content-between ">
              <div class="form-outline col-md-6">
                <input type="text" class="form-control" (keyup)="searchType($event)" placeholder="Search"
                  aria-label="Search" [(ngModel)]="multiSearch" id="searchdata">
              </div>
              <button class="custom-btn btn-8 text-center" (click)="filter_status_btn()"><span><i class="las la-filter"></i>
                  Filters</span></button>
            </div>
            <div class="drop_down_filter_list d-flex justify-content-end" [ngClass]="filter_status?'on':''">
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="nameSearch"
                  (change)="deSelect($event,'task_name')" placeholder="Task Name">
                  <ng-option *ngFor="let task_name of nameFilterList" [value]="task_name">
                    {{task_name}}
                  </ng-option>
                </ng-select>
              </div>
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="typeSearch"
                  (change)="deSelect($event,'task_type')" placeholder="Task Type">
                  <ng-option *ngFor="let task_type of typeFilterList" [value]="task_type">
                    {{task_type}}
                  </ng-option>
                </ng-select>
              </div>
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="statusSearch"
                  (change)="deSelect($event,'task_status')" placeholder="Task Status">
                  <ng-option *ngFor="let task_status of statusFilterList" [value]="task_status">
                    {{task_status}}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
              <a routerLink="/create-task" class="custom-btn btn-8 text-center d-flex">
                <span>
                  <i class="las la-plus"></i>Add Task
                </span>
              </a>
        </div>
      </div>
      
      <div class="card-body">
        <div class="table-back employee-office-table">
          <div class="table-responsive"
          infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="500"
          (scrolled)="onScroll()"
          [scrollWindow]="false">
            <table class="table custom-table table-hover table-hover">
              <thead>
                <tr>
                  <th>SL_No</th>
                  <th>Task Name</th>
                  <th>Project Name</th>
                  <th>Module Name</th>
                  <th>Task Type</th>
                  <th class="text-center">Status</th>
                  <th class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="recurringTaskList==undefined || recurringTaskList==''; else dataTable">
                  <tr>
                    <td class="text-center" colspan="6">No Data Found!!</td>
                  </tr>
                </ng-container>
                <ng-template #dataTable>
                  <tr *ngFor="let recurringTaskLists of recurringTaskList | tableFilter:searchKey : selectedfield| tableSearch : objKey; index as i">
                    <td>{{i+1}}</td>
                    <td>{{recurringTaskLists.task_name}}</td>
                    <td>{{recurringTaskLists.project_name}}</td>
                    <td>{{recurringTaskLists.module_name}}</td>
                    <td>{{recurringTaskLists.task_type|titlecase}}</td>
                    <td class="text-center">
                      <span class="badge alert-success"
                        *ngIf="recurringTaskLists.task_status=='finish'">{{recurringTaskLists.task_status}}</span>
                      <span class="badge alert-primary"
                        *ngIf="recurringTaskLists.task_status=='active'">{{recurringTaskLists.task_status}}</span>
                      <span class="badge alert-danger"
                        *ngIf="recurringTaskLists.task_status=='inactive'">{{recurringTaskLists.task_status}}</span>
                      <span class="badge alert-danger"
                        *ngIf="recurringTaskLists.task_status=='stop'">{{recurringTaskLists.task_status}}</span>
                    </td>
                    <td>
                      <a class="" [routerLink]='["/manage-task/",recurringTaskLists.t_id]'><span
                          class=" edit text-success text-center"><i class="las la-edit"></i></span>
                      </a>
                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>