<div class="main-content" *ngIf="loadAllComponant">
  <div class="ctm-border-radius  grow card">
    <div id="basic1" class="card-header d-flex align-center justify-content-between">
        <div class="card_title_left">
          <h4 class="card-title  mb-0 mt-0" *ngIf="employeeList.length>0; else title"> <b><i
            class="las la-info-circle"></i>{{employeeList.length}} Employees</b></h4>
            <ng-template #title>
              <h4 class="card-title  mb-0 mt-0"> <b><i class="las la-info-circle"></i>No Employee </b></h4>
            </ng-template>
        </div>
        <div class="card_right_ d-flex align-items-center justify-content-end">
          <div class="filter_contetn">
            <div class="filter_drop_down ">
              <div class="input-group">
                <div class="form-outline">
                  <input type="text" class="form-control" (keyup)="searchType($event)" placeholder="Search" aria-label="Search" [(ngModel)]="multiSearch" id="searchdata">
                </div>
              </div>
              <button class="custom-btn btn-8 text-center" (click)="filter_status_btn()"><span><i class="las la-filter"></i> Filters</span></button>
            </div>
            <div class="drop_down_filter_list d-flex justify-content-end" [ngClass]="filter_status?'on':''">
              <!-- <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="nameSearch" id="name" (change)="deSelect($event,'name')" placeholder="Name">
                  <ng-option *ngFor="let name of nameFilterList" [value]="name">
                    {{name}}
                  </ng-option>
                </ng-select>
              </div> -->
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="emailSearch" id="email" (change)="deSelect($event,'email')" placeholder="Email">
                  <ng-option *ngFor="let email of emailFilterList" [value]="email">
                    {{email}}
                  </ng-option>
                </ng-select>
              </div>
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="designationSearch"
                  id="designation" (change)="deSelect($event,'designation')" placeholder="Designation">
                  <ng-option *ngFor="let designation of designationFilterList" [value]="designation">
                      <span *ngIf="designation ==''" class="d-none"></span>
                      <span *ngIf="designation == 'php_developer'">PHP Developer</span>
                      <span *ngIf="designation =='senior_php_developer'">Senior PHP Developer</span>
                      <span *ngIf="designation =='junior_php_developer'">Junior PHP Developer</span>
                      <span *ngIf="designation =='software_developer'">Software Developer</span>
                      <span *ngIf="designation =='junior_software_developer'">Junior Software Developer</span>
                      <span *ngIf="designation =='senior_software_developer'">Senior Software Developer</span>
                      <span *ngIf="designation =='graphic_designer'">Graphic Designer</span>
                      <span *ngIf="designation =='junior_graphic_designer'">Junior Graphic Designer</span>
                      <span *ngIf="designation =='senior_graphic_designer'">Senior Graphic Designer</span>
                      <span *ngIf="designation =='manager'">Manager</span>
                      <span *ngIf="designation =='executive'">Executive</span>
                      <span *ngIf="designation =='web_developer'">Web Developer</span>
                      <span *ngIf="designation =='back_office_executive'">Back Office Executive</span>
                      <span *ngIf="designation =='senior_executive_hr_and_admin'">Senior Executive HR and Admin</span>
                      <span *ngIf="designation =='digital_marketing_and_content_writer'">Digital Marketing & Content Writer</span>
                      <span *ngIf="designation =='junior_digital_marketing_and_content_writer'">Junior Digital Marketing & Content Writer</span>
                      <span *ngIf="designation =='senior_digital_marketing_and_content_writer'">Senior Digital Marketing & Content Writer</span>
                      <span *ngIf="designation =='junior_web_developer'">Junior Web Developer</span>
                      <span *ngIf="designation =='senior_web_developer'">Senior Web Developer</span>
                  </ng-option>
                </ng-select>
              </div>
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="roleSearch"
                  (change)="deSelect($event,'roles')" placeholder="Role">
                  <ng-option *ngFor="let roles of roleFilterList" [value]="roles">
                    {{roles}}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <a routerLink="/add-employee" class="custom-btn btn-8 text-center"><span><i class="las la-plus"></i>Add employee</span> </a>
        </div>
    </div>
    <div class="card-body">
      <div class="table-back employee-office-table">
        <div class="table-responsive"
        infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="500"
          (scrolled)="onScroll()"
          [scrollWindow]="false">
          <table class="table custom-table table-hover table-hover" id="empolyeeId">
            <thead>
              <tr>
                <th>Id</th>
                <th>name</th>
                <th>Email</th>
                <th>Designation</th>
                <th>Role</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody *ngIf="employeeList!==undefined && employeeList!==null">
              <tr *ngFor="let employeeLists of employeeList | tableFilter:searchKey : selectedfield | tableSearch:objKey">
                <td>{{employeeLists.empid}}</td>
                <td> <a [routerLink]="['/manage-employee/',employeeLists.u_id]">{{employeeLists.name}}</a></td>
                <td>{{employeeLists.email}}</td>
                <td>
                  <p *ngIf="employeeLists.designation =='software_developer'">Software Developer</p>
                  <p *ngIf="employeeLists.designation =='junior_software_developer'">Junior Software Developer</p>
                  <p *ngIf="employeeLists.designation =='senior_software_developer'">Senior Software Developer</p>
                  <p *ngIf="employeeLists.designation =='graphic_designer'">Graphic Designer</p>
                  <p *ngIf="employeeLists.designation =='junior_graphic_designer'">Junior Graphic Designer</p>
                  <p *ngIf="employeeLists.designation =='senior_graphic_designer'">Senior Graphic Designer</p>
                  <p *ngIf="employeeLists.designation =='manager'">Manager</p>
                  <p *ngIf="employeeLists.designation =='executive'">Executive</p>
                  <p *ngIf="employeeLists.designation =='web_developer'">Web Developer</p>
                  <p *ngIf="employeeLists.designation =='junior_web_developer'">Junior Web Developer</p>
                  <p *ngIf="employeeLists.designation =='senior_web_developer'">Senior Web Developer</p>
                  <p *ngIf="employeeLists.designation =='php_developer'">PHP Developer</p>
                  <p *ngIf="employeeLists.designation =='senior_php_developer'">Senior PHP Developer</p>
                  <p *ngIf="employeeLists.designation =='junior_php_developer'">Junior PHP Developer</p>
                  <p *ngIf="employeeLists.designation =='digital_marketing_and_content_writer'">Digital Marketing & Content Writer</p>
                  <p *ngIf="employeeLists.designation =='junior_digital_marketing_and_content_writer'">Junior Digital Marketing & Content Writer</p>
                  <p *ngIf="employeeLists.designation =='senior_digital_marketing_and_content_writer'">Senior Digital Marketing & Content Writer</p>
                  <p *ngIf="employeeLists.designation =='back_office_executive'">Back Office Executive</p>
                  <p *ngIf="employeeLists.designation =='senior_executive_hr_and_admin'">Senior Executive HR and Admin</p>
                </td>
                <td>{{employeeLists.roles.toString()}}</td>
                <td> <a class="" [routerLink]="['/manage-employee/',employeeLists.u_id]"><span class="edit text-success text-center"><i class="las la-edit"></i></span></a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
