<div class="main-content" *ngIf="loadAllComponant">
  <div class="quicklink-sidebar-menu ctm-border-radius  bg-white  grow">
    <div class="ctm-border-radius  grow card" *ngIf="taskList != undefined || taskList != null || taskList !='' ">
      <div id="basic1" class="card-header d-flex align-center justify-content-between">
        <div class="card_title_left">
          <h4 class="card-title  mb-0 mt-2" *ngIf="taskList.length > 0;else title"> 
            <b><i class="las la-info-circle"></i>{{taskList.length}} Tasks Available </b>
          </h4>
          <ng-template #title>
            <h4 class="card-title  mb-0 mt-2"> <b><i class="las la-info-circle"></i> No Task Available Right Now</b></h4>
          </ng-template>
        </div>
        <div class="card_right_ d-flex align-items-center justify-content-end">
          <div class="filter_contetn">
            <div class="filter_drop_down align-center justify-content-between ">
              <div class="form-outline col-md-6">
                <input type="text" class="form-control" (keyup)="searchType($event)" placeholder="Search" aria-label="Search"
                  [(ngModel)]="multiSearch" id="searchdata">
              </div>
              <button class="custom-btn btn-8 text-center" (click)="filter_status_btn()"><span><i class="las la-filter"></i>
                  Filters</span></button>
            </div>
            <div class="drop_down_filter_list d-flex justify-content-end" [ngClass]="filter_status?'on':''">
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="nameSearch"
                  (change)="deSelect($event,'t_name')" placeholder="Task Name">
                  <ng-option *ngFor="let t_name of nameFilterList" [value]="t_name">
                    {{t_name}}
                  </ng-option>
                </ng-select>
              </div>
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="typeSearch"
                  (change)="deSelect($event,'m_type')" placeholder="Module Type">
                  <ng-option *ngFor="let m_type of typeFilterList" [value]="m_type">
                    {{m_type}}
                  </ng-option>
                </ng-select>
              </div>
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="statusSearch"
                  (change)="deSelect($event,'t_status')" placeholder="Task Status">
                  <ng-option *ngFor="let t_status of statusFilterList" [value]="t_status">
                    {{t_status == 1?'Active':'Inative'}}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
          <ul class="nav nav-tabs  border-0 tab-list-emp">
            <li class="nav-item pl-3">
              <a routerLink="/create-automated-task" class="custom-btn btn-8 text-center"><span>
                  <i class="las la-plus"></i>Add Task</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    
      <div class="card-body">
        <div class="table-back employee-office-table">
          <div class="table-responsive">
            <table class="table custom-table table-hover table-hover" id="empolyeeId">
              <thead>
                <tr>
                  <th>SL_No</th>
                  <th>Task Name</th>
                  <th>Module Type</th>
                  <th>Task Effort</th>
                  <th>Task Type</th>
                  <th class="text-center">Status</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody  *ngIf="taskList!==undefined && taskList!==null">
                <tr *ngFor="let taskLists of taskList| tableFilter:searchKey : selectedfield | tableSearch : objKey; index as i">
                  <td>{{i+1}}</td>
                  <td>{{taskLists.t_name}}</td>
                  <td>{{taskLists.m_type}}</td>
                  <td>{{taskLists.t_effort}}</td>
                  <td>{{taskLists.t_type}}</td>
                  <td class="text-center">
                    <button (click)="onToggle(taskLists.id,taskLists.t_status)" type="button" [ngClass]="taskLists.t_status=='1'?'btn btn-outline-success':'btn btn-outline-danger'">{{taskLists.t_status=='1'?'Active':'Inactive'}}</button>
                    <!-- <span class="badge alert-success"
                      *ngIf="taskLists.t_status=='0'">Inactive</span>
                    <span class="badge alert-primary"
                      *ngIf="taskLists.t_status=='1'">Active</span>
                    <span class="badge alert-danger"
                      *ngIf="taskLists.t_status=='2'">Finished</span>
                    <span class="badge alert-warning text-dark"
                      *ngIf="taskLists.t_status=='3'">Stopped</span> -->
                  </td>
                  <td>{{taskLists.created_at | date}}</td>
                  <td>
                    <a>
                      <span class=" edit text-success text-center">
                      <i class="las la-trash-alt" (click)="deleteTask(taskLists.id)"></i></span>
                    </a>
                  </td>
                  <!-- <td>{{taskLists.assign_by}}</td> -->
                  <!-- <td>
                    <a class="" [routerLink]='["/manage-task/",encodeURIParam(taskLists.t_id)]'><span
                        class=" edit text-success text-center"><i class="las la-pen"></i></span>
                    </a>
                  </td> -->
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>