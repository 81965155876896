import { Component, OnInit ,ChangeDetectionStrategy,ViewChild,TemplateRef,Input} from '@angular/core';
import { DropEvent } from 'ng-drag-drop';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { RestService } from 'src/app/rest.service';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { startOfDay, endOfDay, subDays, addDays, endOfMonth, isSameDay, isSameMonth, addHours } from 'date-fns';
import { Subject } from 'rxjs';
import { PagesService } from '../pages.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { CalendarEvent, CalendarEventAction, CalendarEventTimesChangedEvent, CalendarView } from 'angular-calendar';

@Component({
  selector: 'app-task-dashboard',
  templateUrl: './task-dashboard.component.html',
  styleUrls: ['./task-dashboard.component.scss']
})
export class TaskDashboardComponent implements OnInit {
  
  @ViewChild('timesheetdata') timesheetdata: any;

  public popUpStatus     = '';
  public popOn           = false;
  public remimderForm!   : FormGroup;
  public timesheetForm!  : FormGroup;
  public taskRemimderForm!: FormGroup;
  public tagForm!        : FormGroup;
  public userList        : any;
  public selectUser      : any;
  public selectedUserList: any[] = [];
  public selectedUsers   : any = [];
  public srcWidth        : any;
  public srcWidthVal     : any;
  public getReminders    : any;
  public remiderData     : any;
  public reminderRow     : any;
  public reminderStatus  : any;
  public title           = 'Task Reminder';
  public getDate         : any;
  public empId           = '';
  public userRoles       : any;
  public uname           : any;
  public dropdownMenu    : any;
  public countAdvanceDay : any;
  public taskSearch      :any;
  public moduleSearch    :any;
  public remDropdownMenu : any;
  public userId = 0;
  public actionPops      = false;
  public taskPopUp       = false;
  public isAdmin         = false;
  public isShown         = false;
  public isShow          = true;
  public isShowBtn       = true;
  public remStatus       = false;
  public comments = '';
  public startDate       : any;
  public getToday        : any = new Date();
  public today           : any = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  public now             : any;
  public taskList        : any;
  public objKey          : any;
  public loadAllComponant= false;
  public rStatus         = '';
  public id              = '';
  public switchStatus    = 'timesheet';
  public task_id         :number = 0;
  public multiSearch     : string = '';
  public r_task_id       = '';
  public minDateLimit    : any;
  public viewAdEventDate : Date = new Date;
  public year            : any;
  public month           : any;
  public day             : any;
  public list1           : any[] = [];
  public list2           : any[] = [];
  public list3           : any[] = [];
  public unAssignTasks   : any[] = [];
  public prevDay         = true;
  public nxtDay          = false;
  public payload         : any;
  public docStatus       : any;
  public taskOverviewData: any = [];
  public Math            = Math;
  public assignedEmp     : any = [];
  public empList         = <any>[];
  public itemList        = <any>[];
  public taskFilterList  = <any>[];
  public moduleFilterList= <any>[];
  public getMessage: any = [];
  public taskRowId       = 0;
  public userApiCall     = 1;
  public birthdayChecke  = true;
  public timeSheetToSave = {
    start      : <any>'',
    end        : <any>'',
    date       : <any>'',
    task_id    : '',
    description: '',
    reason     : ''
  };
  public timeSheetData  = false;
  public refresh        = new Subject<void>();
  public events         : CalendarEvent[] = [];
  public activeDayIsOpen: boolean = false;
  public taskObj      = {
    t_name: <any>'',
    p_name: <any>'',
    m_name: <any>'',
    t_id  : <any>'',
    m_id  : <any>'',
    p_id  : <any>''
  }
  public showOverView     = false;
  public showActivity     = false;
  public showCalender     = false;
  public showTimesheet    = false;
  public sidePanel        = false;
  public timeSheetEffInfo = <any>[];
  public total_effort     = 0;
  public taskTimeList     = <any>[];
  public view             : CalendarView = CalendarView.Month;
  public CalendarView     = CalendarView;
  public viewDate: Date   = new Date();
  public filter_status    = false;
  public nameFilterList   = <any>[];
  public searchKey        = '';
  public selectedfield    = '';
  public nameSearch       : any;
  public selectedUser     = '';
  public enablePanelTab   = true;
  public hsl              : any;
  public remindTask       = false;
  public defaultOption    = 'Remind Me';
  public time             : any;
  public taggedUsers      : any = [];
  public selectedMembers  :any = [];
  public selectedIds      :any = [];
  public openTagModal     = true;
  public taskName         = '';
  public mentionKey:any ='';
  public selectedIdsForMsgSave:any = [];
  public openModel = true;
  
  constructor(
    private modalService: NgbModal,
    public rest        : RestService,
    private formBuilder: FormBuilder,
    private route      : ActivatedRoute,
    private router     : Router,
    public pageService : PagesService,
    private localSt    : LocalStorageService,
    private toaster    : ToastrService,
    public ngxService  : NgxUiLoaderService

  ) { }

  ngOnInit(): void {
    this.remimderForm = this.formBuilder.group({
      title        : [null, [Validators.required]],
      description  : [null, [Validators.required]],
      reminder_date: [null, [Validators.required]],
      r_id         : [null],
      memberlist   : [null],
      reminder_time: [null],
      task_id      : [null],
      priority     : [null, [Validators.required]]
    });

    this.timesheetForm = this.formBuilder.group({
      task_id    : [null, [Validators.required]],
      description: [null, [Validators.required]],
      start_time : [null, [Validators.required]],
      end_time   : [null, [Validators.required]],
    });

    this.taskRemimderForm = this.formBuilder.group({
      title        : [null, [Validators.required]],
      description  : [null, [Validators.required]],
      reminder_date: [null, [Validators.required]],
      priority     : [null, [Validators.required]],
      task_id      : [null],
      reminder_time: [null]
    });

    // this.getReminder(this.empId);
    this.getUnassignTasks();
    this.getAllTaks();
    let udata = this.localSt.retrieve('user');
    if (udata != null) {
      this.uname = udata.data.name;
      // if(this.birthdayChecke == true){
      //   this.birthdayBuddy(this.uname,udata.data.empid);
      // }
      this.userRoles = udata.data.roles;
      if (this.userRoles.includes('Admin')) {
        this.isAdmin = true;
      }
    }
    this.setMinMaxDate();
  }
  actionPopUp(val: any) {
  }

  encodeURIParam(param: any) {
    if(param !== '' || param !== null)
    {
      return param.replace('/', '_');
    }
  }
  onPop() {
    this.popOn = !this.popOn;
    this.isShown = false;
    this.setMinMaxDate();
    this.getMembers();
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  viewReminderData(id: any, status: any) {
    if (id != '' && id != '') {
      if (status != 0) {
        this.toaster.warning("Only upcoming reminders are allowed to edit!")
      }
      this.isShown = true;
      this.rest.getData({ r_id: id }, 'getreminderbyid').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.reminderRow = data.data;
            this.startDate = {
              year: parseInt(formatDate(this.reminderRow.reminder_date, 'yyyy', 'en')),
              month: parseInt(formatDate(this.reminderRow.reminder_date, 'MM', 'en')),
              day: parseInt(formatDate(this.reminderRow.reminder_date, 'dd', 'en'))
            };
            this.remimderForm.patchValue({
              title: this.reminderRow.title,
              description: this.reminderRow.description,
              reminder_date: this.startDate,
              priority: this.reminderRow.priority,
              r_id: this.reminderRow.id,
              memberlist: this.reminderRow.memberlist
            })
            this.popOn = !this.popOn;
          } else {
            this.toaster.success(data.message)
          }
        }
      })
    }
  }

  addEditReminder(form: FormGroup) {
    if (form.invalid) {
      return;
    } else {
      let apiPayload = { ...form.value };
      if (apiPayload.r_id != null) {
        apiPayload.reminder_date = this.dateConvert(apiPayload.reminder_date)
        this.rest.getData(apiPayload, 'updatereminder').subscribe({
          next: (data) => {
            if (data.state == '200') {
              this.toaster.success(data.message);
              this.getReminder(this.empId);
              form.reset();
            } else {
              this.toaster.success(data.message)
            }
          }
        })
      } else {
        apiPayload.reminder_date = this.startDate;
        this.rest.getData(apiPayload, 'createreminder').subscribe({
          next: (data) => {
            if (data.state == '200') {
              this.toaster.success(data.message);
              this.getReminder(this.empId);
              location.reload();
            } else {
              this.toaster.success(data.message)
            }
          }
        })
      }
    }
  }

  reminderStatusByDate(rdate:Date) {
    if(this.today > rdate){
      this.rStatus = '#Expired';
    }else if(this.today < rdate){
      this.rStatus = '#Upcomming';
    }else {
      this.rStatus = '#Today';
    }
    return this.rStatus;
  }

  taskInfo(t:any = '') {
    this.taskObj.t_name = t.task_name;
    this.taskObj.t_id   = t.t_id;
    this.sidePanel      = !this.sidePanel;
    this.sidePanel      = true;
    this.task_id        = t.id;
    if (this.task_id !== undefined || this.task_id > 0) {
      this.selectedTab('timesheet');
    }
  }

  pokeUser(t:any = '') {
      let payload = {'t_id':t.t_id};
      this.rest.getData(payload, 'pokeuser').subscribe({
        next: (data) => {
          this.toaster.success(data.message);
          this.getAllTaks();
        },
        error: (e) => {
          this.loadAllComponant = true;
          this.toaster.warning('Something went wrong!!');
        },
      })
  }

  closePanel(){
    this.sidePanel = false;
    this.assignedEmp = [];
    this.taskOverviewData = [];
  }

  directTaskView(i:any = ''){
    if(i.task_status == 'inactive'){
      this.enablePanelTab = false;
    }
    this.r_task_id      = i.t_id;
    this.taskObj.t_name = i.task_name;
    this.taskObj.t_id   = i.t_id;
    this.sidePanel      = !this.sidePanel;
    this.sidePanel      = true;
    this.task_id        = i.id;
    if (this.task_id !== undefined || this.task_id > 0) {
      this.selectedTab('overview');
    }
    this.getTaskData(this.taskObj.t_id);
  }

  selectedTab(tab: any = '') {
    if (tab == 'overview') {
      this.getTimeSheetData(this.taskObj.t_id);
      this.showOverView  = true;
      this.switchStatus  = tab;
      this.showTimesheet = false;
      this.showCalender  = false;
      this.showActivity  = false;
    } else if (tab == 'activity') {
      this.switchStatus = tab;
      this.showActivity  = true;
      this.showOverView  = false;
      this.showTimesheet = false;
      this.showCalender  = false;
    } else if (tab == 'calender') {
      this.switchStatus = tab;
      this.showCalender  = true;
      this.showOverView  = false;
      this.showTimesheet = false;
      this.showActivity  = false;
    } else if (tab == 'timesheet') {
      this.getTaskData(this.taskObj.t_id);
      this.getTimeSheetData(this.taskObj.t_id);
      this.showTimesheet = true;
      this.showOverView  = false;
      this.showCalender  = false;
      this.showActivity  = false;
      this.switchStatus  = tab;
    } else if(tab == 'assign_member') {
      this.getTaskData(this.taskObj.t_id);
      this.getTimeSheetData(this.taskObj.t_id);
      this.showCalender  = true;
      this.showTimesheet = false;
      this.showOverView  = false;
      this.showActivity  = false;
      this.switchStatus  = tab;
    }else {
      this.showTimesheet = true;
      this.switchStatus  = tab;
    }
  }

  getTaskData(id: string) {
    if (id == '' || id == undefined || id == null) {
      return;
    } else {
      this.payload = {
        taskID: id,
      };
      this.itemList = [];
      this.rest.getData(this.payload, 'gettasktnfo').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.taskRowId = data.data.taskRowId;
            this.getComments(this.taskRowId);
            this.taskOverviewData = data.data;
            let tempArr = [];
            for(let user of this.taskOverviewData.asigned_data){
              tempArr.push(user.name);
            }
            this.taskOverviewData.projectUsers.forEach((element: any) => {
              let tmpObj    = { id: element.id, name: element.name };
              this.itemList.push(tmpObj);
            });
            this.empList = this.pageService.loadMore(
              this.taskOverviewData.asigned_data
            );
            this.assignedEmp = tempArr;
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.loadAllComponant = true;
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }

  getComments(id: number) {
    if (id > 0) {
      this.rest.getData({ id }, 'getcomments').subscribe({
        next: (data) => {
          if (data.state == '200') {
            let prevLen = this.getMessage.length;
            if (prevLen !== 0 && prevLen !== data.data.length) {
              let checkNtify = data.data[data.data.length - 1];
              if (this.userId !== checkNtify.member_id) {
                this.pageService.notifyMe('Task', checkNtify.message);
              }
            }
            this.getMessage = data.data;
          }else{
            this.getMessage = [];
          }
        },
        error: (e) => {
          //this.toaster.warning('Something went wrong');
        },
      });
    }
  }

  saveComments() {
    if (this.comments != '' && this.taskRowId > 0) {
      this.rest
        .getData(
          { t_id: this.taskRowId, message: this.comments, tagged_users:this.selectedIdsForMsgSave },
          'savecomments'
        )
        .subscribe({
          next: (data) => {
            this.ngxService.stop();
            if (data.state == '200') {
              this.selectedIdsForMsgSave = [];
              this.openTagModal = !this.openTagModal;
              this.getComments(this.taskRowId);
              this.toaster.success("Sent!");
              this.sendMailToTaggdedUsers(this.selectedIds,this.comments);
              this.selectedIds = [];
              this.comments = '';
            } else {
              this.toaster.warning(data.message);
            }
          },
          error: (e) => {
            this.toaster.warning('Something went wrong');
          },
        });
    }
  }

  // calender tab //
  closeOpenMonthViewDay() {
    //this.getTimesheet();
    this.activeDayIsOpen = false;
  }

  addTimesheetData(form: FormGroup) {
    if (form.value.description == '' || form.value.description == null) {
      this.toaster.warning("Description field is required!");
      return;
    } else if (form.value.start_time == '' || form.value.start_time == '') {
      this.toaster.warning("Start time and End time fields are required!");
      return;
    } else {
      let formData = { ...form.value };
      if (formData.task_id != null) {
        let apiPayload = {
          task_id: formData.task_id,
          start_time: formData.start_time,
          end_time: formData.end_time,
          entry_date: formatDate(this.viewAdEventDate, 'yyyy-MM-dd', 'en-US'),
          comment: formData.description
        }
        this.rest.getData(apiPayload, 'addtimesheetself').subscribe({
          next: (data) => {
            this.ngxService.stop();
            if (data.state == '200' || data.state == 200) {
              this.toaster.success(data.message);
              this.timesheetForm.controls['description'].reset();
              this.timesheetForm.controls['start_time'].reset();
              this.timesheetForm.controls['end_time'].reset();
              this.getAllTaks();
            } else {
              this.toaster.warning("Something went wrong!!")
            }
          },
          error: (e) => {
            this.toaster.warning(e.error.message);
            this.ngxService.stop();
          },
        })
      }
    }
  }

  getTimeSheetData(id: string) {
    if (id != '' && id != undefined && id != null){
      this.rest.getData({ t_id: id }, 'getsingletimedetails').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.taskTimeList = data.data.timeSheet;
            this.timeSheetEffInfo = data.data.effortData;
            this.total_effort = data.data.total_effort;
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }

  getReminder(id: any) {
    this.rest.getData({}, 'getreminder').subscribe({
      next: (data) => {
        this.getReminders = data.data;
        let hours = this.getToday.getHours();
        let minutes = this.getToday.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = hours < 10 ? '0' + hours : hours;
        // appending zero in the start if hours less than 10
        minutes = minutes < 10 ? '0' + minutes : minutes;
        this.now = hours + ':' + minutes + ' ' + ampm;
      }
    })
  }

  getMembers() {
    this.rest.getData({}, 'getemployees').subscribe({
      next: (data) => {
        if(data.state == '200'){
          this.userList = data.data.filter((obj:any) => obj.status === 1);
        }
      },
      error: (e) => {
        console.log(e);
        //this.toaster.warning('Something wend wrong!');
      },
    })
  }

  onSelected(){

  }

  setMinMaxDate() {
    let today = new Date();
    let tomorrowDate = today.getDate();
    today.setDate(tomorrowDate);
    this.minDateLimit = {
      year: parseInt(formatDate(today, 'yyyy', 'en')),
      month: parseInt(formatDate(today, 'MM', 'en')),
      day: parseInt(formatDate(today, 'dd', 'en'))
    }
  }

  dateConvert(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    const convertDate = year + '-' + month + '-' + day;
    return convertDate;
  }

  onDateSelect(event: any) {
    this.isShown = true;
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    this.startDate = year + "-" + month + "-" + day;
  }

  completeRem(id: any) {
    if (id != '' && id != undefined) {
      this.rest.getData({ r_id: id }, 'completereminder').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.reminderRow = data.data;
            this.getReminder(this.empId);
          } else {
            this.toaster.warning(data.message)
          }
        },
        error: (e) => {
          this.toaster.warning('Something wend wrong!');
        },
      })
    }
  }

  taskDateBox(){
    this.remindTask = !this.remindTask;
  }

  deleteRem(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      width: 400,
    }).then((result) => {
      if (result.isConfirmed) {
        if (id != '' && id != undefined) {
          this.rest.getData({ r_id: id }, 'deletereminder').subscribe({
            next: (data) => {
              if (data.state == '200') {
                this.reminderRow = data.data;
                this.toaster.success(data.message)
                this.getReminder(this.empId);
              } else {
                this.toaster.warning(data.message)
              }
            }
          })
        }
      }
    })
  }

  reminderMenu() {
    this.remStatus = true;
  }
  actionPop() {
    this.actionPops = !this.actionPops;
    this.dropdownMenu = "0";
  }

  taskOverView() {
    this.taskPopUp = !this.taskPopUp;
    this.dropdownMenu = "0";
  }

  getAllTaks() {
    this.rest.getData({}, 'mydashboardinfo').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        this.taskList = data.data;
        this.list1 = data.data.pendingTask;
        this.list2 = data.data.onGoingTask;
        this.list3 = data.data.completedTask;
      },
      error: (e) => {
        this.loadAllComponant = true;
        this.toaster.warning('Something went wrong!!');
      },
    })
  }

  // ---------------------------------drag and drop------------------------------

  onList1Drop(e: DropEvent) {
    this.updateTask(e.dragData, 'inactive');
  }

  onList2Drop(e: DropEvent) {
    this.updateTask(e.dragData, 'active');
  }

  onList3Drop(e: DropEvent) {
    this.updateTask(e.dragData, 'finish');
  }

  removeItem(item: any, list: Array<any>) {
    let index = list.map(function (e) {
      return e.name
    }).indexOf(item.name);
    list.splice(index, 1);
  }

  updateTask(task: any, status: string) {
    let payload = { 'task_status': status, 't_id': task.t_id };
    this.rest.getData(payload, 'changetaskstatus').subscribe({
      next: (data) => {
        this.getAllTaks();
      },
      error: (e) => {
        this.loadAllComponant = true;
        this.toaster.warning('Something went wrong!!');
      },
    })
  }

  dateChangePop(ev: any) {
    if (ev == "prev") {
      this.viewAdEventDate = new Date(this.viewAdEventDate.setDate(this.viewAdEventDate.getDate() - 1));
      this.prevDay = false;
      this.nxtDay = true;
    } else if (ev == "next") {
      this.prevDay = true;
      this.nxtDay = false;
      this.viewAdEventDate = new Date(this.viewAdEventDate.setDate(this.viewAdEventDate.getDate() + 1));
    }
  }

  addEventBtn() {
    //this.timeSheetData   = !this.timeSheetData;
    this.viewAdEventDate = new Date();
    this.nxtDay = false;
    this.prevDay = true;
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New Task',
        start: startOfDay(this.viewAdEventDate),
        end: endOfDay(this.viewAdEventDate),
        meta: 'taskname',
      },
    ];
  }

  //------------------------------------------filter and search------------------------------------

  changeUser(id: any) {
    let uid = id.target.value
    if (uid == undefined || uid == '' || uid == null || uid == "All") {
      this.getAllTaks();
    }else{
      this.ngxService.start();
      this.rest.getData({ u_id: uid }, 'usertasks').subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.loadAllComponant = true;
          this.taskList = data.data;
          this.list1 = data.data.pendingTask;
          this.list2 = data.data.onGoingTask;
          this.list3 = data.data.completedTask;
        },
        error: (e) => {
          this.ngxService.stop();
          this.loadAllComponant = true;
          this.toaster.warning('Something went wrong!!');
        },
      })
    }
  }

  convertColor(string:any) {
    this.hsl = this.pageService.convertNameToColor(string);
  }

  convertToLetter(letter:any) {
    this.convertColor(letter);
    return this.pageService.getFirstLetters(letter);
  }

  // -----------------------------------------task reminder----------------------------------

  addTaskReminder(form: FormGroup) {
    if (form.invalid) {
      return;
    } else {
      let apiPayload = { ...form.value };
      apiPayload.reminder_date = this.dateConvert(apiPayload.reminder_date);
      apiPayload.reminder_date = this.startDate;
      this.rest.getData(apiPayload, 'createreminder').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.toaster.success("Reminder set!!");
            this.getReminder(this.empId);
            this.taskRemimderForm.reset();
          } else {
            this.toaster.success(data.message)
          }
        },
        error: (e) => {
          this.toaster.warning('Something went wrong!!');
        },
      })
    }
  }

  getScreenSize() {
    if(window.innerWidth <= 900){
      this.srcWidth =1;
      this.srcWidthVal =1;
    }else{
      this.srcWidth =3;
      this.srcWidthVal =2;
    }
  }

  docAction(ev: any) {
    if (this.docStatus == ev) {
      this.docStatus = 0;
    } else {
      this.docStatus = ev;
    }
  }

  deleteUser(id: any) {
    if (id !== '' && this.taskRowId > 0) {
      this.ngxService.start();
      this.rest
        .getData({ users: id, t_id: this.taskRowId }, 'removetaskuser')
        .subscribe({
          next: (data) => {
            this.ngxService.stop();
            if (data.state == '200') {
              this.toaster.success(data.message);
              this.getTaskData(this.taskObj.t_id);
              this.selectedUsers = [];
              this.userApiCall = 0;
            } else {
              this.toaster.warning(data.message);
            }
          },
          error: (e) => {
            this.ngxService.stop();
            this.toaster.warning('Something went wrong');
          },
        });
    }
  }

  select_user(ev: any,name:any) {
    Swal.fire({
      title: 'Add '+name+' ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.selectedUsers.push(ev);
        if (this.selectedUsers.length > 0) this.addUsers();
      }
    });
  }

  addUsers() {
    let apiPayload = { t_id: 0, users: [] };
    apiPayload.t_id = this.task_id;
    apiPayload.users = this.selectedUsers;
    this.rest.getData(apiPayload, 'addtaskmember').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.getUnassignTasks();
          this.toaster.success(data.message);
          this.selectedUsers = [];
          this.userApiCall = 0;
          this.getTaskData(this.taskObj.t_id);
          this.getAllTaks();
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning('Something went wrong');
      },
    });
  }

  showEmp() {
    this.isShowBtn = false;
    setTimeout(() => {
      this.isShowBtn = true;
      this.empList = this.pageService.loadMore(
        this.taskOverviewData.asigned_data,
        this.empList.length
      );
    }, 1000);
  }

  // birthdayBuddy(name: any, emp_id: any) {
  //   if (name !== undefined && emp_id !== undefined) {
  //     let payload = {
  //       emp_id:emp_id
  //     }
  //     this.rest.getData(payload, 'birthdaychecker').subscribe({
  //       next: (data) => {
  //         if (data.state == '200') {
  //           Swal.fire({
  //             title: "Wishing you many many happy returns of the day, " + name,
  //             //title: "Custom width, padding, color, background.",
  //             width: 600,
  //             padding: "3em",
  //             color: "#f28aeb",
  //             background: "#fff url(https://img.freepik.com/free-vector/beautiful-birthday-party-with-pink-flying-balloons-confetti-background_1035-23631.jpg?size=626&ext=jpg&ga=GA1.1.1300559719.1701496893&semt=ais)",
  //             backdrop: `
  //             rgba(01,0,123,0.4)
  //             url("assets/")
  //             left top
  //             no-repeat
  //           `
  //           });
  //           this.birthdayChecke = false;
  //         }
  //       },
  //     });
  //   }
  // }

  addMe(tsk_id:any){
    let assignTaskPayload = {
      t_id:tsk_id
    }
  Swal.fire({
    title: 'Do you want to assign yourself ?',
    icon: 'warning',
    showCancelButton: true,
    cancelButtonText: "No",
    confirmButtonColor: '#3085d6',
    cancelButtonColor: '#ea556f',
    iconColor:"#ea556f",
    width: '400px',
    confirmButtonText: 'Yes',
  }).then((result) => {
    if (result.isConfirmed) {
      this.rest.getData(assignTaskPayload, 'addmetask').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.getUnassignTasks();
            this.getAllTaks();
            this.userApiCall = 0;
            this.getTaskData(this.taskObj.t_id);
            this.toaster.success(data.message);
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.toaster.warning('Something went wrong');
        },
      });
    }
  });
  }

  

  getUnassignTasks() {
    this.rest.getData({}, 'getunassigntasks').subscribe({
      next: (data) => {
        if(data.state == '200'){
          this.unAssignTasks = data.data;
          this.createFilterData();
        }else{
          this.unAssignTasks = [];
        }
      },
      error: (e) => {
        console.log(e);
        this.toaster.warning('Something went wrong!!');
      },
    })
  }

  deSelect(event:any,filedType:string){
    this.selectedfield = filedType;
    if(filedType==='m_name'){
      this.searchKey = this.moduleSearch;     
    }   
  }

  filter_status_btn(){
    this.filter_status = !this.filter_status;
  }
  createFilterData(){
    let mFilterList=[];
  
    for (let task of  this.unAssignTasks) {
        mFilterList.push(task.modules);
    }
  
    // Making all array unique
    this.moduleFilterList = [...new Set(mFilterList)];
  
  }

  searchType(event: any) {
    let searchkey = event.target.value;
    if (searchkey.length > 1) {
      this.objKey = searchkey;
    } else if (searchkey.length == 0) {
      this.objKey = searchkey;
    }
  }
  // task member tagging

  mentionEmp(u: any) {
    this.mentionKey = u.target.value;
    if (this.empList?.length > 2) {
      if (this.mentionKey.includes(" @") && this.openModel) {
        if (this.openTagModal) {
          this.openModal();
        }
        this.tagForm = this.formBuilder.group({
          tagged_user: [null],
        });
      }
    }
  }

  @ViewChild('editModal') public editModal : |TemplateRef<any>|undefined;
  openModal(){
    this.modalService.open(this.editModal);
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  tagMembers(form: FormGroup) {
    if (form.invalid) {
      return;
    }
    this.isShow = false;
    let apiPayload = {tagged_user: []};
    apiPayload.tagged_user = this.selectedIds;
    this.rest.getData(apiPayload, 'taggedmembers').subscribe({
      next: (data) => {
        this.isShow = true;
        if (data.state == '200') {
          this.openTagModal = !this.openTagModal;
          let parentChecked = data.data;
          parentChecked.forEach((element: any) => {
            this.selectedMembers.push(element.name);
            this.selectedIdsForMsgSave.push(element.id);
          });
          let prevComments = this.comments;
          this.comments = prevComments + this.selectedMembers.toString();
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.isShow = true;
        this.toaster.warning('Something went wrong');
      },
    });
  }

  
  sendMailToTaggdedUsers(ids:any=[],t_message:any='') {
    let taskUrl = "https://erp.bookyup.com/manage-task/"+this.route.snapshot.params['id'];
    let taskname = this.taskObj.t_name;
    let message = t_message;
    let taggUserPayload = {
      tagged_user:ids,
      task_name:taskname,
      t_link:taskUrl,
      t_msg:message
    }
    this.rest.getData(taggUserPayload,'sendmailtotaggedmembers').subscribe({
      next: (data) => {},
      error: (e) => {
        console.log(e);
      },
    });
  }

  

    /* This function is used to delete unassigned task */
    deleteUnassignTask(id: string) {
      Swal.fire({
        title: 'Do you really want to delete this task?',
        // text: "You won't be able to revert this!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#ea556f',
        iconColor:"#ea556f",
        confirmButtonText: 'Yes',
      }).then((result) => {
        if (result.isConfirmed) {
          if (id != '' && id != undefined && id != null) {
            this.ngxService.start();
            this.rest.getData({ t_id: id }, 'deletetask').subscribe({
              next: (data) => {
                this.ngxService.stop();
                if (data.state == '200') {
                  this.toaster.success(data.message);
                  this.getUnassignTasks();
                } else {
                  this.toaster.warning(data.message);
                }
              },
              error: (e) => {
                console.log(e);
                this.toaster.warning('Something went wrong');
              },
            });
          } else {
            console.log('Empty');
          }
        }
      });
    }

}
