<div class="main-content" *ngIf="loadAllComponant">
  <div class="ctm-border-radius  grow card">
    <div id="basic1" class="card-header d-flex align-center justify-content-between">
      <div class="card_title_left">
        <h6 class="card-title  mb-0 mt-0" *ngIf="moduleList.length>0; else title"> <b><i class="las la-info-circle"></i>
          {{moduleList.length}} Modules are showing </b></h6>
      <ng-template #title>
        <h6 class="card-title  mb-0 mt-2"> <b><i class="las la-info-circle"></i> No Project Module Available Right Now</b>
        </h6>
      </ng-template>
      </div>
      <div class="card_right_ d-flex align-items-center justify-content-end">
        <div class="filter_contetn">
          <div class="filter_drop_down d-flex justify-content-between">
            <div class="form-outline">
              <input type="text" class="form-control" (keyup)="searchType($event)" placeholder="Search By Module Name" aria-label="Search By Module Name"
                [(ngModel)]="multiSearch" id="searchdata">
            </div>
            <button class="custom-btn btn-8 text-center" (click)="filter_status_btn()"><span><i class="las la-filter"></i>Filters</span></button>
          </div>
          <div class="drop_down_filter_list d-flex justify-content-end" [ngClass]="filter_status?'on':''">

            <div class="input-group mb-3">
              <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true"
                (change)="deSelect($event,'p_name')" [(ngModel)]="projectSearch" placeholder="Project Name">
                <ng-option *ngFor="let project of projectFilterList" [value]="project">
                  {{project}}
                </ng-option>
              </ng-select>
            </div>

            <div class="input-group mb-3">
              <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true"
                (change)="deSelect($event,'m_type')" [(ngModel)]="typeSearch" placeholder="Module Type">
                <ng-option *ngFor="let type of typeFilterList" [value]="type">
                  {{type}}
                </ng-option>
              </ng-select>
            </div>
            <!-- <div class="input-group mb-3">
              <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true"
                (change)="deSelect($event,'m_status')" [(ngModel)]="statusSearch" placeholder="Module Status">
                <ng-option *ngFor="let status of statusFilterList" [value]="status">
                  {{status}}
                </ng-option>
              </ng-select>
            </div> -->
          </div>
        </div>
        <a routerLink="/create-module" class="custom-btn btn-8 text-center"><span><i class="las la-plus"></i>Add Module</span>
        </a>
      </div>
  </div>
    <div class="card-body">
    
      <div class="table-back employee-office-table">
        <div class="table-responsive"
        infiniteScroll
        [infiniteScrollDistance]="2"
        [infiniteScrollThrottle]="500"
        (scrolled)="onScroll()"
        [scrollWindow]="false">
          <table class="table custom-table table-hover table-hover"
            *ngIf="moduleList != undefined || moduleList != null || moduleList !='' ">
            <thead>
              <tr>
                <th>Id</th>
                <th>Module Name</th>
                <th>Module Type</th>
                <th>Project Name</th>
                <th class="text-center">Effort (PD)</th>
                <!-- <th>Estimated End Date</th> -->
                <th>Status</th>
                <th>Created At</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="moduleList?.length < 1">
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td colspan="5">No Data Found!</td>
              </tr>

              <tr *ngFor="let moduleLists of moduleList | tableFilter:searchKey : selectedfield 
              | tableSearch: objKey; index as i">
                <td>{{i+1}}</td>
                <td><a class="" [routerLink]='["/manage-module/",encodeURIParam(moduleLists.m_id)]'>{{moduleLists.m_name | titlecase}}</a></td>
                <td>{{moduleLists.m_type| titlecase}}</td>
                <td>{{moduleLists.p_name| titlecase}}</td>
                <td class="text-center"><span class="badge alert-info ">{{moduleLists.m_estimated_effort}}</span></td>
                <!-- <td>{{moduleLists.m_estimated_end_date | date}}</td> -->
                <td><span class="badge alert-warning" *ngIf="moduleLists.m_status=='Inpogress'">Inprogress</span>
                  <span class="badge alert-danger"
                  *ngIf="moduleLists.m_status=='Planned'">Planned</span>
                  <span class="badge alert-primary"
                  *ngIf="moduleLists.m_status=='Completed'">Completed</span></td>
                <td>{{moduleLists.created_at | date}}</td>
                <td class="text-center"><a class="" [routerLink]='["/manage-module/",encodeURIParam(moduleLists.m_id)]'>
                  <span class=" edit text-success text-center"><i class="las la-edit"></i></span></a></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>