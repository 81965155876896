<div class="main-content" *ngIf="loadAllComponant">
  <div class="drag_drop">
    <div class="top_tab">
      <div class="d-flex">
        <!-- Unassign task -->
        <div class="col-3">
          <div class="list_card">

            <div class="drag_headert unassign_head">
              <h6>Unassign
                <small class="badge">
                  {{unAssignTasks.length}}
                </small>
              </h6>
            </div>

            <!-- empty -->
            <div class="allCardData unassign_card" *ngIf="unAssignTasks?.length == 0">
              <div class="card tasks-box">
                <div class="task-card">
                  <h6 class="text-center">Empty</h6>
                </div>
              </div>
            </div>

            <div class="allCardData unassign_card">
              <div class="drag_tag"
                *ngFor="let task of unAssignTasks">
                <div class="card uncommon-card tasks-box raise-button with-shadow"
                  style="background-color: #f1f2f4; color: #ea556f;margin-bottom: 5px;">
                  <div class="add_me plus_add">
                    <button class="cta" (click)="addMe(task.id)">
                      <i class="las la-plus"></i>
                      <span class="button-text">Assign Me</span>
                    </button>
                  </div>

                  <div class="timeCd">
                    <i class="las la-calendar-alt"></i>
                    <span>
                      {{task.updated_at | date}}
                    </span>
                  </div>

                  <div class="card-body task-card">
                    <h6 class="fs-15 text-truncate task-title">
                      <a href="javascript: void(0);" class="link-dark d-block" (click)="directTaskView(task)"
                        tooltip={{task.task_name}} placement="bottom">
                        <i class="las la-check-circle"></i>
                        {{task.task_name?.length>20?task.task_name.substr(0,20)+'...':task.task_name| titlecase}}
                      </a>
                    </h6>

                    <p class="text-muted">
                      <i class="las la-info-circle" tooltip={{task.modules.m_name}} placement="bottom"></i>
                      {{task.modules.m_name?.length>20?task.modules.m_name.substr(0,20)+'...':task.modules.m_name|
                      titlecase}}
                    </p>

                    <div class="extraInfoTxt d-flex justify-content-between align-items-center p-0">
                      <div class="d-flex tm justify-content-between align-items-center w-100">
                        <p class="text-muted"> <b><i class="las la-clock"></i></b> {{ task.task_estimated_pd}} Hr</p>
                      </div>
                      <a href="javascript: void(0);" (click)="deleteUnassignTask(task.t_id)"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-trash3" viewBox="0 0 16 16">
                        <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                      </svg>
                      </a>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Inactive task -->
        <div class="col-3">
          <div class="list_card" droppable [dropScope]="'swap-target-2'" [dragOverClass]="'drag-target-border'"
            [dragHintClass]="'drag-hint'" (onDrop)="onList1Drop($event)">

            <div class="drag_headert">
              <h6>Inactive
                <small class="badge bg-warning">
                  {{list1.length}}
                </small>
              </h6>
            </div>

            <!-- Empty card -->
            <div class="allCardData" *ngIf="list1?.length == 0">
              <div class="card tasks-box">
                <div class="task-card">
                  <h6 class="text-center">Empty</h6>
                </div>
              </div>
            </div>

            <div class="allCardData">
              <div class="drag_tag" draggable *ngFor="let task of list1" [dragScope]="'swap-target-1'" [dragClass]="'drag-over'" [dragData]="task">
                <div class="card tasks-box raise-button with-shadow" (click)="directTaskView(task)" style="background-color: rgb(255, 255, 255); color: #ea556f;">

                  <div *ngIf="task.chats !== null" class="plus_add">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
                      <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                      <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2"/>
                    </svg>
                  </div>
                  
                  <div class="timeCd">
                    <i class="las la-calendar-alt"></i>
                    <span>
                      {{task.updated_at | date}}
                    </span>
                  </div>

                  <div class="card-body task-card">
                    <h6 class="fs-15 text-truncate task-title">
                      <a href="javascript: void(0);" class="link-dark d-block" tooltip={{task.task_name}} placement="bottom">
                        <i class="las la-check-circle"></i>
                        {{task.task_name?.length>20?task.task_name.substr(0,20)+'...':task.task_name| titlecase}}
                      </a>
                    </h6>

                    <p class="text-muted">
                      <i class="las la-info-circle" tooltip={{task.modules.m_name}} placement="bottom"></i>
                      {{task.modules.m_name?.length>20?task.modules.m_name.substr(0,20)+'...':task.modules.m_name|titlecase}}
                    </p>

                    <div class="extraInfoTxt d-flex justify-content-between align-items-center p-0">
                      <div class="d-flex tm justify-content-between align-items-center w-100">

                        <p class="text-muted"> <b><i class="las la-clock"></i></b> {{ task.task_estimated_pd}} / <span
                            [ngClass]="task.used_time > task.task_estimated_pd?'text-danger':''">{{task.used_time}} Hr
                          </span></p>

                        <div class="userBox">
                          <div class="flex-shrink-0 " *ngFor="let emp of task.assigned">
                            <a href="javascript: void(0);" class="userShape-Sm" tooltip={{emp}} placement="bottom"
                              data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom"
                              aria-label="hello" [ngStyle]="{'background-color':hsl}">
                              {{convertToLetter(emp)}}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Active task -->
        <div class="col-3">
          <div class="list_card" droppable [dropScope]="['swap-target-1','swap-target-3']"
            [dragOverClass]="'drag-target-border'" [dragHintClass]="'drag-hint'" (onDrop)="onList2Drop($event)">

            <div class="drag_headert">
              <h6>Active
                <small class="badge bg-warning">
                  {{list2.length}}
                </small>
              </h6>
            </div>

            <!-- empty task -->
            <div class="allCardData" *ngIf="list2?.length == 0">
              <div class="card tasks-box">
                <div class="task-card">
                  <h6 class="text-center">Empty</h6>
                </div>
              </div>
            </div>

            <div class="allCardData">
              <div class="drag_tag" draggable *ngFor="let task of list2 " [dragScope]="'swap-target-2'"
                [dragClass]="'drag-over'" [dragData]="task">
                <div class="card tasks-box raise-button with-shadow"  (click)="directTaskView(task)"  style="background-color: rgb(255, 255, 255); color: #ea556f;">

                  <div *ngIf="task.chats !== null" class="plus_add">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
                      <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                      <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2"/>
                    </svg>
                  </div>

                  <div class="timeCd">
                    <i class="las la-calendar-alt"></i>
                    <span>
                      {{task.updated_at | date}}
                    </span>
                  </div>
                  
                  <div class="card-body task-card">
                    <h6 class="fs-15 text-truncate task-title">
                      <a href="javascript: void(0);" class="link-dark d-block" tooltip={{task.task_name}} placement="bottom">
                        <i class="las la-check-circle"></i>
                        {{task.task_name?.length>20?task.task_name.substr(0,20)+'...':task.task_name| titlecase}}
                      </a>
                    </h6>

                    <p class="text-muted">
                      <i class="las la-info-circle" tooltip={{task.modules.m_name}} placement="bottom"></i>
                      {{task.modules.m_name?.length>20?task.modules.m_name.substr(0,20)+'...':task.modules.m_name|
                      titlecase}}
                    </p>
                    <!-- task time -->
                    <div class="extraInfoTxt d-flex justify-content-between align-items-center">
                      <div class="d-flex tm justify-content-between align-items-center w-100">
                        <p class="text-muted"> <b><i class="las la-clock"></i></b> {{ task.task_estimated_pd}} / <span
                            [ngClass]="task.used_time > task.task_estimated_pd?'text-danger':''">{{task.used_time}} Hr
                          </span></p>
                        <!-- members -->
                        <div class="userBox">
                          <div class="flex-shrink-0 " *ngFor="let emp of task.assigned">
                            <a href="javascript: void(0);" class="userShape-Sm" tooltip={{emp}} placement="bottom"
                              data-bs-placement="bottom" aria-label="hello" [ngStyle]="{'background-color':hsl}">
                              {{convertToLetter(emp)}}
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Finished task -->
        <div class="col-3">
          <div class="list_card" droppable [dropScope]="'swap-target-2'" [dragOverClass]="'drag-target-border'"
            [dragHintClass]="'drag-hint'" (onDrop)="onList3Drop($event)">

            <div class="drag_headert">
              <h6>Finished
                <small class="badge bg-warning">
                  {{list3.length}}
                </small>
              </h6>
            </div>

            <div class="allCardData" *ngIf="list3?.length == 0">
              <div class="card tasks-box">
                <div class="task-card">
                  <h6 class="text-center">Empty</h6>
                </div>
              </div>
            </div>
            <div class="allCardData">
              <div class="drag_tag" draggable *ngFor="let task of list3" [dragScope]="'swap-target-3'"
                [dragClass]="'drag-over'" [dragData]="task">
                <div class="card tasks-box raise-button with-shadow" (click)="directTaskView(task)" style="background-color: rgb(255, 255, 255); color: #ea556f;">

                  <div *ngIf="task.chats !== null" class="plus_add">
                    <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-dots" viewBox="0 0 16 16">
                      <path d="M5 8a1 1 0 1 1-2 0 1 1 0 0 1 2 0m4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0m3 1a1 1 0 1 0 0-2 1 1 0 0 0 0 2"/>
                      <path d="m2.165 15.803.02-.004c1.83-.363 2.948-.842 3.468-1.105A9 9 0 0 0 8 15c4.418 0 8-3.134 8-7s-3.582-7-8-7-8 3.134-8 7c0 1.76.743 3.37 1.97 4.6a10.4 10.4 0 0 1-.524 2.318l-.003.011a11 11 0 0 1-.244.637c-.079.186.074.394.273.362a22 22 0 0 0 .693-.125m.8-3.108a1 1 0 0 0-.287-.801C1.618 10.83 1 9.468 1 8c0-3.192 3.004-6 7-6s7 2.808 7 6-3.004 6-7 6a8 8 0 0 1-2.088-.272 1 1 0 0 0-.711.074c-.387.196-1.24.57-2.634.893a11 11 0 0 0 .398-2"/>
                    </svg>
                  </div>

                  <div class="timeCd">
                    <i class="las la-calendar-alt"></i>
                    <span>
                      {{task.updated_at | date}}
                    </span>
                  </div>

                  <div class="card-body task-card">

                    <h6 class="fs-15 text-truncate task-title">
                      <a href="javascript: void(0);" class="link-dark d-block" tooltip={{task.task_name}} placement="bottom">
                        <i class="las la-check-circle"></i>
                        {{task.task_name?.length>20?task.task_name.substr(0,20)+'...':task.task_name| titlecase}}
                      </a>
                    </h6>

                    <p class="text-muted">
                      <i class="las la-info-circle" tooltip={{task.modules.m_name}} placement="bottom"></i>
                      {{task.modules.m_name?.length>20?task.modules.m_name.substr(0,20)+'...':task.modules.m_name|
                      titlecase}}
                    </p>
                    <div class="extraInfoTxt d-flex justify-content-between align-items-center">
                      <div class="d-flex tm justify-content-between align-items-center w-100">
                        <p class="text-muted"> <b><i class="las la-clock"></i></b> {{ task.task_estimated_pd}} / <span
                            [ngClass]="task.used_time > task.task_estimated_pd?'text-danger':''">{{task.used_time}} Hr
                          </span></p>
                        <!-- <i class="lar la-thumbs-up"></i> -->

                        <div class="userBox">
                          <div class="flex-shrink-0 " *ngFor="let emp of task.assigned">
                            <a href="javascript: void(0);" class="userShape-Sm" tooltip={{emp}} placement="bottom"
                              data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom"
                              aria-label="hello" [ngStyle]="{'background-color':hsl}">
                              {{convertToLetter(emp)}}
                            </a>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <!-- Reminder Start-->
        <!-- <div class="col-lg-3">
          
                  <div class="list_card">
                    <div class="drag_header">
                      <div class="d-flex align-items-center justify-content-between">
                        <h6 class="mr-auto truncate font-medium text-neutral-800">Reminders <small
                            class="badge bg-success align-bottom ms-1 totaltask-badge"
                            *ngIf="getReminders != undefined">{{getReminders?.length}}</small></h6>
                        <button (click)="onPop()">
                          <i class="las la-plus addrembutton"></i>
                        </button>
                      </div>
                    </div>
                  
                    <div class="allCardData" *ngIf="getReminders == ''">
                      <div class="card uncommon-card tasks-box">
                        <div class="task-card">
                          <h6 class="text-center">No reminders!</h6>
                        </div>
                      </div>
                    </div>
                  
                    <div class="allCardData ">
                      <div class="drag_tag " *ngFor="let remiderData of getReminders">
                        <div class="card tasks-box rem_card">
                          <div class="card-body">
                            <div class="d-flex mb-2">
                              <a href="javascript:void(0)"
                                class="text-muted fw-medium fs-14 flex-grow-1">{{reminderStatusByDate(remiderData.reminder_date)}}</a>
                              <h5><i class="las la-trash-alt" (click)="deleteRem(remiderData.id)"></i></h5>
                            </div>
                            <h6 class="fs-15 text-truncate task-title"><a href="javascript:void(0)"
                                class="link-dark d-block">{{remiderData.title}}</a></h6>
                            <p class="text-muted" tooltip={{remiderData.description}} placement="bottom" data-bs-toggle="tooltip"
                              data-bs-trigger="hover" data-bs-placement="bottom">
                              {{remiderData.description?.length>15?remiderData.description.substr(0,20)+'...':remiderData.description
                              }}</p>
                            <div class="d-flex align-items-center">
                              <div class="flex-grow-1 bgcard">
                                <span *ngIf="remiderData.priority == 'h'" class="badge bg-secondary bg-danger">High</span>
                                <span *ngIf="remiderData.priority == 'm'" class="badge bg-secondary bg-warning">Medium</span>
                                <span *ngIf="remiderData.priority == 'l'" class="badge bg-secondary">Low</span>
                              </div>
                            </div>
                          </div>
                          <div class="card-footer border-top-dashed" *ngIf="remiderData.status == 0">
                            <div class="d-flex align-items-left">
                              <a href="javascript:void(0);" (click)="completeRem(remiderData.id)" class="text-muted cbn"
                                id="dropdownMenuLink5" data-bs-toggle="dropdown" aria-expanded="false">
                                Done
                              </a>
                            </div>
                          </div>
                          <div class="progress progress-sm">
                            <div *ngIf="remiderData.status == 0" class="progress-bar bg-warning" role="progressbar" style="width: 100%"
                              aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            <div *ngIf="remiderData.status == 1" class="progress-bar bg-success" role="progressbar" style="width: 100%"
                              aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                            <div *ngIf="remiderData.status == 2" class="progress-bar bg-danger" role="progressbar" style="width: 100%"
                              aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
        </div> -->
        <!-- Reminder End -->


        <!-- side panel for all -->

        <!-- reminder panel -->
        <div class="pos_sidebar">
          <div class="fixed d-flex" [ngClass]="popOn?'on':''">
            <div class="pos_container">
              <div class="min-h-0 d-flex flex-column overflow-auto">
                <div class="px-4">
                  <div class="d-flex align-items-start justify-content-between">
                    <div class="pos_header">
                      <h2 class="text-lg">Reminder</h2>
                      <button type="button" *ngIf="isAdmin" class="btn btn-success"
                        [routerLink]="['/reminder-history/']">Reminder history</button>
                    </div>
                    <div class="ml-3 d-flex align-items-center">
                      <button (click)="onPop()" type="button" class="rounded-md bg-white" tabindex="0"><svg
                          xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke-width="2"
                          stroke="currentColor" aria-hidden="true" class="h-6 w-6">
                          <path stroke-linecap="round" stroke-linejoin="round" d="M6 18L18 6M6 6l12 12"></path>
                        </svg></button>
                    </div>
                  </div>
                </div>
                <form [formGroup]="remimderForm" (ngSubmit)="addEditReminder(remimderForm)" #fd="ngForm"
                  [ngxFvControl]="fd">
                  <div class="accordion add-employee" id="accordion-details">
                    <div class="card  grow ctm-border-radius border-0 shadow-none">
                      <div class="card-body p-0">
                        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                          data-parent="#accordion-details">
                          <div class="row">
                            <input type="hidden" class="form-control" id="r_id" #ngxFvFormControl
                              formControlName="r_id">
                            <div class="col-lg-12 form-group">
                              <ngx-fv-validate [control]="remimderForm.controls['title']">
                                <label #ngxFvName>
                                  Title
                                </label>
                                <div class="input-group mb-3">
                                  <div class="input-group-text"><i class="las la-envelope-open"></i>
                                  </div>
                                  <input type="text" class="form-control" id="title" placeholder="Ex: My reminder"
                                    #ngxFvFormControl formControlName="title" required>
                                </div>
                                <ngx-fv-error></ngx-fv-error>
                              </ngx-fv-validate>
                            </div>

                            <div class="col-lg-12 form-group">
                              <ngx-fv-validate [control]="remimderForm.controls['description']">
                                <label #ngxFvName>
                                  Description
                                </label>
                                <div class="input-group mb-3">
                                  <div class="input-group-text"><i class="las la-envelope-open"></i>
                                  </div>
                                  <textarea class="form-control" id="description" placeholder="Description"
                                    #ngxFvFormControl formControlName="description" required></textarea>
                                </div>
                                <ngx-fv-error></ngx-fv-error>
                              </ngx-fv-validate>
                            </div>

                            <div class="col-lg-12 form-group">
                              <ngx-fv-validate [control]="remimderForm.controls['reminder_date']">
                                <label #ngxFvName>
                                  Date
                                </label>
                                <div class="input-group mb-3">
                                  <div class="input-group-text">
                                    <i class="las la-calendar-alt"></i>
                                  </div>
                                  <input type="text" class="form-control" ngbDatepicker #dpStart="ngbDatepicker"
                                    id="reminder_date" placeholder="YYYY-MM-DD" [minDate]="minDateLimit" name="dpStart" (dateSelect)="onDateSelect($event)" (click)="dpStart.toggle()" #ngxFvFormControl formControlName="reminder_date" readonly>
                                </div>
                                <ngx-fv-error></ngx-fv-error>
                              </ngx-fv-validate>
                            </div>

                            <div class="col-lg-12 form-group">
                              <ngx-fv-validate [control]="remimderForm.controls['reminder_time']">
                                <label #ngxFvName>
                                  Time
                                </label>
                                <div class="input-group mb-3">
                                  <div class="input-group-text"><i class="las la-envelope-open"></i>
                                  </div>
                                  <input type="time" class="form-control" id="reminder_time"
                                    placeholder="Ex: My reminder" #ngxFvFormControl formControlName="reminder_time"
                                    required>
                                </div>
                                <ngx-fv-error></ngx-fv-error>
                              </ngx-fv-validate>
                            </div>

                            <div class="col-lg-12 form-group">
                              <ngx-fv-validate [control]="remimderForm.controls['priority']">
                                <label #ngxFvName>
                                  Priority
                                </label>
                                <div class="input-group mb-3">
                                  <div class="input-group-text"><i class="lar la-id-card"></i>
                                  </div>
                                  <select class="form-control select select2-hidden-accessible" tabindex="-1"
                                    aria-hidden="true" #ngxFvFormControl id="priority" formControlName="priority"
                                    required>
                                    <option value="">Set Priority</option>
                                    <option value="h">High</option>
                                    <option value="m">Medium</option>
                                    <option value="l">Low</option>
                                  </select>
                                </div>
                                <ngx-fv-error></ngx-fv-error>
                              </ngx-fv-validate>
                            </div>
                            <div class="col-lg-12 form-group www" *ngIf="isAdmin">
                              <label #ngxFvName>
                                Add Members
                              </label>
                              <div class="input-group mb-3">
                                <div class="input-group-text"><i class="las la-user-secret"></i>
                                </div>
                                <ng-select [multiple]="true" [selectableGroup]="true" aria-hidden="true" #ngxFvFormControl formControlName="memberlist" [closeOnSelect]="false" (change)="select_user($event,'')">
                                  <ng-option *ngFor="let usr of userList" [value]="usr.empid">{{usr.name}}
                                  </ng-option>
                                </ng-select>
                              </div>
                            </div>
                            <div class="input-group mb-3" *ngIf="isShown">
                              <button class="btn custom-btn btn-8" type="submit"><span>Set
                                  Reminder</span></button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
              <div class="footer_sidebar">
              </div>
            </div>
          </div>
        </div>

        <!-- Add timesheet panel -->
        <div class="card timesheet" [ngClass]="sidePanel?'on':''">
          <div class="card-body">
            <div class="modal-header">
              <div class="pos_header">
                <h2 class="text-lg"> TASK : {{taskObj.t_name | uppercase}} </h2>
                <a href="javascript:void(0)" (click)="closePanel()" class="close"></a>
              </div>
            </div>
            <div class="switch-button" [ngClass]="switchStatus?'active-left':'active-right'"><span
                class="active"></span>
              <button class="switch-button-case left" [ngClass]="switchStatus == 'timesheet' ?'active-case':''"
                (click)="selectedTab('timesheet')">Add
                Timesheet</button>
              <button class="switch-button-case right" [ngClass]="switchStatus=='overview'?'active-case':''"
                (click)="selectedTab('overview')">Task Overview</button>
              <button class="switch-button-case right" [ngClass]="switchStatus=='activity'?'active-case':''"
                (click)="selectedTab('activity')">Activity</button>
            </div>

            <div class="table-responsive sidebar_tab" *ngIf="switchStatus == 'timesheet'">
              <form [formGroup]="timesheetForm" (ngSubmit)="timesheetForm.valid && addTimesheetData(timesheetForm)">
                <div class="timedataCard position-relative tabTb">
                  <div class="headerDate">
                    <h4 *ngIf="viewAdEventDate" class=" d-flex justify-content-between align-items-center">
                      <a *ngIf="prevDay" href="javascript:void(0)" (click)="dateChangePop('prev')">
                        <i class="las la-arrow-circle-left text-primary"></i>
                      </a> <span> {{ viewAdEventDate | date:'mediumDate' }}</span>
                      <a href="javascript:void(0)" *ngIf="nxtDay" (click)="dateChangePop('next')">
                        <i class="las text-primary la-arrow-circle-right"></i>
                      </a>
                    </h4>
                  </div>
                  <div class="container">
                    <div class="row">
                      <input type="hidden" class="form-control" [(ngModel)]="task_id" id="task_id" #ngxFvFormControl
                        formControlName="task_id">
                      <div class="col-lg-12">
                        <label #ngxFvName>
                          Description
                        </label>
                        <div class="input-group mb-3">
                          <textarea autosize class="form-control" #ngxFvFormControl formControlName="description"
                            placeholder="Describe here..." id="description" name="description"
                            required="required"></textarea>
                        </div>
                      </div>
                      <div class="col-lg-6 start-time-now">
                        <label #ngxFvName>
                          Start Time (12:00 Hours)
                        </label>
                        <div class="input-group mb-8">
                          <input type="time" class="form-control" #ngxFvFormControl id="start_time"
                            formControlName="start_time">
                        </div>
                      </div>
                      <div class="col-lg-6 start-time-now">
                        <label #ngxFvName>
                          End Time (12:00 Hours)
                        </label>
                        <div class="input-group mb-8">
                          <input type="time" class="form-control" id="end_time" formControlName="end_time">
                        </div>
                      </div>

                      <div class="col-lg-12 form-group">
                        <div class="input-group mb-3 mt-2">
                          <button class="custom-btn btn-8 text-center" type="submit" [disabled]="timesheetForm.invalid">Submit Entry</button>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </form>
            </div>
            <!-- end timesheet panel -->

            <!-- task overview -->
            <div class="table-responsive sidebar_tab" *ngIf="switchStatus == 'overview'">
              <div class="timedataCard position-relative tabTb card card shadow-none">
                <div class="overview ">
                  <nav aria-label="breadcrumb">
                    <div class="d-flex justify-content-center" *ngIf="taskOverviewData?.length < 1">
                      <div class="spinner-border" role="status">
                        <span class="visually-hidden">Loading...</span>
                      </div>
                    </div>
                    <ol class="breadcrumb ">
                      <li class="breadcrumb-item d-flex align-items-center"><small class="pmtt">P</small>{{taskOverviewData.project_name}}</li>
                      <li class="breadcrumb-item d-flex align-items-center active" aria-current="page"><small class="badge">M</small>{{taskOverviewData.module_name}}</li>
                    </ol>
                  </nav>
                  <br>
                  <h4 class="text-muted taskName"><i class="las la-check-circle"></i>
                    <a [routerLink]='["/manage-task/",encodeURIParam(taskObj.t_id)]'> {{
                      taskOverviewData.task_name}}</a>
                  </h4>
                  <h6 class="text-muted taskDesk"><i class="las la-info-circle"></i> Description : {{
                    taskOverviewData.task_description}}</h6>
                  <h4 class="text-muted "><i class="las la-user-circle"></i> {{ taskOverviewData.assign_by_user}}</h4>
                  <h4 class="tagStatus">
                    <span><i class="las la-info-circle"></i> {{taskOverviewData.task_status}}</span>
                    <span><i class="las la-calendar-check"></i>{{taskOverviewData.created_at| date}}</span>
                    <span><i class="las la-bell"></i></span>
                    <span class="border-0"><i class="las la-hourglass-start"></i>
                      {{taskOverviewData.estimated_hours > 0?taskOverviewData.estimated_hours:0}} hr</span>
                  </h4>

                  <div class="form-check text-end " (change)="taskDateBox()">
                    <input class="form-check-input round" type="checkbox" value="" id="flexCheckIndeterminate">
                    <label class="form-check-label" for="flexCheckIndeterminate">
                      Remind Me
                    </label>
                  </div>
                  
                </div>
              </div>

              <div class="timeDatePickReminder" *ngIf="remindTask">
                <div class="container">
                  <form [formGroup]="taskRemimderForm" (ngSubmit)="addTaskReminder(taskRemimderForm)" #fd="ngForm">
                    <div class="row">
                      <input type="hidden" class="form-control" id="title" #ngxFvFormControl formControlName="title"
                        [(ngModel)]="title">
                      <input type="hidden" class="form-control" id="description" #ngxFvFormControl
                        formControlName="description" [(ngModel)]="taskObj.t_name">
                      <input type="hidden" class="form-control" id="task_id" #ngxFvFormControl
                        formControlName="task_id" [(ngModel)]="r_task_id">
                      <div class="col-lg-3 form-group">
                        <label #ngxFvName>
                          Date
                        </label>
                        <div class="input-group mb-4">
                          <div class="input-group-text">
                            <i class="las la-calendar-alt"></i>
                          </div>
                          <input type="text" class="form-control" ngbDatepicker #dpStart="ngbDatepicker"
                            id="reminder_date" placeholder="YYYY-MM-DD" [minDate]="minDateLimit" name="dpStart"
                            (dateSelect)="onDateSelect($event)" (click)="dpStart.toggle()" #ngxFvFormControl
                            formControlName="reminder_date" readonly>
                        </div>
                      </div>
                      <div class="col-lg-3 form-group">
                        <label #ngxFvName>
                          Time
                        </label>
                        <div class="input-group mb-3">
                          <div class="input-group-text"><i class="las la-envelope-open"></i>
                          </div>
                          <input type="time" class="form-control" id="reminder_time" placeholder="Ex: My reminder"
                            #ngxFvFormControl formControlName="reminder_time" required>
                        </div>
                      </div>
                      <div class="col-lg-3 form-group">
                        <label #ngxFvName>
                          Priority
                        </label>
                        <div class="input-group mb-3">
                          <div class="input-group-text"><i class="lar la-id-card"></i>
                          </div>
                          <select class="form-control select select2-hidden-accessible" tabindex="-1"
                            aria-hidden="true" #ngxFvFormControl formControlName="priority" required>
                            <option value="">Set Priority</option>
                            <option value="h">High</option>
                            <option value="m">Medium</option>
                            <option value="l">Low</option>
                          </select>
                        </div>
                      </div>
                      <div class="col-lg-3 form-group">
                        <br>
                        <div class="input-group mb-3">
                          <button class="btn custom-btn btn-8" type="submit"><span>Save</span></button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>

              <div class="card card shadow-none">
                <div class="card-header align-items-center d-flex border-bottom-dashed"
                  *ngIf="taskOverviewData.task_status == 'active'">
                  <h4 class="card-title mb-0 flex-grow-1">Members</h4>
                  <div class="flex-shrink-0">
                      <svg (click)="docAction(2)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ff287e" class="bi bi-person-add" viewBox="0 0 16 16">
                        <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                        <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/></svg>
                  </div>
                </div>
                <div class="card-body">
                  <div class="d-flex align-items-center" *ngFor="let employeeLists of taskOverviewData.asigned_data">
                    <div class="avatar-xs flex-shrink-0 me-3">
                      <h4 class="userShape-Sm" [ngStyle]="{'background-color':hsl}">
                        {{convertToLetter(employeeLists.name)}}
                      </h4>
                    </div>
                    <p>
                      <a href="javascript:void(0);" class="text-body d-block">{{employeeLists.name}}</a>
                    </p>
                  </div>
                  <!-- end list -->
                </div>
              </div>

            <!-- chats -->
            <br>
            <div class="card">
              <div class="card-header align-items-center d-flex">
                <h3 class="card-title mb-0 flex-grow-1 text-center">Task Discussion</h3>
              </div>
      
              <div class="card-body">
                <div class="px-3 mx-n3 mb-2 scroll_comment chatbg">
                  <div class="simplebar-wrapper ">
                    <div class="simplebar-mask">
                      <div class="simplebar-offset">
                        <div class="simplebar-content-wrapper ">
                          <div class="simplebar-content " *ngIf="getMessage!==undefined && getMessage!==null">
                            <div class="text-center" *ngIf="getMessage.length==0">
                              <p><b>Disclaimer</b> : This conversation is visible to all task assigned members and admin.</p>
                            </div>
                            <div class="d-flex mb-4 " *ngFor="let msg of getMessage">
                              <div class="speech-wrapper">
                                <!--  Speech Bubble alternative -->
                                <div class="bubble " [ngClass]="{'alt': msg.member_id === msg.login_user_id}">
                                  <div class="txt">
                                    <p class="name" [ngClass]="{'alt': msg.member_id === msg.login_user_id}">{{(msg.member_id === msg.login_user_id)?"You":msg.member_name}}</p>
                                    <p class="message">{{msg.message}}</p>
                                  </div>
                                  <span class="timestamp">{{msg.date}}</span>
                                  <div class="bubble-arrow" [ngClass]="{'alt': msg.member_id === msg.login_user_id}"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="simplebar-track simplebar-horizontal">
                    <div class="simplebar-scrollbar"></div>
                  </div>
                  <div class="simplebar-track simplebar-vertical">
                    <div class="simplebar-scrollbar">
                    </div>
                  </div>
                </div>

                <form class="mt-4">
                  <div class="row g-3">
                    <div class="col-10">
                      <textarea class="form-control bg-light border-light" id="exampleFormControlTextarea1" rows="2" placeholder="Enter your message..." name="comments" (keyup)="mentionEmp($event)" [(ngModel)]=comments></textarea>
                    </div>
                    <div class="col-1">
                      <div class="btn-container">
                        <div class="buttons">
                          <a href="javascript:void(0);" (click)="saveComments()" class="button3">
                            <svg  xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-send" viewBox="0 0 16 16">
                              <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
                            </svg></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

              </div>
            </div>
            <!-- end chats -->

            </div>

            <!-- members list slider -->
            <div class="memberList" [ngClass]="this.docStatus==2?'on':''" *ngIf="itemList.length>0">
              <div class="TopList">
                <div class="modal-content border-0">
                  <div class="modal-header p-3 ps-4 bg-soft-success">
                    <p class="modal-title" id="inviteMembersModalLabel">Members</p>
                    <button type="button" class="btn-close" (click)="docAction(0)"></button>
                  </div>
                  <div class="modal-body p-4">
                    <div class="search-box mb-3">
                      <input type="text" (keyup)="searchType($event)" class="form-control bg-light border-light u_find" placeholder="Search here..." [(ngModel)]="multiSearch">
                      <!-- <i class="las la-search"></i> -->
                    </div>
                    <div class="mx-n4 px-4" class="cardmembers">
                      <div class="simplebar-wrapper">
                        <div class="simplebar-height-auto-observer-wrapper">
                          <div class="simplebar-height-auto-observer"></div>
                        </div>
                        <div class="simplebar-mask">
                          <div class="simplebar-offset">
                            <div class="simplebar-content-wrapper">
                              <div class="simplebar-content">
                                <div class="vstack gap-3">
                                  <div class="d-flex align-items-center" *ngFor="let usr of itemList|tableSearch:objKey;">
                                    <div class="avatar-xs flex-shrink-0 me-3">
                                      <h4 class="userShape-Sm" [ngStyle]="{'background-color':hsl}">
                                        {{convertToLetter(usr.name)}}
                                      </h4>
                                    </div>
                                    <div class="flex-grow-1">
                                      <p class="fs-13 mb-0"><a href="#" class="text-body d-block">{{usr.name}}</a></p>
                                    </div>
                                    <div class="flex-shrink-0">
                                      <svg xmlns="http://www.w3.org/2000/svg" (click)="select_user(usr.id,usr.name)" width="22" height="22" fill="#ea556f"
                                        class="bi bi-plus-circle" viewBox="0 0 16 16">
                                        <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                                        <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                                      </svg>
                                    </div>
                                  </div>
                                  <!-- end member item -->
                                </div>
                                <!-- end list -->
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-light w-xs">Cancel</button>
                  </div> -->
                </div>
                <!-- end modal-content -->
              </div>
              <!-- modal-dialog -->
            </div>
      
            <!-- Activity Tab -->
            <div class="table-responsive sidebar_tab activity-tab" *ngIf="switchStatus == 'activity'">
              <div class="card_activity">
                <div class="card  grow ctm-border-radius">
                  <div class="card-header" id="basic1">
                    <h4 class="cursor-pointer mb-0">
                      <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
                        data-target="#basic-one" aria-expanded="true">
                        Timesheet Details
                      </a>
                    </h4>
                  </div>
                  <div class="card-body p-0">
                    <div class="table-back employee-office-table">
                      <div class="hd-header" *ngIf="taskOverviewData!=undefined">
                        <h6> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-hourglass" viewBox="0 0 16 16">
                            <path
                              d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2h-7z" />
                          </svg> Assigned: {{pageService.formatSeconds(taskOverviewData.estimated_hours*60)}}</h6>
                        <h6 *ngIf="total_effort!=null && total_effort!=undefined"> <svg
                            xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                            class="bi bi-hourglass" viewBox="0 0 16 16">
                            <path
                              d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2h-7z" />
                          </svg>Total Used :
                          {{total_effort > 0?pageService.formatSeconds(total_effort):'0 h'}}</h6>
                      </div>
                      <div class="worktime">
                        <ul *ngFor="let time of timeSheetEffInfo; index as i">
                          <li><b>Employee:</b> {{time.name}}</li>
                          <li><b>Time:</b> {{pageService.formatSeconds(time.total_effort)}}</li>
                        </ul>
                      </div>
                      <div class="table-responsive">
                        <table class="table custom-table table-hover table-hover">
                          <thead>
                            <tr>
                              <th>Sl No.</th>
                              <th>Effort</th>
                              <th>Member Name</th>
                              <th>Description</th>
                              <th>Entry Date</th>
                              <th>Start Time</th>
                              <th>End Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            <tr *ngIf="taskTimeList?.length < 1">
                              <td></td>
                              <td></td>
                              <td></td>
                              <td>
                                <h3><i class="las la-frown"></i> No Data!!</h3>
                              </td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>
                            <tr *ngFor="let taskTime of taskTimeList; index as i">
                              <td>{{i+1}}</td>
                              <td>{{pageService.timeConvert(taskTime.effort)}}</td>
                              <td>{{taskTime.username}}</td>
                              <td class="maxWidth">{{taskTime.comment}}</td>
                              <td>{{taskTime.entry_date|date}}</td>
                              <td>{{taskTime.start_time}}</td>
                              <td>{{taskTime.end_time}}</td>
                              <td></td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- End Activity tab -->

            <!-- Calender Tab -->
            <div class="table-responsive sidebar_tab activity-tab" *ngIf="switchStatus =='calender'">
              <div class="col-6 text-end">
                <div class="btn_grp ">
                  <div class="eventTask d-flex justify-content-end">
                    Coming Soon....
                  </div>
                </div>
              </div>
            </div>
            <!-- End calendar tab -->
          </div>
        </div>

      </div>
    </div>
  </div>
</div>

<ng-template #editModal let-modal>
  <!-- <div class="modal-header">
    <span><h3>Disclaimer: </h3>If you are tagging your messages to other members,tag them first before writing your message!</span>
  </div> -->
  <div class="modal-body">
    <div class="wrap">
      <form [formGroup]="tagForm" (ngSubmit)="tagMembers(tagForm)" #fd="ngForm" [ngxFvControl]="fd">
        <div class="row">
          <div class="col-lg-12 form-group">
            <ngx-fv-validate [control]="tagForm.controls['tagged_user']">
              <label #ngxFvName>
                Select Members
              </label>
              <ng-select formControlName="tagged_user" [(ngModel)]="selectedIds" [multiple]="true" [closeOnSelect]="false" >
                <ng-option *ngFor="let user of empList;" [value]="user.id">{{user.name}}</ng-option>
              </ng-select>
              <ngx-fv-error></ngx-fv-error>
            </ngx-fv-validate>
          </div>
        </div>
        <br>
    
        <div class="col-lg-12 form-group">
          <div class="input-group mb-3" *ngIf="isShow">
            <button class="btn custom-btn btn-8" (click)="closeModal()"><span>Tag</span></button>
          </div>
        </div>
      </form>
    </div>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Cancel</button>
  </div> -->
</ng-template>


<ngx-ui-loader></ngx-ui-loader>