<div class="main-content" *ngIf="loadAllComponant;else dataNotLoaded">
  <div class="newCard">
    <div class="card" *ngIf="taskData!=undefined">
      <div class="bg-soft-warning">
        <div class="card-body pb-0 px-4">
          <div class="row mb-3">
            <div class="col-md">
              <div class="row align-items-center g-3">
                <div class="col-md-auto">
                  <div class="avatar-md">
                    <div class="avatar-title bg-white rounded-circle">
                      <img src="https://eu.ui-avatars.com/api/?name={{taskData.task_name}}&size=80" alt=""
                        class="avatar-xs rounded-circle">
                    </div>
                  </div>
                </div>
                <div class="col-md">
                  <div>
                    <h4 class="fw-bold">{{taskData.task_name | titlecase}}</h4>
                    <div class="hstack gap-3 flex-wrap">
                      <div><i class="ri-building-line align-bottom me-1"></i> {{taskData.project_name | titlecase}} -
                        {{taskData.module_name | titlecase}}</div>
                      <div class="vr"></div>
                      <div>Created By : {{taskData.assign_by_user | titlecase}}</div>
                      <div class="vr"></div>
                      <div>Created Date : <span class="">{{ taskData.created_at | date:'mediumDate'}}</span></div>
                      <div class="vr"></div>
                      <div>Last Update : <span class="">{{ taskData.updated_at | date:'mediumDate'}}</span></div>
                      <!-- <div class="badge rounded-pill bg-info fs-12">New</div> -->
                      <!-- <div class="badge rounded-pill bg-danger fs-12">High</div> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-auto button_card">
              <div class="hstack gap-1 flex-wrap">
                <button (click)="docAction(1)" class="custom-btn btn-8 text-center">Edit
                </button>
                <button (click)="deleteTask(taskData.task_id)" class="custom-btn btn-8 text-center">Delete
                </button>
                <button (click)="cloneTask(taskData)" class="custom-btn btn-8 text-center">Clone
                </button>
              </div>
            </div>
          </div>
          <ul class="nav nav-tabs-custom border-bottom-0" role="tablist">
            <li class="nav-item" role="presentation">
              <a class="nav-link  active" data-bs-toggle="tab" [ngClass]="tabval=='task_details'?'active':''" href="javascript:void(0)" (click)="changeTab('task_details')" role="tab" aria-selected="true">
                Overview
              </a>
            </li>
  
            <li class="nav-item" role="presentation">
              <a class="nav-link " href="javascript:void(0)" [ngClass]="tabval=='get_timedata'?'active':''"
                (click)="changeTab('get_timedata')">
                Activities
              </a>
            </li>
            <li class="nav-item" role="presentation" *ngIf="lsData.includes('Admin') || lsData.includes('Manager')">
              <a class="nav-link " class="nav-link " href="javascript:void(0)" [ngClass]="tabval=='logs'?'active':''"
                (click)="changeTab('logs')">
                Logs
              </a>
            </li>
            <li class="nav-item" role="presentation" *ngIf="lsData.includes('Admin') || lsData.includes('Manager')">
              <a class="nav-link " class="nav-link " href="javascript:void(0)" [ngClass]="tabval=='reportTimesheet'?'active':''"
                (click)="changeTab('reportTimesheet')">
                Report
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <div class="card_activity" *ngIf="tabval=='get_timedata'">
    <div class="card  grow ctm-border-radius">
      <div class="card-header" id="basic1">
        <h4 class="cursor-pointer mb-0">
          <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
            data-target="#basic-one" aria-expanded="true">
            Timesheet Details
          </a>
        </h4>
      </div>
      <div class="card-body p-0">
        <div class="table-back employee-office-table">
          <div class="hd-header" *ngIf="taskData!=undefined">
            <h6> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                class="bi bi-hourglass" viewBox="0 0 16 16">
                <path
                  d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2h-7z" />
              </svg> Assigned: {{pageService.formatSeconds(taskData.estimated_hours*60)}}</h6>
            <h6 *ngIf="total_effort!=null && total_effort!=undefined"> <svg xmlns="http://www.w3.org/2000/svg" width="16"
                height="16" fill="currentColor" class="bi bi-hourglass" viewBox="0 0 16 16">
                <path
                  d="M2 1.5a.5.5 0 0 1 .5-.5h11a.5.5 0 0 1 0 1h-1v1a4.5 4.5 0 0 1-2.557 4.06c-.29.139-.443.377-.443.59v.7c0 .213.154.451.443.59A4.5 4.5 0 0 1 12.5 13v1h1a.5.5 0 0 1 0 1h-11a.5.5 0 1 1 0-1h1v-1a4.5 4.5 0 0 1 2.557-4.06c.29-.139.443-.377.443-.59v-.7c0-.213-.154-.451-.443-.59A4.5 4.5 0 0 1 3.5 3V2h-1a.5.5 0 0 1-.5-.5zm2.5.5v1a3.5 3.5 0 0 0 1.989 3.158c.533.256 1.011.791 1.011 1.491v.702c0 .7-.478 1.235-1.011 1.491A3.5 3.5 0 0 0 4.5 13v1h7v-1a3.5 3.5 0 0 0-1.989-3.158C8.978 9.586 8.5 9.052 8.5 8.351v-.702c0-.7.478-1.235 1.011-1.491A3.5 3.5 0 0 0 11.5 3V2h-7z" />
              </svg> Used :
              {{Math.round(total_effort*1/60)+" h"}}</h6>
          </div>
          <div class="worktime">
            <ul *ngFor="let time of timeSheetEffInfo">
              <li><b>Employee:</b> {{time.name}}</li>
              <li><b>Time:</b> {{pageService.formatSeconds(time.total_effort)}}</li>
            </ul>
          </div>
          <div class="table-responsive">
            <table class="table custom-table table-hover table-hover">
              <thead>
                <tr>
                  <th>Sl No.</th>
                  <th>Effort</th>
                  <th>Member Name</th>
                  <th>Description</th>
                  <th>Entry Date</th>
                  <th>Start Time</th>
                  <th>End Time</th>
                </tr>
              </thead>
              <tbody *ngIf="taskTimeList != undefined">
                <tr *ngFor="let taskTime of taskTimeList">
                  <td>{{taskTime.id}}</td>
                  <td>{{pageService.timeConvert(taskTime.effort)}}</td>
                  <td>{{taskTime.username}}</td>
                  <td class="maxWidth">{{taskTime.comment}}</td>
                  <td>{{taskTime.entry_date}}</td>
                  <td>{{taskTime.start_time}}</td>
                  <td>{{taskTime.end_time}}</td>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="card_activity" *ngIf="tabval=='reportTimesheet'">
    <div class="card  grow ctm-border-radius h-200">
      <div class="card-header" id="basic1">
        <h4 class="cursor-pointer mb-0">
          <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
            data-target="#basic-one" aria-expanded="true">
            Report
          </a>
        </h4>
      </div>
      <div class="card-body p-0">
        <div class="table-responsive" *ngIf="showChart;else noChartData">
          <apx-chart 
                      [series]="chartOptions.series" 
                      [chart]="chartOptions.chart" 
                      [xaxis]="chartOptions.xaxis"
                      [title]="chartOptions.title"
                      [labels]="chartOptions.labels">
              </apx-chart>
        </div>
        <ng-template #noChartData>
          <div class="alert alert-danger">No Data Available!!!</div>
        </ng-template>
      </div>
    </div>
  </div>
  
  <div class="all_info_cont" *ngIf="tabval=='task_details'">
    <div class="row">
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="text-muted" *ngIf="taskData!=undefined">
              <h6 class="mb-3 text-uppercase">Description</h6>
              {{taskData.task_description}}
              <div class="pt-3 border-top border-top-dashed mt-4">
                <div class="row">
                  <div class="col-lg-3 col-sm-6">
                    <div *ngIf="taskData!=undefined">
                      <p class="mb-2 text-uppercase ">Start Date :</p>
                      <h5 class="fs-15 mb-0">{{ taskData.start_date | date:'mediumDate'}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6">
                    <div *ngIf="taskData!=undefined">
                      <p class="mb-2 text-uppercase ">Due Date :</p>
                      <h5 class="fs-15 mb-0">{{ taskData.end_date | date:'mediumDate'}}</h5>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6">
                    <div *ngIf="taskData!=undefined">
                      <p class="mb-2 text-uppercase ">Estimated Time :</p>
                      <div class="fs-15 mb-0">{{taskData.estimated_hours+" hr"}}</div>
                    </div>
                  </div>
                  <div class="col-lg-3 col-sm-6">
                    <div *ngIf="taskData!=undefined">
                      <p class="mb-2 text-uppercase ">Status :</p>
                      <div class="">{{taskData.task_status|titlecase}}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="progress progress-sm"
            *ngIf="taskData!=undefined && taskData.task_status=='finish'; else progressValue ">
            <div class="progress-bar bg-success" role="progressbar" [style.width]="100+'%'" aria-valuemin="0"
              aria-valuemax="100"></div>
          </div>
          <ng-template #progressValue>
            <div class="progress-bar bg-warning" role="progressbar" [style.width]="progressBarValue+'%'" aria-valuemin="0"
              aria-valuemax="100"></div>
          </ng-template>
          <!-- end card body -->
        </div>
        <div class="card">
          <div class="card-header align-items-center d-flex">
            <h3 class="card-title mb-0 flex-grow-1 text-center">Task Discussion</h3>
          </div><!-- end card header -->
  
          <div class="card-body">
            <div class="px-3 mx-n3 mb-2 scroll_comment chatbg">
              <div class="simplebar-wrapper ">
                <div class="simplebar-mask">
                  <div class="simplebar-offset">
                    <div class="simplebar-content-wrapper ">
                      <div class="simplebar-content " *ngIf="getMessage!==undefined && getMessage!==null">
                        <div class="text-center" *ngIf="getMessage.length==0">
                          <p><b>Disclaimer</b> : This conversation is visible to all task assigned members and admin.</p>
                        </div>
                        <div class="d-flex mb-4 " *ngFor="let msg of getMessage">
                          <div class="speech-wrapper">
                            <!--  Speech Bubble alternative -->
                            <div class="bubble " [ngClass]="{'alt': msg.member_id === msg.login_user_id}">
                              <div class="txt">
                                <p class="name" [ngClass]="{'alt': msg.member_id === msg.login_user_id}">{{(msg.member_id === msg.login_user_id)?"You":msg.member_name}}</p>
                                <p class="message">{{msg.message}}</p>
                              </div>
                              <span class="timestamp">{{msg.date}}</span>
                              <div class="bubble-arrow" [ngClass]="{'alt': msg.member_id === msg.login_user_id}"></div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="simplebar-track simplebar-horizontal">
                <div class="simplebar-scrollbar"></div>
              </div>
              <div class="simplebar-track simplebar-vertical">
                <div class="simplebar-scrollbar">
                </div>
              </div>
            </div>
            <form class="mt-4">
              <div class="row g-3">
                <div class="col-11">
                  <textarea class="form-control bg-light border-light" #input ngM  id="exampleFormControlTextarea1" rows="2" placeholder="Enter your message..." name="comments" (keyup)="mentionEmp($event)" [(ngModel)]=comments></textarea>
                </div>
                <div class="col-1">
                  <a href="javascript:void(0);" class="raise-button with-shadow sndbtn" style="background-color: #55ea6b; color: #ffffff;" (click)="saveComments()"><svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="#ffff" class="bi bi-send" viewBox="0 0 16 16">
                    <path d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"/>
                  </svg></a>
                </div>
              </div>
            </form>
          </div>
          <!-- end card body -->
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <h5 class="card-title mb-4">Tags</h5>
              <h5 class="card-title mb-4">
  
                <svg xmlns="http://www.w3.org/2000/svg" (click)="showSelect(true);" width="20" height="20" fill="#ff287e" class="bi bi-bookmark-plus" viewBox="0 0 16 16">
                  <path d="M2 2a2 2 0 0 1 2-2h8a2 2 0 0 1 2 2v13.5a.5.5 0 0 1-.777.416L8 13.101l-5.223 2.815A.5.5 0 0 1 2 15.5zm2-1a1 1 0 0 0-1 1v12.566l4.723-2.482a.5.5 0 0 1 .554 0L13 14.566V2a1 1 0 0 0-1-1z"/>
                  <path d="M8 4a.5.5 0 0 1 .5.5V6H10a.5.5 0 0 1 0 1H8.5v1.5a.5.5 0 0 1-1 0V7H6a.5.5 0 0 1 0-1h1.5V4.5A.5.5 0 0 1 8 4"/>
                </svg>
              </h5>
            </div>
  
            <div class="d-flex flex-wrap gap-2 fs-16" *ngIf="taskData!=undefined && taskData.tags.length>0">
              <div class="badge  bg-secondary tagText" *ngFor="let tag of taskData.tags">{{tag.name}} <span class="badge text-white" (click)="removeTag(tag.id)"><i class="las la-times"></i></span></div>
            </div>
            <div class="d-flex my-2" *ngIf="addTagBtn">
              <ng-select [multiple]="true" [addTag]="addNewTag" [(ngModel)]="selectedTagList">
                <ng-option *ngFor="let tag of tagList" [value]="tag.name">{{tag.name}}</ng-option>
              </ng-select>
              <button class="btn bg-custom text-light btn-sm mx-1" (click)="addTag()">add</button>
            </div>
          </div>
        </div>
        
        <div class="card">
          <div class="card-header align-items-center d-flex border-bottom-dashed">
            <h4 class="card-title mb-0 flex-grow-1">Members</h4>
            <div class="flex-shrink-0" ><svg *ngIf="taskData!=undefined && taskData.task_status == 'active' || taskData.task_status == 'unassigned'" (click)="docAction(2)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ff287e" class="bi bi-person-add" viewBox="0 0 16 16">
                <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/>
              </svg>
            </div>
          </div>
  
          <div class="card-body">
            <div class="mx-n3 px-3">
              <div class="simplebar-wrapper">
                <div class="simplebar-mask">
                  <div class="simplebar-offset">
                    <div class="simplebar-content-wrapper">
                      <div class="simplebar-content">
  
                        <div class="vstack gap-3" *ngIf="empList ==''">
                          <div class="d-flex align-items-center">
                            <div class="avatar-xs flex-shrink-0 me-3">
                            </div>
                            <div class="flex-grow-1">
                              <h5 class="fs-13 mb-0"><a href="javascript:void(0);"
                                  class="text-body d-block">No One Assigned!!</a></h5>
                            </div>
                          </div>
                        </div>
  
                        <div class="vstack gap-3" *ngIf="taskData!=undefined">
                          <div class="d-flex align-items-center" *ngFor="let employeeLists of empList">
                            <div class="avatar-xs flex-shrink-0 me-3">
                              <h4 class="userShape-Sm" [ngStyle]="{'background-color':hsl}">
                                {{convertToLetter(employeeLists.name)}}
                              </h4>
                            </div>
                            <div class="flex-grow-1">
                              <h5 class="fs-13 mb-0"><a href="javascript:void(0);"
                                  class="text-body d-block">{{employeeLists.name}}</a></h5>
                            </div>
                            <div class="flex-shrink-0">
                              <div class="d-flex align-items-center gap-1">
                                <button  *ngIf="empList?.length > 1" type="button" class="btn btn-light btn-sm" (click)="deleteUser(employeeLists.id,employeeLists.name)"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ff287e" class="bi bi-trash" viewBox="0 0 16 16">
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                </svg></button>
  
                              </div>
                            </div>
                          </div>
                          <button class="raise-button with-shadow" type="button" disabled *ngIf="!isShowBtn">
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="visually-hidden">Loading...</span>
                          </button>
                        </div>
                        <!-- end list -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
        <div class="card">
          <div class="card-header align-items-center d-flex border-bottom-dashed">
            <h4 class="card-title mb-0 flex-grow-1">Attachments</h4>
            <div class="flex-shrink-0">
              <input type="file" class="d-none" multiple id="upload-input" (change)="onFileChange($event)">
                <svg xmlns="http://www.w3.org/2000/svg" (click)="upload()" width="24" height="24" fill="#ff287e" class="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16">
                  <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z"/>
                  <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                </svg>
  
              <ng-template #showBtn>
                  <button class="custom-btn btn-8 text-center" style="background-color: white; color: #ea556f;" disabled> <i class="las la-paperclip"></i> Upload Document</button>
              </ng-template>
            </div>
          </div>
          <div class="card-body">
            <div class="vstack gap-2">
              <!-- <div class="border rounded border-dashed p-2">
                    <div class="d-flex align-items-center">
                        <div class="flex-shrink-0 me-3">
                            <div class="avatar-sm">
                                <div class="avatar-title bg-light text-secondary rounded fs-24">
                                  <i class="las la-file"></i>
                                </div>
                            </div>
                        </div>
                        <div class="flex-grow-1 overflow-hidden">
                            <h5 class="fs-13 mb-1"><a href="#" class="text-body text-truncate d-block">App-pages.zip</a></h5>
                            <div>2.2MB</div>
                        </div>
                        <div class="flex-shrink-0 ms-2">
                            <div class="d-flex gap-1">
                                <button type="button" class="btn btn-icon text-muted btn-sm fs-18">
                                  <i  class="las la-file-download"></i>
                                </button>
                                <div class="dropdown">
                                    <button class="btn btn-icon text-muted btn-sm fs-18 dropdown" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                                      <i  class="las la-ellipsis-h"></i>
                                    </button>
                                    <ul class="dropdown-menu">
                                        <li><a class="dropdown-item" href="#"><i class="ri-pencil-fill align-bottom me-2 text-muted"></i> Rename</a></li>
                                        <li><a class="dropdown-item" href="#"><i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> -->
              <ng-container *ngIf="attechList.length>0;else attachemnt">
                <div class="border rounded border-dashed p-2" *ngFor="let list of attechList">
                  <div class="d-flex align-items-center">
                    <div class="flex-shrink-0 me-3">
                      <div class="avatar-sm">
                        <div class="avatar-title bg-light text-secondary rounded fs-24">
                          <i class="las la-file"></i>
                        </div>
                      </div>
                    </div>
                    <div class="flex-grow-1 overflow-hidden">
                      <h5 class="fs-13 mb-1"><a href="{{list.link}}" class="text-body text-truncate d-block">{{(list.name?.length > 10 ?list.name.substr(0,10)+'...':list.name)}}-{{list.updated_at|date :'short'}}</a></h5>
                      <!-- <div>2.4MB</div> -->
                    </div>
                    <div class="flex-shrink-0 ms-2">
                      <div class="d-flex gap-1" >
                        <a type="button" download href="{{list.link}}" class="btn btn-icon text-muted btn-sm fs-18">
                          <i class="las la-file-download"></i>
                        </a>
                        <div class="dropdown">
                          <button class="btn btn-icon text-muted btn-sm fs-18 dropdown" type="button"
                            data-bs-toggle="dropdown" aria-expanded="false" (click)="docAction(list.id)">
                            <i class="las la-ellipsis-h"></i>
                          </button>
                          <ul class="dropdown-menu" [ngClass]="docStatus==list.id?'on':''">
                            <li>
                              <a class="dropdown-item" href="javascript:void(0)" (click)="deleteAttechment(list.id)"><i class="ri-delete-bin-fill align-bottom me-2 text-muted"></i> Delete
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </ng-container>
              <ng-template #attachemnt>
                <div class="text-center">
                  <img src="../../../assets/file.webp" class="img-fluid">
                  <p class="text-mute">No Attachments Available </p>
                </div>
              </ng-template>
              <div class="mt-2 text-center">
                <button type="button" class="raise-button with-shadow" style="background-color: white; color: #ea556f;" *ngIf="isShow && getAttchmentList.length!==attechList.length" (click)="showMore()">View more
                </button>
                <button class="raise-button with-shadow" style="background-color: white; color: #ea556f;" type="button" disabled *ngIf="!isShow">
                  <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  <span class="visually-hidden">Loading...</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  
  <div class="accordion add-employee " id="accordion-details" *ngIf="tabval=='logs'">
    <div class="card  grow ctm-border-radius">
      <div class="card-header" id="basic1">
        <div class="d-flex justify-content-between align-items-center">
          <ul class="nav nav-tabs border-0">
            <li class="nav-item" *ngIf="lsData.includes('Admin') || lsData.includes('Manager')">
              <a class="nav-link " href="javascript:void(0)" [ngClass]="subtabval=='task_pd'?'active':''"
                (click)="changeSubTab('task_pd')">Estimated Time Logs</a>
            </li>
            <li class="nav-item">
              <a class="nav-link " href="javascript:void(0)" [ngClass]="subtabval=='task_stage'?'active':''"
                (click)="changeSubTab('task_stage')">Task Stage Log</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
          <div class="accordion add-employee " id="accordion-details" *ngIf="subtabval=='task_stage'">
            <div class="card  grow ctm-border-radius">
              <div class="card-header" id="basic1">
                <h4 class="cursor-pointer mb-0">
                  <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
                    data-target="#basic-one" aria-expanded="true">
                    Task Stage
                  </a>
                </h4>
              </div>
              <div class="card-body p-0">
                <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                  data-parent="#accordion-details">
                  <div class="row">
                    <table class="table custom-table table-hover table-hover" id="empolyeeId">
                      <thead>
                        <tr>
                          <th>Sl_no</th>
                          <th>Modified By</th>
                          <th>Before Stage</th>
                          <th>After Stage</th>
                          <th>Change Date</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="taskStage!==undefined && taskStage!==null && taskStage!==''">
                        <tr *ngFor="let stage of taskStage; index as i ">
                          <td>{{i+1}}</td>
                          <td>{{stage.action_by}}</td>
                          <td> <span class="badge bg-primary">{{stage.before_stage}}</span></td>
                          <td> <span class="badge bg-primary">{{stage.after_stage}}</span></td>
                          <td>{{stage.update_on.toLocaleString()}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="accordion add-employee " id="accordion-details" *ngIf="subtabval=='task_pd'">
            <div class="card  grow ctm-border-radius">
              <div class="card-header" id="basic1">
                <h4 class="cursor-pointer mb-0">
                  <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
                    data-target="#basic-one" aria-expanded="true">
                    Task PD Status
                  </a>
                </h4>
              </div>
              <div class="card-body p-0">
                <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                  data-parent="#accordion-details">
                  <div class="row">
                    <table class="table custom-table table-hover table-hover" id="empolyeeId">
                      <thead>
                        <tr>
                          <th>Sl_no</th>
                          <th>Modified By</th>
                          <th>Before PD</th>
                          <th>After PD</th>
                          <th>Change Date</th>
                        </tr>
                      </thead>
                      <tbody *ngIf="pdDetails!==undefined && pdDetails!==null && pdDetails!==''">
                        <tr *ngFor="let pd of pdDetails.pdData; index as i ">
                          <td>{{i+1}}</td>
                          <td>{{pd.action_by}}</td>
                          <td>{{pd.before_pd}} PD</td>
                          <td>{{pd.after_pd}} PD</td>
                          <td>{{pd.update_on.toLocaleString()}}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <!-- task edit -->
  <div class="accordion add-employee " id="taskedit" [ngClass]="docStatus==1?'on':''">
  
    <div class="card  grow ctm-border-radius shadow-none">
      <div class="modal-header p-3 ps-4 bg-soft-success">
        <h5 class="modal-title" id="inviteMembersModalLabel">Edit Task</h5>
        <button type="button" class="btn-close" (click)="docAction(0)"></button>
      </div>
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
          <form [formGroup]="taskForm" (ngSubmit)="onSubmit(taskForm)" #fd="ngForm" [ngxFvControl]="fd">
            <div class="row">
              <div class="col-lg-6 form-group">
                <label for="">Project name</label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="las la-user-secret"></i>
                  </div>
                  <input type="text" value="bookyup project" class="form-control" readonly="true" #ngxFvFormControl
                    formControlName="p_name">
                </div>
              </div>
              <div class="col-lg-6 form-group">
                <label>
                  Module Name
                </label>
                <div class="input-group mb-3" *ngIf="taskData!=undefined">
                  <div class="input-group-text"><i class="las la-user-secret"></i>
                  </div>
                  <input type="text" class="form-control" readonly="true" #ngxFvFormControl formControlName="m_name">
                </div>
              </div>
              <div class="col-lg-6 form-group" *ngIf="taskData!=undefined">
                <ngx-fv-validate [control]="taskForm.controls['task_name']">
                  <label #ngxFvName>Task name</label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-user-secret"></i>
                    </div>
                    <input type="text" #ngxFvFormControl formControlName="task_name" class="form-control" readonly="true">
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="taskForm.controls['task_type']">
                  <label #ngxFvName>
                    Task Type
                  </label>
                  <div class="input-group mb-3" *ngIf="taskData!=undefined">
                    <div class="input-group-text"><i class="las la-user-secret"></i>
                    </div>
                    <!-- <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      #ngxFvFormControl formControlName="task_type">
                      <option value="">Task Type </option>
                      <option value="Business Menu Work">Business Menu Work</option>
                      <option value="designing"> Designing</option>
                      <option value="development">Development</option>
                      <option value="Email Marketing">Email Marketing</option>
                      <option value="Facebook Content Writing">Facebook Content Writing</option>
                      <option value="Facebook Creative Design ">Facebook Creative Design </option>
                      <option value="Website Creative Design ">Website Creative Design </option>
                      <option value="Website Content Writing">Website Content Writing</option>
                    </select> -->
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"  ngxFvFormControl formControlName="task_type">
                      <option value="">Select Type</option>
                      <ng-container *ngFor="let task of taskTypeList">
                        <option value="{{task.type_name}}">{{task.type_name| titlecase}}</option>
                      </ng-container>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="taskForm.controls['task_estimated_pd']">
                  <label #ngxFvName>Estimated Hours</label>
                  <div class="input-group mb-3" *ngIf="taskData!=undefined">
                    <div class="input-group-text"><i class="las la-business-time"></i>
                    </div>
                    <input type="number" min="1" max="20" value="{{taskData.estimated_hours}}" #ngxFvFormControl
                      formControlName="task_estimated_pd" class="form-control">
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group" *ngIf="taskData!=undefined">
                <ngx-fv-validate [control]="taskForm.controls['task_status']">
                  <label #ngxFvName>
                    Task Status
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-user-secret"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      #ngxFvFormControl formControlName="task_status">
                      <option value="" selected> Select Status</option>
                      <option value="active"> Active</option>
                      <option value="inactive">Inactive</option>
                      <option value="stop"> Stop</option>
                      <option value="finish">Finish</option>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-12 form-group" *ngIf="taskData!=undefined">
                <ngx-fv-validate [control]="taskForm.controls['task_description']">
                  <label #ngxFvName>
                    Task Description
                  </label>
                  <div class="input-group mb-3">
                    <textarea class="form-control" placeholder="test description  "
                      formControlName="task_description"></textarea>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
                  <div class="col-lg-12 form-group">
                    <div class="input-group mb-3" *ngIf="isShow">
                      <button class="btn custom-btn btn-8 updbtn"><span>Update</span></button>
                    </div>
                  </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  
  <div class="memberList" [ngClass]="this.docStatus==2?'on':''" *ngIf="itemList.length>0">
    <div class="TopList">
      <div class="modal-content border-0">
        <div class="modal-header p-3 ps-4 bg-soft-success">
          <h5 class="modal-title" id="inviteMembersModalLabel">Members </h5>
          <button type="button" class="btn-close" (click)="docAction(0)"></button>
        </div>
        <div class="modal-body p-4">
          <div class="search-box mb-3">
            <input type="text" (keyup)="searchType($event)" class="form-control bg-light border-light u_find" placeholder="Search here..." [(ngModel)]="multiSearch">
            <!-- <i class="las la-search"></i> -->
          </div>
          <div class="mx-n4 px-4" class="cardmembers">
            <div class="simplebar-wrapper">
              <div class="simplebar-height-auto-observer-wrapper">
                <div class="simplebar-height-auto-observer">
                </div>
              </div>
              <div class="simplebar-mask">
                <div class="simplebar-offset">
                  <div class="simplebar-content-wrapper">
                    <div class="simplebar-content">
                      <div class="vstack gap-3">
                        <div class="d-flex align-items-center" *ngFor="let usr of itemList|tableSearch:objKey;">
                          <div class="avatar-xs flex-shrink-0 me-3">
                            <h4 class="userShape-Sm" [ngStyle]="{'background-color':hsl}">
                              {{convertToLetter(usr.name)}}
                            </h4>
                          </div>
                          <div class="flex-grow-1">
                            <h5 class="fs-13 mb-0"><a href="javascript:void(0)" class="text-body d-block">{{usr.name}}</a></h5>
                          </div>
                          <div class="flex-shrink-0">
                           <svg xmlns="http://www.w3.org/2000/svg" (click)="select_user(usr.id,usr.name)" width="20" height="20" fill="#ea556f" class="bi bi-plus-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16"/>
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4"/>
                            </svg>
                          </div>
                        </div>
                        <!-- end member item -->
                      </div>
                      <!-- end list -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" (click)="docAction(0)" class="btn btn-light w-xs">Cancel</button>
        </div> -->
      </div>
      <!-- end modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>
  <!-- task edit -->
  
  <!-- Add tag -->
  <div class="memberList" [ngClass]="docStatus==3?'on':''">
    <div class="TopList">
      <div class="modal-content border-0">
        <div class="modal-header p-3 ps-4 bg-soft-success">
          <h5 class="modal-title" id="inviteMembersModalLabel">Tags</h5>
          <button type="button" class="custom-btn btn-8 text-center" (click)="docAction(0)"></button>
        </div>
        <div class="modal-body p-4">
          <!-- <div class="search-box mb-3">
            <input type="text" class="form-control bg-light border-light" placeholder="Search here...">
            <i class="las la-search"></i>
          </div> -->
          <div class="mx-n4 px-4" class="cardmembers">
            <div class="simplebar-wrapper">
              <div class="simplebar-height-auto-observer-wrapper">
                <div class="simplebar-height-auto-observer"></div>
              </div>
              <div class="simplebar-mask">
                <div class="simplebar-offset">
                  <div class="simplebar-content-wrapper">
                    <div class="simplebar-content">
                      <div class="vstack gap-3" *ngIf="tagList!==undefined && tagList!=null && tagList.length>0">
                        <div class="d-flex align-items-center" *ngFor="let tag of tagList">
                          <div class="flex-grow-1">
                            <h5 class="fs-13 mb-0"><a href="javascript:void(0)" class="text-body d-block">{{tag.name}}</a></h5>
                          </div>
                          <div class="flex-shrink-0">
                            <!-- <button type="button" class="btn btn-light btn-sm" (click)="addTag(tag.id)">Add</button> -->
                          </div>
                        </div>
                      </div>
                      <!-- end list -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-light w-xs" (click)="docAction(0)">Cancel</button>
        </div> -->
      </div>
      <!-- end modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>
  </div>
  
  <ng-template #editModal let-modal>
    <!-- <div class="modal-header">
      <span><h3>Disclaimer: </h3>If you are tagging your messages to other members,tag them first before writing your message!</span>
    </div> -->
    <div class="modal-body">
      <div class="wrap">
        <form [formGroup]="tagForm" (ngSubmit)="tagMembers(tagForm)" #fd="ngForm" [ngxFvControl]="fd">
          <div class="row">
            <div class="col-lg-12 form-group">
              <ngx-fv-validate [control]="tagForm.controls['tagged_user']">
                <label #ngxFvName>
                  Select Members
                </label>
                <ng-select formControlName="tagged_user" [(ngModel)]="selectedIds" [multiple]="true" [closeOnSelect]="false" >
                  <ng-option *ngFor="let user of empList;" [value]="user.id">{{user.name|titlecase}}</ng-option>
                </ng-select>
                <ngx-fv-error></ngx-fv-error>
              </ngx-fv-validate>
            </div>
          </div>
          <br>
      
          <div class="col-lg-12 form-group">
            <div class="input-group mb-3" *ngIf="isShow">
              <button class="btn custom-btn btn-8" (click)="closeModal()"><span>Tag</span></button>
            </div>
          </div>
        </form>
      </div>
    </div>
    <!-- <div class="modal-footer">
      <button type="button" class="btn btn-outline-dark" (click)="modal.close()">Cancel</button>
    </div> -->
  </ng-template>
  
  <ng-template #dataNotLoaded>
    <ngx-ui-loader></ngx-ui-loader>
  </ng-template>