import { Component, OnInit } from '@angular/core';
import { RestService } from '../../rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { AuthServiceService } from '../../auth-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PagesService } from '../pages.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CurrencyPipe } from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-create-task',
  templateUrl: './create-task.component.html',
  styleUrls: ['./create-task.component.scss'],
})
export class CreateTaskComponent implements OnInit {
  loadDataInfomationStatus = false;
  taskTypeList = <any>[];
  items = <any>[];
  projectList = <any>[];
  moduleList = <any>[];
  userList = <any>[];
  repeat_day = <any>[];
  taskForm!: FormGroup;
  taskData = <any>[];
  error = false;
  cloneData: any;
  cloneProjectId = '';
  cloneModuleId = '';
  isShown: boolean = false;
  cloneKey: any;
  isShow = true;
  public tagList: any;
  public showMembers: boolean = false;
  public formData = new FormData();
  selected = '';
  public isUnassign:boolean = false;
  public loadAllComponant = false;
  public loggedInUserName: string = '';
  public fileType = [
    'image/jpeg', //image
    'image/png', //image
    'image/gif', //image
    'text/csv', //CSV
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //XLSX
    'application/vnd.ms-excel', //XLS
    'application/x-zip-compressed', //zip
    'application/pdf', //PDF
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //DOCX
  ];
  constructor(
    public rest: RestService,
    private formBuilder: FormBuilder,
    private ls: LocalStorageService,
    private auth: AuthServiceService,
    private route: Router,
    private toaster: ToastrService,
    private pageService: PagesService,
    private activatedroute: ActivatedRoute,
    private ngxService: NgxUiLoaderService
  ) {}

  ngOnInit(): void {
    this.getUserData();
    this.cloneKey = this.activatedroute.snapshot.queryParamMap.get('clone');
    this.getProject();
    this.getTaskType();
    /* This is object set for adding validation on selected fileds */
    this.taskForm = this.formBuilder.group({
      task_name: [null, [Validators.required]],
      task_estimated_pd: [null, [Validators.required]],
      task_status: ['', [Validators.required]],
      task_type: ['', [Validators.required]],
      task_description: [null, [Validators.required]],
      users: [null, [Validators.required]],
      module_id: ['', [Validators.required]],
      task_repeat: ['0'],
      tags: [],
    });
    if (this.cloneKey == 'true') {
      this.setFormValue();
    }
  }

  getUserData() {
    this.rest.getData({}, 'getloginuser').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.loggedInUserName = data.data.name;
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning("Authorization error!!");
      },
    });
  }

  get module_id() { return this.taskForm.get('module_id'); }
  /* This function is used to set clone task data */
  setFormValue() {
    this.cloneData = this.pageService.getData();
    let assignToIds = <any>[];
    console.log(this.cloneData);
    this.cloneData.asigned_data.forEach((element: any) => {
      assignToIds.push(element.id);
    });
    console.log(this.cloneData.assignToIds);
    if (this.cloneData !== undefined) {
      this.taskForm.patchValue({
        task_name: this.cloneData.task_name,
        task_estimated_pd: this.cloneData.estimated_hours,
        task_status: this.cloneData.task_status,
        task_type: this.cloneData.task_type,
        task_description: this.cloneData.task_description,
        users: assignToIds,
      });
      this.cloneProjectId = this.cloneData.project_key;
      this.cloneModuleId = this.cloneData.module_key;
      this.getModule(this.cloneProjectId);
    } else {
      this.cloneKey == 'false';
    }
  }

  loadDataInfomation(e: any) {
    this.loadDataInfomationStatus = !this.loadDataInfomationStatus;
  }

  loadDataModule(e: any) {
    this.items = [];
    this.getModule(e.target.value);
  }
  /* This function is used to fetch all project */
  getProject() {
    this.rest.getData({}, 'getallproject').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.projectList = data.data;
        } else {
          this.toaster.warning(data.message);
        }
        this.getTagList();
      },
      error: (e) => {
        this.loadAllComponant = true;
        this.toaster.error('Something went wrong!!');
      },
    });
  }
  /* This function is used to fetch all module */
  getModule(pid: any) {
    if (pid == '' || pid == undefined) {
      this.toaster.error('Please select project name!');
    }
    this.getUser(pid);
    this.rest.getData({ project_id: pid }, 'getmodule').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.moduleList = data.data;
          for (let module of this.moduleList) {
            this.items.push({ m_id: module.m_id, m_name: module.m_name, id: module.id })
          }
          if (this.cloneKey == 'true') {
            this.taskForm.patchValue({
              module_id: this.cloneModuleId,
            });
          }
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.toaster.error('Something went wrong!!');
      },
    });
  }
  /* This function is used to fetch assigned user of project for assigning as a task users */
  getUser(pid: any) {
    if (pid == '' || pid == undefined) {
      this.toaster.error('Please select project name!');
    }
    this.rest.getData({ project_id: pid }, 'getprojectuserlist').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.userList = data.data[0].users;
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.toaster.error('Something went wrong!!');
      },
    });
  }

  removeUnassign(){
    if(this.taskForm.controls['task_status'].value == 'unassigned'){
      this.taskForm.patchValue({users:['Unassigned']})
    }else{
      this.taskForm.patchValue({users:''})
    }
  }

  identifyUser(){
    console.log(this.taskForm.controls['users'].value);
  }
  /* This function is used to create new task */
  onSubmit(form: FormGroup) {
    if (form.invalid) {
      return;
    }
    if (form.value.task_estimated_pd > 20) {
      this.toaster.error('Maximum estimated time is 20 hours!');
      return;
    }
    if (form.value.task_description.replace(/ /g, '').length < 20) {
      this.toaster.error('Minimum characters length should be 20 characters!');
      return;
    }
    this.ngxService.start();
    this.isShow = false;
    Object.entries(this.taskForm.value).forEach(([key, value]: any[]) => {
      if (key == 'users') {
        value.forEach((v: number) => {
          if(v.toString() == 'Unassigned'){
            v = 0
          }
          this.formData.append('users[]', v.toString());
        });
      } else if (key == 'tags') {
        if (value !== null && value !== undefined) {
          value.forEach((v: number) => {
            this.formData.append('tags[]', v.toString());
          });
        }
      } else {
        this.formData.append(key, value);
      }
    });
    this.repeat_day.forEach((v: number) => {
      this.formData.append('repeat_day[]', v.toString());
    });
    this.rest.getData(this.formData, 'addtask', true).subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.isShow = true;
        if (data.state == '200') {
          this.toaster.success(data.message);
          setTimeout(() => {
            this.route.navigate(['task']);
          }, 1500);
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.isShow = true;
        this.toaster.error('Something went wrong');
      },
    });
  }
  /* This function is used to add days for repeating task */
  addDay(ch: any, e: any) {
    if (e.target.checked) {
      this.repeat_day.push(ch);
    } else {
      for (var i = 0; i < this.repeat_day.length; i++) {
        if (this.repeat_day[i] === ch) {
          this.repeat_day.splice(i, 1);
        }
      }
    }
  }

  isRecurring(event: any) {
    let checkRecurring = event.currentTarget.value;
    if (checkRecurring == 0) {
      this.isShown = false;
    } else if (checkRecurring == 1) {
      this.isShown = true;
    }
  }
  /* This function is used to push files to formdata */
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        let size = (event.target.files[i].size / 1024 / 1024) | 0;
        if (this.fileType.includes(event.target.files[i].type) && size <= 4) {
          this.formData.append('files[]', event.target.files[i]);
        }
      }
    }
  }
  /* This function is used to fetch tag list */
  getTagList() {
    this.rest.getData({}, 'gettaglist').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.tagList = data.data;
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        //this.toaster.error('Something went wrong!!');
      },
    });
  }

  addNewTag = (term: string) => {
    this.selected = term;
    return term;
  }

  getTaskType() {
    this.rest.getData({}, 'gettasktypes').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.taskTypeList = data.data;
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        console.log(e);
        // this.toaster.warning("Something went wrong!");
      },
    });
  }

  addTaskType(type: any = '') {
    let mtype = type.target.value;
    if (mtype == 'Add New') {
      this.taskForm.reset();
      Swal.fire({
        title: 'Enter new type',
        width: 400,
        input: 'text',
        inputAttributes: {
          autocapitalize: 'on'
        },
        iconColor:"#ea556f",
        showCancelButton: true,
        confirmButtonText: 'Add',
        showLoaderOnConfirm: true,
        preConfirm: (t_payload) => {
          this.rest.getData({ type: t_payload }, 'addtasktype').subscribe({
            next: (data) => {
              if (data.state == '200') {
                this.getTaskType();
                this.toaster.success("Added!");
              } else {
                this.toaster.warning(data.message);
              }
            },
            error: (e) => {
              this.isShow = true;
              this.error = true;
              //this.toaster.error('Something went wrong!');
            },
          });
        },
      })
    }
  }

  // eventCheck(event:any){
  //   if(event.target.checked == true){
  //     this.taskForm.patchValue({users:['Unassigned']})
  //   }else if(event.target.checked == false){
  //     this.taskForm.patchValue({users:''})
  //   }
  // }
}
