import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'app-roster-list',
  templateUrl: './roster-list.component.html',
  styleUrls: ['./roster-list.component.scss']
})
export class RosterListComponent implements OnInit {
  rosterList = <any>[];
  public searchKey = '';
  public selectedfield = '';
  public objKey: any;
  shiftForm!: FormGroup;
  employeeList = <any>[];
  shiftName: string = '';
  employeeName: string = '';
  selectedUser: string = '';
  startDate!: string;
  endDate!: string;
  public multiSearch: string = '';
  weekdays: string[] = [];
  dayNames:any[] = [];
  selectedEmpId:any='';
  timetableData: { user_id: any; shift_name: string; r_id:any;data: any[] } = {
    user_id: '',
    shift_name: '',
    r_id:'',
    data: []
  };
  isEditable = true;
  isClone = false;
  public scrollList = true;
  public filter_status = false;
  public projectSearch: string = '';
  public filterData: any;
  public rosterSearch: any;
  public empSearch: any;
  public dateSearch: any;
  public nameFilterList = <any>[];
  public employeeFilterList = <any>[];
  public dateFilterList = <any>[];
  
  isContentVisible: boolean = false;
  days: any[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  country:any = 'Bangaldesh';
  showModal: boolean = false;
  showCloneModal: boolean = false;
  constructor(
    public rest: RestService,
    private route: Router,
    private toaster: ToastrService,
    private fb: FormBuilder
  ) {
    this.shiftForm = this.fb.group({
      user: [[]], // Bind to the dropdown
    });
  }

  ngOnInit(): void {
    this.getRosterList();
    this.getEmployees();
  }
  
  openModal() {
    this.showModal = true;
  }

  openCloneModal() {
    this.showCloneModal = true;
  }

  // Close Modal (Reset States)
  closeModal() {
    this.showModal = false;
    this.timetableData = { user_id: '', shift_name: '', r_id:'', data: [] }; // Reset on close
  }

  closeCloneModal() {
    this.showCloneModal = false;
    this.timetableData = { user_id: '', shift_name: '', r_id:'', data: [] }; // Reset on close
  }
  onNameChange(newName: string) {
    this.shiftName = newName;
  }


  getRosterList() {
    this.rest.getData({}, 'getrostershiftlist').subscribe({
      next: (data) => {
        if (data.state === '200') {
          this.rosterList = data.data;
          this.createFilterData();
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: () => this.toaster.error('Something went wrong')
    });
  }

  getWeekdays() {
    if (!this.startDate || !this.endDate) {
      this.toaster.warning('Please select both start and end dates.');
      return;
    }
    let start = new Date(this.startDate);
    let end = new Date(this.endDate);
    if (start > end) {
      this.toaster.warning('Start date must be before or equal to end date.');
      return;
    }
    this.weekdays = [];
    let currentDate = start;
    while (currentDate <= end) {
      this.weekdays.push(this.days[currentDate.getDay()]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  getEmployees() {
    this.rest.getData({country:this.country},'getbdemployess').subscribe({
      next: (data) => {
        if (data.state === '200') {
          this.employeeList = data.data;
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: () => this.toaster.error('Something went wrong')
    });
  }

  encodeURIParam(param:any){
    return param.replace('/','_');
  }

  // View Roster (Modify Payload)
  viewRoster(dataObj: any[],shiftTitle:any,rId:any,eName:any) {
    this.employeeName = eName;
    this.shiftName = shiftTitle;
    this.timetableData = {
      user_id: '',
      shift_name: '',
      r_id:rId,
      data: []
    };
    dataObj.forEach((dta: any) => {
      this.timetableData.data.push({
        date: dta.r_date,
        day: dta.r_day,
        startTime: dta.r_start_time || '',
        endTime: dta.r_end_time || '',
        weekoff: dta.r_weekoff || false,
      });
    });
    this.showModal = true;
  }

  viewCloneRoster(dataObj: any[],shiftTitle:any,r_Id:any,eName:any) {
    this.employeeName = eName;
    this.shiftName = shiftTitle;
    this.timetableData = {
      user_id: this.selectedUser || '',
      shift_name: this.shiftName || '',
      r_id: '',
      data: []
    };
    dataObj.forEach((dta: any) => {
      this.timetableData.data.push({
        date: dta.r_date,
        day: dta.r_day,
        startTime: dta.r_start_time || '',
        endTime: dta.r_end_time || '',
        weekoff: dta.r_weekoff || false,
      });
    });
    this.showCloneModal = true;
  }


  SubmitCloneReq() {
    if(!this.shiftName && !this.selectedUser){
      this.toaster.warning("Shift name and Employee is required");
      return;
    }
    if(!this.shiftName){
      this.toaster.warning("Please enter Shift name");
      return;
    }
    if(!this.selectedUser){
      this.toaster.warning("Please select Employee first");
      return;
    }
    this.timetableData.user_id = this.selectedUser;
    this.timetableData.shift_name = this.shiftName;
    this.rest.getData(this.timetableData, 'createrostershift').subscribe({
      next: (data) => {
        if(data.state=='200'){
          this.getRosterList();
          this.closeCloneModal();
          this.toaster.success(data.message);
        }else{
          this.toaster.error(data.message);
        }
      },error: (e) => {
        this.toaster.error('Something went wrong');
      },
    });
  }

  // Final Submission (Include Employee and Shift Name)
  finalSubmit() {
    this.timetableData.user_id = this.selectedUser;
    this.timetableData.shift_name = this.shiftName;
    console.log('Submitting Updated Data:', this.timetableData);
    
    this.rest.getData(this.timetableData, 'createrostershift').subscribe({
      next: (data) => {
        if(data.state=='200'){
          this.getRosterList();
          this.closeModal();
          this.toaster.success(data.message);
        }else{
          this.toaster.error(data.message);
        }
      },error: (e) => {
        this.toaster.error('Something went wrong');
      },
    });
  }

  deleteRoster(rId:any){
    this.rest.getData({r_id:rId}, 'deleteroste').subscribe({
      next: (data) => {
        if(data.state=='200'){
          this.getRosterList();
          this.closeModal();
          this.toaster.success(data.message);
        }else{
          this.toaster.error(data.message);
        }
      },error: (e) => {
        this.toaster.error('Something went wrong');
      },
    });
  }

  changeCountry(): void {
    this.country = this.country === 'Bangaldesh' ? 'India' : 'Bangaldesh';
    console.log(this.country);
    this.getEmployees();
  }

  getCountryCode(country: string): string {
    switch (country) {
      case 'India': return 'IND';
      case 'Bangladesh': return 'BD';
      default: return country;
    }
  }

  filter_status_btn() {
    this.filter_status = !this.filter_status;
  }

  deSelect(event: any, filedType: string) {
    this.selectedfield = filedType;
    if (filedType === 'r_name') {
      this.empSearch = undefined;
      this.dateSearch = undefined;
      this.searchKey = this.rosterSearch;
    } else if (filedType === 'employee') {
      this.rosterSearch = undefined;
      this.dateSearch = undefined;
      this.searchKey = this.empSearch;
    } else if (filedType === 'date_range') {
      this.empSearch = undefined;
      this.rosterSearch = undefined;
      this.searchKey = this.dateSearch;
    }
  }

  createFilterData() {
    let rNameFilterList = [];
    let rEmpFilterList = [];
    let rDateFilterList = [];
    for (let roster of this.rosterList) {
      rNameFilterList.push(roster.r_name);
      rEmpFilterList.push(roster.employee);
      rDateFilterList.push(roster.date_range);
      console.log("filter",roster);
    }
    this.nameFilterList   = [...new Set(rNameFilterList)];
    this.employeeFilterList   = [...new Set(rEmpFilterList)];
    this.dateFilterList = [...new Set(rDateFilterList)];
  }

  searchType(event: any) {
    let searchkey = event.target.value;
    if (searchkey.length > 2) {
      this.scrollList = false;
      this.findRoster(searchkey);
    } else if (searchkey.length == 0) {
      this.scrollList = true;
      this.rosterList = [];
      this.getRosterList();
    }
  }

  findRoster(key = '') {
    let key1 = '';
    if(key !== '' || key !== undefined){
      key1 = key;
    }
    this.rest.getData({keywords:key1}, 'getrostershiftlist').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.rosterList = [];
          this.rosterList = data.data;
          this.createFilterData();
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        //this.toaster.error('Something went wrong');
      },
    });
  }

  autoFillTimes(index: number, field: 'startTime' | 'endTime', value: string): void {
    if (index === 0) {
      // Auto-fill only if it's the first row
      for (let i = 1; i < this.timetableData.data.length; i++) {
        this.timetableData.data[i][field] = value;
      }
    }
  }

  onWeekOffChange(index: number, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const isChecked = checkbox.checked;
  
    if (isChecked) {
      this.timetableData.data[index].startTime = null;
      this.timetableData.data[index].endTime = null;
    }
  }

}
