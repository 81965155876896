<div class="main-content">
  <div class="ctm-border-radius  grow card">
    <div id="basic1" class="card-header d-flex align-center justify-content-between">
      <div class="card_title_left">
        <h4 class="card-title  mb-0 mt-0"> <b><i class="las la-file"></i> Send Email Notification</b></h4>
      </div>
    </div>
    <div class="card-body">

      <div class="table-back employee-office-table">
      <div class="table-responsive">
          <table class="table custom-table table-hover table-hover table-responsive">
            <thead>
              <tr>
                <th class="text-center thead-default">SL_no</th>
                <th>Report</th>
                <th>Description</th>
                <th>Recipient</th>
                <th>Day</th>
                <th class="text-center">Action</th>
              </tr>
            </thead>
            <tbody>
              
              <tr>
                <td class="text-center">1</td>
                <td><b>Daily Work Report</b> </td>
                <td>Daily employee working hours and task report.</td>
                <td>Admin</td>
                <td class="text-right">
                  <select name="dateChange" id="dateChange" (change)="reportDay('daily_work',$event.target)">
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                  </select>
                </td>
                <td class="text-center">
                  <div class="card_right_ d-flex align-items-center justify-content-center">
                    <a (click)="sendReport('working-report')" class="custom-btn btn-8 text-center"><span>
                      <i class="las la-plus"></i>{{buttonThree}}</span>
                    </a>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="text-center">2</td>
                <td><b>Under Working Report</b> </td>
                <td>Employees who worked less than 6 hours</td>
                <td>Admin</td>
                <td class="text-right">
                  <select name="dateChange" id="dateChange" (change)="reportDay('under_work',$event.target)">
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                  </select>
                </td>
                <td class="text-center">
                  <div class="card_right_ d-flex align-items-center justify-content-center">
                    <a (click)="sendReport('under-working')" class="custom-btn btn-8 text-center"><span>
                      <i class="las la-plus"></i>{{buttonTwo}}</span>
                    </a>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="text-center">3</td>
                <td><b>Timesheet Defaulter</b></td>
                <td>Employees who missed submitting their timesheets yesterday will be considered defaulters</td>
                <td>Employee</td>
                <td class="text-right">
                  Yesterday
                  <!-- <select name="dateChange" id="dateChange" (change)="reportDay('dafaulter',$event.target)">
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                  </select> -->
                </td>
                <td class="text-center">
                  <div class="card_right_ d-flex align-items-center justify-content-center">
                    <a (click)="sendReport('timesheet-defaulter')" class="custom-btn btn-8 text-center"><span>
                      <i class="las la-plus"></i>{{buttonOne}}</span>
                    </a>
                  </div>
                </td>
              </tr>

              <tr>
                <td class="text-center">3</td>
                <td><b>Reminder</b></td>
                <td>Send reminder emails if any reminders are scheduled for today (every early morning).</td>
                <td>Employee</td>
                <td class="text-right">
                  Today
                  <!-- <select name="dateChange" id="dateChange" (change)="reportDay('dafaulter',$event.target)">
                    <option value="today">Today</option>
                    <option value="yesterday">Yesterday</option>
                  </select> -->
                </td>
                <td class="text-center">
                  <div class="card_right_ d-flex align-items-center justify-content-center">
                    <a (click)="sendReport('reminder')" class="custom-btn btn-8 text-center"><span>
                      <i class="las la-plus"></i>{{buttonFour}}</span>
                    </a>
                  </div>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
  <ngx-ui-loader></ngx-ui-loader>