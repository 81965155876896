import { Component, OnInit} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { LocalStorageService } from 'ngx-webstorage';
import { RestService } from 'src/app/rest.service';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PagesService } from '../../pages.service';
import { FormBuilder ,FormGroup,Validators} from '@angular/forms';
@Component({
  selector: 'app-my-dashboard',
  templateUrl: './my-dashboard.component.html',
  styleUrls: ['./my-dashboard.component.scss']
})
export class MyDashboardComponent implements OnInit {

  public activityTab   = false;
  public leaveTab      = false;
  public taskTab       = false;
  public noteTab       = false;
  public isShown       = false;
  public isAdmin       = false;
  public hsl           : any;
  public startDate     : any;
  public minDateLimit  : any;
  public achivementTab = false;
  public clock: string = "";
  public empId         = '';
  public reportTab     = false;
  public noticeTab     = false;
  public today         : any = formatDate(new Date(), 'yyyy-MM-dd', 'en');
  public getReminders  : any;
  public now           : any;
  public rStatus       = '';
  public empData:any   = [];
  public selectedUsers : any = [];
  public announcements:any = [];
  public taskList:any  = [];
  public activeLeveList:any = [];
  public remimderForm!   : FormGroup;
  public taskMessages = <any>[];
  public leave:any = 0;
  public taskStatistics:any = [];
  public attendance:any = 0;
  public motivationalQuotes:any ='';
  public quote:any ='';
  public months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
  public days = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
  public TaskNameChat:any       ='';
  public birthDayBoy:any = [];
  public getToday        : any = new Date();
  public bdPayload: Date = new Date();
  public u_id = 0;
  public t_id:any;
  public reminderTitle:any = 'No Event'

  constructor(
    private formBuilder: FormBuilder,
    private modalService: NgbModal,
    public rest        : RestService,
    public pageService : PagesService,
    private localSt    : LocalStorageService,
    private toaster    : ToastrService,
    public ngxService  : NgxUiLoaderService

  ) {
  }

  ngOnInit(): void {
    // this.getCurrentDateAndMonth();
    this.toggleTab('activity');
    this.getUserInfo();
    this.getRecentActivity();
    this.remimderForm = this.formBuilder.group({
      title        : [null, [Validators.required]],
      description  : [null, [Validators.required]],
      reminder_date: [null, [Validators.required]],
      r_id         : [null],
      memberlist   : [null],
      reminder_time: [null],
      task_id      : [null],
      priority     : [null, [Validators.required]]
    });
  }

  getReminder(id:any) {
    this.rest.getData({}, 'getreminder').subscribe({
      next: (data) => {
        this.getReminders = data.data;
        let hours = this.getToday.getHours();
        let minutes = this.getToday.getMinutes();
        const ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12; // the hour '0' should be '12'
        hours = hours < 10 ? '0' + hours : hours;
        // appending zero in the start if hours less than 10
        minutes = minutes < 10 ? '0' + minutes : minutes;
        this.now = hours + ':' + minutes + ' ' + ampm;
      }
    })
  }

  reminderStatusByDate(rdate:Date) {
    if(this.today > rdate){
      this.rStatus = '#Expired';
    }else if(this.today < rdate){
      this.rStatus = '#Upcomming';
    }else {
      this.rStatus = '#Today';
    }
    return this.rStatus;
  }

  getUserInfo() {
    this.rest.getData({}, 'getloginuser').subscribe({
      next: (data) => {
        this.empData = data.data;
        this.leave = data.data.leaves;
        this.attendance = data.data.attendanceCount;
      }
    })
  }

  getRecentActivity(){
    this.rest.getData({},'activitydata').subscribe({
      next:(data) => {
        console.log(data.data);
        this.empData = data.data;
        this.reminderTitle = this.empData.reminders.title;
        this.announcements = this.empData.announcement;
        console.log(this.announcements);
        this.u_id = data.data.employee_info.u_id;
        this.taskStatistics = data.data.task_statistics;
        this.taskMessages = data.data.conversation.msg_data;
        this.TaskNameChat = data.data.conversation.task;
        this.t_id = data.data.conversation.msg_data[0].t_id;
      }
    })
  }

  getTasksByDate(range:any){
    if(range > 0){
      this.rest.getData({range:range},'tasksbydate').subscribe({
        next:(data) => {
          this.taskList = data.data;
        }
      })
    }
  }

  getEmpLeaves(){
    this.rest.getData({},'getempleavedata').subscribe({
      next:(data) => {
        this.activeLeveList = data.data.pending;
      }
    })
  }

  // helpers

  humanize(str:any) {
    str = str.replace(/[$@%-/_/]/g, ' ');
    return str.toUpperCase();
  }

  dateConvert(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    const convertDate = year + '-' + month + '-' + day;
    return convertDate;
  }

  addEditReminder(form: FormGroup) {
    if (form.invalid) {
      return;
    } else {
      let apiPayload = { ...form.value };
      if (apiPayload.r_id != null) {
        apiPayload.reminder_date = this.dateConvert(apiPayload.reminder_date)
        this.rest.getData(apiPayload, 'updatereminder').subscribe({
          next: (data) => {
            if (data.state == '200') {
              this.toaster.success(data.message);
              this.getReminder(this.empId);
              form.reset();
            } else {
              this.toaster.success(data.message)
            }
          }
        })
      } else {
        apiPayload.reminder_date = this.startDate;
        this.rest.getData(apiPayload, 'createreminder').subscribe({
          next: (data) => {
            if (data.state == '200') {
              this.toaster.success(data.message);
              this.getReminder(this.empId);
              form.reset();
            } else {
              this.toaster.success(data.message)
            }
          }
        })
      }
    }
  }

  deleteRem(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes',
      width: 400,
    }).then((result) => {
      if (result.isConfirmed) {
        if (id != '' && id != undefined) {
          this.rest.getData({ r_id: id }, 'deletereminder').subscribe({
            next: (data) => {
              if (data.state == '200') {
                this.getReminders = data.data;
                this.toaster.success(data.message)
                this.getReminder(this.empId);
              } else {
                this.toaster.warning(data.message)
              }
            }
          })
        }
      }
    })
  }

  reminderStatusMsg(status:any,date:any,title:any)
  {
    let statusM = '';
    if(status == '0' && date < this.today){
      statusM = 'Upcomming';
    }else if(date === this.today){
      statusM = 'Today';
    }else if(status == '1'){
      statusM = 'Completed'
    }else if(status == '2'){
      statusM = 'Expired'
    }
    return statusM;
  }


  // getQuotes() {
  //   fetch("https://type.fit/api/quotes")
  //     .then((response) => response.json())
  //     .then((quotesData) => (this.motivationalQuotes = quotesData));
  //     console.log("--",this.motivationalQuotes);
  // }

  convertToAmPm(time24h:any) {
    let timeParts = time24h.split(':');
    let hours = parseInt(timeParts[0]);
    let minutes = parseInt(timeParts[1]);
    let amPm = (hours >= 12) ? 'PM' : 'AM';
    hours = (hours > 12) ? hours - 12 : hours;
    hours = (hours == 0) ? 12 : hours;
    return hours.toString().padStart(2,'0')+':'+ minutes.toString().padStart(2,'0')+' '+amPm;
  }


  encodeURIParam(param:any){
    return param.replace('/','_');
  }

  toggleTab(tab:any=''){
    if(tab == 'activity'){
      this.activityTab = true;
      this.noticeTab = false;
      this.leaveTab = false;
      this.taskTab = false;
      this.noteTab = false;
      this.achivementTab = false;
    }else if(tab == 'task'){
      this.getTasksByDate(30);
      this.taskTab = true;
      this.activityTab = false;
      this.noticeTab = false;
      this.leaveTab = false;
      this.noteTab = false;
    }else if(tab == 'achivement'){
      this.achivementTab = true;
      this.activityTab = false;
      this.noticeTab = false;
      this.leaveTab = false;
      this.taskTab = false;
      this.noteTab = false;
    }else if(tab == 'leave'){
      this. getEmpLeaves();
      this.leaveTab = true;
      this.noticeTab = false;
      this.activityTab = false;
      this.taskTab = false;
      this.noteTab = false;
      this.achivementTab = false;
    }else if(tab == 'notice'){
      this.noticeTab = true;
      this.leaveTab = false;
      this.taskTab = false;
      this.noteTab = false;
      this.achivementTab = false;
    }else if(tab == 'note'){
      this.getReminder(this.empId);
      this.noteTab = true;
      this.activityTab = false;
      this.noticeTab = false;
      this.leaveTab = false;
      this.taskTab = false;
      this.achivementTab = false;
    }else{
      this.activityTab = true;
      this.noticeTab = false;
      this.leaveTab = false;
      this.taskTab = false;
      this.noteTab = false;
      this.achivementTab = false;
    }
  }

  // getCurrentDateAndMonth() {
  //   let month = this.bdPayload.getMonth() + 1; // Months are zero-based
  //   let day = this.bdPayload.getDate();
  //   let formattedMonth = (month < 10) ? `0${month}` : `${month}`;
  //   let formattedDay = (day < 10) ? `0${day}` : `${day}`;
  //   let dateMonth = `${formattedMonth}-${formattedDay}`;
  //   this.rest.getData({dm:dateMonth},'birthdaychecker').subscribe({
  //     next:(data) => {
  //       this.birthDayBoy = data.data;
  //     }
  //   })
  // }

  convertColor(string:any) {
    this.hsl = this.pageService.convertNameToColor(string);
  }

  convertToLetter(letter:any) {
    this.convertColor(letter);
    return this.pageService.getFirstLetters(letter);
  }

  open(modal: any): void {
    this.modalService.open(modal);
  }

  setMinMaxDate() {
    let today = new Date();
    let tomorrowDate = today.getDate();
    today.setDate(tomorrowDate);
    this.minDateLimit = {
      year: parseInt(formatDate(today, 'yyyy', 'en')),
      month: parseInt(formatDate(today, 'MM', 'en')),
      day: parseInt(formatDate(today, 'dd', 'en'))
    }
  }

  onDateSelect(event: any) {
    this.isShown = true;
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    this.startDate = year + "-" + month + "-" + day;
  }
  
}