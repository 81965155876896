import { Component, OnInit ,HostListener} from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from 'src/app/rest.service';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-view-attendance',
  templateUrl: './view-attendance.component.html',
  
})
export class ViewAttendanceComponent implements OnInit {
  public filter_status = false;
  public attendanceList:any = [];
  public attendanceListObj:any = [];
  public finalDate='';
  maxDate = new Date();
  public filterData: any;
  public searchKey = '';
  public nameSearch: any = undefined;
  public shiftSearch: any = undefined;
  public countrySearch: any = undefined;
  public countryFilterList: any;
  public shiftFilterList : any;
  public ouTimeFilterList : any;
  public selectedfield = '';
  public nameFilterList = <any>[];
  public typeFilterList = <any>[];
  public statusFilterList = <any>[];          
  public objKey: any;
  public multiSearch: string = '';
  public maxObject:any;
  public isDataLoaded=false;
  public scrolling: any;
  public paginateData: any;
  public showEmp = true;
  public lastPage = true;
  public offset = <any>0;
  public limit = <any>0;
  public addDataToArray: any;
  public isTrue = true;
  public currentPosition = window.pageYOffset;

  constructor(
    public rest: RestService,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService ,
  ) { }

  ngOnInit(): void {
    let d = new Date();
    this.maxObject = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.getAttendance();
  }
  filter_status_btn(){
    this.filter_status = !this.filter_status;
  }

  onDateSelect(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;;
    this.finalDate = year + "-" + month + "-" + day;
    this.getAttendance(this.finalDate);
  }
  /* fetching attendance list date wise */
  getAttendance(date=""){
    this.rest.getData({ queryDate:date,offset:this.offset,limit: this.limit}, 'viewattandance').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.isDataLoaded=true;
        if(data.state=='200'){
            this.attendanceList = data.data;
          this.createFilterData();
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        //this.toaster.error('Internal server error!!');
      },
    });
  }

  deSelect(event: any, filedType: string) {
    this.selectedfield = filedType;
    if (filedType === 'country') {
      this.searchKey = this.countrySearch;
    } else if (filedType === 'name') {
      this.searchKey = this.nameSearch;
    }
  }

  createFilterData(){
    let aNameFilterList    = [];
    let aCountryFilterList = [];
    let aShiftFilterList = [];
    console.log(this.attendanceList);
    for (let attendance of this.attendanceList) {
      aNameFilterList.push(attendance.name);
      aCountryFilterList.push(attendance.country);
      aShiftFilterList.push(attendance.shift_name);
    }
    this.nameFilterList = [...new Set(aNameFilterList)];
    this.countryFilterList = [...new Set(aCountryFilterList)];
    this.shiftFilterList = [...new Set(aShiftFilterList)];
  }

  searchType(event: any) {
    let searchkey = event.target.value;
    if (searchkey.length > 1) {
      this.objKey = searchkey;
    } else if (searchkey.length == 0) {
      this.objKey = searchkey;
    }
  }
}
