import { Component, OnInit, HostListener } from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-employees-list',
  templateUrl: './employees-list.component.html',
  styleUrls: ['./employees-list.component.scss'],
})
export class EmployeesListComponent implements OnInit {
  public showloader: boolean = false;
  public employeeList: any = [];
  public employeeObj: any;
  public error = false;
  public nameSearch: any;
  public emailSearch: any;
  public designationSearch: any;
  public roleSearch: any;
  public employe: any;
  public filter_status = false;
  public searchKey = '';
  public selectedfield = '';
  public nameFilterList = <any>[];
  public emailFilterList = <any>[];
  public designationFilterList = <any>[];
  public roleFilterList = <any>[];
  public multiSearch: string = '';
  public objKey: any;
  public loadAllComponant = false;
  public scrolling: any;
  public paginateData: any;
  public showEmp = true;
  public lastPage = true;
  public offset = <any>0;
  public limit = <any>15;
  public addDataToArray: any;
  public isTrue = true;
  public scrollList = true;
  public currentPosition = window.pageYOffset;

  constructor(
    public rest: RestService,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.getEmployeeList();
  }

  getEmployeeList() {
    this.rest.getData({offset: this.offset, limit: this.limit}, 'getalluser').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.employeeList = [...this.employeeList,...data.data];
          this.createFilterData();
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.error = true;
        this.loadAllComponant = true;
        this.toaster.error('Something went wrong!!');
      },
    });
  }

  findEmp(key = '') {
    let key1 = '';
    if(key !== '' || key !== undefined){
      key1 = key;
    }
    this.rest.getData({keywords:key1}, 'getalluser').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.employeeList = [];
          this.employeeList = data.data;
          this.createFilterData();
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.loadAllComponant = true;
        //this.toaster.error('Something went wrong');
      },
    });
  }

  onScroll(){
    if(this.scrollList == true){
      this.limit   = 15;
      this.offset += 15;
      console.log("==",this.offset);
      this.getEmployeeList();
    }
  }

  deSelect(event: any, filedType: string) {
    this.selectedfield = filedType;
    if (filedType === 'name') {
      this.emailSearch = undefined;
      this.roleSearch = undefined;
      this.designationSearch = undefined;
      this.searchKey = this.nameSearch;
    } else if (filedType === 'email') {
      this.nameSearch = undefined;
      this.roleSearch = undefined;
      this.designationSearch = undefined;
      this.searchKey = this.emailSearch;
    } else if (filedType === 'roles') {
      this.emailSearch = undefined;
      this.nameSearch = undefined;
      this.designationSearch = undefined;
      this.searchKey = this.roleSearch;
    } else if (filedType === 'designation') {
      this.emailSearch = undefined;
      this.nameSearch = undefined;
      this.searchKey = this.designationSearch;
    }
  }

  filter_status_btn() {
    this.filter_status = !this.filter_status;
  }

  createFilterData() {
    let nFilterList = [];
    let eFilterList = [];
    let dFilterList = [];
    let rFilterList = [];
    for (let employee of this.employeeList) {
      nFilterList.push(employee.name);
      eFilterList.push(employee.email);
      dFilterList.push(employee.designation);
      for (let rl of employee.roles) {
        rFilterList.push(rl);
      }
    }
    // Making all array unique
    this.nameFilterList  = [...new Set(nFilterList)];
    this.emailFilterList = [...new Set(eFilterList)];
    this.designationFilterList = [...new Set(dFilterList)];
    this.roleFilterList = [...new Set(rFilterList)];
  }

  searchType(event: any) {
    let searchkey = event.target.value;
    if (searchkey.length > 2) {
      this.scrollList = false;
      this.findEmp(searchkey);
    } else if (searchkey.length == 0) {
      this.scrollList = true;
      this.employeeList = [];
      this.getEmployeeList();
    }
  }

}
