<div class="main_content" *ngIf="loadAllComponant">
  <div class="page-content">
    <div class="new-profile">
      <div class="profile-foreground position-relative mx-n4 mt-n4">
        <div class="profile-wid-bg">
        </div>
      </div>
      <div class="p-2">
        <div class="row g-4 align-items-center">
          <div class="col-auto">
            <h4 class="userShape" [ngStyle]="{'background-color':hsl}">
              {{userName}}
            </h4>
          </div>
          <!--end col-->
          <div class="col">
            <div class="p-2">
              <h3 class="text-white mb-1">{{userData.name}}</h3>
              <div class="hstack text-white gap-1" *ngIf="userData.designation!=null && userData.designation!=undefined && userData.designation!==''">
                <i class="las la-user-tag"></i> {{userData.designation.replaceAll('_',' ') | titlecase}}
              </div>
              <div class="hstack text-white gap-1">
                <div class="me-2"><i class="las la-envelope-open"></i> {{userData.email}}</div>
                <!-- <div>
                  <i class="las la-user-tag"></i> {{userData.roles.toString()}}
                </div> -->
              </div>
              <div>
                <small class="text-white" *ngIf="userData!==undefined && userData!==null && userData.mobile!==''"> <i
                    class="las la-phone-square"></i> {{userData.mobile}}</small>
              </div>
            </div>
          </div>
          
          <div class="col-12 col-lg-auto order-last order-lg-0">
            <div class="row text text-white-50 text-center">
              <!-- <div class="col-lg-5 col-4">
                <div class="p-2">
                  <p class="fs-14 mb-0">{{currentDate | date:'MMMM'}}</p>
                  <h4 class="text-white mb-1"
                    *ngIf="userData.effective_hour!==undefined && userData.effective_hour!==null; else noActivity">
                    {{timeConvert(userData.effective_hour)}}/{{timeConvert((userData.workingDay*8*60))}}
                  </h4>
                  <ng-template #noActivity>
                    00/00
                  </ng-template>
                  <p class="fs-14 mb-0">Working</p>
                </div>
              </div> -->
              <div class="col-lg-3 col-4">
                <div class="p-2">
                  <p class="fs-14 mb-0">{{currentDate | date:'MMMM'}}</p>
                  <h4 class="text-white mb-1" *ngIf="userData!==undefined && userData!==null">
                    {{userData.attendanceCount}}/{{userData.workingDay}}</h4>
                  <p class="fs-14 mb-0">Attendance   </p>
                </div>
              </div>
              <div class="col-lg-3 col-4">
                <div class="p-2 ">
                  <p class="fs-14 mb-0">Years</p>
                  <h4 class="text-white mb-1" *ngIf="userData!==undefined && userData!==null">{{userData.leaveCount}}/24
                  </h4>
                  <p class="fs-14 mb-0">Leaves</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="profile-flow">
        <div class="row">
          <div class="col-lg-12">
            <div>
              <div class="d-flex">
                <!-- Nav tabs -->
                <ul class="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                  <li class="nav-item" role="presentation" [ngClass]="edit_info=='edit'?'active':''">
                    <a class="nav-link text-white" href="javascript:void(0)" (click)="toggle_edit_button('edit')"
                      role="tab" aria-selected="true">
                      <i class="ri-airplay-fill d-inline-block"></i> <span class=" d-md-inline-block">Overview</span>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation" [ngClass]="edit_info=='addShift'?'active':''">
                    <a class="nav-link text-white" href="javascript:void(0)" (click)="toggle_edit_button('addShift')"
                      role="tab" aria-selected="true">
                      <i class="ri-airplay-fill d-inline-block"></i> <span class=" d-md-inline-block">Schedule</span>
                    </a>
                  </li>
                  <li class="nav-item" role="presentation" [ngClass]="edit_info=='Activity'?'active':''">
                    <a class="nav-link text-white" href="javascript:void(0)" (click)="toggle_edit_button('Activity')"
                      role="tab" aria-selected="true">
                      <i class="ri-airplay-fill d-inline-block"></i> <span class=" d-md-inline-block">Activity</span>
                    </a>
                  </li>
                  <!-- <li class="nav-item" role="presentation" [ngClass]="edit_info=='Documents'?'active':''">
                    <a class="nav-link text-white" routerLink="javascript:void(0)" (click)="toggle_edit_button('Documents')"
                      role="tab" aria-selected="true">
                      <i class="ri-airplay-fill d-inline-block"></i> <span class=" d-md-inline-block">Documents</span>
                    </a>
                  </li> -->
                </ul>
                <div class="flex-shrink-0 mobileBtnground">
                  <a href="javascript:void(0)" (click)="docAction(1)" class="btn custom-btn btn-8 me-1">
                    <i class="las la-edit"></i> <span>Edit</span> </a>
                  <button class="btn custom-btn btn-8" (click)="toggle_edit_button('timesheet')"><span>Timesheet</span></button>
                </div>
              </div>
              <!-- Tab panes -->
              <div class="tab-content pt-4 text-muted">
                <div class="active" id="overview-tab" role="tabpanel" *ngIf="edit_info=='edit'">
                  <div class="row">
                    <div class="col-xxl-3">
                      <!-- <div class="card">
                        <div class="card-body">
                          <h5 class="card-title mb-5">Complete Your Profile</h5>
                          <div class="progress animated-progress custom-progress progress-label">
                            <div class="progress-bar bg-danger" role="progressbar" style="width: 30%" aria-valuenow="30"
                              aria-valuemin="0" aria-valuemax="100">
                              <div class="label">30%</div>
                            </div>
                          </div>
                        </div>
                      </div> -->

                      <div class="card">
                        <div class="card-body">
                          <div class="card-header align-items-center d-flex">
                            <h6 class="card-title mb-3">Personal Info</h6>
                          </div>
                          <div class="profile-tab">
                            <table class="table table-borderless mb-0">
                              <tbody>
                                <tr>
                                  <th class="ps-0" scope="row">Full Name :</th>
                                  <td class="text-muted">{{userData.name}}</td>
                                </tr>
                                <tr>
                                  <th class="ps-0" scope="row">Mobile :</th>
                                  <td class="text-muted"> {{userData.mobile}}</td>
                                </tr>
                                <tr>
                                  <th class="ps-0" scope="row">E-mail :</th>
                                  <td class="text-muted">{{userData.email}}</td>
                                </tr>
                                <tr>
                                  <th class="ps-0" scope="row">Location :</th>
                                  <td class="text-muted">{{userData.address == null?'No Address':userData.address}}
                                  </td>
                                </tr>
                                <tr>
                                  <th class="ps-0" scope="row">Joining Date</th>
                                  <td class="text-muted">{{userData.join_date | date:"mediumDate"}}</td>
                                </tr>
                                <tr>
                                  <th class="ps-0" scope="row">Emp. Type</th>
                                  <td class="text-muted">{{userData.type}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <div class="card">
                        <div class="card-header align-items-center d-flex border-bottom-dashed position-relative">
                          <h4 class="card-title mb-0 flex-grow-1">Documents</h4>
                          <div class="flex-shrink-0">
                              <svg xmlns="http://www.w3.org/2000/svg" (click)="docmentSubmit()" width="24" height="24" fill="#ff287e" class="bi bi-file-earmark-arrow-up" viewBox="0 0 16 16">
                                <path d="M8.5 11.5a.5.5 0 0 1-1 0V7.707L6.354 8.854a.5.5 0 1 1-.708-.708l2-2a.5.5 0 0 1 .708 0l2 2a.5.5 0 0 1-.708.708L8.5 7.707z"/>
                                <path d="M14 14V4.5L9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2M9.5 3A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z"/>
                              </svg>
                          </div>
                          <div class="menuCrad" [ngClass]="docmentSubmitPopUp?'on':''">
                            <h5 class="d-flex justify-content-between align-items-center">Select Attachments <i
                                class="las la-times text-primary" (click)="docmentSubmit()"></i></h5>
                            <ul>
                              <input type="file" multiple id="upload-input" class="d-none"
                                (change)="onFileChange($event)">
                              <li class="border-0 pb-0">
                                <a (click)="upload('image')" class="uploadDoc">Photo</a>
                              </li>
                              <li class="border-0 pb-0">
                                <a (click)="upload('academic_doc')" class="uploadDoc">Academic Documents</a>
                              </li>
                              <li class="border-0 pb-0">
                                <a (click)="upload('identity_doc')" class="uploadDoc">Identity Proof</a>
                              </li>
                              <li class="border-0 pb-0">
                                <a (click)="upload('employment_doc')" class="uploadDoc">Previous Experiance Documents</a>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div class="card-body">
                          <div class="table-responsive">
                            <table class="table table-vcenter text-nowrap table-bordered" id="files-tables" role="grid"
                              aria-describedby="files-tables_info">
                              <thead>
                                <tr role="row">
                                  <th>No</th>
                                  <th>File Name</th>
                                  <th>Type</th>
                                  <th>Actions</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngIf="userData.documents.length>0;else noData">
                                  <tr *ngFor="let file of userData.documents">
                                    <td>{{file.id}}</td>
                                    <td>{{file.original_name}}</td>
                                    <td>{{file.type}}</td>
                                    <td>
                                      <a routerLink="{{file.name}}" target="_BLANK" class="btn  alert-success me-2"><i
                                          class="las la-eye"></i></a>
                                      <a href="javascript:void(0);" class="btn alert-danger"
                                        (click)="deleteDoc(file)"><i class="las la-trash"></i></a>
                                    </td>
                                  </tr>
                                </ng-container>
                                <ng-template #noData>
                                  <tr>
                                    <td colspan="3">
                                      No data available!!!
                                    </td>
                                  </tr>
                                </ng-template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                      <!-- skills -->
                      <!-- <div class="card">
                        <div class="card-body">
                          <h5 class="card-title mb-4">Skills</h5>
                          <div class="d-flex flex-wrap gap-2 fs-15">
                            <a href="javascript:void(0);" class="badge alert-primary">Photoshop</a>
                            <a href="javascript:void(0);" class="badge alert-primary">illustrator</a>
                            <a href="javascript:void(0);" class="badge alert-primary">HTML</a>
                            <a href="javascript:void(0);" class="badge alert-primary">CSS</a>
                            <a href="javascript:void(0);" class="badge alert-primary">Javascript</a>
                            <a href="javascript:void(0);" class="badge alert-primary">Php</a>
                            <a href="javascript:void(0);" class="badge alert-primary">Python</a>
                          </div>
                        </div>
                      </div> -->

                    </div>
                    <div class="col-xxl-9">
                      <!-- <div class="card">
                        <div class="card-body">
                          <div class="row">
                            <div class="col-6 col-md-4">
                              <div class="d-flex mt-4">
                                <div class="flex-grow-1 overflow-hidden">
                                  <p class="mb-1">Designation :</p>
                                  <h6 class="text-truncate mb-0">{{userData.designation.replaceAll('_',' ') | titlecase}}</h6>
                                </div>
                              </div>
                            </div>
                            <div class="col-6 col-md-4">
                              <div class="d-flex mt-4">
                                <div class="flex-shrink-0 avatar-xs align-self-center me-3">
                                  <div class="avatar-title bg-light rounded-circle  text-primary">
                                    <i class="ri-global-line"></i>
                                  </div>
                                </div>
                                <div class="flex-grow-1 overflow-hidden">
                                  <p class="mb-1">Status :</p>
                                  <a href="javascript:void(0)" class="badge alert-primary">{{userData.status == 1?'Active':'Inactive'}}</a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                      <div class="row">
                        <div class="col-lg-12">
                          <div class="card">
                            <div class="card-header align-items-center d-flex">
                                <input type="text" (keyup)="searchType($event)"  class="form-control bg-light border-light u_find" placeholder="Search tasks here..." [(ngModel)]="multiSearch">
                            </div>
                            <div class="card-body">
                              <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                                data-parent="#accordion-details">
                                <div class="row">
                                  <div class="table-responsive">
                                    <table class="table custom-table table-hover table-hover">
                                      <thead>
                                        <tr>
                                          <th >No.</th>
                                          <th >Task Name</th>
                                          <th>Module Name</th>
                                          <th>Project Name</th>
                                          <th class="text-center">Status</th>
                                          <th>Date</th>
                                          <!-- <th>Action</th> -->
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr class="uploadDoc" *ngFor="let taskLists of taskList|tableSearch:objKey; index as i;" [routerLink]='["/manage-task/",encodeURIParam(taskLists.t_id)]'>
                                          <td>{{i+1}}</td>
                                          <td class="text-wrap w-50">{{taskLists.task_name| titlecase}}</td>
                                          <td class="link-dark d-block" tooltip={{taskLists.module_name}} placement="bottom">{{taskLists.module_name?.length>15?taskLists.module_name.substr(0,15)+'..':taskLists.module_name| titlecase}}</td>
                                          <td tooltip={{taskLists.project_name}} placement="bottom">{{taskLists.project_name?.length>15?taskLists.project_name.substr(0,15)+'..':taskLists.project_name}}</td>
                                          <td class="text-center">
                                            <span class="badge alert-success"
                                              *ngIf="taskLists.task_status=='finish'">{{taskLists.task_status}}</span>
                                            <span class="badge alert-primary"
                                              *ngIf="taskLists.task_status=='active'">{{taskLists.task_status}}</span>
                                            <span class="badge alert-danger"
                                              *ngIf="taskLists.task_status=='inactive'">{{taskLists.task_status}}</span>
                                          </td>
                                          <td>{{taskLists.created_at | date}}</td>
                                          <!-- <td>
                                            <a href="javascript: void(0);" class="" [routerLink]='["/manage-task/",taskLists.t_id]'><span
                                                class=" edit text-success text-center"><i class="las la-edit"></i></span>
                                            </a>
                                          </td> -->
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="" id="projects" role="tabpanel" *ngIf="edit_info=='addShift'">
                  <form [formGroup]="scheduleForm" (ngSubmit)="saveSchedule(scheduleForm)" #fd="ngForm"
                    [ngxFvControl]="fd">
                    <div class="accordion" id="accordion-details">
                      <div class="card shadow-sm grow ctm-border-radius">
                        <div class="card-header" id="basic1">
                          <h4 class="cursor-pointer mb-0">
                            <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
                              data-target="#basic-one" aria-expanded="true">
                              Set Schedule
                            </a>
                          </h4>
                        </div>
                        <div class="card-body p-0">
                          <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                            data-parent="#accordion-details">
                            <div class="table-responsive">
                              <table class="table">
                                <tbody>
                                  <tr>
                                    <td>
                                      <span class="w-50">Sunday</span>
                                    </td>
                                    <td>:</td>
                                    <td>
                                      <span class="font-weight-semibold">
                                        <select id="" class="form-control" formControlName="sunday">
                                          <option value="">Select Shift</option>
                                          <ng-container *ngIf="shiftList!=undefined && shiftList!=null">
                                            <ng-container *ngFor="let list of shiftList">
                                              <option value="{{list.id}}">{{list.name + " (" + list.start_time + " - " +
                                                list.end_time + ")"}}
                                              </option>
                                            </ng-container>
                                          </ng-container>
                                        </select>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span class="w-50">Monday</span>
                                    </td>
                                    <td>:</td>
                                    <td>
                                      <span class="font-weight-semibold">
                                        <select name="" id="" class="form-control" formControlName="monday">
                                          <option value="">Select Shift</option>
                                          <ng-container *ngIf="shiftList!=undefined && shiftList!=null">
                                            <ng-container *ngFor="let list of shiftList">
                                              <option value="{{list.id}}">{{list.name + ' (' + list.start_time+' -
                                                '+list.end_time+')'}}
                                              </option>
                                            </ng-container>
                                          </ng-container>
                                        </select>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span class="w-50">Tuesday</span>
                                    </td>
                                    <td>:</td>
                                    <td>
                                      <span class="font-weight-semibold">
                                        <select name="" id="" class="form-control" formControlName="tuesday">
                                          <option value="">Select Shift</option>
                                          <ng-container *ngIf="shiftList!=undefined && shiftList!=null">
                                            <ng-container *ngFor="let list of shiftList">
                                              <option value="{{list.id}}">{{list.name + ' (' + list.start_time+' -
                                                '+list.end_time+')'}}
                                              </option>
                                            </ng-container>
                                          </ng-container>
                                        </select>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span class="w-50">Wednesday</span>
                                    </td>
                                    <td>:</td>
                                    <td>
                                      <span class="font-weight-semibold">
                                        <select name="" id="" class="form-control" formControlName="wednesday">
                                          <option value="">Select Shift</option>
                                          <ng-container *ngIf="shiftList!=undefined && shiftList!=null">
                                            <ng-container *ngFor="let list of shiftList">
                                              <option value="{{list.id}}">{{list.name + ' (' + list.start_time+' -
                                                '+list.end_time+')'}}
                                              </option>
                                            </ng-container>
                                          </ng-container>
                                        </select>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span class="w-50">Thursday</span>
                                    </td>
                                    <td>:</td>
                                    <td>
                                      <span class="font-weight-semibold">
                                        <select name="" id="" class="form-control" formControlName="thursday">
                                          <option value="">Select Shift</option>
                                          <ng-container *ngIf="shiftList!=undefined && shiftList!=null">
                                            <ng-container *ngFor="let list of shiftList">
                                              <option value="{{list.id}}">{{list.name + ' (' + list.start_time+' -
                                                '+list.end_time+')'}}
                                              </option>
                                            </ng-container>
                                          </ng-container>
                                        </select>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span class="w-50">Friday</span>
                                    </td>
                                    <td>:</td>
                                    <td>
                                      <span class="font-weight-semibold">
                                        <select name="" id="" class="form-control" formControlName="friday">
                                          <option value="">Select Shift</option>
                                          <ng-container *ngIf="shiftList!=undefined && shiftList!=null">
                                            <ng-container *ngFor="let list of shiftList">
                                              <option value="{{list.id}}">{{list.name + ' (' + list.start_time+' -
                                                '+list.end_time+')'}}
                                              </option>
                                            </ng-container>
                                          </ng-container>
                                        </select>
                                      </span>
                                    </td>
                                  </tr>
                                  <tr>
                                    <td>
                                      <span class="w-50">Saturday</span>
                                    </td>
                                    <td>:</td>
                                    <td>
                                      <span class="font-weight-semibold">
                                        <select name="" id="" class="form-control" formControlName="saturday">
                                          <option value="">Select Shift</option>
                                          <ng-container *ngIf="shiftList!=undefined && shiftList!=null">
                                            <ng-container *ngFor="let list of shiftList">
                                              <option value="{{list.id}}">{{list.name + ' (' + list.start_time+' -
                                                '+list.end_time+')'}}
                                              </option>
                                            </ng-container>
                                          </ng-container>
                                        </select>
                                      </span>
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                              <button class="btn custom-btn btn-8" type="submit" *ngIf="isShow">Save</button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                  <!--end card-->
                </div>
                <div class="" id="projects" role="tabpanel" *ngIf="edit_info=='Activity'">

                  <div class="accordion" id="accordion-details">
                    <div class="card shadow-sm grow ctm-border-radius">
                      <div class="card-header" id="basic1">
                        <div class="row align-items-center">
                          <div class="col-lg-6">
                            <div class="card_title">
                              <h4 class="cursor-pointer mb-0">
                                <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
                                  data-target="#basic-one" aria-expanded="true">
                                  Activity Log
                                  <strong>{{timeConvert(effHour)}}/{{timeConvert(userData.workingDay*8*60)}}</strong>
                                </a>
                              </h4>
                            </div>
                          </div>
                          <div class="col-lg-6">
                            <div class="left_top">
                              <div class="row justify-content-end align-items-center text-end">
                                <div class="col-lg-8 extrafil">
                                  <div class="input-group">
                                    <div class="input-group-text"><i class="las la-user-secret"></i>
                                    </div>
                                    <input type="text" class="form-control" ngbDatepicker [(ngModel)]="startDate"
                                      #joinStart="ngbDatepicker" placeholder="Start Date" (click)="joinStart.toggle()"
                                      (dateSelect)="onDateSelect($event,'start')" [maxDate]="maxObjectStart"
                                      [minDate]="validateMinStart">
                                  </div>
                                  <div class="input-group">
                                    <div class="input-group-text"><i class="las la-user-secret"></i>
                                    </div>
                                    <input type="text" class="form-control" ngbDatepicker [(ngModel)]="endDate"
                                      #dpStart="ngbDatepicker" placeholder="End Date"
                                      (dateSelect)="onDateSelect($event,'end')" (click)="dpStart.toggle()"
                                      [maxDate]="maxObjectEnd" [minDate]="validateMinEnd">
                                  </div>
                                  <button class="btn custom-btn" (click)="dateWiseReport()"> <span>Search</span></button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body p-0">
                        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                          data-parent="#accordion-details">
                          <div class="table-responsive">
                            <table class="table custom-table table-hover table-hover">
                              <thead>
                                <tr>
                                  <th>Sl_No</th>
                                  <th>Date</th>
                                  <th>Task Name</th>
                                  <th>Working Hours</th>
                                </tr>
                              </thead>
                              <tbody>
                                <ng-container *ngIf="activityData==undefined || activityData==''; else dataTable">
                                  <tr>
                                      <td class="text-center" colspan="6">No Data Found!!</td>
                                  </tr>
                              </ng-container>
                              <ng-template #dataTable>                                  
                                <tr *ngFor="let log of activityData; index as i">
                                  <td>{{i+1}}</td>
                                  <td>{{log.entry_date}}</td>
                                  <td>{{log.task_name}}</td>
                                  <td>{{timeConvert(log.effort,"d")}}</td>
                                </tr>
                              </ng-template>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end card-->
                </div>
                <div class="" id="projects" role="tabpanel" *ngIf="edit_info=='timesheet'">
                  <div class="accordion" id="accordion-details">
                    <div class="card shadow-sm grow ctm-border-radius">
                      <div class="card-header" id="basic1">
                        <div class="row align-items-center">
                          <div class="col-lg-6">
                            <div class="card_title">
                              <h4 class="cursor-pointer mb-0">
                                <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
                                  data-target="#basic-one" aria-expanded="true">
                                  Timesheet
                                </a>
                              </h4>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="card-body p-0">
                        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                          data-parent="#accordion-details">
                          <app-timesheet [user_id]="userData.u_id"></app-timesheet>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!--end card-->
                </div>
                <!-- Document upload Start -->
                <ng-container *ngIf="edit_info=='Documents'">
                  <div class="card" id="projects" role="tabpanel">
                    <div class="card-header align-items-center d-flex border-bottom-dashed">
                      <h4 class="card-title mb-0 flex-grow-1">Attachments</h4>
                      <div class="flex-shrink-0">
                      </div>
                    </div>
                    <div class="card-body">
                      <div class="vstack gap-2">
                        <!-- <div class="row">
                              <div class="col-lg-6 form-group">
                                <label>
                                  Image <strong>(MAX 4MB)</strong>
                                </label>
                                <div class="input-group mb-3">
                                  <div class="input-group-text"><i class="las la-user-secret"></i>
                                  </div>
                                  <input multiple id="file" type="file" class="form-control"
                                    (change)="onFileChange($event,'image')">
                                </div>
                              </div>
                              <div class="col-lg-6 form-group">
                                <label>
                                  Identity Documents <strong>(MAX 4MB)</strong>
                                </label>
                                <div class="input-group mb-3">
                                  <div class="input-group-text"><i class="las la-user-secret"></i>
                                  </div>
                                  <input multiple id="file" type="file" class="form-control"
                                    (change)="onFileChange($event,'identity_doc')">
                                </div>
                              </div>
                              <div class="col-lg-6 form-group">
                                <label>
                                  Academic Documents <strong>(MAX 4MB)</strong>
                                </label>
                                <div class="input-group mb-3">
                                  <div class="input-group-text"><i class="las la-user-secret"></i>
                                  </div>
                                  <input multiple id="file" type="file" class="form-control"
                                    (change)="onFileChange($event,'academic_doc')">
                                </div>
                              </div>
                              <div class="col-lg-6 form-group">
                                <label>
                                  Documents of past employment <strong>(MAX 4MB)</strong>
                                </label>
                                <div class="input-group mb-3">
                                  <div class="input-group-text"><i class="las la-user-secret"></i>
                                  </div>
                                  <input multiple id="file" type="file" class="form-control"
                                    (change)="onFileChange($event,'employment_doc')">
                                </div>
                              </div>
                              <div class="col-lg-12 form-group">
                                <div class="input-group mb-3" *ngIf="isShow">
                                  <button class="btn custom-btn btn-8" (click)="uploadFile()"><span>Upload</span></button>
                                </div>
                              </div>
                            </div> -->
                      </div>
                    </div>

                  </div>
                  <div class="card">
                    <div class="card-body">
                      <!-- <table class="table table-vcenter text-nowrap table-bordered" id="files-tables"
                            role="grid" aria-describedby="files-tables_info">
                            <thead>
                              <tr role="row">
                                <th>No</th>
                                <th>File Name</th>
                                <th>Upload By</th>
                                <th>
                                  Actions</th>
                              </tr>
                            </thead>
                            <tbody> -->
                      <!-- <tr>
                                <td>1</td>
                                <td> <a href="javascript:void(0);" class="font-weight-semibold fs-14 mt-5">document.pdf<span
                                      class="text-muted ms-2">(23 KB)</span></a>
                                  <div class="clearfix"></div> <small class="text-muted">2 hours ago</small>
                                </td>
                                <td>Admin</td>
                                <td>
                                  <div class="d-flex">
                                     <a href="javascript:void(0);" class="btn  alert-success me-2"
                                    ><i class="las la-eye"></i></a>
                                     <a
                                     href="javascript:void(0);" class="btn alert-danger"><i class="las la-trash"></i></a>
                                  </div>
                                </td>
                              </tr> -->
                      <!-- <ng-container *ngIf="userData.documents.length>0;else noData">
                                <tr *ngFor="let file of userData.documents">
                                  <td>{{file.id}}</td>
                                  <td>{{file.original_name}}</td>
                                  <td>{{file.type}}</td>
                                  <td>
                                    <a routerLink="{{file.name}}" target="_BLANK" class="btn  alert-success me-2"
                                    ><i class="las la-eye"></i></a>
                                     <a
                                     href="javascript:void(0);" class="btn alert-danger" (click)="deleteDoc(file)"><i class="las la-trash"></i></a>
                                  </td>
                                </tr>
                              </ng-container>
                              <ng-template #noData>
                                <tr>
                                  <td colspan="3">
                                    No data available!!!
                                  </td>
                                </tr>
                              </ng-template>
                            </tbody>
                          </table> -->
                    </div>
                  </div>
                </ng-container>
                <!-- End Upload Document -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="accordion add-employee " id="taskedit" [ngClass]="docStatus==1?'on':''">
    <div class=" ctm-border-radius shadow-none">
      <div class="modal-header p-3 ps-4 bg-soft-success">
        <h5 class="modal-title" id="inviteMembersModalLabel">Edit Profile</h5>
        <button type="button" class="btn-close" (click)="docAction(0)"></button>
      </div>
      <div class="card-body p-0">
        <form [formGroup]="userForm" (ngSubmit)="onSubmit(userForm)" #fd="ngForm" [ngxFvControl]="fd">
          <div class="accordion add-employee" id="accordion-details">
            <div class="grow ctm-border-radius">
              <div class="card-body p-0">
                <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                  data-parent="#accordion-details">
                  <div class="row">
                    <div class="col-lg-6 form-group">
                      <ngx-fv-validate [control]="userForm.controls['type']">
                        <label #ngxFvName>
                          Type
                        </label>
                        <div class="input-group mb-3">
                          <div class="input-group-text"><i class="lar la-id-card"></i>
                          </div>
                          <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                            #ngxFvFormControl formControlName="type">
                            <option value="">Employee type</option>
                            <option value="Full time">Full time</option>
                            <option value="Part time">Part time</option>
                            <option value="Trainee">Trainee</option>
                          </select>
                        </div>
                        <ngx-fv-error></ngx-fv-error>
                      </ngx-fv-validate>
                    </div>
                    <div class="col-lg-6 form-group">
                      <ngx-fv-validate [control]="userForm.controls['name']">
                        <label #ngxFvName>
                          Name
                        </label>
                        <div class="input-group mb-3">
                          <div class="input-group-text"><i class="las la-file-signature"></i>
                          </div>
                          <input type="text" class="form-control" id="Name" placeholder="Name" #ngxFvFormControl
                            formControlName="name">
                        </div>
                        <ngx-fv-error></ngx-fv-error>
                      </ngx-fv-validate>
                    </div>
                    <div class="col-lg-6 form-group">
                      <ngx-fv-validate [control]="userForm.controls['email']">
                        <label #ngxFvName>
                          Email
                        </label>
                        <div class="input-group mb-3">
                          <div class="input-group-text"><i class="las la-envelope-open"></i>
                          </div>
                          <input type="email" class="form-control" id="Email" placeholder="Email" #ngxFvFormControl
                            formControlName="email">
                        </div>
                        <ngx-fv-error></ngx-fv-error>
                      </ngx-fv-validate>
                    </div>
                    <div class="col-lg-6 form-group">
                      <ngx-fv-validate [control]="userForm.controls['mobile']">
                        <label #ngxFvName>
                          Mobile
                        </label>
                        <div class="input-group mb-3">
                          <div class="input-group-text"><i class="las la-mobile"></i>
                          </div>
                          <input type="email" class="form-control" id="mobile" placeholder="Mobile" #ngxFvFormControl
                            formControlName="mobile">
                        </div>
                        <ngx-fv-error></ngx-fv-error>
                      </ngx-fv-validate>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="accordion add-employee mt-2" id="accordion-details">
            <div class="  grow ctm-border-radius">
              <div class="card-header" id="basic1">
                <h4 class="cursor-pointer mb-0">
                  <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
                    data-target="#basic-one" aria-expanded="true">
                    Employee Details
                  </a>
                </h4>
              </div>
              <div class="card-body p-0">
                <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                  data-parent="#accordion-details">

                  <div class="row">
                    <div class="col-lg-6 form-group">
                      <ngx-fv-validate [control]="userForm.controls['status']">
                        <label #ngxFvName>
                          Status
                        </label>
                        <div class="input-group mb-3">
                          <div class="input-group-text"><i class="las la-globe"></i>
                          </div>
                          <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                            #ngxFvFormControl formControlName="status">
                            <option selected="">Status</option>
                            <option value="1">Active</option>
                            <option value="0">Inactive</option>
                          </select>
                        </div>
                        <ngx-fv-error></ngx-fv-error>
                      </ngx-fv-validate>
                    </div>
                    <div class="col-lg-6 form-group">
                      <ngx-fv-validate [control]="userForm.controls['designation']">
                        <label #ngxFvName>
                          Designation
                        </label>
                        <div class="input-group mb-3">
                          <div class="input-group-text"><i class="las la-globe"></i>
                          </div>
                          <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                            #ngxFvFormControl formControlName="designation">
                            <option selected="">Choose Designation</option>
                            <option value="software_developer">Software Developer</option>
                            <option value="php_developer">PHP Developer</option>
                            <option value="senior_php_developer">Senior PHP Developer</option>
                            <option value="junior_php_developer">Junior PHP Developer</option>
                            <option value="senior_software_developer">Senior Software Developer</option>
                            <option value="graphic_designer">Graphic Designer</option>
                            <option value="junior_graphic_designer">Junior Graphic Designer</option>
                            <option value="senior_graphic_designer">Senior Graphic Designer</option>
                            <option value="digital_marketing_and_content_writer">Digital Marketing & Content Writer
                            </option>
                            <option value="junior_digital_marketing_and_content_writer">Junior Digital Marketing &
                              Content
                              Writer</option>
                            <option value="senior_digital_marketing_and_content_writer">Senior Digital Marketing &
                              Content
                              Writer</option>
                            <option value="manager">Manager</option>
                            <option value="executive">Executive</option>
                            <option value="back_office_executive">Back Office Executive</option>
                            <option value="senior_executive_hr_and_admin">Senior Executive HR and Admin</option>
                            <option value="web_developer">Web Developer</option>
                            <option value="junior_web_developer">Junior Web Developer</option>
                            <option value="senior_web_developer">Senior Web Developer</option>
                          </select>
                        </div>
                        <ngx-fv-error></ngx-fv-error>
                      </ngx-fv-validate>
                    </div>
                    <div class="col-lg-6 form-group">
                      <ngx-fv-validate [control]="userForm.controls['country']">
                        <label #ngxFvName>
                          Country
                        </label>
                        <div class="input-group mb-3">
                          <div class="input-group-text"><i class="las la-globe"></i>
                          </div>
                          <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                            #ngxFvFormControl formControlName="country">
                            <option selected="">Employee Country</option>
                            <option value="India">India</option>
                            <option value="Bangaldesh">Bangaldesh</option>
                            <option value="Uk">UK</option>
                          </select>
                        </div>
                        <ngx-fv-error></ngx-fv-error>
                      </ngx-fv-validate>
                    </div>
                    <div class="col-lg-6 form-group">
                      <ngx-fv-validate [control]="userForm.controls['dob']">
                        <label #ngxFvName>
                          DOB
                        </label>
                        <div class="input-group mb-3">
                          <div class="input-group-text"><i class="las la-calendar-alt"></i>
                          </div>
                          <input type="text" class="form-control" ngbDatepicker #dpStart="ngbDatepicker" id="dob"
                            placeholder="DOB" name="dob" (click)="dpStart.toggle()" #ngxFvFormControl
                            formControlName="dob">
                        </div>
                        <ngx-fv-error></ngx-fv-error>
                      </ngx-fv-validate>
                    </div>
                    <div class="col-lg-6 form-group">
                      <ngx-fv-validate [control]="userForm.controls['join_date']">
                        <label #ngxFvName>
                          Join Date
                        </label>
                        <div class="input-group mb-3">
                          <div class="input-group-text"><i class="las la-calendar-alt"></i>
                          </div>
                          <input type="text" [value]="userData.join_date" class="form-control" ngbDatepicker
                            #joinStart="ngbDatepicker" id="join_date" placeholder="Join Date"
                            (click)="joinStart.toggle()" #ngxFvFormControl formControlName="join_date" name="join_date">
                        </div>
                        <ngx-fv-error></ngx-fv-error>
                      </ngx-fv-validate>
                    </div>
                    <div class="col-md-12">
                      <div class="grp mb-2">
                        <p class="mb-2">Permission</p>
                        <div class="form-check form-check-inline" *ngFor="let ch of permissionData">
                          <input class="form-check-input" type="checkbox" name="{{ch.name}}" id="{{ch.name}}"
                            value="{{ch.value}}" [checked]="checkboxSelect(ch.value)"
                            (change)="permission_toggle(ch.value,$event)">
                          <label class="form-check-label" for="{{ch.name}}">{{ch.name}}</label>
                        </div>
                      </div>
                    </div>
                    <div class="input-group mb-3" *ngIf="isShow">
                      <button class="btn custom-btn btn-8 updbtn" type="submit"><span>Update</span></button>
                    </div>
                  </div>

                </div>
              </div>

            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>