<div class="main_content" *ngIf="loadAllComponant">
  <div class="all_info_cont">
    <div class="row">
      <div class="col-lg-12">
        <div class="card project_card">
          <div class="bg-soft-warning">
            <div class="card-body pb-0 px-4">
              <div class="row mb-3">
                <div class="col-md">
                  <div class="row align-items-center g-3">
                    <div class="col-md-auto">
                      <div class="avatar-md">
                        <div class="avatar-title bg-white rounded-circle">
                          <img src="https://eu.ui-avatars.com/api/?name={{projectData.p_name}}&size=80" alt=""
                            class="avatar-xs rounded-circle">
                        </div>
                      </div>
                    </div>
                    <div class="col-md">
                      <div>
                        <h4 class="fw-bold" *ngIf="projectData!=undefined && projectData.p_name!==undefined">
                          {{projectData.p_name}}</h4>
                        <div class="hstack gap-3 flex-wrap">

                          <!-- <div class="vr"></div> -->
                          <div>Created Date : <span class=""
                              *ngIf="projectData!=undefined && projectData.created_at!==undefined">{{projectData.created_at
                              |
                              date:'mediumDate'}}</span>
                          </div>
                          <div class="vr"></div>
                          <!-- <button type="button" class="btn py-0 fs-16" (click)="docStatus=openCloseTab(docStatus,2)">
                            <i class="las la-edit"></i> Edit
                          </button> -->
                          <!-- <a (click)="docStatus=openCloseTab(docStatus,2)" class="custom-btn btn-8 text-center"><i class="las la-edit"></i>Edit
                          </a> -->
                          <!-- <div class="vr"></div> 
                          <button type="button" class="btn py-0 fs-16 text-white bg-danger"
                            (click)="deleteProject(projectData.p_id)">
                            <i class="las la-trash-alt"></i> Delete
                          </button> -->
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                
              <div class="col-md-auto button_card">
                <div class="hstack gap-1 flex-wrap">
                  
                  <a (click)="docStatus=openCloseTab(docStatus,2)" class="custom-btn btn-8 text-center"><i class="las la-edit"></i>Edit
                  </a>
                </div>
              </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="card mb-3">
          <div class="card-body">
            <div class="table_card">
              <table class="table">
                <tbody>
                  <tr>
                    <td>
                      <span class="w-50">Project Type</span>
                    </td>
                    <td>:</td>
                    <td>
                      <span class="font-weight-semibold text-success">{{projectData.p_type}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="w-50">Project Stage</span>
                    </td>
                    <td>:</td>
                    <td>
                      <span class="font-weight-semibold">{{projectData.p_stage}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="w-50">Estimated End Date</span>
                    </td>
                    <td>:</td>
                    <td>
                      <span class="font-weight-semibold">{{projectData.p_estimeted_end_date}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="w-50">Estimated Time</span>
                    </td>
                    <td>:</td>
                    <td>
                      <span
                        class="font-weight-semibold">{{timeConvert((projectData.p_estimeted_effort*8)*60,"d")}}</span>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="w-50">Status</span>
                    </td>
                    <td>:</td>
                    <td>
                      <select name="statusChange" [(ngModel)]="projectData.p_status" id="statusChange" class="badge bg-{{statusColor}}" (change)="statusChange($event.target)">
                        <option value="active" [selected]="projectData.p_status == 'active'">Active</option>
                        <option value="inactive" [selected]="projectData.p_status == 'inactive'">Inactive</option>
                        <option value="stop" [selected]="projectData.p_status == 'stop'">Stop</option>
                        <option value="finished" [selected]="projectData.p_status == 'finished'">Finished</option>
                      </select>
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <span class="w-50">Health</span>
                    </td>
                    <td>:</td>
                    <td>
                      <div class="health">
                        <span class="open"></span>
                        <span class="{{healthStatus}} on "></span>
                        <span class="{{healthStatus}} on"></span>
                        <span class="{{healthStatus}} on"></span>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="p-3">
                <div class="d-flex align-items-end justify-content-between">
                  <h6 class="fs-12"> Health Status</h6>
                  <h6 class="fs-12">{{projectHealth}}%</h6>
                </div>
                <div class="progress">
                  <div class="progress-bar" role="progressbar" [style.width]="projectHealth+'%'"
                    aria-valuemin="0" attr.aria-valuemax="{{projectHealth}}">{{projectHealth}}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header align-items-center d-flex border-bottom-dashed">
            <h4 class="card-title mb-0 flex-grow-1">Members</h4>
            <div class="flex-shrink-0">
                <svg *ngIf="projectData.p_status == 'active'" (click)="docStatus=openCloseTab(docStatus,1)" xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="#ff287e" class="bi bi-person-add" viewBox="0 0 16 16">
                  <path d="M12.5 16a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7m.5-5v1h1a.5.5 0 0 1 0 1h-1v1a.5.5 0 0 1-1 0v-1h-1a.5.5 0 0 1 0-1h1v-1a.5.5 0 0 1 1 0m-2-6a3 3 0 1 1-6 0 3 3 0 0 1 6 0M8 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4"/>
                  <path d="M8.256 14a4.5 4.5 0 0 1-.229-1.004H3c.001-.246.154-.986.832-1.664C4.484 10.68 5.711 10 8 10q.39 0 .74.025c.226-.341.496-.65.804-.918Q8.844 9.002 8 9c-5 0-6 3-6 4s1 1 1 1z"/></svg>
            </div>
          </div>

          <div class="card-body">
            <div class="mx-n3 px-3 max-card-val">
              <div class="simplebar-wrapper">
                <div class="simplebar-mask">
                  <div class="simplebar-offset">
                    <div class="simplebar-content-wrapper">
                      <div class="simplebar-content">

                        <div class="vstack gap-3">
                          <div class="d-flex align-items-center" *ngFor="let employeeLists of empList">
                            <div class="avatar-xs flex-shrink-0 me-3">
                              <h4 class="userShape-Sm" [ngStyle]="{'background-color':hsl}">
                                {{convertToLetter(employeeLists.name)}}
                              </h4>
                            </div>
                            <div class="flex-grow-1">
                              <h5 class="fs-13 mb-0"><a class="text-body d-block">{{employeeLists.name}}</a></h5>
                            </div>
                            <div class="flex-shrink-0">
                              <div class="d-flex align-items-center gap-1">
                                <button  *ngIf="empList?.length > 1" type="button" class="btn btn-light btn-sm" (click)="deleteUser(employeeLists.id,employeeLists.name)"><svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ff287e" class="bi bi-trash" viewBox="0 0 16 16">
                                  <path d="M5.5 5.5A.5.5 0 0 1 6 6v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m2.5 0a.5.5 0 0 1 .5.5v6a.5.5 0 0 1-1 0V6a.5.5 0 0 1 .5-.5m3 .5a.5.5 0 0 0-1 0v6a.5.5 0 0 0 1 0z"/>
                                  <path d="M14.5 3a1 1 0 0 1-1 1H13v9a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V4h-.5a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1H6a1 1 0 0 1 1-1h2a1 1 0 0 1 1 1h3.5a1 1 0 0 1 1 1zM4.118 4 4 4.059V13a1 1 0 0 0 1 1h6a1 1 0 0 0 1-1V4.059L11.882 4zM2.5 3h11V2h-11z"/>
                                </svg></button>
                              </div>
                            </div>
                          </div>
                          <button type="button" class="btn btn-success"
                            *ngIf="isShowBtn && projectData.userList!=undefined && projectData.userList.length!==empList.length"
                            (click)="showEmp()">View
                            more</button>
                          <button class="btn btn-success" type="button" *ngIf="!isShowBtn" disabled>
                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span class="visually-hidden">Loading...</span>
                          </button>
                        </div>
                        
                        <!-- end list -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- end card body -->
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="text-muted" *ngIf="projectData!=undefined && projectData.p_description!==undefined">
              <h6 class="mb-3 text-uppercase">Description</h6>
              {{projectData.p_description}}
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-body">
            <div class="text-muted">
              <h6 class="mb-3 text-uppercase">Health report</h6>
            </div>
              <div class="text-center health" *ngIf="healthReason == undefined || healthReason == null">
                <div class="spinner-border" role="status">
                  <span class="sr-only">Loading...</span>
                </div>
              </div>
            <tr>
              <td>
                <div class="health">
                  <ul *ngIf="healthReason != undefined || healthReason != null" class="text-wrap">
                    <li *ngIf="healthReason.estDateMessage != undefined || healthReason.estDateMessage != null">
                      {{healthReason.estDateMessage}}
                    </li>
                    <li
                      *ngIf="healthReason.estEffortMessage != undefined || healthReason.estEffortMessage != null">
                      {{healthReason.estEffortMessage}}
                    </li>
                    <li *ngIf="healthReason.moduleMessage != undefined || healthReason.moduleMessage != null">
                      {{healthReason.moduleMessage}}
                    </li>
                    <li *ngIf="healthReason.tskMessage != undefined || healthReason.tskMessage != null">
                      {{healthReason.tskMessage}}
                    </li>
                  </ul>
                </div>
              </td>
            </tr>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div>
              <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0 innerTab subtabInfo">
                <li class="nav-item" role="presentation">
                  <a class="nav-link " [ngClass]="tabval=='module_list'?'active':''" (click)="changeTab('module_list')"
                    aria-selected="true">
                    Module List
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" [ngClass]="tabval=='logs'?'active':''" (click)="changeTab('logs')">
                    Logs
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" [ngClass]="tabval=='project_report'?'active':''"
                    (click)="changeTab('project_report')" aria-selected="false" tabindex="-1">
                    Reports
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <div class="tab-content" *ngIf="tabval=='module_list'">
              <div class="table-responsive">
                <table class="table custom-table table-hover table-hover" id="empolyeeId">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Module Name</th>
                      <th>Module Type</th>
                      <th>Estimated Effort</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <ng-container
                      *ngIf="projectData.moduleList==undefined || projectData.moduleList==''; else dataTable">
                      <tr>
                        <td class="text-center" colspan="6">No Data Found!!</td>
                      </tr>
                    </ng-container>
                    <ng-template #dataTable>
                      <tr *ngFor="let module of projectData.moduleList;index as i ">
                        <td>{{i+1}}</td>
                        <td>{{module.m_name}}</td>
                        <td>{{module.m_type}}</td>
                        <td>{{timeConvert(module.m_estimated_effort*8*60,"d")}}</td>
                        <td><span class="badge  alert-primary text-primary">{{module.m_status}}</span></td>
                        <td>
                          <a class="btn text-center  alert-success"
                            [routerLink]='["/manage-module/",module.m_id]'><span><i class="las la-eye"></i></span></a>
                        </td>
                      </tr>
                    </ng-template>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="tab-content" *ngIf="tabval=='project_logs'">
              <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                data-parent="#accordion-details">
                <div class="row">
                  <div class="prodetail"
                    *ngIf="projectData!==undefined && projectData!==null && projectData.p_description">
                    {{projectData.p_description}}
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content" *ngIf="tabval=='project_report'">
              <ul class="nav nav-tabs border-0 subtabInfo">
                <li class="nav-item">
                  <a class="nav-link " [ngClass]="subtabval=='estimatedTimeReport'?'active':''"
                    (click)="changeSubTab('estimatedTimeReport')">Time</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link " [ngClass]="subtabval=='milestone'?'active':''"
                    (click)="changeSubTab('milestone')">Milestone</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link " [ngClass]="subtabval=='reportModule'?'active':''"
                    (click)="changeSubTab('reportModule')">Module</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="subtabval=='timesheetreport'?'active':''"
                    (click)="changeSubTab('timesheetreport')" aria-selected="false" tabindex="-1">
                    Employee
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="subtabval=='taskreport'?'active':''"
                    (click)="changeSubTab('taskreport')" aria-selected="false" tabindex="-1">
                    Task
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="subtabval=='alltaskreport'?'active':''"
                    (click)="changeSubTab('alltaskreport')" aria-selected="false" tabindex="-1">
                    All Task
                  </a>
                </li>
                <li class="nav-item">
                  <a class="nav-link" [ngClass]="subtabval=='monthlytaskreport'?'active':''"
                    (click)="changeSubTab('monthlytaskreport')" aria-selected="false" tabindex="-1">
                    Task Report(Monthly)
                  </a>
                </li>
              </ul>
              <div class="odmodule" *ngIf="subtabval=='milestone'">
                <div id="basic-one" class="ctm-padding collapse show p-0" aria-labelledby="basic1"
                  data-parent="#accordion-details">
                  <div class="hd-header d-flex justify-content-between alert alert-primary">
                    <h6 class="m-0">Project Milestone</h6>
                  </div>
                  <div class="table-responsive h-200" *ngIf="showTaskChart;else noChartData">
                    <apx-chart [series]="chartOptionsMilestone.series" [chart]="chartOptionsMilestone.chart"
                      [xaxis]="chartOptionsMilestone.xaxis" [fill]="chartOptionsMilestone.fill"
                      [dataLabels]="chartOptionsMilestone.dataLabels" [legend]="chartOptionsMilestone.legend"
                      [plotOptions]="chartOptionsMilestone.plotOptions" [title]="chartOptionsMilestone.title"
                      [labels]="chartOptionsMilestone.labels">
                    </apx-chart>
                  </div>
                  <ng-template #noChartData>
                    <div>No Data Available!!!</div>
                  </ng-template>
                </div>
              </div>
              <div class="odmodule" *ngIf="subtabval=='estimatedTimeReport'">
                <div id="basic-one" class="ctm-padding collapse show p-0" aria-labelledby="basic1"
                  data-parent="#accordion-details">
                  <div class="hd-header d-flex justify-content-between alert alert-primary">
                    <h6 class="m-0">Assigned : {{timeConvert(projectData.p_estimeted_effort*8*60,"d")}}
                    </h6>
                    <h6 class="m-0"
                      *ngIf="estimetedTimeRepost.totalEffort!=null && estimetedTimeRepost.totalEffort!=undefined">
                      Remaining:
                      {{timeConvert(((projectData.p_estimeted_effort*8*60)-estimetedTimeRepost.totalEffort),"d")}}
                    </h6>
                  </div>
                  <div class="table-responsive">
                    <table class="table custom-table table-hover table-hover" id="empolyeeId">
                      <thead>
                        <tr>
                          <th>Module Name</th>
                          <th>Assigned</th>
                          <th>Spend</th>
                          <th>Remaining</th>
                          <th>Percentage</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container
                          *ngIf="estimetedTimeRepost.projectEffort==undefined || estimetedTimeRepost.projectEffort==''; else dataTable">
                          <tr>
                            <td class="text-center" colspan="6">No Data Found!!</td>
                          </tr>
                        </ng-container>
                        <ng-template #dataTable>
                          <tr *ngFor="let time of estimetedTimeRepost.projectEffort">
                            <td>{{time.module_name}}</td>
                            <td> {{timeConvert(time.assigned_pd,"d")}}</td>
                            <td>{{timeConvert(time.spend_pd,"d")}}</td>
                            <td>{{timeConvert(time.remaining_pd,"d")}}</td>
                            <td> {{ time.percentage }}%</td>
                            <td><a class="btn alert-success text-success text-center mx-1"
                                [routerLink]="['/manage-module',time.m_id]"><span><i class="las la-eye"></i></span></a>
                            </td>
                          </tr>
                        </ng-template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
              <div class="odmodule" *ngIf="subtabval=='reportModule'">
                <div id="basic-one" class="ctm-padding collapse show p-0" aria-labelledby="basic1"
                  data-parent="#accordion-details">
                  <div class="hd-header d-flex justify-content-between alert alert-primary">
                    <h6 class="m-0">Report</h6>
                  </div>
                  <div class="table-responsive h-200" *ngIf="showChart;else noChartData">
                    <apx-chart [series]="chartOptions.series" [chart]="chartOptions.chart" [xaxis]="chartOptions.xaxis"
                      [title]="chartOptions.title" [labels]="chartOptions.labels"></apx-chart>
                  </div>
                  <ng-template #noChartData>
                    <div>No Data Available!!!</div>
                  </ng-template>
                </div>
              </div>
              <div class="odmodule" *ngIf="subtabval=='timesheetreport'">
                <div id="basic-one" class="ctm-padding collapse show p-0" aria-labelledby="basic1"
                  data-parent="#accordion-details">
                  <div class="hd-header d-flex justify-content-between alert alert-primary">
                    <h6 class="m-0">Employees Report</h6>
                  </div>
                  <div class="table-responsive h-200" *ngIf="showEmpChart;else noChartData">
                    <apx-chart [series]="chartOptionsEmp.series" [chart]="chartOptionsEmp.chart"
                      [xaxis]="chartOptionsEmp.xaxis" [title]="chartOptionsEmp.title"
                      [labels]="chartOptionsEmp.labels"></apx-chart>
                  </div>
                  <ng-template #noChartData>
                    <div>No Data Available!!!</div>
                  </ng-template>
                </div>
              </div>
              <div class="odmodule" *ngIf="subtabval=='taskreport'">
                <div id="basic-one" class="ctm-padding collapse show p-0" aria-labelledby="basic1"
                  data-parent="#accordion-details">
                  <div class="hd-header d-flex justify-content-between alert alert-primary">
                    <h6 class="m-0">Task Report</h6>
                  </div>
                  <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                    data-parent="#accordion-details">
                    <div class="row">
                      <div class="prodetail">
                        <div class="row">
                          <div class="col-lg-4">
                            <p class="mb-2">Select Start Date</p>
                            <div class="input-group">
                              <div class="input-group-text"><i class="las la-user-secret"></i>
                              </div>
                              <input type="text" class="form-control" ngbDatepicker [(ngModel)]="startDate"
                                #joinStart="ngbDatepicker" id="start_date" placeholder="Start Date"
                                (click)="joinStart.toggle()" (dateSelect)="onDateSelect($event,'start')" name="sdate"
                                [maxDate]="maxObjectStart" [minDate]="validateMinStart">
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <p class="mb-2">Select End Date</p>
                            <div class="input-group">
                              <div class="input-group-text"><i class="las la-user-secret"></i>
                              </div>
                              <input type="text" class="form-control" [(ngModel)]="endDate" ngbDatepicker
                                #dpStart="ngbDatepicker" id="end_date" placeholder="End Date" name="edate"
                                (dateSelect)="onDateSelect($event,'end')" (click)="dpStart.toggle()"
                                [maxDate]="maxObjectEnd" [minDate]="validateMinEnd">
                            </div>
                          </div>
                          <div class="col-lg-2">
                            <p class="mb-2">Click korun</p>
                            <div class="input-group mb-3 text-end">
                              <button class="btn custom-btn btn-8" (click)="reportTask(projectRwId)"
                                type="submit"><span>Search</span></button>
                            </div>
                          </div>
                          <div class="col-lg-2">
                            <p class="mb-2">Select No Of Task</p>
                            <div class="input-group">
                              <select name="limit" id="limit" class="form-control" (change)="limitData($event,'n')">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive h-200" *ngIf="showTaskChart;else noChartData">
                    <ul class="nav nav-tabs border-0 subtabInfo">
                      <li class="nav-item">
                        <a class="nav-link " (click)="getTaskRepo('top',employeeReportTaskData)">Top 5</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link " (click)="getTaskRepo('least',employeeReportTaskData)">Least 5</a>
                      </li>
                    </ul>
                    <apx-chart
                    [series]="chartOptionsTask.series"
                    [chart]="chartOptionsTask.chart"
                    [xaxis]="chartOptionsTask.xaxis"
                    [yaxis]="chartOptionsTask.yaxis"
                    [title]="chartOptionsTask.title"
                    [labels]="chartOptionsTask.labels"
                    [stroke]="chartOptionsTask.stroke"
                    [dataLabels]="chartOptionsTask.dataLabels"
                    [fill]="chartOptionsTask.fill"
                    [tooltip]="chartOptionsTask.tooltip"
                    [plotOptions]="chartOptionsTask.plotOptions"
                    >
                    </apx-chart>
                  </div>
                  <ng-template #noChartData>
                    <div>No Data Available!!!</div>
                  </ng-template>
                </div>
              </div>
              <div class="odmodule" *ngIf="subtabval=='alltaskreport'">
                <div id="basic-one" class="ctm-padding collapse show p-0" aria-labelledby="basic1"
                  data-parent="#accordion-details">
                  <div class="hd-header d-flex justify-content-between alert alert-primary">
                    <h6 class="m-0">All Task Report</h6>
                  </div>
                  <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                    data-parent="#accordion-details">
                    <div class="row align-items-end">
                      <div class="prodetail">
                        <div class="row">
                          <div class="col-lg-4">
                            <p class="mb-2">Select Start Date</p>
                            <div class="input-group">
                              <div class="input-group-text"><i class="las la-user-secret"></i>
                              </div>
                              <input type="text" class="form-control" ngbDatepicker [(ngModel)]="startDate"
                                #joinStart="ngbDatepicker" id="start_date" placeholder="Start Date"
                                (click)="joinStart.toggle()" (dateSelect)="onDateSelect($event,'start')" name="sdate"
                                [maxDate]="maxObjectStart" [minDate]="validateMinStart">
                            </div>
                          </div>
                          <div class="col-lg-4">
                            <p class="mb-2">Select End Date</p>
                            <div class="input-group">
                              <div class="input-group-text"><i class="las la-user-secret"></i>
                              </div>
                              <input type="text" class="form-control" [(ngModel)]="endDate" ngbDatepicker
                                #dpStart="ngbDatepicker" id="end_date" placeholder="End Date" name="edate"
                                (dateSelect)="onDateSelect($event,'end')" (click)="dpStart.toggle()"
                                [maxDate]="maxObjectEnd" [minDate]="validateMinEnd">
                            </div>
                          </div>
                          <div class="col-lg-2 ">
                            <div class="input-group text-end">
                              <button class="btn custom-btn btn-8 h-100" (click)="reportAllTask(projectRwId)"
                                type="submit"><span>Search</span></button>
                            </div>
                          </div>
                          <div class="col-lg-2">
                            <p class="mb-2">Select No Of Task</p>
                            <div class="input-group">
                              <select name="limit" id="limit" class="form-control" (change)="limitData($event,'all')">
                                <option value="5">5</option>
                                <option value="10">10</option>
                                <option value="15">15</option>
                                <option value="20">20</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="table-responsive h-200" *ngIf="showTaskChart;else noChartData">
                    <ul class="nav nav-tabs border-0 subtabInfo">
                      <li class="nav-item">
                        <a class="nav-link " (click)="getTaskRepo('top',allTasktData)">Top 5</a>
                      </li>
                      <li class="nav-item">
                        <a class="nav-link " (click)="getTaskRepo('least',allTasktData)">Least 5</a>
                      </li>
                    </ul>
                    <apx-chart [series]="chartOptionsTask.series" [chart]="chartOptionsTask.chart"
                      [xaxis]="chartOptionsTask.xaxis" [yaxis]="chartOptionsTask.yaxis" [title]="chartOptionsTask.title"
                      [labels]="chartOptionsTask.labels" [stroke]="chartOptionsTask.stroke"
                      [dataLabels]="chartOptionsTask.dataLabels" [fill]="chartOptionsTask.fill"  [plotOptions]="chartOptionsTask.plotOptions"></apx-chart>
                  </div>
                  <ng-template #noChartData>
                    <div>No Data Available!!!</div>
                  </ng-template>
                </div>
              </div>
              <div class="odmodule" *ngIf="subtabval=='monthlytaskreport'">
                <div id="basic-one" class="ctm-padding collapse show p-0" aria-labelledby="basic1"
                  data-parent="#accordion-details">
                  <div class="hd-header d-flex justify-content-between alert alert-primary">
                    <h6 class="m-0">Monthly Task Report</h6>
                  </div>
                  <div class="table-responsive h-200" *ngIf="showTaskChart;else noChartData">
                    <apx-chart [series]="chartOptionsMonthlyTask.series" [chart]="chartOptionsMonthlyTask.chart"
                      [xaxis]="chartOptionsMonthlyTask.xaxis" [yaxis]="chartOptionsMonthlyTask.yaxis"
                      [title]="chartOptionsMonthlyTask.title" [labels]="chartOptionsMonthlyTask.labels"
                      [stroke]="chartOptionsMonthlyTask.stroke" [dataLabels]="chartOptionsMonthlyTask.dataLabels"
                      [plotOptions]="chartOptionsMonthlyTask.plotOptions" [fill]="chartOptionsMonthlyTask.fill"
                      [tooltip]="chartOptionsMonthlyTask.tooltip" [legend]="chartOptionsMonthlyTask.legend"></apx-chart>
                  </div>
                  <ng-template #noChartData>
                    <div>No Data Available!!!</div>
                  </ng-template>
                </div>
              </div>
            </div>
            <div class="tab-content" *ngIf="tabval=='logs'">
              <ul class="nav nav-tabs border-0 subtabInfo">
                <li class="nav-item">
                  <a class="nav-link " [ngClass]="subtabval=='project_stage'?'active':''"
                    (click)="changeSubTab('project_stage')">Stage Log</a>
                </li>
                <li class="nav-item">
                  <a class="nav-link " [ngClass]="subtabval=='estimeted_effort'?'active':''"
                    (click)="changeSubTab('estimeted_effort')">Time Logs</a>
                </li>
              </ul>
              <div class="pro_stage" *ngIf="subtabval=='project_stage'">
                <div class="table-responsive">
                  <table class="table custom-table table-hover table-hover" id="empolyeeId">
                    <thead>
                      <tr>
                        <th>Sl_no</th>
                        <th>Modified By</th>
                        <th>Before Stage</th>
                        <th>After Stage</th>
                        <th>Change Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="projectStage==undefined || projectStage==''; else dataTable">
                        <tr>
                          <td class="text-center" colspan="6">No Data Found!!</td>
                        </tr>
                      </ng-container>
                      <ng-template #dataTable>
                        <tr *ngFor="let stage of projectStage; index as i ">
                          <td>{{i+1}}</td>
                          <td>{{stage.action_by}}</td>
                          <td> <span class="badge bg-primary">{{stage.before_stage}}</span></td>
                          <td> <span class="badge bg-primary">{{stage.after_stage}}</span></td>
                          <td>{{stage.update_on.toLocaleString()}}</td>
                        </tr>
                      </ng-template>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="effor" *ngIf="subtabval=='estimeted_effort'">
                <div class="table-responsive">
                  <table class="table custom-table table-hover table-hover" id="empolyeeId">
                    <thead>
                      <tr>
                        <th>Sl_no</th>
                        <th>Modified By</th>
                        <th>Before PD</th>
                        <th>After PD</th>
                        <th>Change Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      <ng-container *ngIf="projectEstLog==undefined || projectEstLog==''; else dataTable">
                        <tr>
                          <td class="text-center" colspan="6">No Data Found!!</td>
                        </tr>
                      </ng-container>
                      <ng-template #dataTable>
                        <tr *ngFor="let pd of projectEstLog; index as i ">
                          <td>{{i+1}}</td>
                          <td>{{pd.action_by}}</td>
                          <td>{{pd.before_est_effort}} PD</td>
                          <td>{{pd.after_est_effort}} PD</td>
                          <td>{{pd.update_on.toLocaleString()}}</td>
                        </tr>
                      </ng-template>
                    </tbody>
                  </table>
                </div>
              </div>
              <div class="odmodule" *ngIf="subtabval=='estimatedTimeReport'">
                <div id="basic-one" class="ctm-padding collapse show p-0" aria-labelledby="basic1"
                  data-parent="#accordion-details">
                  <div class="hd-header d-flex justify-content-between alert alert-primary">
                    <h6 class="m-0">Assigned : {{timeConvert(projectData.p_estimeted_effort*8*60,"d")}}
                    </h6>
                    <h6 class="m-0"
                      *ngIf="estimetedTimeRepost.totalEffort!=null && estimetedTimeRepost.totalEffort!=undefined">
                      Remaining:
                      {{timeConvert(((projectData.p_estimeted_effort*8*60)-estimetedTimeRepost.totalEffort),"d")}}
                    </h6>
                  </div>
                  <div class="table-responsive">
                    <table class="table custom-table table-hover table-hover" id="empolyeeId">
                      <thead>
                        <tr>
                          <th>Module Name</th>
                          <th>Assigned</th>
                          <th>Spend</th>
                          <th>Remaining</th>
                          <th>Percentage</th>
                          <th>View</th>
                        </tr>
                      </thead>
                      <tbody>
                        <ng-container
                          *ngIf="estimetedTimeRepost.projectEffort==undefined || estimetedTimeRepost.projectEffort==''; else dataTable">
                          <tr>
                            <td class="text-center" colspan="6">No Data Found!!</td>
                          </tr>
                        </ng-container>
                        <ng-template #dataTable>
                          <tr *ngFor="let time of estimetedTimeRepost.projectEffort">
                            <td>{{time.module_name}}</td>
                            <td> {{timeConvert(time.assigned_pd,"d")}}</td>
                            <td>{{timeConvert(time.spend_pd,"d")}}</td>
                            <td>{{timeConvert(time.remaining_pd,"d")}}</td>
                            <td> {{ time.percentage }}%</td>
                            <td><a class="btn alert-success text-success text-center mx-1"
                                [routerLink]="['/manage-module',time.m_id]"><span><i class="las la-eye"></i></span></a>
                            </td>
                          </tr>
                        </ng-template>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <!--end tab-content-->
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="memberList" [ngClass]="docStatus==1?'on':''" *ngIf="itemList.length>0">
    <div class="TopList">
      <div class="modal-content border-0">
        <div class="modal-header p-3 ps-4 bg-soft-success">
          <h5 class="modal-title" id="inviteMembersModalLabel">Members</h5>
          <button type="button" class="btn-close" (click)="docStatus=openCloseTab(docStatus,0)"></button>
        </div>
        <div class="modal-body p-4">
          <div class="search-box mb-3">
            <input type="text" (keyup)="searchType($event)" class="form-control bg-light border-light u_find" placeholder="Search here..." [(ngModel)]="multiSearch">
            <!-- <i class="las la-search"></i> -->
          </div>
          <div class="cardmembers  px-4 table-responsive">
            <div class="simplebar-wrapper ">

              <div class="simplebar-mask">
                <div class="simplebar-offset">
                  <div class="simplebar-content-wrapper">
                    <div class="simplebar-content">
                      <div class="vstack gap-3">
                        <div class="d-flex align-items-center" *ngFor="let usr of itemList|tableSearch:objKey;">
                          <div class="avatar-xs flex-shrink-0 me-3">
                            <h4 class="userShape-Sm" [ngStyle]="{'background-color':hsl}">
                              {{convertToLetter(usr.name)}}
                            </h4>
                          </div>
                          <div class="flex-grow-1">
                            <h5 class="fs-13 mb-0"><a routerLink="#" class="text-body d-block">{{usr.name}}</a></h5>
                          </div>
                          <div class="flex-shrink-0">
                            <svg xmlns="http://www.w3.org/2000/svg" (click)="select_user(usr.id,usr.name)" width="20" height="20" fill="#ea556f"
                              class="bi bi-plus-circle" viewBox="0 0 16 16">
                              <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14m0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16" />
                              <path d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4" />
                            </svg>
                          </div>
                        </div>
                        <!-- end member item -->
                      </div>
                      <!-- end list -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light w-xs">Cancel</button>
        </div>
      </div>
      <!-- end modal-content -->
    </div>
    <!-- modal-dialog -->
  </div>
  <div class="accordion add-employee " id="taskedit" [ngClass]="docStatus==2?'on':''">

    <div class="card  grow ctm-border-radius shadow-none">
      <div class="modal-header p-3 ps-4 bg-soft-success">
        <h5 class="modal-title" id="inviteMembersModalLabel">Edit Project</h5>
        <button type="button" class="btn-close" (click)="docStatus=openCloseTab(docStatus,0)"></button>
      </div>
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
          <div class="row">
            <div class="prodetail">
              <form [formGroup]="projectForm" (ngSubmit)="onSubmit(projectForm)" #fd="ngForm" [ngxFvControl]="fd">
                <div class="row">
                  <div class="col-lg-12 form-group">
                    <label>
                      <p class="mb-2">Project Name</p>
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="lab la-git-alt"></i>
                      </div>
                      <input type="text" readonly class="form-control" id="pname" placeholder="Project Name"
                        #ngxFvFormControl formControlName="pname">
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label>
                      <p class="mb-2">Project Domain</p>
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="las la-random"></i>
                      </div>
                      <input type="text" readonly class="form-control" id="p_domain" placeholder="Project domain"
                        #ngxFvFormControl formControlName="pdomain">
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <label>
                      <p class="mb-2">Project Type</p>
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="las la-layer-group"></i>
                      </div>
                      <input type="text" readonly class="form-control" id="ptype" #ngxFvFormControl
                        formControlName="ptype" name="ptype" placeholder="Project Name">
                    </div>
                  </div>

                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="projectForm.controls['pstage']">
                      <label #ngxFvName>
                        <p class="mb-2">Project Stage</p>
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-user-secret"></i>
                        </div>
                        <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                          #ngxFvFormControl formControlName="pstage" name="pstage">
                          <option selected="Planning">Planning</option>
                          <option selected="Designing">Designing</option>
                          <option selected="Execution">Execution</option>
                        </select>
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>

                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="projectForm.controls['pstatus']">
                      <label #ngxFvName>
                        <p class="mb-2">Project Status</p>
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-user-secret"></i>
                        </div>
                        <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                          #ngxFvFormControl formControlName="pstatus" name="pstatus">
                          <option selected="active">Active</option>
                          <option selected="inactive">Inactive</option>
                          <option selected="stop">Stop</option>
                          <option selected="finished">Finish</option>
                        </select>
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>

                  <div class="col-lg-12 form-group">
                    <ngx-fv-validate [control]="projectForm.controls['pdescription']">
                      <label #ngxFvName>
                        <p class="mb-2">Project Description</p>
                      </label>

                      <div class="input-group mb-3">
                        <textarea class="form-control" placeholder="" #ngxFvFormControl
                          formControlName="pdescription"> </textarea>
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="projectForm.controls['pestimetedenddate']">
                      <label #ngxFvName>
                        <p class="mb-2">Project End Date</p>
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-calendar"></i>
                        </div>
                        <input type="text" ngbDatepicker #dpStart="ngbDatepicker" name="dpStart"
                          (click)="dpStart.toggle()" class="form-control" #ngxFvFormControl
                          formControlName="pestimetedenddate">
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="projectForm.controls['pestimetedeffort']">
                      <label #ngxFvName>
                        <p class="mb-2">Project Effort</p>
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-business-time"></i>
                        </div>
                        <input type="Number" class="form-control" id="username" placeholder="Est End Duration in PD"
                          #ngxFvFormControl formControlName="pestimetedeffort">
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-12 form-group">
                    <div class="input-group mb-3" *ngIf="isShow">
                      <button class="btn custom-btn btn-8 updbtn" type="submit"><span>Update</span></button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>