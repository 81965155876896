import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private Loadersubject = new Subject<any>();

  startLoader() {
    this.Loadersubject.next({ action: true });
  }

  closeLoader() {
    this.Loadersubject.next({action: false });
  }

  getLoader(): Observable<any> {
      return this.Loadersubject.asObservable();
  }

  constructor() { }
}
