<div class="main-content" *ngIf="loadAllComponant">
  <div class="card shadow-sm grow ctm-border-radius mb-2">
    <div class="card-body align-items-center d-flex justify-content-between">
      <h4 class="card-title  mb-0 mt-0"> <strong><i class="las la-info-circle"></i>
          Shift List</strong></h4>

      <ul class="nav nav-tabs  border-0 tab-list-emp">

        <li class="nav-item pl-3">
          <a routerLink="/add-shift" class="custom-btn btn-8 text-center"><span><i class="las la-plus"></i> Add
              Shift</span> </a>
        </li>
      </ul>
    </div>
  </div>
  <div class="ctm-border-radius shadow-sm grow card">
    <div class="card-body">

      <div class="table-back employee-office-table">
        <div class="table-responsive">
          <table class="table custom-table table-hover table-hover">
            <thead>
              <tr>
                <th>SL No</th>
                <th>Shift Name</th>
                <th>Shift Country</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <ng-container *ngIf="shiftList==undefined || shiftList==''; else dataTable">
                <tr>
                  <td class="text-center" colspan="6">No Data Found!!</td>
                </tr>
              </ng-container>
              <ng-template #dataTable>
                <tr *ngFor="let list of shiftList; index as i">
                  <td>{{i+1}}</td>
                  <td>{{list.name}}</td>
                  <td>{{list.s_country}}</td>
                  <td>{{list.start_time}}</td>
                  <td>{{list.end_time}}</td>
                  <td>
                    <button class="btn btn-danger" (click)="deleteShift(list.id)">Delete</button>
                  </td>
                </tr>
              </ng-template>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>