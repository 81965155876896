import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule , ReactiveFormsModule} from '@angular/forms'
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './layout/header/header.component';
import { FooterComponent } from './layout/footer/footer.component';
import { SidebarComponent } from './layout/sidebar/sidebar.component';
import { LoginComponent } from './pages/login/login.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ProjectComponent } from './pages/project/project.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import {NgbPaginationModule, NgbAlertModule} from '@ng-bootstrap/ng-bootstrap';
import { TaskComponent } from './pages/task/task.component';
import { ModuleComponent } from './pages/module/module.component';
import { CreateProjectComponent } from './pages/create-project/create-project.component';
import { CreateTaskComponent } from './pages/create-task/create-task.component';
import { CreateModuleComponent } from './pages/create-module/create-module.component';
import { TimesheetComponent } from './pages/timesheet/timesheet.component';
import { CommonModule } from '@angular/common';
import { FlatpickrModule } from 'angularx-flatpickr';
import { CalendarModule, DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { NgbModalModule } from '@ng-bootstrap/ng-bootstrap';
import { RestService } from "./rest.service";
import { NgxFormValidationsModule } from 'ngx-form-validations';
import { HttpClientModule,HTTP_INTERCEPTORS} from '@angular/common/http';
import { NgxWebstorageModule } from 'ngx-webstorage';
import { TmdateFilterPipe } from './tmdate-filter.pipe';
import { LoaderOneComponent } from './layout/loader/loader-one/loader-one.component';
import { LoaderTwoComponent } from './layout/loader/loader-two/loader-two.component';
import { MyHttpInterceptor } from './my-http-interceptor';
import { EmployeesModule } from './pages/employees/employees.module';
import { AuthServiceService } from './auth-service.service';
import { AngularMultiSelectModule } from 'angular2-multiselect-dropdown';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { NgSelectModule } from "@ng-select/ng-select";
import { ProjectDashboardComponent } from './pages/project-dashboard/project-dashboard.component';
import { ManageEmployeeComponent } from './pages/manage-employee/manage-employee.component';
import { TaskManageComponent } from './pages/task-manage/task-manage.component';
import { ManageModuleComponent } from './pages/manage-module/manage-module.component';
import { ManageProjectComponent } from './pages/manage-project/manage-project.component';
import { ShareModule } from './share/share.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';
import { SharedSearchModule } from './shared-search/shared-search.module';
import { NgxVerticalTimelineModule } from 'ngx-vertical-timeline';
import { SweetAlert2Module } from '@sweetalert2/ngx-sweetalert2';
import { WebcamModule } from 'ngx-webcam';
import { NgApexchartsModule } from 'ng-apexcharts';
import {AutosizeModule} from 'ngx-autosize';
import { NgDragDropModule } from 'ng-drag-drop';
import { ParticlesModule } from 'ngx-particle';
import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { TooltipModule } from 'ng2-tooltip-directive';

import {
  NgxUiLoaderModule,
  NgxUiLoaderConfig,
  SPINNER,
  POSITION,
  PB_DIRECTION,
} from "ngx-ui-loader";
import { RosterComponent } from './pages/roster/roster/roster.component';
import { ShiftListComponent } from './pages/roster/shift-list/shift-list.component';
import { CreateShiftComponent } from './pages/roster/create-shift/create-shift.component';
import { RecurringTaskComponent } from './pages/recurring-task/recurring-task.component';
import { ReminderHistoryComponent } from './pages/reminder-history/reminder-history.component';
import { TagsComponent } from './pages/tags/tags.component';
import { WorkReportsComponent } from './pages/work-reports/work-reports.component';
import { IvyCarouselModule } from 'angular-responsive-carousel';
import { AutomatedTaskComponent } from './pages/automated-task/automated-task.component';
import { CreateAutomatedTaskComponent } from './pages/create-automated-task/create-automated-task.component';
import { TaskDashboardComponent } from './pages/task-dashboard/task-dashboard.component';
import { AnnouncementsComponent } from './pages/announcements/announcements.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { RosterShiftsComponent } from './pages/roster-shifts/roster-shifts.component';
import { RosterListComponent } from './pages/roster-list/roster-list.component';
// import { ManageTimesheetComponent } from './pages/manage-timesheet/manage-timesheet.component';


const ngxUiLoaderConfig: NgxUiLoaderConfig = {
  bgsColor: "red",
  pbColor: "#ea556f",
  fgsColor: "#ea556f",
  bgsPosition: POSITION.bottomCenter,
  bgsSize: 40,
  bgsType: SPINNER.rectangleBounce, // background spinner type
  fgsType: SPINNER.wanderingCubes,
  // foreground spinner type
  pbDirection: PB_DIRECTION.leftToRight, // progress bar direction
  pbThickness: 5, // progress bar thickness
};



@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    LoginComponent,
    DashboardComponent,
    ProfileComponent,
    ProjectComponent,
    TaskComponent,
    ModuleComponent,
    CreateProjectComponent,
    CreateTaskComponent,
    CreateModuleComponent,
    TimesheetComponent,
    TmdateFilterPipe,
    LoaderOneComponent,
    LoaderTwoComponent,
    ForbiddenComponent,
    NotfoundComponent,
    ProjectDashboardComponent,
    ManageEmployeeComponent,
    TaskManageComponent,
    ManageProjectComponent,
    ManageModuleComponent,
    RosterComponent,
    ShiftListComponent,
    CreateShiftComponent,
    RecurringTaskComponent,
    ReminderHistoryComponent,
    TagsComponent,
    WorkReportsComponent,
    AutomatedTaskComponent,
    CreateAutomatedTaskComponent,
    TaskDashboardComponent,
    AnnouncementsComponent,
    ReportsComponent,
    RosterShiftsComponent,
    RosterListComponent
    
    
  ],
  imports: [
    BrowserModule,
    AutosizeModule,
    NgApexchartsModule,
    TooltipModule,
    NgxVerticalTimelineModule,
    EmployeesModule,
    CommonModule,
    AppRoutingModule,
    NgbModule,
    ParticlesModule,
    NgDragDropModule.forRoot(),
    NgbPaginationModule,
    NgbAlertModule,
    FormsModule,
    ReactiveFormsModule,
    AngularMultiSelectModule,
    BrowserAnimationsModule,
    HttpClientModule,
    IvyCarouselModule,
    WebcamModule,
    InfiniteScrollModule,
    ToastrModule.forRoot({
      timeOut: 1000,
      positionClass: 'toast-bottom-right',
      preventDuplicates: true,
    }),
    NgbModalModule,
    EmployeesModule,
    NgxWebstorageModule.forRoot(),
    NgxUiLoaderModule.forRoot(ngxUiLoaderConfig),
    NgxFormValidationsModule,
    NgSelectModule,
    ShareModule,
    SharedSearchModule,
    SweetAlert2Module.forRoot(),
    FlatpickrModule.forRoot(),
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    // NgApexchartsModule,
    
    
  ],
  providers: [RestService,
    AuthServiceService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: MyHttpInterceptor,
      multi: true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
