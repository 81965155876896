import { Injectable, Injector } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpErrorResponse,
  HttpRequest
} from "@angular/common/http";
import { catchError, throwError } from "rxjs";
import { AuthServiceService } from "./auth-service.service";


@Injectable()
export class MyHttpInterceptor implements HttpInterceptor {
  constructor(public auth:AuthServiceService) {}

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): any {
    //console.log("intercepted request ... ");

    // Clone the request to add the new header.
    const authReq = req.clone({
       headers: req.headers.set("Authorization", "Basic dXNlcjpJbm5vU2VlZE5ld0FwSUAyMDE4")
    });

    //console.log("Sending request with new header now ...");

    // send the newly created request
    return next.handle(authReq).pipe(catchError(err => {
      if (err instanceof HttpErrorResponse) {
          if (err.status === 401) {
              console.log(err.error);
              this.auth.logout();
          }
      }

      return throwError(err);
      
}));
    
  }
}
