import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { PagesService } from '../../pages.service';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-attendance-report',
  templateUrl: './attendance-report.component.html',
  styleUrls: ['./attendance-report.component.scss'],
})
export class AttendanceReportComponent implements OnInit {
  public tabval = 'empReport';
  public menuToggle = '';
  public itemList: any;
  public selectedUserList: any[] = [];
  public startDate = '';
  public validateMinStart: any;
  public validateMinEnd: any;
  public endDate = '';
  public maxObjectStart: any;
  public maxObjectEnd: any;
  public userList = <any>[];
  public isVisibleDate = false;
  public isVisibleEmp = false;
  public getDateReportList: any;
  public getEmpReportList: any;
  public dateHeading: any;
  public empName: any;
  public dateReportHeadings : any;
  public loadAllComponant = false;
  // public isBtnShow=false;
  constructor(
    public rest: RestService,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService,
    public pagesService: PagesService
  ) { }

  ngOnInit(): void {
    let d = new Date();
    this.maxObjectStart = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.maxObjectEnd = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.validateMinStart = {
      year: 2022,
      month: 9,
      day: 1,
    }
    this.validateMinEnd = {
      year: 2022,
      month: 9,
      day: 1,
    }
    this.getEmployeeList();
  }

  changeTab(tab: string) {
    this.tabval = tab;
  }

  dateConvert(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    const convertDate = year + '-' + month + '-' + day;
    return convertDate;
  }

  select_user(ev: any) {
    console.log(ev);
    this.selectedUserList = ev;
  }
/* get all user list*/
  getEmployeeList() {
    this.ngxService.start();
    this.rest.getData({}, 'getemployees').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.itemList = data.data;
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        this.toaster.error('Something went wrong!');
      },
    });
  }

  /* get attendance report user wise */
  getEmpReport() {
    if (this.endDate === '' && this.startDate === '') {
      this.toaster.error('Please select dates!');
      return;
    }
    this.startDate = this.dateConvert(this.startDate);
    this.endDate = this.dateConvert(this.endDate);
    if (this.startDate > this.endDate) {
      this.toaster.error('Start date should not be greater than end date!!!');
      return;
    }
    this.rest.getData({ sdate: this.startDate, edate: this.endDate, user_id: this.selectedUserList }, 'getemployeereport').subscribe({
      next: (data) => {
        this.ngxService.stop();
        if (data.state == '200') {
          this.isVisibleEmp = true;         
          this.getEmpReportList = data.data;
          console.log(this.getEmpReportList);
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.toaster.error('Something went wrong!');
      },
    });
  }

  onDateSelect(ev: any, type = "") {
    let d = new Date();
    if (type == 'start') {
      this.validateMinEnd = {
        year: ev.year,
        month: ev.month,
        day: ev.day,
      };
    } else {
      this.maxObjectStart = {
        year: ev.year,
        month: ev.month,
        day: ev.day,
      };
    }
  }


  /* get attendance report date wise */
  getDateReport() {
    this.ngxService.start();
    this.startDate = this.dateConvert(this.startDate);
    this.endDate = this.dateConvert(this.endDate);
    this.rest.getData({ sdate: this.startDate, edate: this.endDate }, 'getdatereport').subscribe({
      next: (data) => {
        this.ngxService.stop();
        if (data.state == '200') { 
          this.getDateReportList = data.data;
          console.log(this.getDateReportList);
          for (let rep of this.getDateReportList) {
            let keys = Object.keys(rep);
              this.dateReportHeadings = keys;
          }
          this.isVisibleDate = true;
          // this.pagesService.exportExcel(data.data);        
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.toaster.error('Something went wrong');
      },
    });
  }

  // downloadReport(data: any,name="report") {
  //   console.log(data);
  //   if ((name && data) != '' && (name && data) != undefined) {
  //     this.pagesService.exportExcel(data, name);
  //   } else {
  //     this.toaster.error("Something error!")
  //   }
  // }
  /* download excel report */
  downloadReport(data: any,name="report") {
    Swal.fire({
      title: 'Do you want to download ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      confirmButtonText: 'Yes!',
    }).then((result) => {
      if (result.isConfirmed) {
        if ((name && data) != '' && (name && data) != undefined) {
          this.pagesService.exportExcel(data, name);
        } else {
          this.toaster.error("Something went wrong!")
        }
      }
    });
  }
}
