<!-- 

<div class="auth-page-wrapper pt-5">
  auth page bg 
  <div class="auth-one-bg-position auth-one-bg" id="auth-particles"
    style="background:url(assets/colleagues-giving-fist-bump.jpg)">
    <div class="bg-overlay"></div>
    <div class="shape">
      <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink"
        viewBox="0 0 1440 120">
        <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
      </svg>
    </div>
  </div>
  <div class="auth-page-content">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center mt-sm-5 mb-4 text-white-50">
            <div>
              <a href="javascript:void(0)" class="d-inline-block auth-logo">
                <img src="../../../assets/logo.png" alt="" height="20">
              </a>
            </div>
          </div>
        </div>
      </div>
       

      <div class="row justify-content-center">
        <div class="col-md-8 col-lg-6 col-xl-5">
          <div class="card mt-4">

            <div class="card-body p-4">
              <div class="text-center mt-2">
                <h5 class="text-primary">Welcome Back !</h5>
                <p class="text-muted">Sign in to continue to Account</p>
              </div>
              <div class="p-2 mt-4">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)" #fd="ngForm" [ngxFvControl]="fd">
                  <ngx-fv-validate [control]="loginForm.controls['email']">
                    <div class="input-group mb-3">
                      <label #ngxFvName class="d-none">
                        Username
                      </label>
                      <div class="input-group-text"><i class="las la-user-lock"></i>
                      </div>
                      <input type="text" class="form-control" #ngxFvFormControl id="username" placeholder="Username"
                        formControlName="email" autocomplete="new-email">
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                  <ngx-fv-validate [control]="loginForm.controls['password']">
                    <div class="input-group mb-3">
                      <label #ngxFvName class="d-none">
                        Password
                      </label>
                      <div class="input-group-text"><i class="las la-key"></i>
                      </div>
                      <input type="password" class="form-control" #ngxFvFormControl id="password"
                        placeholder="password " formControlName="password" autocomplete="new-password">

                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                  <button type="submit" class="btn w-100 custom-btn btn-12 d-block"><span>SIGN IN</span></button>

                </form>
                <div *ngIf="error">*Invalid login</div>
              </div>
            </div>
          
          </div>
        </div>
      </div>
    </div>
  </div>
  <footer class="footer">
    <div class="container">
      <div class="row">
        <div class="col-lg-12">
          <div class="text-center">
            <p class="mb-0 text-muted">©
              <script>document.write(new Date().getFullYear())</script>2022 All Right Resevered. Powered <i
                class="mdi mdi-heart text-danger"></i> by Bookyup
            </p>
          </div>
        </div>
      </div>
    </div>
  </footer>
</div>-->
<!-- <section class="loginPanel" style="background: linear-gradient(274deg,  #1b3447cc 47%, #000000 100%),url(assets/colleagues-giving-fist-bump.jpg),url(assets/colleagues-giving-fist-bump.jpg);
background-size: cover;">
  <div class="container h-p100">
    <div class="row align-items-center min-vh-100">
      <div class="col-12">
        <div class="loginContainer">
          <div class="row g-0 justify-content-center  align-items-stretch">
            <div class="col-lg-4">
              <div class="loginLeftcontent h-100">
                <h2>GET STARTED WITH BOOKYUP</h2>
                <p>SIGN IN TO START YOUR SESSION</p>
                <p><b>- SIGN WITH -</b></p>

              </div>
            </div>
            <div class="col-lg-5">
              <div class="loginRightcontent h-100">
                <form [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)" #fd="ngForm" [ngxFvControl]="fd">
                  <ngx-fv-validate [control]="loginForm.controls['email']">
                    <div class="input-group mb-3">
                      <label #ngxFvName class="d-none">
                        Username
                      </label>
                      <div class="input-group-text"><i class="las la-user-lock"></i>
                      </div>
                      <input type="text" class="form-control" #ngxFvFormControl id="username" placeholder="Username"
                        formControlName="email" autocomplete="new-email">
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                  <ngx-fv-validate [control]="loginForm.controls['password']">
                    <div class="input-group mb-3">
                      <label #ngxFvName class="d-none">
                        Password
                      </label>
                      <div class="input-group-text"><i class="las la-key"></i>
                      </div>
                      <input type="password" class="form-control" #ngxFvFormControl id="password"
                        placeholder="password " formControlName="password" autocomplete="new-password">

                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                  <button type="submit" class="btn w-100 custom-btn btn-12 d-block"><span>SIGN IN</span></button>

                </form>
                <div *ngIf="error">*Invalid login</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</section> --> 
<!-- <div class="auth-wrapper align-items-stretch aut-bg-img aut-bg-right" style="background:url(assets/colleagues-giving-fist-bump.jpg)">
  <div class="flex-grow-1">
    <div class="h-100 d-md-flex align-items-center auth-side-img">
      <div class="col-sm-10 auth-content w-auto">
      </div>
    </div>
    <div class="auth-side-form">
      <div class="auth-content">
        <div class="row justify-content-center">
          <div class="col-md-9">
            <div class="text-center">
              <img src="../../../assets/logo.png" alt="" class="img-fluid mb-3">
              <h1 class="f-w-400">Hi there, please log in</h1>
            </div>

              <form class="my-5" [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)" #fd="ngForm" [ngxFvControl]="fd">
                <ngx-fv-validate [control]="loginForm.controls['email']">
              <div class="form-group">
                <label>Email address</label>
                <input type="email" class="form-control"  #ngxFvFormControl id="username"
                formControlName="email" autocomplete="new-email" placeholder="Ex. user@bookyup.com">
              </div>
              <ngx-fv-error></ngx-fv-error>
            </ngx-fv-validate>
            <ngx-fv-validate [control]="loginForm.controls['password']">
              <div class="form-group">
                <label>Password</label>
                <input type="password" class="form-control" #ngxFvFormControl id="password"
                placeholder="password " formControlName="password" autocomplete="new-password">
              </div>
              <ngx-fv-error></ngx-fv-error>
            </ngx-fv-validate>
              <div class="form-group">
                <button class="btn btn-primary custom-btn btn-12 mb-4 w-100">Sign In</button>
              </div>
              <div *ngIf="error">*Invalid login</div>
            </form>
          
          </div>
        </div>
      </div>
      <div class="footer-cont text-center">
        <h6 class="mb-0 text-muted">&copy; All Rights Reserved. Bookyup</h6>
      </div>
    </div>
  </div>
</div> -->
<div class="auth-page-wrapper pt-5">
  <!-- auth page bg -->
  <div class="auth-one-bg-position auth-one-bg" id="auth-particles" style="background:url(../../../assets/auth-one-bg.jpg)">
      <div class="bg-overlay"></div>

      <div class="shape">
          <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 1440 120">
              <path d="M 0,36 C 144,53.6 432,123.2 720,124 C 1008,124.8 1296,56.8 1440,40L1440 140L0 140z"></path>
          </svg>
      </div>
      <particles [style]="myStyle" [width]="width" [height]="height" ></particles>

  <!-- auth page content -->
  <div class="auth-page-content">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="text-center mt-sm-5 mb-4 text-white-50">
                      <div>
                          <a href="/" class="d-inline-block auth-logo">
                              <img src="../../../assets/logo.png" alt="" height="20">
                          </a>
                      </div>
                  </div>
              </div>
          </div>
          <!-- end row -->

          <div class="row justify-content-center">
              <div class="col-md-8 col-lg-6 col-xl-5 col-11">
                  <div class="card mt-4">

                      <div class="card-body p-4">
                          <div class="text-center mt-2">
                              <h5>Welcome Back!</h5>
                              <!-- <p class="text-muted">Sign in to continue to Bookyup Erp.</p> -->
                          </div>
                          <div class="p-2 mt-4 mobi">
                              <form  [formGroup]="loginForm" (ngSubmit)="onSubmit(loginForm)" #fd="ngForm" [ngxFvControl]="fd">
                                
                                <ngx-fv-validate [control]="loginForm.controls['email']">
                                  <div class="mb-3">
                                      <label for="username" class="form-label" #ngxFvName>Username</label>
                                      <input type="text" class="form-control" #ngxFvFormControl id="username"
                                      formControlName="email" autocomplete="new-email" placeholder="Example@hotmail.com">
                                  </div>
                                  <ngx-fv-error></ngx-fv-error>
                                  </ngx-fv-validate>
                                  <ngx-fv-validate [control]="loginForm.controls['password']">
                                  <div class="mb-3">
                                     
                                      <label class="form-label" for="password-input" #ngxFvName>Password</label>
                                      <div class="position-relative auth-pass-inputgroup mb-3">
                                          <input type="password" class="form-control pe-5 password-input" #ngxFvFormControl id="password"
                                          placeholder="Password " formControlName="password" autocomplete="new-password">
                                        
                                      </div>
                                  </div>
                                    <ngx-fv-error></ngx-fv-error>
                                  </ngx-fv-validate>
                                    
                                  <!-- <div class="form-check">
                                      <input class="form-check-input" type="checkbox" value="" id="auth-remember-check">
                                      <label class="form-check-label" for="auth-remember-check">Remember me</label>
                                  </div> -->

                                  <div class="mt-4">
                                      <button class="btn custom-btn btn-8 w-100" type="submit">Log In</button>
                                  </div>
                                  <br>
                                  <div *ngIf="error">*Invalid Username Or Password</div>
                              </form>
                          </div>
                      </div>
                      <!-- end card body -->
                  </div>
              </div>
          </div>
          <!-- end row -->
      </div>
      <!-- end container -->
  </div>

  <footer class="footer">
      <div class="container">
          <div class="row">
              <div class="col-lg-12">
                  <div class="text-center">
                      <p class="mb-0 text-muted">©
                          <script>document.write(new Date().getFullYear())</script>2023 Erp Bookyup | All Right Reserved
                      </p>
                  </div>
              </div>
          </div>
      </div>
  </footer>
  <!-- end Footer -->
</div>