import { Component, OnInit, ViewChild } from '@angular/core';
import { RestService } from '../../rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexNonAxisChartSeries,
  ApexChart,
  ApexPlotOptions,
  ApexResponsive,
  ApexDataLabels,
  ApexXAxis,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexTitleSubtitle,
  ApexLegend,
  ApexStroke,
} from 'ng-apexcharts';
import { PagesService } from '../pages.service';
import Swal from 'sweetalert2';

export type ChartOptionsMileStone = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  fill: ApexFill;
  legend: ApexLegend;
  dataLabels: ApexDataLabels;
  xaxis: ApexXAxis;
  plotOptions: ApexPlotOptions;
};
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};

export type ChartOptionsEmp = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};

export type ChartOptionsTask = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  title: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
  labels: string[];
  colors: string[];
  stroke: ApexStroke; // ApexStroke;
  dataLabels: ApexDataLabels; // ApexDataLabels;
  fill: ApexFill;
  tooltip: ApexTooltip;
  subtitle: ApexTitleSubtitle;
};

export type ChartOptionsMonthlyTask = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  dataLabels: ApexDataLabels;
  plotOptions: ApexPlotOptions;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis;
  stroke: ApexStroke;
  title: ApexTitleSubtitle;
  tooltip: ApexTooltip;
  fill: ApexFill;
  legend: ApexLegend;
};

@Component({
  selector: 'app-manage-project',
  templateUrl: './manage-project.component.html',
  styleUrls: ['./manage-project.component.scss'],
})
export class ManageProjectComponent implements OnInit {
  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  public chartOptionsEmp: Partial<ChartOptionsEmp> | any;
  public chartOptionsTask: Partial<ChartOptionsTask> | any;
  public chartOptionsMonthlyTask: Partial<ChartOptionsMonthlyTask> | any;
  public chartOptionsMilestone: Partial<ChartOptionsMileStone> | any;
  public tabval = 'module_list';
  public showChart = false;
  public showEmpChart = false;
  public showTaskChart = false;
  public projectForm!: FormGroup;
  public userForm!: FormGroup;
  public ttlExpected = 0;
  public ttlSpendTime = 0;
  public error = false;
  public healthStatus = '';
  public healthReason: any;
  public statusColor: any = 'primary';
  public finalDate = '';
  isShowBtn = true;
  public taskLimit: number = 5;
  public allTaskLimit: number = 5;
  empList: any;
  public userApiCall = 0;
  public stageApiCall = 0;
  public projectApiCall = 0;
  public estimetedApiCall = 0;
  public userlist = <any>[];
  public userIdlist = <any>[];
  public itemList: any;
  public selectedItems: any = [];
  public estimetedTimeRepost = <any>[];
  public employeeReportData = <any>[];
  public multiSearch : string = '';
  public employeeReportTaskData = <any>[];
  public startDate = '';
  public validateMinStart: any;
  public validateMinEnd: any;
  public endDate = '';
  public selYear = 0;
  public objKey : any;
  public maxObjectStart: any;
  public maxObjectEnd: any;
  public allTasktData = <any>[];
  public empReportMonthlyTaskData = <any>[];
  public milestone = <any>[];
  public projectId: any = '';
  public projectHealth: number = 0;
  public projectRwId: number = 0;
  public projectData = <any>[];
  public group = new FormGroup({});
  public projectStage = <any>[];
  public projectEstLog = <any>[];
  public menuToggle = false;
  public minDate: any;
  public hsl: any;
  public fLetter: any;
  public subtabval = 'project_stage';
  public isShow = true;
  public test: string = '';
  public loggedInUserName: string = '';
  public docStatus: any;
  public selectedUserList: any[] = [];
  public selectedUserObj = <any>[];
  public loadAllComponant = false;
  constructor(
    public rest: RestService,
    private formBuilder: FormBuilder,
    private route: Router,
    private router: ActivatedRoute,
    public ngxService: NgxUiLoaderService,
    public pageService: PagesService,
    private toaster: ToastrService
  ) {
    let d = new Date();
    this.minDate = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.maxObjectStart = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.maxObjectEnd = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.validateMinStart = {
      year: 2022,
      month: 9,
      day: 1,
    };
    this.validateMinEnd = {
      year: 2022,
      month: 9,
      day: 1,
    };
  }

  ngOnInit(): void {
    this.getUserData();
    this.projectId = this.router.snapshot.params['id'].replace('_', '/');
    this.itemList = [];
    // validations
    this.projectForm = this.formBuilder.group({
      pestimetedeffort: [null,[Validators.required, Validators.pattern(/^[0-9]\d*$/)],
      ],
      pestimetedenddate: [null, [Validators.required]],
      pdescription: [null, [Validators.required]],
      pstatus: [null, [Validators.required]],
      pstage: [null, [Validators.required]],
      ptype: [],
      pname: [],
      pdomain: [],
    });
    // call apis
    this.getProject(this.projectId);
  }

  getUserData() {
    this.rest.getData({}, 'getloginuser').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.loggedInUserName = data.data.name;
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning("Authorization error!!");
      },
    });
  }

  changeTab(tab: any) {
    this.tabval = tab;
    if (tab == 'logs') {
      this.changeSubTab(this.subtabval);
    } else if (tab == 'project_report') {
      this.subtabval = 'estimatedTimeReport';
      this.changeSubTab(this.subtabval);
    }
    this.toogleMobile();
  }

  changeSubTab(tab: any) {
    this.subtabval = tab;
    if (tab == 'project_stage') {
      this.getprojectStage(this.projectRwId);
    } else if (tab == 'estimeted_effort') {
      this.getEstimetedEffort(this.projectRwId);
    } else if (tab == 'estimatedTimeReport' || tab == 'reportModule') {
      this.estimatedTimeReport(this.projectRwId);
    } else if (tab == 'timesheetreport') {
      this.employeeReport(this.projectRwId);
    } else if (tab == 'taskreport') {
      this.reportTask(this.projectRwId);
    } else if (tab == 'alltaskreport') {
      this.reportAllTask(this.projectRwId);
    } else if (tab == 'monthlytaskreport') {
      this.empMonthlyTaskReport(this.projectRwId);
    } else if (tab == 'milestone') {
      this.milestoneReport(this.projectRwId);
    }
  }

  /* Api call to get project data for updatation*/
  getProject(id: string) {
    if (id !== '') {
      // this.ngxService.start();
      this.rest.getData({ project_id: id }, 'getproject').subscribe({
        next: (data) => {
          // this.ngxService.stop();
          this.loadAllComponant = true;
          if (data.state == '200') {
            this.projectData = data.data;
            this.projectRwId = this.projectData.id;
            this.checkHealth(this.projectData.id);
            this.empList = this.pageService.loadMore(this.projectData.userList);
            this.itemList = this.projectData.addUserList;
            this.projectHealth = this.projectData.p_health;
            this.setFormValue();
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          // this.ngxService.stop();
          this.loadAllComponant = true;
          this.toaster.error('Something went wrong!!!');
        },
      });
    }
  }

  // This function used to delete project

  deleteProject(id: string) {
    Swal.fire({
      title: 'Deleting this project will result in losing all its data and reports.Do you really want to contine?',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      confirmButtonText: 'Yes',
      width: 400,
    }).then((result) => {
      if (result.isConfirmed) {
        if (id != '') {
          this.ngxService.start();
          this.rest.getData({ p_id: id }, 'deleteproject').subscribe({
            next: (data) => {
              this.ngxService.stop();
              if (data.state == '200') {
                this.toaster.success(data.message);
                setTimeout(() => {
                  this.route.navigate(['project']);
                }, 1500);
              } else {
                this.toaster.warning(data.message);
              }
            },
            error: (e) => {
              this.ngxService.stop();
              this.toaster.error('Something went wrong', 'danger');
            },
          });
        }
      }
    });
  }

  /* Function helps to set db value to form filed */
  setFormValue() {
    let pestimetedenddate = this.projectData.p_estimeted_end_date.split('-');
    const dateProject = new NgbDate(
      parseInt(pestimetedenddate[0]),
      parseInt(pestimetedenddate[1]),
      parseInt(pestimetedenddate[2])
    );
    this.projectForm.patchValue({
      pstage: this.projectData.p_stage,
      pstatus: this.projectData.p_status,
      pestimetedeffort: this.projectData.p_estimeted_effort,
      pestimetedenddate: dateProject,
      pdescription: this.projectData.p_description,
      ptype: this.projectData.p_type,
      pname: this.projectData.p_name,
      pdomain: this.projectData.p_domain,
    });
  }

  /* Function helps to convert ngdate sort date string  */
  dateConvert(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    const convertDate = year + '-' + month + '-' + day;
    return convertDate;
  }
  /* Api call call for updation */
  onSubmit(form: FormGroup) {
    this.error = false;
    if (form.invalid) {
      return;
    }
    this.ngxService.start();
    this.isShow = false;
    let apiPayload = { ...form.value };
    apiPayload.pestimetedenddate = this.dateConvert(
      apiPayload.pestimetedenddate
    );
    apiPayload.p_id = this.projectRwId;
    this.rest.getData(apiPayload, 'updateproject').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.isShow = true;
        this.estimetedApiCall = 0;
        this.stageApiCall = 0;
        if (data.state == '200') {
          this.toaster.success(data.message);
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.isShow = true;
        this.toaster.error('Something went wrong');
      },
    });
  }

  /* Api call call for getting project stage data */
  getprojectStage(id: any) {
    if (id !== '' && this.stageApiCall == 0) {
      this.ngxService.start();
      this.rest
        .getData({project_id:id,type:'stage'},'getprojectstage')
        .subscribe({
          next: (data) => {
            this.ngxService.stop();
            if (data.state == '200') {
              this.projectStage = data.data;
              this.stageApiCall = 1;
            }
          },
          error: (e) => {
            this.ngxService.stop();
            this.error = true;
            this.toaster.error('Something went wrong');
          },
        });
    }
  }

  /* Api call call to add user */
  addUsers() {
    // this.ngxService.start();
    this.error = false;
    let apiPayload = { p_id: 0, users: [] };
    apiPayload.p_id = this.projectRwId;
    this.itemList.forEach((el: any) => {
      if (this.selectedUserList.includes(el.id)) {
        this.selectedUserObj.push({ id: el.id, role: el.roles });
      }
    });
    this.ngxService.start();
    apiPayload.users = this.selectedUserObj;
    this.rest.getData(apiPayload, 'addMember').subscribe({
      next: (data) => {
        this.ngxService.stop();
        if (data.state == '200') {
          this.toaster.success(data.message);
          this.selectedUserObj = [];
          this.selectedUserList = [];
          this.getProject(this.projectId);
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.toaster.error('Something went wrong');
      },
    });
  }

  /* Api call call to delete user */
  deleteUser(id: any,name:any) {
    Swal.fire({
      title: 'Remove '+name+' ?',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      confirmButtonText: 'Yes',
      width: 400,
    }).then((result) => {
      if (result.isConfirmed) {
        if (id !== '' && this.projectRwId > 0) {
          this.ngxService.start();
          this.rest
            .getData({ users: id, p_id: this.projectRwId }, 'removeuser')
            .subscribe({
              next: (data) => {
                this.ngxService.stop();
                if (data.state == '200') {
                  this.toaster.success(data.message);
                  this.getProject(this.projectId);
                } else {
                  this.toaster.error(data.message);
                }
              },
              error: (e) => {
                this.ngxService.stop();
                this.toaster.error('Something went wrong');
              },
            });
        }
      }
    });
  }

  /* Api call call for getting project stage data */
  getEstimetedEffort(id: any) {
    if (id !== '' && this.estimetedApiCall == 0) {
      this.ngxService.start();
      this.rest
        .getData(
          { project_id: id, type: 'estimeted_effort' },
          'getprojectstage'
        )
        .subscribe({
          next: (data) => {
            this.ngxService.stop();
            this.estimetedApiCall = 1;
            if (data.state == '200') {
              this.projectEstLog = data.data;
            }
          },
          error: (e) => {
            this.ngxService.stop();
            this.toaster.error('Something went wrong');
          },
        });
    }
  }
  toogleMobile() {
    this.menuToggle = !this.menuToggle;
  }
  select_user(ev: any,name:any) {
    Swal.fire({
      title: 'Add '+name+' ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.selectedUserList.push(ev);
        if (this.selectedUserList.length > 0) this.addUsers();
      }
    });
  }
  /* Api call for getting time info of all modules
    assign time, spand time and percentage of module */
  estimatedTimeReport(pId: number) {
    if (pId > 0 && this.estimetedTimeRepost.length == 0) {
      this.ngxService.start();
      this.rest
        .getData({ project_id: pId }, 'projectestimatedtimereport')
        .subscribe({
          next: (data) => {
            this.ngxService.stop();
            if (data.state == '200') {
              this.estimetedTimeRepost = data.data;
              this.setChartData(
                this.estimetedTimeRepost.projectEffort,
                this.estimetedTimeRepost.totalEffort
              );
            } else {
              this.toaster.error(data.message);
            }
          },
          error: (e) => {
            this.ngxService.stop();
            this.toaster.error('Something went wrong');
          },
        });
    }
  }
  /* Api call for getting employee report */
  employeeReport(pId: number) {
    if (pId > 0 && this.employeeReportData.length == 0) {
      this.ngxService.start();
      this.rest.getData({ project_id: pId }, 'timesheetreport').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.employeeReportData = data.data;
            this.setChartDataEmp(this.employeeReportData);
          } else {
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }
  /* Api call for getting task report last 3month */
  reportTask(pId: number) {
    this.startDate =
      this.startDate == '' ? '' : this.dateConvert(this.startDate);
    this.endDate = this.endDate == '' ? '' : this.dateConvert(this.endDate);
    if (this.endDate !== '' && this.endDate !== '') {
      this.employeeReportTaskData = [];
    }
    if (pId > 0) {
      this.ngxService.start();
      let payload = {
        project_id: pId,
        type: 'n',
        s_date: this.startDate,
        e_date: this.endDate,
        limit: this.taskLimit,
      };
      this.rest.getData(payload, 'taskreport').subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.startDate = '';
          this.endDate = '';
          if (data.state == '200') {
            this.employeeReportTaskData = data.data;
            this.getTaskRepo('top', this.employeeReportTaskData);
          } else {
            this.employeeReportTaskData = [];
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }
  /* Api call for getting all task report of select project*/
  reportAllTask(pId: number) {
    this.startDate =
      this.startDate == '' ? '' : this.dateConvert(this.startDate);
    this.endDate = this.endDate == '' ? '' : this.dateConvert(this.endDate);
    if (this.endDate !== '' && this.endDate !== '') {
      this.allTasktData = [];
    }
    if (pId > 0) {
      this.ngxService.start();
      let payload = {
        project_id: pId,
        type: 'all',
        s_date: this.startDate,
        e_date: this.endDate,
        limit: this.allTaskLimit,
      };
      this.rest.getData(payload, 'taskreport').subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.startDate = '';
          this.endDate = '';
          if (data.state == '200') {
            this.allTasktData = data.data;
            this.getTaskRepo('top', this.allTasktData);
          } else {
            this.allTasktData = [];
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }
  /* Api call for getting mothly task progress report*/
  empMonthlyTaskReport(pId: number) {
    if (
      pId > 0 &&
      (this.empReportMonthlyTaskData.length == 0 || this.selYear > 0)
    ) {
      this.ngxService.start();
      let payload: any = { project_id: pId };
      if (this.selYear > 0) {
        payload.year = this.selYear;
      }
      this.rest.getData(payload, 'monthlytaskreport').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.empReportMonthlyTaskData = data.data;
            this.setChartDataMonthlyTaskReport(this.empReportMonthlyTaskData);
          } else {
            this.showTaskChart = false;
            this.empReportMonthlyTaskData = [];
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }
  /* Api call for getting milestone task report*/
  milestoneReport(pId: number) {
    if (pId > 0 && this.milestone.length == 0) {
      this.ngxService.start();
      // change api url
      this.rest.getData({ project_id: pId }, 'milestonereport').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.milestone = data.data;
            this.setChartMilestone(this.milestone);
          } else {
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }

  showEmp() {
    this.isShowBtn = false;
    setTimeout(() => {
      this.isShowBtn = true;
      this.empList = this.pageService.loadMore(
        this.projectData.userList,
        this.empList.length
      );
    }, 1000);
  }
  /* This function is used to convert integer time to readable format */
  timeConvert(min: any, type = 'h') {
    return type == 'h'
      ? this.pageService.timeConvert(min)
      : this.pageService.formatSeconds(min);
  }
  openCloseTab(st: string, val: number) {
    return this.pageService.openCloseTab(st, val);
  }

  convertToLetter(letter: any) {
    this.convertColor(letter);
    return this.pageService.getFirstLetters(letter);
  }
  convertColor(string: any) {
    this.hsl = this.pageService.convertNameToColor(string);
  }
  /* Api call for getting project health report*/
  checkHealth(id: number) {
    if (id > 0) {
      // this.ngxService.start();
      this.rest.getData({ project_id: id }, 'checkhealth').subscribe({
        next: (data) => {
          // this.ngxService.stop();
          if (data.state == '200') {
            if (data.data.health >= 80) {
              this.healthStatus = 'low';
            } else if (data.data.health >= 50 && data.data.health < 80) {
              this.healthStatus = 'mod';
            } else if (data.data.health >= 30 && data.data.health < 50) {
              this.healthStatus = 'mod';
            } else if (data.data.health >= 10 && data.data.health < 30) {
              this.healthStatus = 'high';
            } else {
              this.healthStatus = 'high';
            }
            this.healthReason = data.data.reason;
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          // this.ngxService.stop();
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }
  /* Rendering Chart Data */
  setChartData(data: any, ttl: any) {
    let seriesArr = <any>[];
    let labelsArr = <any>[];
    if (data.length > 0 || data !== '') {
      for (let iterator of data) {
        let percentage = Math.round((iterator.spend_pd / ttl) * 100 * 10) / 10;
        labelsArr.push(iterator.module_name + ' (' + percentage + '%)');
        seriesArr.push(iterator.spend_pd);
      }
      this.showChart = true;
      this.chartOptions = this.pageService.showDonutChart({
        seriesArr,
        labelsArr,
      });
    }
  }

  setChartDataEmp(data: any) {
    let seriesArr = <any>[];
    let labelsArr = <any>[];
    if (data.length > 0 || data !== '') {
      for (let iterator of data) {
        labelsArr.push(
          iterator.name + ' (' + this.timeConvert(iterator.total_effort) + ')'
        );
        seriesArr.push(parseInt(iterator.total_effort));
      }
      this.showEmpChart = true;
      this.chartOptionsEmp = this.pageService.showDonutChart({
        seriesArr,
        labelsArr,
      });
    }
  }

  setChartDataTask(data: any) {
    let colorArr = <any>[];
    let spendTimeArr = <any>[];
    let labelsArr = <any>[];
    if (data.length > 0 || data !== '') {
      for (let iterator of data) {
        labelsArr.push(iterator.tsk_name);
        colorArr.push(iterator.color);
        spendTimeArr.push(parseInt(iterator.effort));
      }
      this.showTaskChart = true;
      this.chartOptionsTask = {
        series: [
          {
            data: spendTimeArr,
          },
        ],
        chart: {
          type: 'bar',
          height: '300',
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
        fill: {
          colors: colorArr,
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#000'],
          },
          formatter: function (val: any, opt: any) {
            let rhours = Math.floor(val / 60);
            let rminutes = Math.round((val / 60 - rhours) * 60);
            let timeStr =
              rhours > 0 ? (rhours == 1 ? rhours + ' h ' : rhours + ' h ') : '';
            timeStr += rminutes == 0 ? '' : rminutes + ' m';
            //return timeStr;
            return timeStr;
          },
          offsetX: 0,
          dropShadow: {
            enabled: false,
          },
        },
        stroke: {
          width: 1,
          colors: colorArr,
        },
        xaxis: {
          categories: labelsArr,
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
        title: {
          text: 'Task Progress Status',
          align: 'center',
          floating: true,
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: true,
          },
          y: {
            title: {
              formatter: function () {
                return '';
              },
            },
          },
        },
      };
    }
  }

  setChartDataMonthlyTaskReport(data: any) {
    if (data.length > 0 || data !== '') {
      this.showTaskChart = true;
      this.chartOptionsMonthlyTask = {
        series: data.data,
        chart: {
          type: 'bar',
          height: 500,
          stacked: true,
          stackType: '100%',
        },
        plotOptions: {
          bar: {
            horizontal: true,
          },
        },
        stroke: {
          width: 1,
          colors: ['#fff'],
        },
        title: {
          text: 'Task monthly report',
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#000'],
          },
          /*  formatter: function (val: any, opt: any) {
            let mins = Math.abs(val) % 60;
            let hours = Math.floor(Math.abs(val) / 60);
            let days = Math.floor(hours / 24);
            let hourss = hours % 24;
            let timeStr =
              days > 0 ? (days == 1 ? days + ' d ' : days + ' d ') : '';
            timeStr +=
              hourss > 0 ? (hourss == 1 ? hourss + ' h ' : hourss + ' h ') : '';
            timeStr += mins == 0 ? '' : mins + ' m.';
            return timeStr;
          }, */
          offsetX: 0,
          dropShadow: {
            enabled: false,
          },
        },
        xaxis: {
          categories: data.category,
          labels: {
            /* formatter: function (val: any) {
              let mins = Math.abs(val) % 60;
              let hours = Math.floor(Math.abs(val) / 60);
              let days = Math.floor(hours / 24);
              let hourss = hours % 24;
              let timeStr =
                days > 0 ? (days == 1 ? days + ' d ' : days + ' d ') : '';
              timeStr +=
                hourss > 0 ? (hourss == 1 ? hourss + ' h ' : hourss + ' h ') : '';
              timeStr += mins == 0 ? '' : mins + ' m.';
              return timeStr;
            }, */
          },
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
        tooltip: {
          y: {
            formatter: function (val: any) {
              return val + ' minutes';
            },
          },
        },
        fill: {
          opacity: 1,
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 40,
        },
      };
    }
  }

  setChartMilestone(data: any) {
    let expectedTime = {
      name: '',
      data: <any>[],
    };
    let spendTime = {
      name: '',
      data: <any>[],
    };

    if (data.length > 0 || data !== '') {
      this.milestone = [];
      for (let iterator of data) {
        let diff1 = Math.abs(
          (new Date(iterator.created_at).getTime() -
            new Date(iterator.estimated_end_date).getTime()) /
            1000 /
            3600 /
            24
        );
        let diff2 = Math.abs(
          (new Date(iterator.task_start_date).getTime() -
            new Date(iterator.task_end_date).getTime()) /
            1000 /
            3600 /
            24
        );
        this.ttlExpected += isNaN(diff1) ? 0 : diff1;
        this.ttlSpendTime += isNaN(diff2) ? 0 : diff2;
        expectedTime.data.push({
          x: iterator.name,
          y: [
            new Date(iterator.created_at).getTime(),
            new Date(iterator.m_estimated_end_date).getTime(),
          ],
        });
        spendTime.data.push({
          x: iterator.name,
          y: [
            new Date(iterator.m_start_date).getTime(),
            new Date(iterator.m_end_date).getTime(),
          ],
        });
      }
      expectedTime.name = 'Expected (' + this.ttlExpected + ' day) ';
      spendTime.name = 'Spend (' + this.ttlSpendTime + ' day) ';
      this.milestone.push(expectedTime);
      this.milestone.push(spendTime);
      this.showTaskChart = true;
      this.chartOptionsMilestone = {
        series: this.milestone,
        chart: {
          height: 450,
          type: 'rangeBar',
        },
        plotOptions: {
          bar: {
            horizontal: true,
            barHeight: '80%',
            dataLabels: {
              hideOverflowingLabels: false,
            },
          },
        },
        xaxis: {
          type: 'datetime',
        },
        dataLabels: {
          enabled: true,
          formatter: function (val: any, opts: any) {
            //let label = opts.w.globals.labels[opts.dataPointIndex];
            let diff = Math.abs(
              (parseInt(val[0]) - parseInt(val[1])) / 1000 / 3600 / 24
            );
            return diff + (diff > 1 ? ' days' : ' day');
          },
          style: {
            colors: ['#f3f4f5', '#fff'],
          },
        },
        fill: {
          type: 'gradient',
          gradient: {
            shade: 'light',
            type: 'vertical',
            shadeIntensity: 0.25,
            gradientToColors: undefined,
            inverseColors: true,
            opacityFrom: 1,
            opacityTo: 1,
            stops: [50, 0, 100, 100],
          },
        },
        legend: {
          position: 'top',
          horizontalAlign: 'left',
        },
      };
    }
  }

  onDateSelect(ev: any, type = '') {
    let d = new Date();
    if (type == 'start') {
      this.validateMinEnd = {
        year: ev.year,
        month: ev.month,
        day: ev.day,
      };
    } else {
      this.maxObjectStart = {
        year: ev.year,
        month: ev.month,
        day: ev.day,
      };
    }
  }

  getTaskRepo(type = 'top', data: any) {
    if (data[type].length > 0) this.setChartDataTask(data[type]);
    else this.showTaskChart = false;
  }
  limitData(param: any, type: string) {
    if (type == 'all') {
      this.allTaskLimit = param.target.value;
      this.reportAllTask(this.projectRwId);
    } else {
      this.taskLimit = param.target.value;
      this.reportTask(this.projectRwId);
    }
  }
  statusChange(ev: any) {
    let status = ev.value;
    this.statusColor = this.setColor(status);
      Swal.fire({
        title: 'Changing its status will result in finishing all the modules and tasks that belong to this project. Do you really want to continue?',
        icon: 'warning',
        showCancelButton: true,
        cancelButtonText: 'No',
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#ea556f',
        iconColor:"#ea556f",
        confirmButtonText: 'Yes',
        width: 400,
      }).then((result) => {
        if (result.isConfirmed) {
          //this.statusColor=this.setColor(status);
          this.changeStatus(status);
        }
      });
  }
  /* Change Project Status */
  changeStatus(status: any) {
    if (status !== null && status !== '') {
      this.ngxService.start();
      this.rest.getData({ status: status,project_id:this.projectRwId }, 'changeprojectstatus').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.toaster.success(data.message);
          } else {
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }

  setColor(st: string) {
    let color = 'primary';
    if (st == 'inactive') color = 'secondary';
    else if (st == 'stop') color = 'danger';
    else if (st == 'finished') color = 'success';
    return color;
  }

  searchType(event: any) {
    let searchkey = event.target.value;
    if (searchkey.length > 1) {
      this.objKey = searchkey;
    } else if (searchkey.length == 0) {
      this.objKey = searchkey;
    }
  }
}
