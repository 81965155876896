import { Component, OnInit } from '@angular/core';
import { RestService } from '../../../rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-create-shift',
  templateUrl: './create-shift.component.html',
  styleUrls: ['./create-shift.component.scss']
})
export class CreateShiftComponent implements OnInit {
  shiftForm!: FormGroup;
  isShow=true;
  constructor(
    public rest: RestService,
    private formBuilder: FormBuilder,
    public ngxService: NgxUiLoaderService,
    private route: Router,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
     /* This is object set for adding validation on selected fileds */
    this.shiftForm = this.formBuilder.group({
      shift_name: [null, [Validators.required]],
      shift_country: [null, [Validators.required]],
      start_time: ['', [Validators.required]],
      end_time: ['', [Validators.required]],
    });
  }
  /* This function is used to get deff between two houres */
  getHourDiff(sTime:string,eTime:string){
    let start_time=new Date("01/01/2007 " + sTime).getTime();
    let end_time=new Date("01/01/2007 " + eTime).getTime();
    let diff =(end_time-start_time) / 1000;
    diff /= (60 * 60);
    return Math.abs(Math.round(diff));
  }
  /* This function used to create new shift */
  onSubmit(form: FormGroup) {
    if (form.invalid) {
      return;
    }
    let payload = { ...form.value };
    if(this.getHourDiff(payload.start_time,payload.end_time)<8){
      this.toaster.error('Shift hours should not be less than 8 hours');
      return;
    }
    this.ngxService.start();
    this.isShow=false;
    this.rest.getData(payload, 'addshift').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.isShow=true;
        if(data.state=='200'){
          this.shiftForm.reset({
            shift_name:'',
            shift_country:'',
            start_time:'',
            end_time: ''
          });
          this.toaster.success(data.message);
        }else{
          this.toaster.error(data.message);
        }
      },error: (e) => {
        this.ngxService.stop();
        this.isShow=true;
        this.toaster.error('Something went wrong');
      },
    });
  }
}
