import { Component, OnInit , ViewChild, TemplateRef} from '@angular/core';
import { RestService } from '../../rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { AuthServiceService } from '../../auth-service.service';
import { Router } from '@angular/router';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-create-module',
  templateUrl: './create-module.component.html',
  styleUrls: ['./create-module.component.scss'],
})
export class CreateModuleComponent implements OnInit {

  
  projectList = <any>[];
  ModuleTypeList = <any>[];
  moduleForm!: FormGroup;
  error = false;
  autoTaskId = <any>[];
  finalDate = '';
  public isShow = true;
  public minDate: any;
  public loadAllComponant = false;
  public selectType = '';
  public automatedTask =  <any>[];
  public masterSelected:boolean = false;
  public moduleType = '';
  public showButton = true;
  public checklist:any;
  public checkedList:any;
  public loggedInUserName: string = '';
  public mKeyword: any ='';

  constructor(
    private ngxService: NgxUiLoaderService,
    public rest: RestService,
    private formBuilder: FormBuilder,
    private ls: LocalStorageService,
    private auth: AuthServiceService,
    private route: Router,
    private toaster: ToastrService,
    private modalService: NgbModal
  ) {
    let d = new Date();
    this.minDate = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
  }

  @ViewChild('editModal') public editModal : |TemplateRef<any>|undefined;
  openModal(){
    this.modalService.open(this.editModal);
  }
  
  ngOnInit(): void {
    this.getUserData();
    this.getProject();
    this.getModuleType();
    /* This is object set for adding validation on selected fileds */
    this.moduleForm = this.formBuilder.group({
      m_name: [null, [Validators.required]],
      m_description: [null, [Validators.required]],
      m_status: ['', [Validators.required]],
      m_type: ['', [Validators.required]],
      m_estimated_effort: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)],],
      m_estimated_end_date: [null, [Validators.required]],
      risk: ['', [Validators.required]],
      challenges: null,
      dependency: null,
      project_id: ['', [Validators.required]],
    });
  }

  getUserData() {
    this.rest.getData({}, 'getloginuser').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.loggedInUserName = data.data.name;
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning("Authorization error!!");
      },
    });
  }

  /* This function is used to fetch all project name to select dropdown */
  getProject() {
    // this.ngxService.start();
    this.rest.getData({}, 'getallproject').subscribe({
      next: (data) => {
        //this.ngxService.stop();
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.projectList = data.data;
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        //this.ngxService.stop();
        this.loadAllComponant = true;
        console.log(e);
        this.toaster.error("Something went wrong!");
      },
    });
  }

  getModuleType() {
    this.rest.getData({}, 'getmoduletypes').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.ModuleTypeList = data.data;
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        console.log(e);
        this.toaster.error("Something went wrong!");
      },
    });
  }

  /* This function is used to save new module */
  onSubmit(form: FormGroup) {
    this.error = false;
    if (form.invalid) {
      return;
    }
    this.ngxService.start();
    this.isShow = false;
    let apiPayload = { ...form.value };
    apiPayload.m_estimated_end_date = this.finalDate;
    this.rest.getData(apiPayload, 'addmodule').subscribe({
      next: (data) => {
          this.ngxService.stop();
        if (data.state == '200') {
          this.toaster.success(data.message);
          if(this.checkedList?.length > 0){
            this.setAutoTasks(data.data.id,data.data.m_type);
          }
          setTimeout(() => {
            this.route.navigate(['module']);
          }, 300);
        } else {
          this.toaster.warning(data.message,'',{ timeOut: 8000});
          this.mKeyword = '';
          this.isShow = true;
        }
      },
      error: (e) => {
        this.isShow = true;
        console.log(e);
        this.ngxService.stop();
        this.error = true;
        this.toaster.error('Something went wrong!');
      },
    });
  }
  /* This function is used to convert date object to php date format */
  onDateSelect(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.finalDate = year + '-' + month + '-' + day;
    console.log(this.finalDate);
  }

  addModuleType(type: any = '') {
    let typeName = type.target.value;
    if (typeName == 'Add New') {
      this.moduleForm.reset();
      Swal.fire({
        title: 'Enter new type',
        width: 400,
        input: 'text',
        iconColor:"#ea556f",
        inputAttributes: {
          autocapitalize: 'on'
        },
        showCancelButton: true,
        confirmButtonText: 'Add',
        showLoaderOnConfirm: true,
        preConfirm: (m_payload) => {
          this.rest.getData({type:m_payload}, 'addmoduletype').subscribe({
            next: (data) => {
              if (data.state == '200') {
                this.getModuleType();
                this.toaster.success("Added!");
              } else {
                this.toaster.error(data.message);
              }
            },
            error: (e) => {
              this.isShow = true;
              console.log(e);
              this.error = true;
              this.toaster.error('Something went wrong!');
            },
          });
        },
      })
    }else {
      this.getAutoTasks(typeName);
    }
  }

  getAutoTasks(type:string){
    console.log(type);
    this.moduleType = type;
      this.rest.getData({type:this.moduleType}, 'getautotasks').subscribe({
        next: (data) => {
          if (data.state == '200') {
            let taskObj = [];
            let taskObj1 = {};
            this.automatedTask  = data.data.inactiveTasks;
            this.masterSelected = false;
            for(let tsk of data.data.activeTasks){
              taskObj1 = {
                t_id:tsk.t_id,
                t_name:tsk.t_name,
                isSelected:false
              };
              taskObj.push(taskObj1);
            }
            this.checklist = taskObj;
            this.openModal();
          } else {
            // this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.isShow = true;
          console.log(e);
          this.error = true;
          this.toaster.error('Something went wrong!');
        },
      });
  }

  getTaskIds(ch: any, e: any){
    if (e.target.checked) {
      this.autoTaskId.push(ch);
    } else {
      for (let i = 0; i < this.autoTaskId.length; i++) {
        if (this.autoTaskId[i] === ch) {
          this.autoTaskId.splice(i, 1);
        }
      }
    }
  }

  checkUncheckAll() {
    for (let i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  isAllSelected() {
    console.log("hello");
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }
 
  getCheckedItemList(){
    this.checkedList = [];
    for (let i = 0; i < this.checklist.length; i++) {
      if(this.checklist[i].isSelected)
      this.checkedList.push(this.checklist[i].t_id);
    }
    this.checkedList = this.checkedList;
  }

  setAutoTasks(m_id:any,m_type:any){
    this.showButton = false;
    let taskPayload = {
      m_id:m_id,
      m_type:m_type,
      t_id:this.checkedList
    }
    if (this.checkedList.length > 0) {
      this.rest.getData(taskPayload, 'createtutotask').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.modalService.dismissAll(this.editModal);
            this.toaster.success(data.message);
            this.autoTaskId = [];
            console.log(this.moduleType);
          } else {
            this.toaster.error(data.message);
          }
          this.showButton = true;
        },
        error: (e) => {
          console.log(e);
          this.toaster.error('Something went wrong');
        },
      });
    }
  }

  activeInsctiveAutoTask(t_id:any = 0){
    if(t_id > 0){
      Swal.fire({
        title: 'Active this task?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#ea556f',
        iconColor:"#ea556f",
        confirmButtonText: 'Yes!',
      }).then((result) => {
        if (result.isConfirmed) {
            this.rest.getData({t_id:t_id}, 'activeautomatedtask').subscribe({
              next: (data) => {
                console.log(data);
                if (data.state == '200') {
                  this.modalService.dismissAll(this.editModal);
                  console.log(this.moduleType);
                  this.getAutoTasks(this.moduleType);
                  this.toaster.success("Added!");
                } else {
                  this.toaster.error(data.message);
                }
              },
              error: (e) => {
                this.isShow = true;
                console.log(e);
                this.error = true;
                this.toaster.error('Something went wrong!');
              },
            });
        }
      });
    }
  }

  clearTaskSelection(){
    this.modalService.dismissAll(this.editModal);
    this.checkedList = [];
    console.log(this.checkedList);
  }

  moduleName(){
    let mKeys = this.mKeyword;
    setTimeout(() => {
        if (mKeys == this.mKeyword) {
          if (this.mKeyword) {
            this.rest.getData({ m_keys: mKeys }, 'checkduplicatemodule').subscribe({
              next: (data) => {
                if (data.state == '201') {
                  this.mKeyword = '';
                  this.toaster.warning(data.message,'',{ timeOut: 9000 });
                }
                this.showButton = true;
              },
              error: (e) => {
                console.log(e);
              },
            });
          }
        }
    }, 3000);
  }
  
}
