<div class="main-content">
    <div class="ctm-border-radius  grow card" *ngIf="rosterList != undefined || rosterList != null || rosterList !='' ">
      <div id="basic1" class="card-header d-flex align-center justify-content-between">
        <div class="card_title_left">
          <h4 class="card-title  mb-0 mt-2" *ngIf="rosterList.length > 0;else title"> <b><i
                class="las la-info-circle"></i>{{rosterList.length}} Rosters Available </b></h4>
          <ng-template #title>
            <h4 class="card-title  mb-0 mt-2"> <b><i class="las la-info-circle"></i> No Roster Available Right Now</b>
            </h4>
          </ng-template>
        </div>

        <div class="card_right_ d-flex align-items-center justify-content-end">
          <div class="filter_contetn">
            <div class="filter_drop_down d-flex justify-content-between">
              <div class="form-outline">
                <input type="text" class="form-control" (keyup)="searchType($event)" placeholder="Search"
                  aria-label="Search" [(ngModel)]="multiSearch" id="searchdata">
              </div>
              <button class="custom-btn btn-8 text-center" (click)="filter_status_btn()">
                <span><i class="las la-filter"></i>Filters</span></button>
            </div>

            <div class="drop_down_filter_list d-flex justify-content-end" [ngClass]="filter_status?'on':''">
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="rosterSearch"
                  (change)="deSelect($event,'r_name')" placeholder="Roster Name">
                  <ng-option *ngFor="let r_name of nameFilterList" [value]="r_name">
                    {{r_name}}
                  </ng-option>
                </ng-select>
              </div>

              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="empSearch"
                  (change)="deSelect($event,'employee')" placeholder="Employee">
                  <ng-option *ngFor="let r_emp of employeeFilterList" [value]="r_emp">
                    {{r_emp}}
                  </ng-option>
                </ng-select>

              </div>
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="dateSearch"
                  (change)="deSelect($event,'date_range')" placeholder="Date">
                  <ng-option *ngFor="let date_range of dateFilterList" [value]="date_range">
                    {{date_range}}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
        <a routerLink="/roster-shifts" class="custom-btn btn-8 text-center">
          <span>
            <i class="las la-plus"></i>Add Roster
          </span>
        </a>
      </div>

      <div class="card-body">
        <div class="timetable-container">
          <div class="quicklink-sidebar-menu ctm-border-radius  bg-white  grow">
            <div class="table-back employee-office-table">
              <div class="table-responsive">
                <table class="table custom-table table-hover table-hover" id="empolyeeId">
                  <thead>
                    <tr>
                      <th>SL_No</th>
                      <th>Date Range</th>
                      <th>Employee</th>
                      <th>Shift Name</th>
                      <th class="text-center">Status</th>
                      <th>Created At</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngIf="rosterList?.length < 1">
                      <td colspan="12">No Data Found!</td>
                    </tr>
                    <tr
                      *ngFor="let roster of rosterList | tableFilter:searchKey : selectedfield | tableSearch : objKey; index as i">
                      <td>{{i+1}}</td>
                      <td>{{roster.date_range.replace(',',' To ')}}</td>
                      <td>{{roster.employee}}</td>
                      <td>{{roster.r_name|titlecase}}</td>
                      <td class="text-center">
                        <span class="badge alert-success"
                          *ngIf="roster.status=='Active'">{{roster.status|titlecase}}</span>
                        <span class="badge alert-danger"
                          *ngIf="roster.status=='Inactive'">{{roster.status|titlecase}}</span>
                      </td>
                      <td>{{roster.created_at | date}}</td>
                      <td>
                        <a (click)="viewRoster(roster.rosters,roster.r_name,roster.r_id,roster.employee)">
                          <span class=" edit text-success text-center"><i class="las la-info-circle"></i></span>
                        </a>
                        <a (click)="viewCloneRoster(roster.rosters,roster.r_name,roster.r_id,roster.employee)">
                          <span class=" edit text-success text-center"><i class="las la-copy"></i></span>
                        </a>
                        <a (click)="deleteRoster(roster.r_id)">
                          <span class=" edit text-danger text-center"><i class="las la-trash"></i></span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- Preview  modal-->
    <div class="modal" [ngClass]="{ 'show d-block': showModal }" tabindex="-1" role="dialog"
      aria-labelledby="review-modal-title" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title">Review and Edit {{ shiftName | titlecase }} Timetable Timetable Of
              {{employeeName|titlecase}}</h5>
            <button class="close" aria-label="Close" (click)="closeModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Day</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Week Off</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let entry of timetableData.data; let i = index">
                    <td>{{ entry.date }}</td>
                    <td>{{ entry.day }}</td>
                    <td>
                      <input type="time" [(ngModel)]="entry.startTime" class="form-control" />
                    </td>
                    <td>
                      <input type="time" [(ngModel)]="entry.endTime" class="form-control" />
                    </td>
                    <td>
                      <input type="checkbox" (change)="onWeekOffChange(i, $event)" name="modalWeekOff-{{ i }}"
                        class="form-check-input roster_days_checkbox" [(ngModel)]="entry.weekoff" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" (click)="closeModal()">Close</button> -->
            <button type="button" class="btn btn-primary" (click)="finalSubmit()">Update</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Clone  modal-->
    <div class="modal" [ngClass]="{ 'show d-block': showCloneModal }" tabindex="-1" role="dialog"
      aria-labelledby="review-modal-title" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h3 class="modal-title">Clone {{ shiftName | titlecase }} Timetable Of {{employeeName|titlecase}}</h3>
            <button class="close" aria-label="Close" (click)="closeCloneModal()">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-lg-6 form-group">
                <label for="shift-name">Roster Name</label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="las la-globe"></i></div>
                  <input type="text" id="shift-name" class="form-control" placeholder="Enter Roster Name"
                    [(ngModel)]="shiftName" required />
                </div>
              </div>
              <div class="col-lg-6 form-group">
                <label for="user" (click)="changeCountry()">Employee {{country === 'Bangladesh' ? 'BD' : 'IND'}}</label>
                <div class="input-group mb-3">
                  <select id="user" class="form-control" [(ngModel)]="selectedUser">
                    <option value="">Select Employee</option>
                    <ng-container *ngFor="let emp of employeeList">
                      <option [value]="emp.empid">
                        {{ emp.name }} / {{ getCountryCode(emp.country) }}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
            </div>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Day</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Week Off</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let entry of timetableData.data; let i = index">
                    <td>{{ entry.date }}</td>
                    <td>{{ entry.day }}</td>
                    <td>
                      <input type="time" [(ngModel)]="entry.startTime" class="form-control" />
                    </td>
                    <td>
                      <input type="time" [(ngModel)]="entry.endTime" class="form-control" />
                    </td>
                    <td>
                      <input type="checkbox" (change)="onWeekOffChange(i, $event)" name="modalWeekOff-{{ i }}"
                        class="form-check-input roster_days_checkbox" [(ngModel)]="entry.weekoff" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-secondary" (click)="closeCloneModal()">Close</button> -->
            <button type="button" class="btn btn-primary" (click)="SubmitCloneReq()">Clone</button>
          </div>
        </div>
      </div>
    </div>
</div>




<ngx-ui-loader></ngx-ui-loader>