<div class="main-contant" *ngIf="loadAllComponant">
  <div class="card  grow ctm-border-radius mb-2">
    <div class="card-body align-center d-flex justify-content-between">
      <h4 class="card-title  mb-0 mt-0"> <b><i class="las la-info-circle"></i> {{dataList.username}} Attendance
          Details</b>
      </h4>
      <ul class="nav nav-tabs  border-0 tab-list-emp">
      </ul>
    </div>
  </div>
  <div class="ctm-border-radius  grow card">
    <div class="card-body">
      <div class="filter_contetn">
        <div class="filter_drop_down ">
          <div class="input-group">
            <div class="form-outline">
            </div>
          </div>
        </div>
      </div>
      <div class="table-back employee-office-table">
        <div class="infocolorcheck">
          <div class="colorcontent">
            <div class="colorbx bg-info"></div>
            <label for="">leave </label>
          </div>
          <div class="colorcontent">
            <div class="colorbx bg-danger"></div>
            <label for="">Absent </label>
          </div>
          <div class="colorcontent">
            <div class="colorbx bg-success"></div>
            <label for="">Present </label>
          </div>
          <div class="colorcontent">
            <div class="colorbx bg-warning"></div>
            <label for="">week off </label>
          </div>
          <div class="colorcontent">
            <div class="colorbx bg-danger"></div>
            <label for="">late </label>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table custom-table table-hover table-hover" id="empolyeeId">
            <thead>
              <tr>
                <th>Sl_no</th>
                <th>Date</th>
                <th>Shift In-time</th>
                <th>Shift Out-time</th>
                <th>InImage</th>
                <th>In-time</th>
                <th>OutImage</th>
                <th>Out-time</th>
                <th>Status</th>
                <th>Total Day Work Hour</th>
                <th>Total Work Effort</th>
              </tr>
            </thead>
            <tbody *ngIf="this.dataList.details!==undefined && this.dataList.details!==null">
              <ng-container *ngFor="let list of this.dataList.details;index as i">
                <tr class="highlight-color" [ngClass]="list.isLate=='late'?'bg-danger dtextchnage text-white':''">
                  <td>{{i+1}}</td>
                  <td>{{list.date}}</td>
                  <td>{{list.shiftInTime}}</td>
                  <td>{{list.shiftOutTime}}</td>
                  <td><img
                      src="{{list.inImage==''?'https://eu.ui-avatars.com/api/?name='+dataList.username+'&size=80':list.inImage}}"
                      class="rounded-circle" width="50" height="50" /></td>
                  <td>{{list.inTime}}</td>
                  <th><img
                      src="{{list.outImage==''?'https://eu.ui-avatars.com/api/?name='+dataList.username+'&size=80':list.outImage}}"
                      class="rounded-circle" width="50" height="50" /></th>
                  <td>{{list.outTime}}</td>
                  <th><span
                      [ngClass]="list.status=='P'?'badge bg-success':'' || list.status=='A'?'badge bg-danger':'' || list.status=='W/O'?'badge bg-warning':'' ||  list.status.indexOf('Leave ')!=-1?'badge bg-info':''">{{list.status}}</span>
                  </th>
                  <td>{{list.attendanceHour!=""?list.attendanceHour+' hrs':""}}</td>
                  <td>{{timeConvert(list.totalWorkEff)}}</td>
                </tr>
              </ng-container>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>