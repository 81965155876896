<div class="main-content" *ngIf="loadAllComponant">
  <div class="accordion add-employee" id="accordion-details">
    <div class="card  grow ctm-border-radius">
      <div class="card-header" id="basic1">
        <h4 class="cursor-pointer mb-0">
          <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
            data-target="#basic-one" aria-expanded="true">
            Create New Task
          </a>
        </h4>
      </div>
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
          <form [formGroup]="taskForm" (ngSubmit)="onSubmit(taskForm)" #fd="ngForm" [ngxFvControl]="fd" >
            <div class="row">
              <div class="col-lg-6 form-group">
                <label>
                  Project Name
                </label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="las la-user-secret"></i>
                  </div>
                  <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                    (change)="loadDataModule($event)">
                    <option value="">Project Name </option>
                    <ng-container *ngFor="let proj of projectList">
                      <option value="{{proj.id}}" [selected]="proj.id == cloneProjectId">{{proj.p_name}} /{{proj.p_id}}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>
              <div class="col-lg-6 form-group">
                <!-- <ngx-fv-validate [control]="taskForm.controls['module_id']"> -->
                  <label #ngxFvName>
                    Module Name
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-user-secret"></i>
                    </div>
                    <select #ngxFvFormControl formControlName="module_id"
                      class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      (change)="loadDataInfomation($event)">
                      <option value="">Module Name </option>
                      <ng-container *ngFor="let mod of items">
                        <option value="{{mod.id}}">{{mod.m_name}} /{{mod.m_id}}</option>
                      </ng-container>
                    </select>
                  </div>
                                
                   <!-- <div class="input-group mb-3">
                    <div class="input-group-text">
                      <i class="las la-user-secret"></i>
                    </div>
                    <ng-select class="w-100" (change)="loadDataInfomation($event)" #ngxFvFormControl formControlName="module_id">
                     <ng-option disabled>select </ng-option>
                      <ng-option value="{{item.id}}" *ngFor="let item of items">
                        {{item.m_name}} / {{item.m_id}}
                      </ng-option>
                    </ng-select>
                    <span class="text-muted" *ngIf="!taskForm.controls['module_id'].valid && 
                    taskForm.controls['module_id']?.touched"> 'Module' field is required</span>
                   </div> -->
                  <!-- <ngx-fv-error></ngx-fv-error> -->
                <!-- </ngx-fv-validate> -->
              </div>
              <div class="task_extra_information ">
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['task_name']">
                      <label #ngxFvName>
                        Task Name
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="lab la-git-alt"></i>
                        </div>
                        <input type="text" class="form-control" id="task_name" placeholder="Task Name" #ngxFvFormControl formControlName="task_name">
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">  
                    <ngx-fv-validate [control]="taskForm.controls['task_type']">
                      <label #ngxFvName>
                        Task Type
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-user-secret"></i>
                        </div>
                        <!-- <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                          #ngxFvFormControl formControlName="task_type">
                          <option value="">Task Type </option>
                          <option value="Business Menu Work">Business Menu Work</option>
                          <option value="designing"> Designing</option>
                          <option value="development">Development</option>
                          <option value="Email Marketing">Email Marketing</option>
                          <option value="Facebook Content Writing">Facebook Content Writing</option>
                          <option value="Facebook Creative Design ">Facebook Creative Design </option>
                          <option value="Website Creative Design ">Website Creative Design </option>
                          <option value="Website Content Writing">Website Content Writing</option>
                        </select> -->
                        <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"  ngxFvFormControl formControlName="task_type" (change)="addTaskType($event)">
                          <option value="">Select Type</option>
                          <ng-container *ngFor="let task of taskTypeList">
                            <option value="{{task.type_name}}">{{task.type_name}}</option>
                          </ng-container>
                          <option><i class="las la-plus-circle"></i> Add New</option>
                        </select>
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['task_estimated_pd']">
                      <label #ngxFvName>
                        Estimated Hour
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-business-time"></i>
                        </div>
                        <input type="number" min="1" max="20" class="form-control" id="task_estimated_pd" placeholder="Task Estimated Hours" #ngxFvFormControl formControlName="task_estimated_pd">  
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['task_status']">
                      <label #ngxFvName>
                        Task Status
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-user-secret"></i>
                        </div>
                        <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true" #ngxFvFormControl formControlName="task_status" (change)="removeUnassign()">
                          <option value="">Task Status </option>
                          <option value="active"> Active</option>
                          <option value="inactive">Inactive</option>
                          <option value="stop"> stop</option>
                          <option value="finish">Finish</option>
                          <option value="unassigned">Unassigned</option>
                        </select>
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-12 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['task_description']">
                      <label #ngxFvName>
                        Task Description
                      </label>
                      <div class="input-group mb-3">
                        <textarea class="form-control" minlength="25" maxlength="500" placeholder="task description " #ngxFvFormControl
                          formControlName="task_description"> </textarea>
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['users']">
                      <label #ngxFvName>
                        Task Assign
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-user-secret"></i>
                        </div>
                        <ng-select formControlName="users" [multiple]="true" (click)="identifyUser()">
                          <ng-option *ngFor="let user of userList" [value]="user.id">{{user.name}}</ng-option>
                        </ng-select>
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">
                    Recurring task
                    <div class="input-group mb-3">
                      <div class="form-check mp_top">
                        <input class="form-check-input" type="radio" name="task_repeat" id="flexRadioDefault1" value="1"
                          (change)="isRecurring($event)" formControlName="task_repeat">
                        <label class="form-check-label" for="flexRadioDefault1">
                          Yes
                        </label>
                      </div>
                      <div class="form-check mp_top">
                        <input class="form-check-input" type="radio" name="task_repeat" id="flexRadioDefault2" value="0"
                          (change)="isRecurring($event)" formControlName="task_repeat">
                        <label class="form-check-label" for="flexRadioDefault2">
                          No
                        </label>
                      </div>
                    </div>
                    <div class="Recurring_task" *ngIf="isShown">
                      <div class="input-group mb-3">
                        <div class="form-check ">
                          <input class="form-check-input" type="checkbox" name="Sun" id="Sun" value="7"
                            (change)="addDay(7,$event)">
                          <label class="form-check-label" for="Sun">
                            Sun
                          </label>
                        </div>
                        <div class="form-check ">
                          <input class="form-check-input" type="checkbox" name="Mon" id="Mon" value="1"
                            (change)="addDay(1,$event)">
                          <label class="form-check-label" for="Mon">
                            Mon
                          </label>
                        </div>
                        <div class="form-check ">
                          <input class="form-check-input" type="checkbox" name="tue" id="tue" value="2"
                            (change)="addDay(2,$event)">
                          <label class="form-check-label" for="tue">
                            Tue
                          </label>
                        </div>
                        <div class="form-check ">
                          <input class="form-check-input" type="checkbox" name="wed" id="wed" value="3"
                            (change)="addDay(3,$event)">
                          <label class="form-check-label" for="wed">
                            Wed
                          </label>
                        </div>
                        <div class="form-check ">
                          <input class="form-check-input" type="checkbox" name="thurs" id="thurs" value="4"
                            (change)="addDay(4,$event)">
                          <label class="form-check-label" for="thurs">
                            Thurs
                          </label>
                        </div>
                        <div class="form-check ">
                          <input class="form-check-input" type="checkbox" name="fri" id="fri" value="5"
                            (change)="addDay(5,$event)">
                          <label class="form-check-label" for="fri">
                            Fri
                          </label>
                        </div>
                        <div class="form-check ">
                          <input class="form-check-input" type="checkbox" name="sat" id="sat" value="6"
                            (change)="addDay(6,$event)">
                          <label class="form-check-label" for="sat">
                            Sat
                          </label>
                        </div>
  
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6 form-group">
                    <!-- <ngx-fv-validate [control]="taskForm.controls['file']"> -->
                      <label #ngxFvName>
                        Attechment File <strong>(MAX 4MB)</strong> 
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-user-secret"></i>
                        </div>
                        <input multiple id="file" type="file"  class="form-control" (change)="onFileChange($event)">
                      </div>
                      <!-- <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate> -->
                  </div>
                  <div class="col-lg-6 form-group">
                      <label>
                        Assign Tags
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-user-secret"></i>
                        </div>
                        <ng-select formControlName="tags"  
                        [multiple]="true" [addTag]="addNewTag"> 
                          <ng-option *ngFor="let tag of tagList" [value]="tag.name">{{tag.name}}</ng-option>
                        </ng-select>
                      </div>
                  </div>
                </div>
                <div class="col-lg-12 form-group">
                  <div class="input-group mb-3" *ngIf="isShow">
                    <button class="btn custom-btn btn-8"><span>Submit Now</span></button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-ui-loader></ngx-ui-loader>