import { Component, OnInit, HostListener } from '@angular/core';
import { RestService } from '../../rest.service';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-project',
  templateUrl: './project.component.html',
  styleUrls: ['./project.component.scss'],
})
export class ProjectComponent implements OnInit {
  public datePickerStart = '';
  public create_project = true;
  public add_module = false;
  public add_task = false;
  public task_assign = false;
  public cStart = '';
  public projectList: any = [];
  public projectListObj: any = [];
  public error = false;
  public showloader: boolean = false;
  public filter_status = false;
  public projectSearch: string = '';
  public filterData: any;
  public searchKey = '';
  public nameSearch: any;
  public typeSearch: any;
  public stageSearch: any;
  public statusSearch: any;
  public domainSearch: any;
  public selectedfield = '';
  public nameFilterList = <any>[];
  public typeFilterList = <any>[];
  public stageFilterList = <any>[];
  public statusFilterList = <any>[];
  public domainFilterList = <any>[];
  public objKey: any;
  public multiSearch: string = '';
  public loadAllComponant = false;
  public scrolling: any;
  public paginateData: any;
  public showEmp = true;
  public lastPage = true;
  public offset = <any>0;
  public limit = <any>10;
  public addDataToArray: any;
  public isTrue = true;
  public scrollList = true;
  public currentPosition = window.pageYOffset;
  public className:any = 'high on';

  constructor(
    public rest: RestService,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService
  ) { }
  ngOnInit(): void {
    this.getProject();
  }
  filter_status_btn() {
    this.filter_status = !this.filter_status;
  }
  pagelink(val: any) {
    if (val == 'create_project') {
      this.create_project = true;
      this.add_module = false;
      this.add_task = false;
      this.task_assign = false;
    } else if (val == 'add_module') {
      this.add_module = true;
      this.create_project = false;
      this.add_task = false;
      this.task_assign = false;
    } else if (val == 'add_task') {
      this.add_task = true;
      this.add_module = false;
      this.create_project = false;
      this.task_assign = false;
    } else if (val == 'task_assign') {
      this.task_assign = true;
      this.add_task = false;
      this.add_module = false;
      this.create_project = false;
    }
  }
  /* This function used to fetch project list */
  getProject(key ='') {
    let key1 = '';
    if(key !== '' || key !== undefined){
      key1 = key;
    }
    this.rest.getData({offset: this.offset, limit: this.limit,keywords:key1}, 'getprojects').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          // data.data.forEach((k:any,v:any)=>{
          //   let healthStatus="";
          //   if (k.p_health >= 80) {
          //     healthStatus = 'low';
          //   } else if (k.p_health >= 50 && k.p_health < 80) {
          //     healthStatus = 'mod';
          //   } else if (k.p_health >= 30 && k.p_health <50) {
          //     healthStatus = 'mod';
          //   } else if (k.p_health >= 10 && k.p_health < 30) {
          //     healthStatus = 'high';
          //   } else {
          //     healthStatus = 'high';
          //   }
          //   k.health=healthStatus;
          //   this.projectList.push(k);
          // });
          this.projectList = [...this.projectList,...data.data];
          this.createFilterData();
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.loadAllComponant = true;
        this.toaster.error('Something wend wrong!');
      },
    });
  }

  onScroll(){
    if(this.scrollList == true){
      this.limit = 15;
      this.offset += 15;
      console.log("==",this.offset);
      this.getProject();
    }
  }

  deSelect(event: any, filedType: string) {
    //console.log(filedType);
    this.selectedfield = filedType;
    if (filedType === 'p_name') {
      this.typeSearch = undefined;
      this.stageSearch = undefined;
      this.statusSearch = undefined;
      this.domainSearch = undefined;
      this.searchKey = this.nameSearch;
    } else if (filedType === 'p_type') {
      this.nameSearch = undefined;
      this.stageSearch = undefined;
      this.statusSearch = undefined;
      this.domainSearch = undefined;
      this.searchKey = this.typeSearch;
    } else if (filedType === 'p_stage') {
      this.nameSearch = undefined;
      this.typeSearch = undefined;
      this.statusSearch = undefined;
      this.domainSearch = undefined;
      this.searchKey = this.stageSearch;
    } else if (filedType === 'p_status') {
      this.nameSearch = undefined;
      this.typeSearch = undefined;
      this.stageSearch = undefined;
      this.domainSearch = undefined;
      this.searchKey = this.statusSearch;
    } else if (filedType === 'p_domain') {
      this.nameSearch = undefined;
      this.typeSearch = undefined;
      this.stageSearch = undefined;
      this.searchKey = this.domainSearch;
    }
  }

  createFilterData() {
    let pnameFilterList = [];
    let ptypeFilterList = [];
    let pstageFilterList = [];
    let pstatusFilterList = [];
    let pdomainFilterList = [];

    for (let project of this.projectList) {
      pnameFilterList.push(project.p_name);
      ptypeFilterList.push(project.p_type);
      pstageFilterList.push(project.p_stage);
      pstatusFilterList.push(project.p_status);
      pdomainFilterList.push(project.p_domain);
    }

    // Making all array unique
    this.nameFilterList = [...new Set(pnameFilterList)];
    this.typeFilterList = [...new Set(ptypeFilterList)];
    this.statusFilterList = [...new Set(pstatusFilterList)];
    this.stageFilterList = [...new Set(pstageFilterList)];
    this.domainFilterList = [...new Set(pdomainFilterList)];
  }

  searchType(event: any) {
    let searchkey = event.target.value;
    if (searchkey.length > 2) {
      this.scrollList = false;
      this.searchProject(searchkey);
    } else if (searchkey.length == 0) {
      this.scrollList = true;
      this.projectList = [];
      this.getProject();
    }
  }

  searchProject(key = '') {
    let key1 = '';
    if(key !== '' || key !== undefined){
      key1 = key;
    }
    this.rest.getData({keywords:key1}, 'getprojects').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.projectList = [];
          console.log(this.projectList);
          this.projectList = data.data;
          this.createFilterData();
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        //this.toaster.error('Something went wrong');
      },
    });
  }

  encodeURIParam(param: any) {
    return param.replace('/', '_');
  }

  getCustomCss(param: any) {
    console.log(param);
    if(param != undefined || param != null ||param != ""){
      if (param >= 80) {
        this.className = 'low on'
      }else if (param >= 50 && param < 80) {
        this.className = 'mod on'
      }else if (param >= 10 && param < 30) {
        this.className = 'high on'
      }else{
        this.className;
      }
      return this.className;
    }
  }
}
