import { Component, ChangeDetectorRef,ViewChild,AfterViewInit, TemplateRef} from '@angular/core';
import {ActivatedRoute,Router, Event, NavigationStart,NavigationEnd, NavigationError} from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { TitleCasePipe, formatDate } from '@angular/common';
import { AlertService } from './alert.service';
import { AuthServiceService } from './auth-service.service';
import { LoaderService } from './loader.service';
import { PagesService } from './pages/pages.service';
import Swal from 'sweetalert2';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from './rest.service';
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Title } from '@angular/platform-browser';
import { filter ,map} from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'app';
  ModuleTypeList = <any>[];
  public currentRoute: string;
  public sidebar_on = false;
  public notifyStatus = false;
  public currentUrl = '';
  public selectedTaskId = '';
  public selectedTagList:any;
  public switchToggle = false;
  public isShow = true;
  public uname = '';
  public today: any = '';
  public menuBoxStatus = false;
  public alerttext = '';
  public alerttype = '';
  public profile_setting = false;
  public showLoader = false;
  public leaveDropDown = false;
  public taskDropDown = false;
  public attendanceDropdown = false;
  public dropDownMenuStatus = '';
  public payload: any;
  public singleTaskData: any;
  public text = '';
  public navDrawer: any;
  public userRoles: any = [];
  public existTaskList = <any>[];
  public fLetter: any;
  public hsl: any;
  public loadAllComponant = false;
  public fabOpenerTab = false;
  public dashboardLink = '/dashboard';
  public addTask    = false;
  public addProject = false;
  public addModule  = false;
  public myClipBoard  = false;
  public sidePanel  = false;
  public empId = '0';
  public cloneKey: any;
  public quickPanelTitle = '';
  public taskTypeList = <any> [];
  public empDesignation:any = '';
  public masterSelected:boolean = false;
  public checklist:any;
  public checkedList:any;
  public automatedTask =  <any>[];
  public showButton = true;
  public moduleType = '';
  autoTaskId = <any>[];
  public objKey : any;
  public multiSearch     : string = '';
  public minTaskTime:any = 1;
  public maxTaskTime:any = 20;
  //---------add task---------//

  public formButton = 'Create';
  public loadDataInfomationStatus = false;
  public items = <any>[];
  public projectList = <any>[];
  public moduleList = <any>[];
  public userList = <any>[];
  public repeat_day = <any>[];
  public taskForm!: FormGroup;
  public taskData = <any>[];
  public error = false;
  public cloneData: any;
  public cloneProjectId = '';
  public cloneModuleId = '';
  public isShown: boolean = false;
  public isDisable: boolean = true;
  public tagList: any;
  public formData = new FormData();
  public minDate:any;
  public moduleForm!: FormGroup;
  public selected = '';
  public finalDate = '';
  public disableTaskButton:boolean= false;
  public t_id = '';
  public oldTaskTime:any=0;
  // ----project-----
  projectForm!: FormGroup;
  //error = false;
  //isShow = true;
  finalEstDate = '';
  finalStartDate = '';
  finalEndDate = '';
  userlist = <any>[];
  itemList: any;
  dropdownSettings = {};
  public selectedItems: any = [];
  public textSanitize = '';
  public uCase=new TitleCasePipe();
  public readOnlyTaskForm: boolean = true;

  public fileType = [
    'image/jpeg', //image
    'image/png', //image
    'image/gif', //image
    'text/csv', //CSV
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //XLSX
    'application/vnd.ms-excel', //XLS
    'application/x-zip-compressed', //zip
    'application/pdf', //PDF
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //DOCX
  ]; 
  constructor(
    public  rest: RestService,
    private route: ActivatedRoute,
    private router: Router,
    private localSt: LocalStorageService,
    private alt: AlertService,
    private auth: AuthServiceService,
    private loader: LoaderService,
    private cdr: ChangeDetectorRef,
    private ngxService: NgxUiLoaderService,
    private toaster: ToastrService,
    private pageService: PagesService,
    private formBuilder: FormBuilder,
    private activatedroute: ActivatedRoute,
    private PagesService: PagesService,
    private modalService: NgbModal,
    private titleService: Title
  ) {
    this.today = new Date().toDateString();
    this.alt.alertWatcher().subscribe((alertData) => {
      this.alerttext = alertData.alertdata;
      this.alerttype = alertData.alerttype;
    });
    this.currentRoute = '';
    this.router.events.subscribe((event: Event) => {
      if (event instanceof NavigationEnd) {
        this.currentRoute = event.url;
        this.notifyStatus = false;
        this.profile_setting = false;
        this.switchToggle = false;
        this.menuBoxStatus = false;
      }
    });
  }
  
  @ViewChild('editModal') public editModal : |TemplateRef<any>|undefined;
  openModal(){
    this.modalService.open(this.editModal);
  }
  
  ngOnInit() {
    this.userRoles = [];
    this.localSt.observe('user').subscribe((value) => {
      let udata = this.localSt.retrieve('user');
      this.updateMenu(udata);
    });
    let udata = this.localSt.retrieve('user');
    this.updateMenu(udata);
    this.router.events.subscribe((res) => {
      if (this.router.url == '/login') {
        this.currentUrl = 'login';
      } else {
        this.currentUrl = this.router.url.replace('/', '');
      }
    });

    this.loader.getLoader().subscribe((value) => {
      this.showLoader = value.action;
    });
    this.sidePanel    = false;

    // titles
    this.router.events.pipe(
      filter((event:any) => event instanceof NavigationEnd),
      map(() => {
        const child: ActivatedRoute | null = this.route.firstChild;
        let title = child && child.snapshot.data['title'];
        if (title) {
          return title;
        }
      })
    ).subscribe((title) => {
      if (title) {
        const transformedTitle = this.uCase.transform(this.transformTitle(title));
        this.titleService.setTitle(`${transformedTitle} | ERP`);
      }
    });
  }

  updateMenu(udata:any){
    if (udata != null) {
      this.uname     = udata.data.name;
      this.fLetter   = this.PagesService.getFirstLetters(this.uname);
      this.hsl       = this.PagesService.convertNameToColor(this.fLetter);
      this.userRoles = udata.data.roles;
      if (this.userRoles.includes('Team Member')) {
        this.dashboardLink = 'my-dashbroad';
      }
      this.cdr.detectChanges();
    }
  }

  profileToggle() {
    this.profile_setting = !this.profile_setting;
    this.notifyStatus = false;
    this.menuBoxStatus = false;
  }
  sidemenu() {
    this.sidebar_on = !this.sidebar_on;
    this.menuBoxStatus = false;
  }
  userlogout() {
    this.auth.logout();
    this.profileToggle();
  }
  ngAfterViewChecked() {
    this.cdr.detectChanges();
  }
  navToggle() {
    this.switchToggle = !this.switchToggle;
    this.notifyStatus = false;
    this.profile_setting = false;
  }
  dropDownMenu(data: any) {
    if (this.dropDownMenuStatus == data) {
      this.dropDownMenuStatus = '';
    } else {
      this.dropDownMenuStatus = data;
    }
  }
  menuBox() {
    this.menuBoxStatus = !this.menuBoxStatus;
    this.notifyStatus = false;
    this.profile_setting = false;
    this.switchToggle = false;
  }

  // notification() {
  //   this.notifyStatus = !this.notifyStatus;
  // }
  
  // ----------------------------------------------------Quick tasks-----------------------------------------

  fabOpener(){
    this.fabOpenerTab = !this.fabOpenerTab;
  }

  dismissPanel(){
    this.sidePanel = false;
    this.t_id = '';
    this.formButton = 'Create';
    this.resetTaskForm();
  }

  quickAdd(event:any){
    this.sidePanel      = !this.sidePanel;
    let t               = event;
    if(t == 'task'){
      this.quickPanelTitle = "Create Task";
      this.addTaskEssentials();
      this.sidePanel    = true;
      this.fabOpenerTab = false;
      this.addTask      = true;
      this.addModule    = false;
      this.myClipBoard  = false;
      this.addProject   = false;
      this.loginUser();
    }else if(t == 'module'){
      this.quickPanelTitle = "Create Module";
      this.addModuleEssentials();
      this.sidePanel    = true;
      this.fabOpenerTab = false;
      this.addModule    = true;
      this.addProject   = false;
      this.addTask      = false;
      this.myClipBoard  = false;
    }else if(t == 'project'){
      this.quickPanelTitle = "Create Project";
      this.addProjectEssentials();
      this.sidePanel    = true;
      this.fabOpenerTab = false;
      this.addProject   = true;
      this.addTask      = false;
      this.addModule    = false;
      this.myClipBoard  = false;
    }else if(t == 'clipboard'){
      this.quickPanelTitle = "Sticky Notes";
      //this.addProjectEssentials();
      this.sidePanel    = true;
      this.fabOpenerTab = false;
      this.addProject   = false;
      this.addTask      = false;
      this.addModule    = false;
      this.myClipBoard  = true;
    }else{
      this.sidePanel = !this.sidePanel;
      this.addTask      = false;
      this.addProject   = false;
      this.addModule    = false;
      this.myClipBoard  = false;
    }
  }

  loginUser(){
    this.rest.getData({}, 'loginuser').subscribe({
      next:(data)=>{
        if(data.state == '200'){
          this.empId = data.data.id;
          this.empDesignation = data.data.designation;
        }else{
          this.toaster.warning(data.message);
        }
      },
      error:(e)=>{
        this.toaster.warning('Authorization error!!');
      },
    });
  }
  
  /* This function is used to fetch all project */
  getProject() {
    this.rest.getData({}, 'getallproject').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.projectList = data.data;
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning('Something went wrong!!');
      },
    });
  }

  //-----------------------------------------create task------------------------------------------------


  addTaskEssentials(){
    this.getProject();
    this.getTaskType();
    this.taskForm = this.formBuilder.group({
      task_name         : [null, [Validators.required]],
      task_estimated_pd : [null, [Validators.required]],
      task_status       : [null,],
      task_type         : [null, [Validators.required]],
      task_description  : [null, [Validators.required]],
      tasks             : [null],
      users             : [null],
      module_id         : [null, [Validators.required]],
      task_repeat       : ['0'],
      tags              : [],
    });
  }

  // fetch selected task data
  pickTask() {
    let id = this.selectedTaskId;
    if (id !== '' && id !== 'new_task') {
      this.readOnlyTaskForm = true;
      this.formButton = 'Re-open';
      this.taskForm.patchValue({task_status:'active'})
      this.payload = {
        taskID: id,
      };
      this.rest.getData(this.payload, 'getsingletask').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.singleTaskData = data.data;
            this.t_id = data.data.task_id;
            this.minTaskTime = this.singleTaskData.estimated_hours;
            this.setFormValue();
            console.log(" tid--",this.t_id);
          } else {
            this.t_id = '';
            this.formButton = 'Create';
            setTimeout(() => {
              this.toaster.warning(data.message);
            }, 800);
          }
        },
        error: (e) => {
          this.t_id = '';
          this.toaster.warning('Something went wrong');
        },
      });
    }else{
      this.minTaskTime = 1;
      this.readOnlyTaskForm = false;
      this.t_id = '';
      this.resetTaskForm();
    }
    this.isDisable = !this.isDisable;
  }

  addUpdateTask(data: FormGroup) {
    if (data.invalid) {
      this.readOnlyTaskForm = false;
      return;
    }
    if(this.t_id == '' || this.t_id == undefined){
      this.onSubmit(data);
      console.log("new");
    }else{
      this.onSubmitUpdate(data);
      console.log("old");
    }
  }

  // create task
  onSubmit(form:any) {
    if(form.value.task_estimated_pd > 20){
      this.toaster.warning('Maximum estimated time is 20 hours!');
      return;
    }
    if(form.value.task_description.replace(/ /g,'').length < 20){
      this.toaster.warning('Minimum characters length should be 20');
      return;
    }
   this.ngxService.start();
   this.isShow = false;
    Object.entries(this.taskForm.value).forEach(([key, value]: any[]) => {
      if (key == 'users') {
        value.forEach((v: number) => {
          if(v.toString() == 'Unassigned'){
            v = 0
          }
          this.formData.append('users[]', v.toString());
        });
      } else if (key == 'tags') {
        if(value!==null && value!==undefined){
          value.forEach((v: number) => {
            this.formData.append('tags[]', v.toString());
          });
        }
      } else {
        this.formData.append(key, value);
      }
    });

    this.repeat_day.forEach((v: number) => {
      this.formData.append('repeat_day[]', v.toString());
    });
    if(this.taskForm.value.users < 1){
      this.formData.append('users[]', this.empId);
    };
    this.rest.getData(this.formData, 'addtask', true).subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.isShow = true;
        if (data.state == '200') {
          this.t_id = ''
          setTimeout(() => {
            this.toaster.success(data.message);
            this.sidePanel = false;
            location.reload();
          }, 1000);
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.isShow = true;
        this.toaster.warning('Something went wrong');
      },
    });
  }

  // update task

  onSubmitUpdate(form: any) {
    if (form.invalid) {
      return;
    }
    let payload = { ...form.value };
    payload.t_id = this.t_id;
    this.rest.getData(payload, 'updatetask').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.t_id = ''
          this.toaster.success(data.message);
          location.reload();
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning('Something went wrong');
      },
    });
  }

     get module_id() { return this.taskForm.get('module_id'); }

    setFormValue() {
      if (this.singleTaskData !== undefined) {
        let assignToIds = <any>[];
        this.singleTaskData.asigned_data.forEach((element: any) => {
          assignToIds.push(element.id);
        });
        this.taskForm.patchValue({
          task_name         : this.singleTaskData.task_name,
          task_estimated_pd : this.singleTaskData.estimated_hours,
          task_status       : this.singleTaskData.task_status,
          task_type         : this.singleTaskData.task_type,
          users             : assignToIds,
          task_description  : this.singleTaskData.task_description
        });
      }
    }
  
    loadDataInfomation(e: any) {
      this.readOnlyTaskForm = false;
      this.isDisable = true;
      this.loadDataInfomationStatus = !this.loadDataInfomationStatus;
      this.getModuleTasks(e);
      this.minTaskTime = 1;
      this.resetTaskForm();
      console.log("md tid--",this.t_id);
    }
  
    loadDataModule(e: any) {
      console.log(e.target.value);
      this.items = [];
      this.t_id = '';
      this.resetTaskForm();
      this.taskForm.patchValue({module_id:'Select Module'})
      if(e.target.value !== "" && e.target.value > 0){
        this.projectModules(e.target.value);
      }else{
        this.items = [];
      }
    }
  
    projectModules(pid: any) {
      this.projectMembers(pid);
      this.rest.getData({ project_id: pid }, 'getmodule').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.moduleList = data.data;
            for(let module of this.moduleList){
              this.items.push({m_id:module.m_id,m_name:module.m_name,id:module.id})
            }
            if (this.cloneKey == 'true') {
              this.taskForm.patchValue({
                module_id: this.cloneModuleId,
              });
            }
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.toaster.warning('Something went wrong!!');
        },
      });
    }
  
    projectMembers(pid: any) {
      this.rest.getData({ project_id: pid }, 'getprojectuserlist').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.userList = data.data[0].users;
          }
        },
        error: (e) => {
          this.toaster.warning('Something went wrong!!');
        },
      });
    }

    resetTaskForm(){
      this.formButton = "Create";
      this.taskForm.controls['tasks'].reset();
      this.taskForm.controls['task_name'].reset();
      // this.taskForm.patchValue({task_name:''})
      this.taskForm.controls['task_estimated_pd'].reset();
      this.taskForm.controls['task_type'].reset();
      this.taskForm.controls['task_description'].reset();
      this.taskForm.controls['task_status'].reset();
      this.taskForm.controls['users'].reset();
      this.t_id = '';
    }


  /* This function is used to push files to formdata */
  onFileChange(event: any) {
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        let size = (event.target.files[i].size / 1024 / 1024) | 0;
        if (this.fileType.includes(event.target.files[i].type) && size <= 4) {
          this.formData.append('files[]', event.target.files[i]);
        }
      }
    }
  }

  identifyUser(){
    if(this.taskForm.controls['users'].value == 'unassigned'){
    this.taskForm.patchValue({users:[]})
    }
  }

  removeUnassign(){
    if(this.taskForm.controls['task_status'].value == 'unassigned'){
      this.taskForm.patchValue({users:['Unassigned']})
      this.userList = [];
    }else{
      if(this.t_id == "")
      {
        this.taskForm.patchValue({users:[]})
      }
    }
  }

  getModuleTasks(id: number) {
    if (id > 0) {
      this.rest.getData({ m_id:id}, 'getmoduletasks').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.existTaskList = data.data;
          }else{
            this.existTaskList = [];
          }
        },
        error: (e) => {
          console.log(e);
        },
      });
    }
  }

  // --------------------------------------------end of add task----------------------------------------
  //------------------------------------------------add module------------------------------------------
  addModuleEssentials(){
    this.cloneKey = this.activatedroute.snapshot.queryParamMap.get('clone');
    this.getProject();
    this.getModuleType();
     /* This is object set for adding validation on selected fileds */
     this.moduleForm = this.formBuilder.group({
      m_name: [null, [Validators.required]],
      m_description: [null, [Validators.required]],
      m_status: ['', [Validators.required]],
      m_type: ['', [Validators.required]],
      m_estimated_effort: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)],],
      m_estimated_end_date: [null, [Validators.required]],
      risk: ['', [Validators.required]],
      challenges: null,
      dependency: null,
      project_id: ['', [Validators.required]],
    });
    if (this.cloneKey == 'true') {
      this.setFormValue();
    }
  }
  onModuleSubmit(form: FormGroup) {
    this.error = false;
    if (form.invalid) {
      return;
    }
    this.ngxService.start();
    this.isShow=false;
    let apiPayload = { ...form.value };
    apiPayload.m_estimated_end_date = this.finalDate;
    this.rest.getData(apiPayload, 'addmodule').subscribe({
      next: (data) => {
        if(data.state=='200'){
          this.toaster.success(data.message);
          if(this.checkedList?.length > 0){
            this.setAutoTasks(data.data.id,data.data.m_type);
          }
          this.sidePanel = false;
          this.isShow    = false;
          setTimeout(() => {
            this.toaster.success(data.message);
          }, 500);
        }else{
          this.toaster.warning(data.message);
          this.isShow=true;
        }
        this.ngxService.stop();
      },
      error: (e) => {
        this.ngxService.stop();
        this.isShow=true;
        console.log(e);
        this.error = true;
        this.toaster.warning('Something went wrong!');
      },
    });
  }
  /* This function is used to convert date object to php date format */
  onDateSelect(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.finalDate = year + '-' + month + '-' + day;
  }

  getModuleType() {
    this.rest.getData({}, 'getmoduletypes').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.ModuleTypeList = data.data;
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        console.log(e);
        this.toaster.warning("Something went wrong!");
      },
    });
  }

  checkUncheckAll() {
    for (let i = 0; i < this.checklist.length; i++) {
      this.checklist[i].isSelected = this.masterSelected;
    }
    this.getCheckedItemList();
  }

  getCheckedItemList(){
    this.checkedList = [];
    for (let i = 0; i < this.checklist.length; i++) {
      if(this.checklist[i].isSelected)
      this.checkedList.push(this.checklist[i].t_id);
    }
    this.checkedList = this.checkedList;
  }

  isAllSelected() {
    this.masterSelected = this.checklist.every(function (item: any) {
      return item.isSelected == true;
    })
    this.getCheckedItemList();
  }

  activeInsctiveAutoTask(t_id:any = 0){
    if(t_id > 0){
      Swal.fire({
        title: 'Active this task?',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Yeah!',
      }).then((result) => {
        if (result.isConfirmed) {
            this.rest.getData({t_id:t_id}, 'activeautomatedtask').subscribe({
              next: (data) => {
                if (data.state == '200') {
                  this.modalService.dismissAll(this.editModal);
                  this.getAutoTasks(this.moduleType);
                  this.toaster.success("Added!");
                } else {
                  this.toaster.warning(data.message);
                }
              },
              error: (e) => {
                this.isShow = true;
                console.log(e);
                this.error = true;
                this.toaster.warning('Something went wrong!');
              },
            });
        }
      });
    }
  }

  clearTaskSelection(){
    this.modalService.dismissAll(this.editModal);
    this.checkedList = [];
  }

  getAutoTasks(type: any = ''){
    let typeName = type.target.value;
      this.moduleType = typeName;
      this.rest.getData({type:this.moduleType}, 'getautotasks').subscribe({
        next: (data) => {
          if (data.state == '200') {
            let taskObj = [];
            let taskObj1 = {};
            this.automatedTask  = data.data.inactiveTasks;
            this.masterSelected = false;
            for(let tsk of data.data.activeTasks){
              taskObj1 = {
                t_id:tsk.t_id,
                t_name:tsk.t_name,
                isSelected:false
              };
              taskObj.push(taskObj1);
            }
            this.checklist = taskObj;
            this.openModal();
          } else {
            // this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.isShow = true;
          console.log(e);
          this.error = true;
          this.toaster.warning('Something went wrong!');
        },
      });
  }

  setAutoTasks(m_id:any,m_type:any){
    this.showButton = false;
    let taskPayload = {
      m_id:m_id,
      m_type:m_type,
      t_id:this.checkedList
    }
    if (this.checkedList.length > 0) {
      this.rest.getData(taskPayload, 'createtutotask').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.modalService.dismissAll(this.editModal);
            this.toaster.success(data.message);
            this.autoTaskId = [];
          } else {
            this.toaster.warning(data.message);
          }
          this.showButton = true;
        },
        error: (e) => {
          console.log(e);
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }

  getTaskIds(ch: any, e: any){
    if (e.target.checked) {
      this.autoTaskId.push(ch);
    } else {
      for (let i = 0; i < this.autoTaskId.length; i++) {
        if (this.autoTaskId[i] === ch) {
          this.autoTaskId.splice(i, 1);
        }
      }
    }
  }


  // -----------------------------------add project form-----------------------------
  addProjectEssentials(){
    this.getAllUser();
    this.itemList = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select Employee',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: 'myclass custom-class',
    };
    /* This is object set for adding validation on selected fileds */
    this.projectForm = this.formBuilder.group({
      pname: [null, [Validators.required]],
      ptype: ['SM', [Validators.required]],
      pstage: [null, [Validators.required]],
      pestimetedeffort: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]\d*$/)],
      ],
      pestimetedenddate: [null, [Validators.required]],
      p_start_date: [null, [Validators.required]],
      p_end_date: [null, [Validators.required]],
      pdescription: [null, [Validators.required]],
      pdomain: ['Development', [Validators.required]],
      users: [null, [Validators.required]],
    });
  }
  onProjectSubmit(form: FormGroup) {
    this.error = false;
    if (form.invalid) {
      return;
    }
    this.isShow = false;
    let apiPayload = { ...form.value };
    apiPayload.pestimetedenddate = this.finalEstDate;
    apiPayload.p_start_date = this.finalStartDate;
    apiPayload.p_end_date = this.finalEndDate;
    let userpayload = <any>[];
    form.value.users.forEach((element: any) => {
      let tmpObj = { id: element, role: 'member' };
      userpayload.push(tmpObj);
    });
    apiPayload.users = userpayload;
    this.rest.getData(apiPayload, 'addproject').subscribe({
      next: (data) => {
        this.isShow = true;
        if (data.state == '200') {
          setTimeout(() => {
            this.toaster.success(data.message);
          }, 500);
        } else {
          this.isShow = true;
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        console.log(e);
        this.error = true;
        this.isShow = true;
        this.toaster.warning('Something went wrong');
      },
    });
  }
  /* This function is used to convert date object to php date format */
  onEstDateSelect(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.finalEstDate = year + '-' + month + '-' + day;
  }
  /* This function is used to convert date object to php date format */
  onStartDateSelect(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.finalStartDate = year + '-' + month + '-' + day;
  }
  /* This function is used to convert date object to php date format */
  onEndDateSelect(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.finalEndDate = year + '-' + month + '-' + day;
  }
  /* This function is used to fetch all user list */
  getAllUser() {
    //this.ngxService.start();
    this.rest.getData({}, 'getemployees').subscribe({
      next: (data) => {
        //this.ngxService.stop();
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.userlist = data.data.filter((obj:any) => obj.status === 1);
          this.userlist.forEach((element: any) => {
            let tmpObj = { id: element.u_id, name: element.name };
            this.itemList.push(tmpObj);
          });
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        //this.ngxService.stop();
        this.loadAllComponant = true;
        this.toaster.warning('Something went wrong!');
      },
    });
  }

  humanize(str:any) {
    str = str.replace(/[$@%-/]/g, ' ');
    return str.toUpperCase();
  }

  getTaskType() {
    this.rest.getData({},'gettasktypes').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.taskTypeList = data.data;
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        console.log(e);
        this.toaster.warning("Something went wrong!");
      },
    });
  }

  convertColor(string:any) {
    this.hsl = this.pageService.convertNameToColor(string);
  }

  convertToLetter(letter:any) {
    this.convertColor(letter);
    return this.pageService.getFirstLetters(letter);
  }

  searchType(event: any) {
    let searchkey = event.target.value;
    if (searchkey.length > 1) {
      this.objKey = searchkey;
    } else if (searchkey.length == 0) {
      this.objKey = searchkey;
    }
  }

  routePage(event: any)
  {
    if(event.target.value == 'task'){
      this.router.navigate(['/task-dashboard']);
    }else if(event.target.value == 'project'){
      this.router.navigate(['/project-dashboard']);
    }else if(event.target.value == 'employee'){
      this.router.navigate(['/employee-dashboard']);
    }
  }

  // Function to transform the title
transformTitle(title:any='') {
  let transformedTitle = title.toLowerCase();
  transformedTitle = transformedTitle
      .replace("component", "")
      .replace("Component", "")
      .replace("manage", " Manage ")
      .replace("Manage", " Manage ")
      .replace("Work", " Work ")
      .replace("work", " Work ")
      .replace("Upload", " Upload ")
      .replace("dash board", "Dashboard")
      .replace("task dashboard", "Workspace");
  return transformedTitle;
}
}
