import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from 'src/app/rest.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute, Router } from '@angular/router';
import { PagesService } from '../../pages.service';
@Component({
  selector: 'app-attendance-details',
  templateUrl: './attendance-details.component.html',
  styleUrls: ['./attendance-details.component.scss'],
})
export class AttendanceDetailsComponent implements OnInit {
  public dataList=<any>{};
  public loadAllComponant = false;
  public loggedInUserName: string = '';

  constructor(
    public rest: RestService,
    private route: ActivatedRoute,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService,
    public pageService:PagesService
  ) {}

  ngOnInit(): void {
    this.getUserData();
    this.dataList.empID = this.route.snapshot.params['id'];
    this.getDetails();
  }

  getUserData() {
    this.rest.getData({}, 'getloginuser').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.loggedInUserName = data.data.name;
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning("Authorization error!!");
      },
    });
  }

  /* api call for fetching attendance details of user */
  getDetails(){
    if(this.dataList.empID){
      this.ngxService.start();
      this.rest.getData({ u_id: this.dataList.empID }, 'attendancedetails').subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.loadAllComponant = true;
          if(data.state='200'){
            this.dataList.details=data.data.report;
            this.dataList.username=data.data.username;
            console.log(this.dataList.details)
          }else{
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.loadAllComponant = true;
          this.toaster.error("Something weng wrong!!!");
        },
      });
    }else{
      window.history.back();
    }
  }
  
  timeConvert(time:any){
    return this.pageService.timeConvert(time);
  }
}
