import { Injectable } from '@angular/core';
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
  NavigationExtras,
} from '@angular/router';
import { Observable } from 'rxjs';
import { AuthServiceService } from './auth-service.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthServiceService, private route: Router) {}
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<boolean> | Promise<boolean> | boolean {
   

    if (this.authService.isLoggednIn()) {
      if(next.data['data']!=undefined){
        if (this.authService.checkParameterizedUrl(state.url,next.data['data'])) {
          return true;
        } else {
          this.route.navigate(['/403']);
          return false;
        }
      }else{

        if (this.authService.checkUrl(state.url)) {
          return true;
        } else {
          this.route.navigate(['/403']);
          return false;
        }

        
      }
     
    } else {
      this.route.navigate(['/login']);

      return false;
    }
  }
}
