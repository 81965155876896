import { Component, OnInit } from '@angular/core';
import { RestService } from '../../rest.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-tags',
  templateUrl: './tags.component.html',
  styleUrls: ['./tags.component.scss']
})
export class TagsComponent implements OnInit {
  public tagData:any={};
  public isShow=true;
  public isDelShow=true;
  public loadAllComponant = false;
  constructor(
    private toaster: ToastrService,
    private ngxService: NgxUiLoaderService,
    public rest: RestService,
  ) { }

  ngOnInit(): void {
    this.getTagList();
  }
  /* This function is used to save new tag */
  saveTag(){
    if(this.tagData.tagName!==undefined && this.tagData.tagName.length>2){
      this.ngxService.start();
      this.isShow=false;
      let payload:any={};
      payload.name=this.tagData.tagName
      if(this.tagData.tagId!==undefined)payload.tag_id=this.tagData.tagId
      this.rest.getData(payload, 'addedittag').subscribe({
        next: (data) => {
          this.isShow=true;
          this.ngxService.stop();
          if (data.state == '200') {
            this.toaster.success(data.message);
            this.tagData.tagName="";
            this.getTagList();
          } else {
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.isShow=true;
          this.ngxService.stop();
          if(e.error.state=='400'){
            this.toaster.error(e.error.message);
          }else{
            this.toaster.error('Something went wrong!!');
          }
        },
      });
    }else{
      this.toaster.error('Tag name is required! and name should be minimum 3 character..');
    }
  }
  /* This function is used to fetch tag list*/
  getTagList() {
    this.ngxService.start();
    this.rest.getData({}, 'gettaglist').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.tagData.tags=data.data;
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        this.toaster.error('Something went wrong!!');
      },
    });
  }
  /* This function is used to delete tag */
  del(id:number){
    if(id>0){
      this.ngxService.start();
      this.isDelShow=false;
      this.rest.getData({tag_id:id}, 'deletetag').subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.isDelShow=true;
          if (data.state == '200') {
            this.toaster.success(data.message);
            this.getTagList();
          } else {
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.isDelShow=true;
          this.toaster.error('Something went wrong!!');
        },
      });
    }
  }

  edit(obj:any){
   this.tagData.tagId=obj.id;
   this.tagData.tagName=obj.name;
  }
}
