<div class="page-content">
  <div class="new-profile">
    <div class="profile-foreground position-relative mx-n4 mt-n4">
      <div class="profile-wid-bg">
        <img src="../../../assets/web.png" alt="" class="profile-wid-img">
      </div>
    </div>
    <div class="pt-4 mb-4 mb-lg-3 pb-lg-4 p-2">
      <div class="row g-4 align-items-center">
        <div class="col-auto" style="margin-left: 10px;">
          <h4 class="userShape" [ngStyle] = "{'background-color':hsl}">
            {{userName}}
          </h4>
        </div>
        <!--end col-->
        <div class="col">
          <div class="p-2">
            <h3 class="text-white mb-1">{{userData.name}}</h3>
            <div class="hstack text-white gap-1">
              {{userData.designation.replaceAll('_',' ') | titlecase}}
            </div>
            <div class="hstack text-white gap-1">
              <div class="me-2"><i class="las la-envelope-open"></i> {{userData.email}}</div>
              <div>
                <small class="text-white" *ngIf="userData!==undefined && userData!==null && userData.mobile!==''"> <i
                    class="las la-phone-square"></i> {{userData.mobile}}</small>
              </div>
              <div>
                <i class="las la-user-tag"></i> <!-- {{roleName}} -->
              </div>
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-auto order-last order-lg-0">
          <div class="row text text-white-50 text-center">
            <div class="col-lg-6 col-4">
              <div class="p-2">
                <p class="fs-14 mb-0">
                  {{currentDate | date:'MMMM'}}
                </p>
                <h4 class="text-white mb-1" *ngIf="userData!==undefined && userData!==null">
                  {{userData.attendanceCount}}/{{userData.workingDay}}</h4>
                <p class="fs-14 mb-0">Attendance</p>
              </div>
            </div>
            <div class="col-lg-6 col-4">
              <div class="p-2 ">
                <p class="fs-14 mb-0">Years</p>
                <h4 class="text-white mb-1" *ngIf="userData!==undefined && userData!==null">{{userData.leaveCount}}/24
                </h4>
                <p class="fs-14 mb-0">Leaves</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="profile-flow">
      <div class="row">
        <div class="col-lg-12">
          <div>
            <div class="d-flex">
              <!-- Nav tabs -->
              <ul class="nav nav-pills animation-nav profile-nav gap-2 gap-lg-3 flex-grow-1" role="tablist">
                <li class="nav-item" role="presentation" [ngClass]="edit_info=='edit'?'active':''">
                  <a class="nav-link text-white" href="javascript:void(0)" (click)="toggle_edit_button('edit')"
                    role="tab" aria-selected="true">
                    <i class="ri-airplay-fill d-inline-block"></i> <span
                      class="d-none d-md-inline-block">Overview</span>
                  </a>
                </li>
                <li class="nav-item" role="presentation" [ngClass]="edit_info=='schedule'?'active':''">
                  <a class="nav-link text-white" href="javascript:void(0)" (click)="toggle_edit_button('schedule')"
                    role="tab" aria-selected="true">
                    <i class="ri-airplay-fill d-inline-block"></i> <span
                      class="d-none d-md-inline-block">Schedule</span>
                  </a>
                </li>

              </ul>
              <div class="flex-shrink-0">
                <a href="javascript:void(0)" class="btn alert-success" (click)="docAction(1)">
                  <i class="las la-edit"></i> Edit </a>
              </div>
            </div>
            <!-- Tab panes -->
            <div class="tab-content pt-4 text-muted">
              <div class="active" id="overview-tab" role="tabpanel" *ngIf="edit_info=='edit'">
                <div class="container-fluid p-0">
                  <div class="row">
                    <div class="col-lg-12 first-profile-sec sec-about-sec mb-2">
                      <div class="card mb-1">
                        <div class="card-body">
                          <h5 class="card-title mb-2">
                            Complete Your Profile
                          </h5>
                          <div class="progress animated-progress custom-progress progress-label">
                            <div _ngcontent-tpk-c139="" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"
                              class="progress-bar" style="width: 30%;">
                              <div class="label">
                                <strong>30%</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="col-lg-6 first-profile-sec sec-about-sec pl-2"  style="padding-right: 3px;">
                  
                      <div class="card mb-2">
                        <div class="card-body">
                          <h4 class="mb-2">Professional Info</h4>
                          <div class="same-div mb-2">
                            <div class="tag-title">
                              <h5>Designation</h5>
                            </div>
                            <div class="tag-colon">
                              <h5>:</h5>
                            </div>
                            <div class="tag-details">
                              <p>{{humanize(userData.designation.replaceAll('_',' '))}}</p>
                            </div>
                          </div>

                          <div class="same-div mb-2">
                            <div class="tag-title">
                              <h5>Join Date</h5>
                            </div>
                            <div class="tag-colon">
                              <h5>:</h5>
                            </div>
                            <div class="tag-details">
                              <p>{{userData.join_date|date}}</p>
                            </div>
                          </div>

                          <div class="same-div mb-2">
                            <div class="tag-title">
                              <h5>Job Type</h5>
                            </div>
                            <div class="tag-colon">
                              <h5>:</h5>
                            </div>
                            <div class="tag-details">
                              <p>{{userData.type}}</p>
                            </div>
                          </div>

                          <div class="same-div mb-2">
                            <div class="tag-title">
                              <h5>Status</h5>
                            </div>
                            <div class="tag-colon">
                              <h5>:</h5>
                            </div>
                            <div class="status-div">
                              <div class="right-div">
                                <p>Active</p>
                              </div>
                            </div>
                          </div>

                        </div>
                      </div>

                      
                  
                      <div class="card mb-1">
                        <div class="card-body">
                          <h4 class="mb-2">Skills</h4>
                          <div class="skill-div">
                            <h6>HTML</h6>
                            <h6>Angular</h6>
                            <h6>CSS</h6>
                            <h6>React</h6>
                            <h6>JavaScript</h6>
                          </div>
                          <!-- For icons 
                          <div class="skill-div">
                            <span><i class="fa-brands fa-html5"></i></span>
                            <span><i class="fa-brands fa-css3-alt"></i></span>
                            <span><i class="fa-brands fa-react"></i></span>
                            <span><i class="fa-brands fa-angular"></i></span>
                            <span><i class="fa-brands fa-php"></i></span>
                            <span><i class="fa-brands fa-sass"></i></span>
                            <span><i class="fa-brands fa-laravel"></i></span>
                            <span><i class="fa-brands fa-js"></i></span>
                            <span><i class="fa-brands fa-wordpress"></i></span>
                            <span><i class="fa-brands fa-bootstrap"></i></span>
                          </div> -->
                        </div>
                      </div>
                    </div>

                    <div class="col-lg-6 sec-about-sec first-profile-sec pr-2" style="padding-left: 3px;">
                      <!-- <div class="card mb-1">
                        <div class="card-body">
                          <h5 class="card-title mb-3">
                            Complete Your Profile
                          </h5>
                          <div class="progress animated-progress custom-progress progress-label">
                            <div _ngcontent-tpk-c139="" role="progressbar" aria-valuenow="30" aria-valuemin="0" aria-valuemax="100"
                              class="progress-bar" style="width: 30%;">
                              <div class="label">
                                <strong>30%</strong>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div> -->
                  
                      <div class="card">
                        <div class="card-body">
                          <h4 class="mb-2">Personal Info</h4>
                          <div class="same-div mb-2">
                            <div class="tag-title">
                              <h5>Name</h5>
                            </div>
                            <div class="tag-colon">
                              <h5>:</h5>
                            </div>
                            <div class="tag-details">
                              <p>{{userData.name}}</p>
                            </div>
                          </div>

                          <div class="same-div mb-2">
                            <div class="tag-title">
                              <h5>Email</h5>
                            </div>
                            <div class="tag-colon">
                              <h5>:</h5>
                            </div>
                            <div class="tag-details">
                              <p>{{userData.email}}</p>
                            </div>
                          </div>

                          <div class="same-div mb-2">
                            <div class="tag-title">
                              <h5>Phone</h5>
                            </div>
                            <div class="tag-colon">
                              <h5>:</h5>
                            </div>
                            <div class="tag-details">
                              <p>{{userData.phone}}</p>
                            </div>
                          </div>

                          <div class="same-div mb-2">
                            <div class="tag-title">
                              <h5>DOB</h5>
                            </div>
                            <div class="tag-colon">
                              <h5>:</h5>
                            </div>
                            <div class="tag-details">
                              <p>{{userData.dob | date}}</p>
                            </div>
                          </div>

                          <div class="same-div mb-2">
                            <div class="tag-title">
                              <h5>Father's Name</h5>
                            </div>
                            <div class="tag-colon">
                              <h5>:</h5>
                            </div>
                            <div class="tag-details">
                              <p>{{userData.father_name}}</p>
                            </div>
                          </div>

                          <div class="same-div mb-2">
                            <div class="tag-title">
                              <h5>Address</h5>
                            </div>
                            <div class="tag-colon">
                              <h5>:</h5>
                            </div>
                            <div class="tag-details">
                              <p>{{userData.address}}</p>
                            </div>
                          </div>

                          <div class="same-div mb-2">
                            <div class="tag-title">
                              <h5>Country</h5>
                            </div>
                            <div class="tag-colon">
                              <h5>:</h5>
                            </div>
                            <div class="tag-details">
                              <p>{{userData.country}}</p>
                            </div>
                          </div>

                          <div class="same-div mb-2">
                            <div class="tag-title">
                              <h5>Marital Status</h5>
                            </div>
                            <div class="tag-colon">
                              <h5>:</h5>
                            </div>
                            <div class="tag-details">
                              <p>{{userData.marital_status}}</p>
                            </div>
                          </div>

                        </div>
                      </div>
                  
                    </div>
                  </div>
              </div>
              </div>
              <div class="" id="projects" role="tabpanel" *ngIf="edit_info=='schedule'">

                <div class="accordion" id="accordion-details">
                  <div class="card shadow-sm grow ctm-border-radius">
                    <div class="card-header" id="basic1">
                      <h4 class="cursor-pointer mb-0">
                        <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
                          data-target="#basic-one" aria-expanded="true">
                          Schedule
                        </a>
                      </h4>
                    </div>
                    <div class="card-body p-0">
                      <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                        data-parent="#accordion-details">
                        <div class="table-responsive">
                          <div class="table-responsive">
                            <table class="table">
                              <tbody>
                                <tr *ngFor="let day of days">
                                  <td>
                                    <span class="w-50">{{day}}</span>
                                  </td>
                                  <td>:</td>
                                  <td>
                                    <span class="font-weight-semibold">
                                      <select id="" class="form-control"
                                        ngModel="{{getScheduleData!=undefined?getScheduleData[day]:''}}"
                                        disabled="true">
                                        <option value="">No Shift Assigned</option>
                                        <ng-container *ngIf="shiftList!=undefined && shiftList!=null">
                                          <ng-container *ngFor="let list of shiftList">
                                            <option value="{{list.id}}">
                                              {{list.name + ' (' + list.start_time+' - '+list.end_time+')'}}
                                            </option>
                                          </ng-container>
                                        </ng-container>
                                      </select>
                                    </span>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <!--end card-->
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="accordion add-employee " id="taskedit" [ngClass]="docStatus==1?'on':''">

  <div class=" ctm-border-radius shadow-none">
    <div class="modal-header p-3 ps-4 bg-soft-success">
      <h5 class="modal-title" id="inviteMembersModalLabel">Edit Profile</h5>
      <button type="button" class="btn-close" (click)="docAction(0)"></button>
    </div>
    <div class="card-body p-0">
      <form [formGroup]="userForm" (ngSubmit)="onSubmit(userForm)" #fd="ngForm" [ngxFvControl]="fd">
        <div class="card  grow ctm-border-radius border-0 shadow-none">          
          <div class="card-body p-0">
            <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
              data-parent="#accordion-details">
  
              <div class="row">
                <div class="col-lg-6 form-group">
                  <ngx-fv-validate [control]="userForm.controls['father_name']">
                    <label #ngxFvName>
                      Father Name
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="las la-file-signature"></i>
                      </div>
                      <input type="text" class="form-control" id="Name" placeholder="Father Name" #ngxFvFormControl
                        formControlName="father_name">
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                </div>
                <div class="col-lg-6 form-group">
                  <ngx-fv-validate [control]="userForm.controls['mobile']">
                    <label #ngxFvName>
                      Mobile
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="las la-mobile"></i>
                      </div>
                      <input type="text" class="form-control" id="mobile" placeholder="Mobile" #ngxFvFormControl
                        formControlName="mobile">
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                </div>
                <div class="col-lg-6 form-group">
                  <ngx-fv-validate [control]="userForm.controls['emergency_mobile_1']">
                    <label #ngxFvName>
                      Emergency Contact Number 01
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="las la-mobile"></i>
                      </div>
                      <input type="text" class="form-control" id="emergency_mobile_1"
                        placeholder=" Emergency Contact Number 01" #ngxFvFormControl formControlName="emergency_mobile_1">
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                </div>
                <div class="col-lg-6 form-group">
                  <ngx-fv-validate [control]="userForm.controls['emergency_mobile_2']">
                    <label #ngxFvName>
                      Emergency Contact Number 02
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="las la-mobile"></i>
                      </div>
                      <input type="text" class="form-control" id="emergency_mobile_2"
                        placeholder="  Emergency Contact Number 02" #ngxFvFormControl
                        formControlName="emergency_mobile_2">
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                </div>
                <div class="col-lg-6 form-group">
                  <ngx-fv-validate [control]="userForm.controls['marital_status']">
                    <label #ngxFvName>
                      Marital Status
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="las la-calendar-alt"></i>
                      </div>
                      <input type="text" class="form-control" placeholder=" Marital Status" #ngxFvFormControl
                        formControlName="marital_status">
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                </div>
                <div class="col-lg-12 form-group">
                  <ngx-fv-validate [control]="userForm.controls['address']">
                    <label #ngxFvName>
                      Permanent Address
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="las la-calendar-alt"></i>
                      </div>
                      <textarea class="form-control" placeholder="Permanent Address" #ngxFvFormControl
                        formControlName="address"></textarea>
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                </div>
                <div class="col-lg-6 form-group">
                  <label>
                    Email
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-envelope-open"></i>
                    </div>
                    <input type="email" class="form-control" readonly placeholder="Email" #ngxFvFormControl
                      formControlName="email">
                  </div>
                </div>
                <div class="col-lg-6 form-group">
                  <ngx-fv-validate [control]="userForm.controls['password']">
                    <label #ngxFvName>
                      Password
                    </label>
                    <div class="input-group mb-3">
                      <div class="input-group-text"><i class="las la-calendar-alt"></i>
                      </div>
                      <input type="Password" class="form-control" id="Password" placeholder="Password" #ngxFvFormControl
                        formControlName="password">
                    </div>
                    <ngx-fv-error></ngx-fv-error>
                  </ngx-fv-validate>
                </div>
                <div class="input-group mb-3" *ngIf="isShow">
                  <button class="btn custom-btn btn-8" type="submit"><span>Submit Now</span></button>
                </div>
              </div>
            </div>
          </div>
        </div>
        
      </form>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>