<div class="main-content">
  <div class="newCard" *ngIf="viewType == 'general'">
    <div class="card_activity">
      <div class="card  grow ctm-border-radius">
        <div class="card-header overview-content d-flex justify-content-between align-items-center " id="basic1">
          <h4 class="cursor-pointer mb-0" *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager'))">
            <button (click)="reportType('detail')" class="btn custom-btn btn-8">Hourly View</button>
          </h4>
          <div class="searchList">
            <ul class="total-overview">
              <li (click)="getWorkReport('today')">
                <button [ngClass]="rangeTitle == 'today'?'badge text-danger p-0':''" >Today</button>
              </li>
              <li (click)="getWorkReport('yesterday')">
                <button [ngClass]="rangeTitle == 'yesterday'?'badge text-danger p-0':''" >Yesterday</button>
              </li>
              <li>
                <button [ngClass]="rangeTitle == 'week'?'badge text-danger p-0':''" (click)="getWorkReport('week')">Week</button>
              </li>
              <li (click)="getWorkReport('month')">
                <button [ngClass]="rangeTitle == 'month'?'badge text-danger p-0':''">Month</button>
              </li>
              <li>
                <button [ngClass]="rangeTitle == 'year'?'badge text-danger p-0':''" (click)="getWorkReport('year')">Year({{year}})</button>
              </li>
            </ul>
            <div class="dropdown"></div>
            <select *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager'))" class="select-project" name="project" [(ngModel)]="selectedUser" (change)="changeUser($event)">
              <option [value]="">All</option>
              <option *ngFor="let user of userList" [value]="user.u_id">{{user.name}}</option>
            </select>
          </div>
        </div>
        <div class="card-body">
          <div class="table-back employee-office-table">
            <div class="table-responsive">
              <table class="table custom-table table-hover table-hover custom-task-table">
                <thead>
                  <tr>
                    <th class="first">SL No.</th>
                    <th class="second">Name </th>
                    <th class="third">Module </th>
                    <th class="four">Task Name </th>
                    <th class="five">Timesheet</th>
                    <th class="six">Alloated Time</th>
                    <th class="seven">Working Time</th>
                    <th class="eight">Entry Date</th>
                    <!-- <th class="nine">Status</th> -->
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="reportList?.length < 1">
                    <td style="text-align:center;" colspan="12">
                      <h3><i class="las la-frown"></i> No Data!!</h3>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr *ngFor="let rep of reportList; index as i" [ngClass]="rep.status == 'Overflow'?'table-warning':''">
                    <td class="first">{{i+1}}</td>
                    <td class="second">{{rep.employee}}</td>
                    <td class="second">{{rep.module_name |titlecase}}</td>
                    
                    <td class="third link-dark d-block" tooltip={{rep.task_name}} placement="bottom">
                      <a href="javascript:void(0)" (click)="open(demoModal,rep.t_id)" class="close">{{rep.task_name?.length>30?rep.task_name.substr(0,30)+'..':rep.task_name| titlecase}}</a>
                    </td>

                    <td class="four">{{rep.timesheet|titlecase}}</td>
                    <td class="five">{{rep.task_time}}</td>
                    <td class="six">{{rep.spent_time}}</td>
                    <td class="seven">{{rep.entry_date | date}}</td>
                    <!-- <td class="nine">{{rep.status}}</td> -->
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>


  <div class="newCard working-card" *ngIf="viewType == 'detail'">
    <div class="card_activity">
      <div class="card  grow ctm-border-radius">
        <div class="card-header overview-content d-flex justify-content-between align-items-center " id="basic1">
          <h4 class="cursor-pointer mb-0">
            <button class="btn custom-btn btn-8" (click)="reportType('general')">General View</button>
          </h4>
          <div class="searchList">
            <ul class="total-overview">
              <li>
                <button [ngClass]="rangeTitle == 'today'?'badge text-danger p-0':''" (click)="getEmpWorkingHours('today')">Today</button>
              </li>
              <li>
                <button [ngClass]="rangeTitle == 'yesterday'?'badge text-danger p-0':''" (click)="getEmpWorkingHours('yesterday')">Yesterday</button>
              </li>
              <li>
                <button [ngClass]="rangeTitle == 'day3'?'badge text-danger p-0':''" (click)="getEmpWorkingHours('day3')">{{dates.day3|date:'MMM d'}}</button>
              </li>
              <li>
                <button [ngClass]="rangeTitle == 'day4'?'badge text-danger p-0':''" (click)="getEmpWorkingHours('day4')">{{dates.day4|date:'MMM d'}}</button>
              </li>
              
              <li>
                <button [ngClass]="rangeTitle == 'day5'?'badge text-danger p-0':''" (click)="getEmpWorkingHours('day5')">{{dates.day5|date:'MMM d'}}</button>
              </li>
              <li>
                <button [ngClass]="rangeTitle == 'day6'?'badge text-danger p-0':''" (click)="getEmpWorkingHours('day6')">{{dates.day6|date:'MMM d'}}</button>
              </li>
              <li>
                <button [ngClass]="rangeTitle == 'day7'?'badge text-danger p-0':''" (click)="getEmpWorkingHours('day7')">{{dates.day7|date:'MMM d'}}</button>
              </li>
            </ul>
          </div>
        </div>
        
        <div class="card-body">
          <table>
            <thead>
              <tr>
                <th class="first">SL No.</th>
                <th class="sec">Name</th>
                <th class="third">Hours</th>
                <th class="four">Tasks</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngIf="workingHourReport?.length < 1">
                <td style="text-align:center;" colspan="12">
                  <h3><i class="las la-frown"></i> No Data!!</h3>
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr *ngFor="let item of workingHourReport; index as i">
                <td class="first-td">{{i+1}}</td>
                <td class="sec-td">{{item.name}}</td>
                <td class="third-td">{{item.total}} hr</td>
                <td class="four-td">
                  <ul>
                    <li *ngFor="let task of item.tasks"><a href="javascript:void(0)" (click)="open(demoModal,task.t_id)" class="close">{{task.task_name|titlecase}}</a></li>
                  </ul>
                </td>
              </tr>
            </tbody>
          </table>
          <!-- <div class="table-back employee-office-table">
            <div class="table-responsive">
              <table class="table custom-table table-hover table-hover custom-task-table">
                <thead>
                  <tr>
                    <th class="first">SL No.</th>
                    <th class="second">Name </th>
                    <th class="third">Task </th>
                    <th class="four">Hours</th> 
                  </tr>
                </thead>
                <tbody>
                  <tr *ngIf="workingHourReport?.length < 1">
                    <td style="text-align:center;" colspan="12">
                      <h3><i class="las la-frown"></i> No Data!!</h3>
                    </td>
                  </tr>
                </tbody>
                <tbody>
                  <tr *ngFor="let wh of workingHourReport; index as i">
                    <td class="first">{{i+1}}</td>
                    <td class="second">{{wh}}</td>
                    <td class="third" >
                      <a class="" [routerLink]='["/manage-task/",encodeURIParam(rep.t_id)]'>
                        <span class=" edit text-success text-center">fggg</span>
                      </a>
                    </td>
                    <td class="six">{{rep.spent_time}}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div> -->
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #demoModal let-modal>
    
  <div class="modal-header">
    <div class="container d-flex flex-column justify-content-center align-items-center">
      <div class="tabs-control w-100 bg-white  rounded-top-4 d-flex justify-content-start align-items-center">
        <ul class="nav nav-tabs" role="tablist">
          <li (click)="toggleTab('task')" ><a class="py-4 text-decoration-none text-dark" [ngClass]="taskTab?'active':''" href="javascript: void(0);" role="tab" data-toggle="tab" >Overview</a></li>
          <li (click)="toggleTab('timesheet')"><a class="py-4 text-decoration-none text-dark" href="javascript: void(0);" [ngClass]="timesheetTab?'active':''" role="tab" data-toggle="tab" >Timesheets</a></li>
        </ul> 
      </div>
    </div>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>

  <div class="modal-body">
    <div class='app'>
      <main class='project right'>
        <div id="user-profile" class="">
          <div class="content">
            <div class="row">
              <div class="col-sm-12 card7-back" >
                <div class="card-7">
                  <!-- Tab panes -->
                  <div class="tab-content">

                    <div role="tabpanel" class="tab-pane active box" id="taskTab" *ngIf="taskTab">     
                      <div class="timedataCard position-relative tabTb card card shadow-none">
                        <div class="overview ">
                          <nav aria-label="breadcrumb">
                            <div class="d-flex justify-content-center" *ngIf="taskOverviewData?.length < 1">
                              <div class="spinner-border" role="status">
                                <span class="visually-hidden">Loading...</span>
                              </div>
                            </div>
                            <ol class="breadcrumb ">
                              <li class="breadcrumb-item d-flex align-items-center"><small class="pmtt">P</small>{{taskOverviewData.project_name}}</li>
                              <li class="breadcrumb-item d-flex align-items-center active" aria-current="page"><small class="badge">M</small>{{taskOverviewData.module_name}}</li>
                            </ol>
                          </nav>
                          <br>
                          <h2 class="text-muted taskName"><i class="las la-check-circle"></i>
                            <a [routerLink]='["/manage-task/",encodeURIParam(payload.taskID)]' (click)="modal.dismiss('Cross click')"> {{
                              taskOverviewData.task_name}}</a>
                          </h2>
                          <h6 class="text-muted taskDesk"><i class="las la-info-circle"></i> Description : {{
                            taskOverviewData.task_description}}</h6>
                          <h6 class="text-muted taskDesk"><i class="las la-calendar-check"></i> Date : {{taskOverviewData.created_at| date}}</h6>
                          <h6 class="text-muted taskDesk"><i class="las la-hourglass-start"></i> Assigned Time : {{taskOverviewData.estimated_hours > 0?taskOverviewData.estimated_hours:0}} hr</h6>

                          <h6 class="text-muted taskDesk"><i class="las la-user-circle"></i> Assigned By : {{ taskOverviewData.assign_by_user}}</h6>
                          <h4 class="tagStatus">
                            <span><i class="las la-info-circle"></i> {{taskOverviewData.task_status}}</span>
                          </h4>
                          
                        </div>
                        <h2><i class="las la-user-friends"></i>  Members</h2>
                        <div class="d-flex align-items-center">
                          <div class="userBox d-flex align-items-center">
                            <div class="flex-shrink-0 " *ngFor="let member of empList | slice:0:3;">
                              <a href="javascript: void(0);" class="userShape-Sm tc d-flex hover-text" [ngStyle]="{'background-color':hsl}">
                                {{convertToLetter(member)}}
                              <span class="tooltip-text top">{{member}}</span>
                              </a>
                            </div>
                            <p class="m-0" tooltip={{empList}}>
                              {{empList?.length > 3 ?' + '+userIconTrimmer(empList?.length):''}}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div role="tabpanel" class="tab-pane active box" id="taskTab" *ngIf="timesheetTab">     
                      <div class="timedataCard position-relative tabTb card card shadow-none">
                        <div class="table-responsive sidebar_tab activity-tab">
                          <div class="card_activity">
                            <div class="card  grow ctm-border-radius">
                              <div class="card-body p-0">
                                <div class="table-back employee-office-table">
                                  <div class="hd-header" *ngIf="taskOverviewData!=undefined">
                                    <ul>
                                      <li>Assigned : {{pageService.formatSeconds(taskOverviewData.estimated_hours*60)}}</li>
                                      <li *ngIf="total_effort!=null && total_effort!=undefined">Total Used :
                                        {{total_effort > 0?pageService.formatSeconds(total_effort):'0 h'}}</li>
                                    </ul>
                                  </div>
                                  <div class="worktime">
                                    <ul *ngFor="let time of timeSheetEffInfo; index as i">
                                      <li>{{time.name}} - {{pageService.formatSeconds(time.total_effort)}}</li>
                                    </ul>
                                  </div>
                                  <div class="table-responsive">
                                    <table class="table custom-table table-hover table-hover">
                                      <thead>
                                        <tr>
                                          <!-- <th>Sl No.</th> -->
                                          <th>Member Name</th>
                                          <th>Effort</th>
                                          <th>Description</th>
                                          <th>Entry Date</th>
                                          <!-- <th>Start Time</th>
                                          <th>End Time</th> -->
                                        </tr>
                                      </thead>
                                      <tbody>
                                        <tr *ngIf="taskTimeList?.length < 1">
                                          <!-- <td></td> -->
                                          <td></td>
                                          <td>
                                            <h3><i class="las la-frown"></i> No Data!!</h3>
                                          </td>
                                          <td></td>
                                          <!-- <td></td> -->
                                        </tr>
                                        <tr *ngFor="let taskTime of taskTimeList; index as i">
                                          <!-- <td>{{i+1}}</td> -->
                                          <td>{{taskTime.username}}</td>
                                          <td>{{pageService.timeConvert(taskTime.effort)}}</td>
                                          <td class="maxWidth">{{taskTime.comment}}</td>
                                          <td>{{taskTime.entry_date|date}}</td>
                                          <!-- <td>{{taskTime.start_time}}</td>
                                          <td>{{taskTime.end_time}}</td> -->
                                          <!-- <td></td> -->
                                        </tr>
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
            </div>
          </div>
        </div>  
      </main>
    </div>
  </div>
</ng-template>

<ngx-ui-loader></ngx-ui-loader>