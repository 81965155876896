import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-automated-task',
  templateUrl: './automated-task.component.html',
  styleUrls: ['./automated-task.component.scss']
})
export class AutomatedTaskComponent implements OnInit {

  public loadAllComponant = true;
  public taskList         = <any>[];
  public isNewProfile     = "";
  public isOn = false;
  public loggedInUserName: string = '';
  public searchKey = '';
  public nameSearch: any;
  public typeSearch: any;
  public statusSearch: any;
  public selectedfield = '';
  public nameFilterList = <any>[];
  public typeFilterList = <any>[];
  public statusFilterList = <any>[];
  public objKey: any;
  public multiSearch: string = '';
  public filter_status = false;
  public scrollList = true;

  constructor(
    public rest: RestService,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.getUserData();
    this.getTasks();
  }

  getUserData() {
    this.rest.getData({}, 'getloginuser').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.loggedInUserName = data.data.name;
        }else{
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning("Authorization error!!");
      },
    });
  }

  getTasks() {
    this.rest.getData({}, 'getallautomatedtask').subscribe({
      next: (res) => {
        this.loadAllComponant = true;
        if (res.state == '200') {
          console.log(res.data);
          this.taskList = res.data;
          this.createFilterData();
        } else {
          this.toaster.warning(res.message);
        }
      },
      error: (e) => {
        this.loadAllComponant = true;
        this.toaster.warning('Something wend wrong!');
      },
    });
  }

  encodeURIParam(param:any){
    return param.replace('/','_');
  }

  deleteTask(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      confirmButtonText: 'Yes!',
    }).then((result) => {
      if (result.isConfirmed) {
        if (id != '' && id != undefined && id != null) {
          this.rest.getData({ id: id }, 'deleteautomatedtask').subscribe({
            next: (data) => {
              if (data.state == '200') {
                this.toaster.success(data.message);
                this.getTasks();
              } else {
                this.toaster.warning(data.message);
              }
            },
            error: (e) => {
              console.log(e);
              this.toaster.warning('Something went wrong');
            },
          });
        } else {
          console.log('Empty');
        }
      }
    });
  }

  onToggle(id:any,status:any) {
    let message = 'Active this task ?'
    if(status == '1'){
      message = 'Inactive this task ?'
    }
    Swal.fire({
      title: message,
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: "No",
      width: '400px',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        let idArr = [];  
        idArr = [id];
        if (idArr.length > 0) {
          this.rest.getData({ id: idArr }, 'changeautotaskstatus').subscribe({
            next: (data) => {
              if (data.state == '200') {
                this.toaster.success(data.message);
                this.getTasks();
              } else {
                this.toaster.warning(data.message);
              }
            },
            error: (e) => {
              console.log(e);
              this.toaster.warning('Something went wrong');
            },
          });
        } else {
          console.log('Empty');
        }
      }
    });
  }

  filter_status_btn() {
    this.filter_status = !this.filter_status;
  }

  deSelect(event: any, filedType: string) {
    this.selectedfield = filedType;
    if (filedType === 't_name') {
      this.typeSearch = undefined;
      this.statusSearch = undefined;
      this.searchKey = this.nameSearch;
    } else if (filedType === 'm_type') {
      this.nameSearch = undefined;
      this.statusSearch = undefined;
      this.searchKey = this.typeSearch;
    } else if (filedType === 't_status') {
      this.nameSearch = undefined;
      this.typeSearch = undefined;
      this.searchKey = this.statusSearch;
    }
  }
  
  createFilterData() {
    let tnameFilterList = [];
    let ttypeFilterList = [];
    let tstatusFilterList = [];
    for (let task of this.taskList) {
      tnameFilterList.push(task.t_name);
      ttypeFilterList.push(task.m_type);
      tstatusFilterList.push(task.t_status);
    }
    this.nameFilterList   = [...new Set(tnameFilterList)];
    this.typeFilterList   = [...new Set(ttypeFilterList)];
    this.statusFilterList = [...new Set(tstatusFilterList)];
  }

  findTask(key = '') {
    let key1 = '';
    if(key !== '' || key !== undefined){
      key1 = key;
    }
    this.rest.getData({keywords:key1}, 'getallautomatedtask').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.taskList = [];
          console.log(this.taskList);
          this.taskList = data.data;
          this.createFilterData();
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.loadAllComponant = true;
        //this.toaster.warning('Something went wrong');
      },
    });
  }

  searchType(event: any) {
    let searchkey = event.target.value;
    if (searchkey.length > 2) {
      this.scrollList = false;
      this.findTask(searchkey);
    } else if (searchkey.length == 0) {
      this.scrollList = true;
      this.taskList = [];
      this.getTasks();
    }
  }

}
