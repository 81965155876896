<!-- <div class="quicklink-sidebar-menu ctm-border-radius  bg-white card  mb-2 grow">
  <div class="card-body">
    <div class="cardTitle d-flex justify-content-between align-items-center">
      <h5 class="p-0 m-0">Time Sheet </h5>
      <button class="btn btn-primary bg-custom border-0 " routerLink="/project"><i
          class="lar la-caret-square-left 48px"></i></button>
    </div>
  </div>
</div> -->
<div class="row main_mobile">
  <div class="col-lg-3">
    <div class="card card-h-100 reminder-color-line">
      <div class="card-body">
        <!-- <button class="btn btn-primary w-100" id="btn-new-event"><i class="mdi mdi-plus"></i> Create New Event</button> -->
        <h5 *ngIf="viewDate"> 
          <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#ff287e" class="bi bi-alarm" viewBox="0 0 16 16">
            <path d="M8.5 5.5a.5.5 0 0 0-1 0v3.362l-1.429 2.38a.5.5 0 1 0 .858.515l1.5-2.5A.5.5 0 0 0 8.5 9z"/>
            <path d="M6.5 0a.5.5 0 0 0 0 1H7v1.07a7.001 7.001 0 0 0-3.273 12.474l-.602.602a.5.5 0 0 0 .707.708l.746-.746A6.97 6.97 0 0 0 8 16a6.97 6.97 0 0 0 3.422-.892l.746.746a.5.5 0 0 0 .707-.708l-.601-.602A7.001 7.001 0 0 0 9 2.07V1h.5a.5.5 0 0 0 0-1zm1.038 3.018a6 6 0 0 1 .924 0 6 6 0 1 1-.924 0M0 3.5c0 .753.333 1.429.86 1.887A8.04 8.04 0 0 1 4.387 1.86 2.5 2.5 0 0 0 0 3.5M13.5 1c-.753 0-1.429.333-1.887.86a8.04 8.04 0 0 1 3.527 3.527A2.5 2.5 0 0 0 13.5 1"/>
          </svg> Reminders </h5>
        <div *ngIf="!reminderCard">
          <div id="external-events">
            No Reminders for {{ viewDate | date:'fullDate' }}
          </div>
        </div>
        <div *ngIf="reminderCard">
          <div id="external-events" *ngFor="let reminder of remList">
            <div class="alert alert-warning">
              #Today <i class="las la-bell"></i>
              <br>
              {{reminder.title}} at {{reminder.reminder_time}}
            </div>
          </div>
        </div>
        <div class="text-center">
          <!-- <button type="button" class="btn btn-success w-100"
            *ngIf="isShowBLoadBtn && getReminders.length !== remList.length" (click)="showMoreRem()">View
            more</button> -->
          <button class="btn btn-success" type="button" disabled *ngIf="!isShowBLoadBtn">
            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
            <span class="visually-hidden">Loading...</span>
          </button>
        </div>
      </div>
    </div>
      <!-- <h5 class="mb-2 text-mute align-items-center d-flex" id="taskView">
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
          stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
          class="feather feather-calendar text-info icon-dual-info">
          <rect x="3" y="4" width="18" height="18" rx="2" ry="2"></rect>
          <line x1="16" y1="2" x2="16" y2="6"></line>
          <line x1="8" y1="2" x2="8" y2="6"></line>
          <line x1="3" y1="10" x2="21" y2="10"></line>
        </svg> 
          {{viewDate|date:'MMM d'}}'s Entry List
      </h5> -->

      <div class=" me-n1 mb-3 taskwrapper">
        <div class="simplebar-wrapper">
          <div class="simplebar-mask">
            <div class="simplebar-offset">
              <div class="simplebar-content-wrapper">
                <div class="simplebar-content">
                  <div id="upcoming-event-list" *ngIf="events.length>0;else tem">
                    <div class="card mb-3" *ngFor="let event of events | tmdateFilter : viewDate;index as i">
                      
                      <div class="card-body task-color-line" [ngClass]="event.meta.overflow == '1'?'overflow-task':''">
                        <div class="d-flex mb-2 justify-content-between">                        
                          <div class="flex-shrink-0">
                          <small class="ms-auto ts-clock"><svg xmlns="http://www.w3.org/2000/svg" width="16" height="16"  fill="currentColor" class="bi bi-clock" viewBox="0 0 16 16">
                            <path d="M8 3.5a.5.5 0 0 0-1 0V9a.5.5 0 0 0 .252.434l3.5 2a.5.5 0 0 0 .496-.868L8 8.71z"/>
                            <path d="M8 16A8 8 0 1 0 8 0a8 8 0 0 0 0 16m7-8A7 7 0 1 1 1 8a7 7 0 0 1 14 0"/>
                          </svg>  {{ event.start | date:'shortTime'}} - {{event.end | date:'shortTime'}} </small></div>
                          <ng-container *ngIf="verifydate()">
                            <!-- <button type="button" class="btn-close text-white" *ngIf="!isAdmin" (click)="deleteEvent(event)"></button> -->
                            <a href="javascript: void(0);" *ngIf="!isAdmin" (click)="deleteEvent(event)"><svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" fill="currentColor" style="color: #ff287e" class="bi bi-trash3" viewBox="0 0 16 16">
                              <path d="M6.5 1h3a.5.5 0 0 1 .5.5v1H6v-1a.5.5 0 0 1 .5-.5M11 2.5v-1A1.5 1.5 0 0 0 9.5 0h-3A1.5 1.5 0 0 0 5 1.5v1H1.5a.5.5 0 0 0 0 1h.538l.853 10.66A2 2 0 0 0 4.885 16h6.23a2 2 0 0 0 1.994-1.84l.853-10.66h.538a.5.5 0 0 0 0-1zm1.958 1-.846 10.58a1 1 0 0 1-.997.92h-6.23a1 1 0 0 1-.997-.92L3.042 3.5zm-7.487 1a.5.5 0 0 1 .528.47l.5 8.5a.5.5 0 0 1-.998.06L5 5.03a.5.5 0 0 1 .47-.53Zm5.058 0a.5.5 0 0 1 .47.53l-.5 8.5a.5.5 0 1 1-.998-.06l.5-8.5a.5.5 0 0 1 .528-.47M8 4.5a.5.5 0 0 1 .5.5v8.5a.5.5 0 0 1-1 0V5a.5.5 0 0 1 .5-.5"/>
                            </svg>
                            </a>
                          </ng-container>
                        </div>
              
                        <h6 class="card-title fs-16 mb-2">
                          <a [routerLink]='["/manage-task/",encodeURIParam(event.meta.t_id)]'>{{event.meta.task_name | titlecase}}</a>
                        </h6>
                        <p class="text-muted text-truncate-two-lines mb-2 tsheet"> {{event.title|titlecase}}</p>
                        <div class="flex-grow-1  ">
                          <span class="fw-medium ">
                            <a class="text-success tooltipx me-2 cust" [routerLink]='["/manage-project/",encodeURIParam(event.meta.p_id)]'> {{event.meta.p_name.substr(0,19)|titlecase}}... <span class="tooltiptext"> {{event.meta.p_name|titlecase}} </span> </a>
                            <a class="text-success tooltipx cust" [routerLink]='["/manage-module/",encodeURIParam(event.meta.m_id)]'> {{event.meta.m_name.substr(0,19)|titlecase}}... <span class="tooltiptext"> {{event.meta.m_name|titlecase}} </span></a> </span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <ng-template #tem>
                    <div class="card x_ad">
                      <img src="../../../assets/download.jpg" alt="">
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--end card-->
  <div class="col-lg-9">
    <div class="card">
      <div class="card-body">
        <div class="row justify-content-between align-items-center">
          <!-- <div class="col-12">
            <div class="calenderTitle text-center">
              <h3 class="ts_title">{{ viewDate |date }}</h3>
            </div>
          </div> -->
          <div class="col-6 text-left">
            <div class="btn-group">
              <div class="btn alert-primary border-0 cst_btn " mwlCalendarPreviousView [view]="view"
                [(viewDate)]="viewDate" (viewDateChange)="closeOpenMonthViewDay()">
                <h3><i class="las la-angle-left"></i></h3>
              </div>
              <h2 class="ts_title">{{ viewDate |date:'MMMM' }}</h2>
              <div class="btn alert-primary  border-0 cst_btn" mwlCalendarNextView [view]="view" [(viewDate)]="viewDate"
                (viewDateChange)="closeOpenMonthViewDay()">
                <h3><i class="las la-angle-right"></i></h3>
              </div>
            </div>
          </div>
          <div class="col-6 text-end">
            <div class="btn_grp ">
              <div class="eventTask d-flex justify-content-end">
                <!-- <button class="btn text-mute  m-hide " (click)="setView(CalendarView.Month)"
                  [class.active]="view === CalendarView.Month">
                  Month
                </button>
                <button class="btn text-mute  m-hide" (click)="setView(CalendarView.Week)"
                  [class.active]="view === CalendarView.Week">
                  Week
                </button>
                <button class="btn text-mute  m-hide" (click)="setView(CalendarView.Day)"
                  [class.active]="view === CalendarView.Day">
                  Day
                </button> -->

                <button type="button" *ngIf="!isAdmin" class="btn custom-btn btn-8" (click)="addEventBtn()">Add Entry</button>
              </div>
            </div>
          </div>
          <div [ngSwitch]="view">
            <mwl-calendar-month-view *ngSwitchCase="CalendarView.Month" [viewDate]="viewDate" [events]="events"
              [refresh]="refresh" [activeDayIsOpen]="activeDayIsOpen" (dayClicked)="dayClicked($event.day)"
              (eventClicked)="handleEvent('Clicked', $event.event)" (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-month-view>
            <mwl-calendar-week-view *ngSwitchCase="CalendarView.Week" [viewDate]="viewDate" [events]="events"
              [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-week-view>
            <mwl-calendar-day-view *ngSwitchCase="CalendarView.Day" [viewDate]="viewDate" [events]="events"
              [refresh]="refresh" (eventClicked)="handleEvent('Clicked', $event.event)"
              (eventTimesChanged)="eventTimesChanged($event)">
            </mwl-calendar-day-view>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="card timesheet" [ngClass]="timeSheetData?'on':''">
    <div class="card-body">
      <!-- <div class="modal-header p-0 mb-0">
        <h6 class="display-3 fs-5 text-mute ">Event Details</h6>
        <button type="button" class="btn-close" (click)="close_timeSheetData()"></button>
      </div> -->
      <button type="button" class="btn-close" (click)="close_timeSheetData()"></button>
      <div class="switch-button" [ngClass]="switchStatus?'active-left':'active-right'"><span class="active"></span>
        <button class="switch-button-case left" [ngClass]="switchStatus=='task'?'active-case':''" (click)="switch('task')">{{timesheetFormTitle}}</button>
        <button class="switch-button-case right" [ngClass]="switchStatus=='notification'?'active-case':''"
          (click)="switch('notification')">Add Reminder</button>
          <!-- <button class="switch-button-case right" [ngClass]="switchStatus=='Checklist'?'active-case':''"
          (click)="switch('Checklist')">Task Checklist</button> -->
      </div>
      <div class="table-responsive sidebar_tab" *ngIf="switchStatus == 'task'">
        <form #timesheetdata="ngForm">
          <div class="timedataCard position-relative tabTb">
            <div class="headerDate">
              <h3 *ngIf="viewAdEventDate" class=" d-flex justify-content-between align-items-center">
                <a *ngIf="prevDay" href="javascript:void(0)" (click)="dateChangePop('prev')">
                  <i class="las la-angle-left "></i>
                </a> <span> {{ viewAdEventDate | date:'mediumDate' }}</span>
                <a href="javascript:void(0)" *ngIf="nxtDay" (click)="dateChangePop('next')">
                  <i class="las la-angle-right "></i>
                </a>
              </h3>
            </div>

            <div class="container">
              <div class="row">    
                <div class="col-lg-12 form-group" *ngIf="!disableTask">
                  <label>
                    Select Task
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text">
                      <i class="las la-user-secret"></i>
                    </div>

                    <ng-select class="w-100" #taskSelected='ngModel' #ngxFvFormControl name="timeSheetToSave.task_id" (change)="isOverFlowTask($event)" [(ngModel)]="resetSelection"
                    [(ngModel)]="timeSheetToSave.task_id"
                    required="required" [disabled]="disableTask">
                      <ng-option value="-1" ></ng-option>
                      <ng-option value="{{task.id}}" class="btn btn-success mr-2" *ngFor="let task of assigneTask">
                          {{task.task_name.length > 29?task.task_name.substr(0,29)+'..':task.task_name| titlecase}}/
                          {{task.module_name.length>30?task.module_name.substr(0,30)+'..':task.module_name| titlecase}}/
                          {{task.project_name.length>15?task.project_name.substr(0,15)+'..':task.project_name| titlecase}}
                          <strong [ngStyle]="task.taken_hours < task.estimated_pd?{'color': 'green'} : {'color': 'red'}">{{task.taken_hours < task.estimated_pd?task.result_hour+' Hrs':+task.taken_hours > task.estimated_pd?plusSign+task.result_hour+' Hrs ':''}}</strong>
                      </ng-option>
                    </ng-select>

                    <div *ngIf="taskSelected.touched && taskSelected.invalid" class="invalid-feedback d-block ">
                      Task Selected is a required field!
                    </div>
                  </div>
                </div>
                <div class="col-lg-12 form-group" *ngIf="isShow">
                  <label>
                    Reason
                  </label>
                  <div class="input-group mb-3">
                    <textarea autosize  class="form-control" #taskDescription='ngModel' placeholder="Mention reasons for taking extra time!" name="timeSheetToSave.reason" [(ngModel)]="timeSheetToSave.reason" required="required"></textarea>
                  </div>
                </div>
                <div class="col-lg-12 form-group">
                  <label>
                    Description
                  </label>
                  <div class="input-group mb-3">
                    <textarea autosize  class="form-control" #taskDescription='ngModel' placeholder="Add Description"
                      name="timeSheetToSave.description" [(ngModel)]="timeSheetToSave.description"
                      required="required"></textarea>
                  </div>
                </div>
                <div class="col-lg-6 form-group">
                  <label>
                    Start Time (24:00 Hours)
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="lab la-git-alt"></i>
                    </div>
                    <input #startTime='ngModel' name="timeSheetToSave.start" class="form-control" type="text"
                      mwlFlatpickr [altInput]="true" [convertModelValue]="true" [enableTime]="true" [noCalendar]="true"
                      dateFormat="H:i" [time24hr]="true" altFormat="H:i" placeholder="Not set"
                      [(ngModel)]="timeSheetToSave.start" required="required" />
                    <div *ngIf="startTime.touched" class="invalid-feedback d-block">
                      <p *ngIf="startTime.errors">
                        {{startTime.errors}}
                        Start is a required field!
                      </p>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 form-group">
                  <label>
                    End Time (24:00 Hours)
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="lab la-git-alt"></i>
                    </div>
                    <input class="form-control" #endTime='ngModel' name="timeSheetToSave.end" type="time" mwlFlatpickr
                      [altInput]="true" [convertModelValue]="true" [enableTime]="true" [noCalendar]="true"
                      dateFormat="H:i" [time24hr]="true" altFormat="H:i" placeholder="Not set"
                      [(ngModel)]="timeSheetToSave.end" />
                    <div *ngIf="endTime.touched" class="invalid-feedback d-block">
                      <p *ngIf="endTime.errors">
                        {{endTime.errors}}
                        End is a required field!
                      </p>
                    </div>
                  </div>

                </div>
                <div class="col-lg-12">
                  <ng-container *ngIf="verifydate()">
                    <button class="btn custom-btn btn-8 wdth" (click)="saveTimesheet()"
                      [disabled]="!timesheetdata.valid">
                      Submit
                    </button>
                  </ng-container>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
      
      <div class="table-responsive sidebar_tab" *ngIf="switchStatus == 'notification'">
        <form [formGroup]="remimderForm" (ngSubmit)="addEditReminder(remimderForm)" #fd="ngForm">
          <div class="accordion add-employee" id="accordion-details">
            <div class="card  p-0 timedataCard grow ctm-border-radius  shadow-none">
              <div class="card-body p-0">
                <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                  data-parent="#accordion-details">
                  <div class="row">
                    <input type="hidden" class="form-control" id="r_id" #ngxFvFormControl formControlName="r_id">
                    <div class="col-lg-12 form-group">
                      <label #ngxFvName>
                        Reminder Title
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-envelope-open"></i>
                        </div>
                        <input type="text" class="form-control" id="title" placeholder="Ex: My reminder"
                          #ngxFvFormControl formControlName="title" required>
                      </div>
                    </div>
                    <div class="col-lg-12 form-group">
                      <label #ngxFvName>
                        Description
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-envelope-open"></i>
                        </div>
                        <textarea class="form-control" id="description" placeholder="Description" #ngxFvFormControl
                          formControlName="description" required></textarea>
                      </div>
                    </div>
                    <div class="col-lg-12 form-group">
                      <label #ngxFvName>
                        Date
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text">
                          <i class="las la-calendar-alt"></i>
                        </div>
                        <input type="text" class="form-control" ngbDatepicker #dpStart="ngbDatepicker"
                          id="reminder_date" placeholder="YYYY-MM-DD" name="dpStart" (click)="dpStart.toggle()"
                          [minDate]="minDateLimit" #ngxFvFormControl formControlName="reminder_date" readonly>
                      </div>
                    </div>
                    <div class="col-lg-12 form-group">
                      <label #ngxFvName>
                        Time
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-envelope-open"></i>
                        </div>
                        <input type="time" class="form-control" id="reminder_time" placeholder="Ex: My reminder"
                          #ngxFvFormControl formControlName="reminder_time" required>
                      </div>
                    </div>
                    <div class="col-lg-12 form-group">
                      <label #ngxFvName>
                        Priority
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="lar la-id-card"></i>
                        </div>
                        <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                          #ngxFvFormControl formControlName="priority" required>
                          <option value="">Set Priority</option>
                          <option value="h">High</option>
                          <option value="m">Medium</option>
                          <option value="l">Low</option>
                        </select>
                      </div>
                    </div>
                    <div class="input-group mb-3">
                      <button class="btn custom-btn btn-8 wdth" type="submit"><span>Set</span></button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
  <ng-template #modalContent let-close="close">
    <div class="modal-header">
      <h5 class="modal-title">Event action occurred</h5>
      <button type="button" class="close" (click)="close()">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <div>
        Action:
        <pre>{{ modalData?.action }}</pre>
      </div>
      <div>
        Event:
        <pre>{{ modalData?.event | json }}</pre>
      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="btn btn-outline-secondary" (click)="close()">
        OK
      </button>
    </div>
  </ng-template>
  <app-loader-one *ngIf="showloader"></app-loader-one>
  <ngx-ui-loader *ngIf="!isAdmin"></ngx-ui-loader>

  <!-- <ng-template #demoModal let-modal>
    <div class="modal-header reminder-head">
      <h4 class="modal-title ts_title" id="modal-basic-title ">Timesheet Details</h4>
      <button type="button" class="btn-close reminder-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
    </div>
    <div class="modal-body reminder-body">
      <div class='app'>
        <main class='project right'>
          <div id="user-profile" class="">
            <div class="content">
              <div class="row">
                <div class="col-sm-12 card7-back" >
                  <div class="card-7">
                    <div class="tab-content">
                      <div role="tabpanel" class="tab-pane active box" id="taskTab">
                          <div class="overview uploadDoc">
                            <nav aria-label="breadcrumb">
                              <ol class="breadcrumb ">
                                <li class="breadcrumb-item d-flex align-items-center">
                                  <small class="pmtt">P</small> {{ timesheetInfoData.meta.p_name|titlecase}}</li>
                                <li class="breadcrumb-item d-flex align-items-center active" aria-current="page">
                                  <small class="badge">M</small> {{ timesheetInfoData.meta.m_name|titlecase}}</li>
                              </ol>
                            </nav>
                            <br>
                            <h6 class="text-muted taskDesk"><i class="las la-calendar-week"></i> Timesheet :
                              {{timesheetInfoData.title|titlecase}}</h6>
                              
                            <h6 class="text-muted taskDesk" [routerLink]='["/manage-task/",encodeURIParam(timesheetInfoData.meta.t_id)]' (click)="modal.dismiss('Cross click')"><i class="las la-list-alt"></i> Task : {{timesheetInfoData.meta.task_name|titlecase}}
                            </h6>

                            <h6 class="text-muted taskDesk" [routerLink]='["/manage-module/",encodeURIParam(timesheetInfoData.meta.m_id)]' (click)="modal.dismiss('Cross click')"><i class="las la-recycle"></i> Module : {{timesheetInfoData.meta.m_name|titlecase}}
                            </h6>
                    
                            <h6 class="text-muted taskDesk" [routerLink]='["/manage-project/",encodeURIParam(timesheetInfoData.meta.p_id)]' (click)="modal.dismiss('Cross click')"><i class="lab la-git"></i> Project : {{timesheetInfoData.meta.p_name|titlecase}}
                            </h6>

                            <h6 class="text-muted taskDesk"><i class="las la-hourglass-start"></i> Effort : {{timesheetInfoData.meta.effort}} Min
                            </h6>
                    
                          </div>
                      </div>
                    </div>
                  </div> 
                </div>
              </div>
            </div>
          </div>  
        </main>
      </div>
    </div>
  </ng-template> -->