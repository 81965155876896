import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableFilter'
})
export class TableFilterPipe implements PipeTransform {

  transform(
    value:      any, 
    searchData: any,
    key:        any, 
    ): 
    any {
      //console.log(key);
    if (searchData != undefined && searchData !='') {
      return value.filter(function (search: any) {
            let retval=false;
            //console.log(typeof(search[key]));
            if(typeof(search[key])=='string' || typeof(search[key])=='number'){
               retval= search[key].indexOf(searchData) > -1;
              //console.log(retval);
            }else{              
              for (let srchdata of  search[key]) {
                //console.log(srchdata)
                  if(srchdata.indexOf(searchData) > -1){
                    retval=true;
                  }
              }
            }

            return retval;
        });
    }else {
      return value;
    }
  }

}

