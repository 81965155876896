<div class="main-content" *ngIf="loadAllComponant">
  <!-- <div class="quicklink-sidebar-menu ctm-border-radius  bg-white card grow">
    <div class="card-body">
      <div class="cardTitle d-flex justify-content-between align-items-center">
        <h5 class="p-0 m-0">Create New Project </h5>
      </div>
    </div>
  </div> -->
  <div class="accordion add-employee" id="accordion-details">
    <div class="card  grow ctm-border-radius">
      <div class="card-header" id="basic1">
        <h4 class="cursor-pointer mb-0">
          <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
            data-target="#basic-one" aria-expanded="true">
            Basic Project Details
            <br>
            <!-- <span class="ctm-text-sm">Organized and secure.</span> -->
          </a>
        </h4>
      </div>
      <div class="card-body p-0">
  
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
          <form [formGroup]="projectForm" (ngSubmit)="onSubmit(projectForm)" #fd="ngForm" [ngxFvControl]="fd">
            <div class="row">
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['pname']">
                  <label #ngxFvName >
                    <p class="mb-2">Project Name</p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="lab la-git-alt"></i>
                    </div>
                    <input type="text" class="form-control" id="pname" placeholder="Project Name" #ngxFvFormControl
                      formControlName="pname">
  
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['pdomain']">
                  <label #ngxFvName >
                    <p class="mb-2">Project Domain</p> 
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-random"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      #ngxFvFormControl formControlName="pdomain">
                      <option value="">Select Domain </option>
                      <option value="Development">Development</option>
                      <option value="UX">UX</option>
                      <option value="Server">Server</option>
                      <option value="Sales">Sales</option>
                      <option value="Support">Back Office</option>
                      <option value="Official">Official</option>
                      <option value="DigitalMarketing">Digital Marketing</option>
                      <option value="Study">Study</option>
                    </select>
  
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['ptype']">
                  <label #ngxFvName >
                    <p class="mb-2">Project Type</p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-layer-group"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      #ngxFvFormControl formControlName="ptype">
                      <option value="">Project Type </option>
                      <option value="SM">Support & Maintainence </option>
                      <option value="FD">Feature Development</option>
                      <option value="RD">R & D</option>
                      <option value="WF">Website & Facebook</option>
                      <option value="NB">Non Billable</option>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
  
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['pstage']">
                  <label #ngxFvName >
                    <p class="mb-2">Project Stage</p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-user-secret"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      #ngxFvFormControl formControlName="pstage">
                      <option selected="Planning">Planning</option>
                      <option selected="Designing">Designing</option>
                      <option selected="Execution">Execution</option>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-12 form-group">
                <ngx-fv-validate [control]="projectForm.controls['pdescription']">
                  <label #ngxFvName >
                    <p class="mb-2">Project Description</p>
                  </label>
                  <div class="input-group mb-3">
                    <textarea class="form-control" placeholder="" #ngxFvFormControl
                      formControlName="pdescription"> </textarea>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['p_start_date']">
                  <label #ngxFvName >
                    <p class="mb-2">Project Start Date</p>
                  </label>
                  <div class="input-group mb-3">  
                    <div class="input-group-text"><i class="las la-calendar"></i>
                    </div>  
                    <input type="text" readonly ngbDatepicker #dpStart="ngbDatepicker" name="dpStart" (click)="dpStart.toggle()"
                      class="form-control" id="fff" placeholder="YY-MM-DD" #ngxFvFormControl
                      formControlName="p_start_date" (dateSelect)="onStartDateSelect($event)" [minDate]="minDate">
                  </div>
                <ngx-fv-error></ngx-fv-error>
              </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['p_end_date']">
                  <label #ngxFvName >
                    <p class="mb-2">Project End Date</p>
                  </label>
                  <div class="input-group mb-3">  
                    <div class="input-group-text"><i class="las la-calendar"></i>
                    </div>  
                    <input type="text" readonly ngbDatepicker #dpEnd="ngbDatepicker" name="dpEnd" (click)="dpEnd.toggle()"
                      class="form-control" id="udfdsername" placeholder="YY-MM-DD" #ngxFvFormControl
                      formControlName="p_end_date" (dateSelect)="onEndDateSelect($event)" [minDate]="minDate">
                  </div>
                <ngx-fv-error></ngx-fv-error>
              </ngx-fv-validate>
              </div>              
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['pestimetedenddate']">
                  <label #ngxFvName >
                    <p class="mb-2">Project Estimate End Date</p>
                  </label>
                  <div class="input-group mb-3">  
                    <div class="input-group-text"><i class="las la-calendar"></i>
                    </div>  
                    <input type="text" readonly ngbDatepicker #dpEsStart="ngbDatepicker" name="dpEsStart" (click)="dpEsStart.toggle()"
                      class="form-control" id="username" placeholder="YY-MM-DD" #ngxFvFormControl
                      formControlName="pestimetedenddate" (dateSelect)="onEstDateSelect($event)" [minDate]="minDate">
                  </div>
                <ngx-fv-error></ngx-fv-error>
              </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['pestimetedeffort']">
                  <label #ngxFvName >
                    <p class="mb-2">Project Effort <small >(1 PD = 8 Hours or 1 working day)</small></p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-business-time"></i>
                    </div>
                    <input type="Number" class="form-control" id="username" placeholder="Est End Duration in PD" #ngxFvFormControl formControlName="pestimetedeffort">
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
  
              <div class="col-lg-12 form-group">
                <ngx-fv-validate [control]="projectForm.controls['users']">
                  <label #ngxFvName >
                    <p class="mb-2">Project Member</p>
                  </label>
                  
                 
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-user-secret"></i>
                    </div>
                    <ng-select  formControlName="users" [multiple]="true" [selectableGroup]="true"
                    [closeOnSelect]="false">
                      <ng-option *ngFor="let usr of userlist" [value]="usr.u_id">{{usr.name}}</ng-option>
                   </ng-select>
  
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
             
          
              <div class="col-lg-12 form-group">
                <div class="input-group mb-3" *ngIf="isShow">
                  <button class="btn custom-btn btn-8" type="submit"><span>Submit Now</span></button>
                </div>
              </div>
  
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>