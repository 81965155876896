import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from 'src/app/rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PagesService } from '../pages.service';

//import { NgxBootstrapConfirmModule } from 'ngx-bootstrap-confirm';
@Component({
  selector: 'app-manage-employee',
  templateUrl: './manage-employee.component.html',
  styleUrls: ['./manage-employee.component.scss'],
})
export class ManageEmployeeComponent implements OnInit {
  public edit_info = 'edit';
  public u_ids:any="2";
  public docmentSubmitPopUp = false;
  public userData = <any>[];
  public userForm!: FormGroup;
  public scheduleForm!:FormGroup;
  public validateMinStart:any;
  public multiSearch : string = '';
  public validateMinEnd:any;
  public maxObjectStart: any;
  public startDate = '';
  public endDate = '';
  public effHour:number=0;
  public maxObjectEnd: any;
  public userLeaveBalance : any=0;
  public usedLeave : any=0;
  public objKey : any;
  public add_leave: any;
  public empID: string = '';
  public shiftList=<any>[];
  public userStatus: string = '';
  public dob: any;
  public saturday=0;
  public docStatus:any;
  public menuToggle ="";
  public getScheduleData=<any>[];
  public getScheduleDataList:any;
  finalDate = '';
  dobDate = <any>'';
  selectedRoles = <any>[];
  public roleName: any;
  public selectedRole = 0;
  public roleList = <any>[];
  public taskList = <any>[];
  public isShow=true;
  public activityData=<any>[];
  public currentDate=new Date();
  public userName: any;
  public hsl: any;
  public loadAllComponant = false;
  public d_key : any;
  public formData= new FormData();
  permissionData: Array<any> = [
    { name: 'Admin', value: 1, checked: false },
    { name: 'Manager', value: 2, checked: false },
    { name: 'Team Lead', value: 3, checked: false },
    { name: 'Team Member', value: 4, checked: false },
  ];
  public fileType = [
    'image/jpeg', //image
    'image/png', //image
    'image/gif', //image
    'application/pdf', //PDF
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //DOCX
  ];
  constructor(
    private ngxService: NgxUiLoaderService,
    private rest: RestService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public pageService: PagesService,
    private router: Router,
    private toaster: ToastrService 
  ) {}

  ngOnInit(): void {
    this.empID = this.route.snapshot.params['id'];
    this.getUserData(this.empID);
    /* Set validation */
    this.userForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      type: ['', [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      mobile: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      join_date: [null, [Validators.required]],
      dob: [null, [Validators.required]],
      country: [null, [Validators.required]],
      status: [null, [Validators.required]],
      designation: [null, [Validators.required]],
      role: [null],
    });
  }
  toggle_edit_button(val: any) {
   this.edit_info = val;
   if(val=='addShift'){
    this.getShiftList();
    this.getSchedule();
    this.scheduleForm = this.formBuilder.group({
      sunday: null,
      monday: null,
      tuesday: null,
      wednesday: null,
      thursday: null,
      friday: null,
      saturday: null,
    });
   }else if(val=='Activity'){
    let d = new Date();
    this.maxObjectStart = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.maxObjectEnd = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.validateMinStart={
      year: 2022,
      month: 9,
      day: 1,
    }
    this.validateMinEnd={
      year: 2022,
      month: 9,
      day: 1,
    }
    this.getActivity();
   }
  }
  /* Api call to get user data for updatation*/
  getUserData(id: string) {
    if (id != '' && id != undefined && id != null) {
      this.ngxService.start();
      this.rest.getData({ u_id: id }, 'getuser').subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.loadAllComponant = true;
          if (data.state == '200') {
            this.userData = data.data;
            this.userName = this.pageService.getFirstLetters(this.userData.name);
            this.hsl = this.pageService.convertNameToColor(this.userName);
            if (this.userData.status == 1) {
              this.userStatus = 'Active';
            } else {
              this.userStatus = 'Inactive';
            }
            this.taskList=this.userData.taskList;
            this.setFormValue();
            this.getLeaveBalance();
          }else{
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.loadAllComponant = true;
          this.toaster.warning("Something went wrong!!");
        },
      });
    }
  }
  /* Function helps to set db value to form filed */
  setFormValue() {
    let db_date = this.userData.dob.split('-');
    let j_date = this.userData.join_date.split('-');
    const dob = new NgbDate(
      parseInt(db_date[0]),
      parseInt(db_date[1]),
      parseInt(db_date[2])
    );
    const jd = new NgbDate(
      parseInt(j_date[0]),
      parseInt(j_date[1]),
      parseInt(j_date[2])
    );
    this.userForm.patchValue({
      name: this.userData.name,
      type: this.userData.type,
      email: this.userData.email,
      mobile: this.userData.mobile,
      country: this.userData.country,
      designation: this.userData.designation,
      status: this.userData.status,
      dob: dob,
      join_date: jd,
      role:this.userData.roles
    });
    if (this.userData.roles !== undefined) {
      if (this.userData.roles.length > 0) {
        for (let i = 0; i < this.userData.roles.length; i++) {
          this.selectedRoles.push(this.userData.roles[i]);
        }
      }
    }
  }
  /* Function helps to convert ngdate sort date string  */
  dateConvert(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    const convertDate = year + '-' + month + '-' + day;
    return convertDate;
  }
  /* Function helps to return checked role data for updation */
  permission_toggle(ch: any, e: any) {
    console.log(ch);
    console.log(e.target.checked);
    if (e.target.checked) {
      this.selectedRoles.push(ch);
      console.log(this.selectedRoles);
    } else {
      for (var i = 0; i < this.selectedRoles.length; i++) {
        if (this.selectedRoles[i] === ch) {
          this.selectedRoles.splice(i, 1);
        }
      }
    }
  }
  /* Function helps to checked user previous role */
  checkboxSelect(roleObj: any) {
    if (this.userData.roles !== undefined) {
      if (this.userData.roles.length == 0) {
        return false;
      } else {
        let permissionArray = this.userData.roles;
        return permissionArray.includes(roleObj);
      }
    }
  }
  docmentSubmit(){
    this.docmentSubmitPopUp = !this.docmentSubmitPopUp;
  }
  /* Api call call for updation */
  onSubmit(form: FormGroup) {
    if (form.invalid) {
      return;
    }
    this.ngxService.start();
    this.isShow=false;
    let apiPayload = { ...form.value };
    apiPayload.u_id = this.userData.u_id;
    apiPayload.dob = this.dateConvert(apiPayload.dob);
    apiPayload.role = this.selectedRoles;
    apiPayload.join_date = this.dateConvert(apiPayload.join_date);
    this.rest.getData(apiPayload, 'updateuser').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.isShow=true;
        if(data.state=='200'){
          this.toaster.success(data.message);
          this.getUserData(this.empID);
        }else{
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.isShow=true;
        this.ngxService.stop();
        this.toaster.warning('Something went wrong');
      },
    });
  }

 /* Getting user Leave Balance */
  getLeaveBalance(){
    let u_id = this.empID;
    if(u_id != ''){
      this.ngxService.start();
      this.rest.getData({u_id:u_id},'getleavebalance').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if(data.state == '200'){
            this.userLeaveBalance = data.data.remaining_leaves.balance;
            this.usedLeave = data.data.used_leaves;
            console.log(this.userLeaveBalance);
            console.log(this.usedLeave);
          }else{
            console.log(this.userLeaveBalance);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.warning('Something went wrong');
        },
      })
    }
  }

  /* Add Leave Balance */
  addLeaveBalance(){
    let leaveBal = this.add_leave;
    let e_id = this.empID;
    if(leaveBal < 25){
      if(leaveBal != '' && leaveBal != undefined){
      this.ngxService.start();
        this.rest.getData({leaveBal:leaveBal, e_id:e_id},'creditleave').subscribe({
          next: (data)=>{
            this.ngxService.stop();
            if(data.state == '200'){
              this.toaster.success(data.message);
              this.getLeaveBalance();
              this.add_leave = '';
            }else{
              this.toaster.warning(data.message);
            }
          },
          error: (e) => {
            this.ngxService.stop();
            this.toaster.warning('Something went wrong');
          },
        })
      }
    }else{
      this.toaster.warning("Maximum Amount is 24!");
    }
  }
  /* Api call for fetching shift list*/
  getShiftList(){
    if(this.shiftList.length==0){
      this.ngxService.start();
      this.rest.getData({}, 'getshiftlist').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.shiftList = data.data;
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }
  /* Api call for saving employee schedule */
  saveSchedule(form: FormGroup){
    let apiPayload = {schedule_arr:{},u_id:0};
    apiPayload.schedule_arr={ ...form.value }
    apiPayload.u_id = this.userData.u_id;
    this.ngxService.start();
    this.isShow=false;
    this.rest.getData(apiPayload, 'updateschedule').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.isShow=true;
        if(data.state=='200'){
          this.toaster.success(data.message);
        }else{
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.isShow=true;
        this.toaster.warning('Something went wrong');
      },
    });
  }
  /* Api call for fetching schedule list */
  getSchedule(){
    //if(this.getScheduleData.length==0){
      this.ngxService.start();
      this.rest.getData({}, 'getschedule').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            if(data.data.length>0){
              this.getScheduleDataList=data.data;
              this.getScheduleDataList.forEach((el:any) => {
                if(el.user_id==this.userData.id){
                  this.getScheduleData=el;
                  console.log(this.getScheduleData)
                  this.setScheduleForm();
                }
              });
            }
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.warning('Something went wrong');
        },
      });
    /* }else{
      console.log(this.shiftList)
      this.getScheduleDataList.forEach((el:any) => {
        if(el.user_id==this.userData.id){
          this.getScheduleData=el;
          console.log(this.getScheduleData)
          this.setScheduleForm();
        }
      });
    } */
  }
  
  /* This function is used to patch value to selected filed */
  setScheduleForm(){
    this.scheduleForm.patchValue({
      sunday: this.getScheduleData.sunday,
      monday: this.getScheduleData.monday,
      tuesday: this.getScheduleData.tuesday,
      wednesday: this.getScheduleData.wednesday,
      thursday: this.getScheduleData.thursday,
      friday: this.getScheduleData.friday,
      saturday: this.getScheduleData.saturday,
    });
  }
  toogleMobile(){
    this.menuToggle = "on";
  }
  docAction(ev:any){
    this.docStatus = ev;
  }
  /* Api calling for employee report */
  getActivity(startDate:string="",endDate:string=""){    
   // if(this.activityData.length==0){
      this.ngxService.start();      
      this.rest.getData({u_id:this.empID,startDate,endDate}, 'employeereport').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.activityData=data.data.data;
            this.effHour=data.data.total;
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.warning('Something went wrong');
        },
      });
   // }
  }

  dateWiseReport(){
    this.startDate = this.startDate==''?'':this.dateConvert(this.startDate);
    this.endDate = this.endDate==''?'':this.dateConvert(this.endDate);
    if(this.endDate!=='' && this.startDate!==''){
      this.getActivity(this.startDate,this.endDate);
    }
  }

  onDateSelect(ev:any,type=""){
    let d = new Date();
    if(type=='start'){
      this.validateMinEnd = {
        year: ev.year,
        month: ev.month,
        day: ev.day,
      };
    }else{
      this.maxObjectStart = {
        year: ev.year,
        month: ev.month,
        day: ev.day,
      };
    }
  }

  getoTimesheet() {
    this.router.navigate(['/time-sheet'], { queryParams: { u_id: this.empID } });
  }
  timeConvert(min:any,type="h"){
    return type=="h"?this.pageService.timeConvert(min):this.pageService.formatSeconds(min);
  }
  /* This function is used to push file data to formdata  */
  onFileChange(event:any){
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        let size = (event.target.files[i].size / 1024 / 1024) | 0;
        if (this.fileType.includes(event.target.files[i].type) && size <= 4) {
          this.formData.append(`${this.d_key}[]`, event.target.files[i]);          
        }
      }
      this.uploadFile();
    }
  }

  
  upload(key: any) {
    this.d_key=key
    document.getElementById('upload-input')?.click();
  }
  /* Api call for uploading employee document */
  uploadFile(){
    //console.log(key);
    if(this.formData.getAll('image[]').length!==0 || this.formData.getAll('identity_doc[]').length!==0 || this.formData.getAll('academic_doc[]').length!==0 || this.formData.getAll('employment_doc[]').length!==0){
      this.ngxService.start();
      this.isShow = false;
      this.formData.append(`user_id`, this.userData.id);
       this.rest.getData(this.formData, 'uploaddocument', true).subscribe({
         next: (data) => {
           this.ngxService.stop();
           this.isShow = true;
           this.getUserData(this.empID);
           if (data.state == '200') {
             this.toaster.success(data.message);
           } else {
             this.toaster.warning(data.message);
           }
         },
         error: (e) => {
           this.ngxService.stop();
           this.isShow = true;
           this.toaster.warning('Something went wrong');
         },
       });
    }else{
      this.toaster.warning("Document list is empty!!!");
    }
  }
  /* Api call for deleting employee document */
  deleteDoc(param:any){
    if(param!=''){
      this.ngxService.start();
       this.rest.getData({doc_id:param.id,user_id:param.user_id}, 'deletedocuments').subscribe({
         next: (data) => {
           this.ngxService.stop();
           if (data.state == '200') {
              this.toaster.success(data.message);
              this.getUserData(this.empID);
           } else {
             this.toaster.warning(data.message);
           }
         },
         error: (e) => {
           this.ngxService.stop();
           this.toaster.warning('Something went wrong');
         },
       });
    }
  }

  searchType(event: any) {
    console.log(event);
    let searchkey = event.target.value;
    if (searchkey.length > 1) {
      this.objKey = searchkey;
    } else if (searchkey.length == 0) {
      this.objKey = searchkey;
    }
  }

  encodeURIParam(taskUrl: any) {
    if(taskUrl !== '' || taskUrl !== null)
    {
      return taskUrl.replace('/', '_');
    }
  }
}
