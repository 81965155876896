<div class="main-content" *ngIf="isDataLoaded;else showLoader">
  <div class="ctm-border-radius  grow card">
    <div id="basic1" class="card-header d-flex align-center justify-content-between">
      <div class="card_title_left">
        <h4 class="card-title  mb-0 mt-0">Attendance List</h4>
      </div>
      <div class="card_right_ d-flex align-items-center justify-content-end">
        <div class="filter_contetn">
          <div class="filter_drop_down ">
            <div class="input-group">
              <div class="form-outline col-md-12">
                <input type="text" class="form-control" (keyup)="searchType($event)" placeholder="Search" aria-label="Search"
                  [(ngModel)]="multiSearch" id="searchdata">
              </div>
            </div>
            <button class="custom-btn btn-8 text-center" (click)="filter_status_btn()"><span>
              <i class="las la-filter"></i>Filters</span></button>
          </div>
          <div class="drop_down_filter_list d-flex justify-content-end" [ngClass]="filter_status?'on':''">
            <div class="input-group mb-3">
              <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="countrySearch"
                (change)="deSelect($event,'country')" placeholder="Country">
                <ng-option *ngFor="let country of countryFilterList" [value]="country">
                  {{country}}
                </ng-option>
              </ng-select>
            </div>
            <div class="input-group mb-3">
              <input type="text" ngbDatepicker #dpStart="ngbDatepicker" name="dpStart" (click)="dpStart.toggle()" class="form-control" placeholder="Select date" (dateSelect)="onDateSelect($event)" [maxDate]="maxObject">
          </div>
          </div>
        </div>
      </div>
  </div>
    <div class="card-body">
      <div class="table-back employee-office-table">
        <div class="infocolorcheck">
          <div class="colorcontent">
            <div class="colorbx bg-success"></div>
            <label for="">leave </label>
          </div>
          <div class="colorcontent">
            <div class="colorbx bg-warning"></div>
            <label for="">week off </label>
          </div>
          <div class="colorcontent">
            <div class="colorbx bg-danger"></div>
            <label for="">late </label>
          </div>
        </div>
        <div class="table-responsive">
          <table class="table custom-table table-hover table-hover" id="empolyeeId">
            <thead>
              <tr>
                <th>Sl_no</th>
                <th>Name</th>
                <th>Country</th>
                <th>Shift</th>
                <th>Shift In-time</th>
                <th>Shift Out-time</th>
                <th>In-time</th>
                <th>Out-time</th>
              </tr>
            </thead>
            <tbody *ngIf="attendanceList!==null && attendanceList.length>0 ; else noDataAvailable">
              <ng-container *ngFor="let list of attendanceList | tableFilter:searchKey : selectedfield | tableSearch : objKey ; index as i">
                <tr [ngClass]="(list.applied_status=='LeaveDay'?'bg-success dtextchnage':'') || (list.late_status!==''?'bg-danger dtextchnage text-white':'')">
                  <td><span>{{i+1}}</span></td>
                  <td><a [routerLink]="['/attendance-details/',list.u_id]"><span>{{list.name}}</span></a></td>
                  <td><span>{{list.country}}</span></td>
                  <td><span>{{list.shift_name}}</span></td>
                  <td><span>{{list.shift_inTime}}</span></td>
                  <td><span>{{list.shift_outTime}}</span></td>
                  <td><span>{{list.inTime}}</span></td>
                  <td><span>{{list.outTime}}</span></td>
                </tr>
              </ng-container>
            </tbody>
            <ng-template #noDataAvailable>
              <tr class="text-center">
                <td colspan="8">No Data Available!!</td>
              </tr>
            </ng-template>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
<ng-template #showLoader>
<ngx-ui-loader></ngx-ui-loader>
</ng-template>