import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { LocalStorageService } from 'ngx-webstorage';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-work-reports',
  templateUrl: './work-reports.component.html',
  styleUrls: ['./work-reports.component.scss']
})
export class WorkReportsComponent implements OnInit {

  public range        = '';
  public reportList   : any = [];
  public itemList     = <any>[];
  public workingHourReport   : any = [];
  public year         = new Date().getFullYear();
  public dte = new Date();
  public userList     : any;
  public sheetList    : any;
  public payload      : any;
  public selectedUser = '';
  public empId        = '';
  public rangeTitle   = 'today';
  public viewType     = 'general';
  public userRole     = '';
  public hsl          : any;
  public taskRowId    = 0;
  public userRoles: any = [];
  public empList      = <any>[];
  public taskOverviewData: any = [];
  public assignedEmp     : any = [];
  public timeSheetEffInfo = <any>[];
  public total_effort     = 0;
  public taskTimeList     = <any>[];
  public timesheetTab  = false;
  public taskTab       = false;
  public serverTime = '';
  public offset = (new Date().getTimezoneOffset());
  public apiDates: any = {};
  public taskObj = {
    t_id:''
  };
  public dates = {
    day1:new Date().setDate(new Date().getDate() - 0),
    day2:new Date().setDate(new Date().getDate() - 1),
    day3:new Date().setDate(new Date().getDate() - 2),
    day4:new Date().setDate(new Date().getDate() - 3),
    day5:new Date().setDate(new Date().getDate() - 4),
    day6:new Date().setDate(new Date().getDate() - 5),
    day7:new Date().setDate(new Date().getDate() - 6)
  }

  constructor(
    public rest:RestService,
    private modalService: NgbModal,
    private localSt: LocalStorageService,
    public pageService : PagesService,
    public toaster: ToastrService,
    public ngxService: NgxUiLoaderService
  ) { }

  public open(modal: any,id:any): void {
    
    this.taskTab = false;
    this.timesheetTab = false;
    this.timesheetTab = !this.timesheetTab;
    if (id == '' || id == undefined || id == null) {
      return;
    } else {
      this.payload = {
        taskID: id,
      };
      this.itemList = [];
      this.rest.getData(this.payload, 'gettasktnfo').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.taskRowId = data.data.taskRowId;
            this.taskOverviewData = data.data;
            let tempArr = [];
            for(let user of this.taskOverviewData.asigned_data){
              tempArr.push(user.name);
            }
            this.taskOverviewData.projectUsers.forEach((element: any) => {
              let tmpObj    = { id: element.id, name: element.name };
              this.itemList.push(tmpObj);
            });
            // this.empList = this.pageService.loadMore(
            //   this.taskOverviewData.asigned_data
            // );
            this.empList = this.taskOverviewData.asigned_data.map((value:any) => value.name);
            this.timesheetTab = false;
            this.taskTab = true;
            this.assignedEmp = tempArr;
            this.modalService.open(modal,{ windowClass: 'test-class' });
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }

  getTaskData(id: string) {
    if (id == '' || id == undefined || id == null) {
      return;
    } else {
      this.payload = {
        taskID: id,
      };
      this.itemList = [];
      this.rest.getData(this.payload, 'gettasktnfo').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.taskRowId = data.data.taskRowId;
            // this.getComments(this.taskRowId);
            this.taskOverviewData = data.data;
            let tempArr = [];
            for(let user of this.taskOverviewData.asigned_data){
              tempArr.push(user.name);
            }
            this.taskOverviewData.projectUsers.forEach((element: any) => {
              let tmpObj    = { id: element.id, name: element.name };
              this.itemList.push(tmpObj);
            });
            this.empList = this.pageService.loadMore(
              this.taskOverviewData.asigned_data
            );
            this.assignedEmp = tempArr;
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }

  getTimeSheetData(id: string) {
    this.timesheetTab = false;
    this.taskTab = false;
    if (id != '' && id != undefined && id != null){
      this.rest.getData({ t_id: id }, 'getsingletimedetails').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.taskTab = false;
            this.timesheetTab = true;
            this.taskTimeList = data.data.timeSheet;
            this.timeSheetEffInfo = data.data.effortData;
            this.total_effort = data.data.total_effort;
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }

  ngOnInit(): void {
    this.apiDates = this.pageService.getFormattedDates();
    this.userRoles = [];
    this.localSt.observe('user').subscribe((value) => {
      let udata = this.localSt.retrieve('user');
    });
    let userData = this.localSt.retrieve('user');
    this.userRoles = userData.data.roles;
    this.getWorkReport("today");
    this.getAllEmployees();
    this.toggleTab('task');
  }

  getWorkReport(type = '',id:any = '') {
    console.log("-",type);
    this.ngxService.start();
    if (type !== undefined) {
      let uid = (id !== '' && id !== undefined) ? this.empId : '';
      let endD;
      let startD = this.apiDates.today;
      switch (type) {
        case 'yesterday':
          endD = this.apiDates.yesterday;
          break;
        case 'week':
          endD = this.apiDates.lastWeek;
          break;
        case 'month':
          endD = this.apiDates.lastMonth;
          break;
        case 'year':
          endD = this.apiDates.lastYear.today;
          break;
        default:
          endD = this.apiDates.today;
      }
      this.rangeTitle = type;
      let payload = {
        startDate: startD,
        endDate:endD,
        u_id: uid
      };
      this.rest.getData(payload , 'viewalltimesheets').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if(data.state = "200"){
            this.serverTime = data.server_time;
            console.log('front end-',this.dte);
            console.log('server end-',this.serverTime);
            this.reportList = data.data;
          }else{
            this.toaster.warning("No data found!");
          }
        },
        error: (e) => {
          console.log(e);
          // this.toaster.error('Something went wrong!');
        },
      });
    }
  }

  getEmpWorkingHours(type = '',id:any = '') {
    this.ngxService.start();
    let uid = '';
    if(id !== '' || id !== undefined){
      uid = this.empId;
    }
    this.rangeTitle = type;
    let payload = {
      range : type,
      u_id  : uid
    }
      this.rest.getData(payload , 'empworkinghoursreport').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if(data.state = "200"){
            this.workingHourReport = data.data;
            this.userRole = data.role;
          }else{
            this.toaster.warning("No data found!");
          }
        },
        error: (e) => {
          console.log(e);
          // this.toaster.error('Something went wrong!');
        },
      });
  }

  getKey(item: any): string {
    return Object.keys(item)[0];
  }

  getValue(item: any): string {
    let sum = 0;
    return item[this.getKey(item)][0]?.hours || '0';
  }

  //--------------------------------------------------search and filter ------------------------------------------
  
  // getting all users
  getAllEmployees(){
    this.rest.getData({}, 'getemployees').subscribe({
      next: (data) => {
        this.userList = data.data.filter((obj:any) => obj.status === 1 && obj.name !== 'Unassaigned');
      },
      error: (e) => {
        //this.toaster.error('Something wend wrong!');
      },
    })
  }

  changeUser(id: any) {
    if(id == '' || id == undefined || id == "All"){
      this.empId = '';
      this.getWorkReport("today",this.empId);
    }else{
      this.empId = id.target.value
      this.getWorkReport("today",this.empId);
    }
  }

  reportType(type:any)
  {
    if(type == 'detail'){
      this.getEmpWorkingHours();
    }
    this.viewType = type;
  }

  encodeURIParam(param:any){
    return param.replace('/','_');
  }

  convertColor(string:any) {
    this.hsl = this.pageService.convertNameToColor(string);
  }

  convertToLetter(letter:any) {
    this.convertColor(letter);
    return this.pageService.getFirstLetters(letter);
  }

  toggleTab(tab:any=''){
    if(tab == 'task'){
      this.taskTab = true;
      this.timesheetTab = false;
    }else if(tab == 'timesheet'){
      this.getTimeSheetData(this.payload.taskID);
      this.timesheetTab = true;
      this.taskTab = !false;
    }
  }

  userIconTrimmer(num:any){
    return num - 3;
  }


}
