import { Component, OnInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { formatDate } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import { RestService } from 'src/app/rest.service';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-reminder-history',
  templateUrl: './reminder-history.component.html',
  styleUrls: ['./reminder-history.component.scss']
})
export class ReminderHistoryComponent implements OnInit {
  public getReminders: any;
  public loadAllComponant = false;
  constructor(
    public rest: RestService, 
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private localSt: LocalStorageService,
    private toaster: ToastrService
    ) { }

  ngOnInit(): void {
    this.getReminderLog();
  }

 /* This function used to fetch reminder list */
  getReminderLog(){
    this.rest.getData({}, 'getreminderhistory').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        this.getReminders = data.data;
        console.log(this.getReminders);
      }
    })
  }

}
