import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { AlertService } from 'src/app/alert.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { formatDate,DatePipe} from '@angular/common';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-announcements',
  templateUrl: './announcements.component.html',
  styleUrls: ['./announcements.component.scss']
})
export class AnnouncementsComponent implements OnInit {

  public announcementForm!: FormGroup;
  public maxDateLimit: any;
  public minDateLimit: any;
  public announcementList: any = [];
  public todayDate:any;
  public getToday : any = new Date();
  public sidePanel  = false;
  public currentUrl = '';
  public minDate: any;
  public finalDate = '';
  public eventRow     : any;
  public modalReference = '';
  public loggedInUserName: string = '';

  constructor(
    private modalService: NgbModal,
    public rest: RestService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    private toaster: ToastrService
  ) {
    let d = new Date();
    this.minDate = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
  }

  ngOnInit(): void {
    this.getUserData();
    this.getAnnouncementList();
    this.announcementForm = this.formBuilder.group({
      announcement_type: [null, [Validators.required]],
      date: [null, [Validators.required]],
      description: [null, [Validators.required]],
    });

    this.router.events.subscribe((res) => {
      if (this.router.url == '/login') {
        this.currentUrl = 'login';
      } else {
        this.currentUrl = this.router.url.replace('/', '');
      }
    });
  }
  
  getUserData() {
    this.rest.getData({}, 'getloginuser').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.loggedInUserName = data.data.name;
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning("Authorization error!!");
      },
    });
  }

  public open(modal: any): void {
    this.modalService.open(modal);
  }

  getAnnouncementList(){
    this.rest.getData({},"getannouncementlist").subscribe({
      next: (data) => {
        console.log("ann",data);
        if (data.state == '200') {
            this.announcementList = data.data;
        } else {
          console.log(data.message);
          //this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        console.log(e);
      },
    })  
  }

  submitAnnouncementForm(form: FormGroup) 
  {
    if (form.invalid) {
      return;
    } else {
      let apiPayload = { ...form.value };
        let year = apiPayload.date.year;
        let month = apiPayload.date.month <= 9 ? '0' + apiPayload.date.month : apiPayload.date.month;;
        let day = apiPayload.date.day <= 9 ? '0' + apiPayload.date.day :apiPayload.date.day;;
        apiPayload.date = year + "-" + month + "-" + day;
      console.log(apiPayload);
      this.rest.getData(apiPayload, "submitannoucement").subscribe({
        next: (data) => {
          console.log(data);
          if (data.state == '200') {
            this.toaster.success(data.message);
            this.getAnnouncementList();
            form.reset();
          } else {
            this.toaster.error(data.message, '', {
              timeOut: 1500,
            });
          }
        },
        error: (e) => {
          console.log(e);
          this.toaster.error('Something went wrong');
        },
      })
    }
  }

  removeAnnouncement(id: any) {
    Swal.fire({
      title: 'Are you sure?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      confirmButtonText: 'Yes',
      width: 400,
    }).then((result) => {
      if (result.isConfirmed) {
        if (id != '' && id != undefined) {
          this.rest.getData({ a_id: id }, 'deleteannoucement').subscribe({
            next: (data) => {
              if (data.state == '200') {
                this.eventRow = data.data;
                this.toaster.success(data.message)
                this.getAnnouncementList();
              } else {
                this.toaster.error(data.message)
              }
            }
          })
        }
      }
    })
  }

}
