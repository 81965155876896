import { Component, OnInit } from '@angular/core';
import { Subject, Observable } from 'rxjs';
import { WebcamImage, WebcamInitError, WebcamUtil } from 'ngx-webcam';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from 'src/app/rest.service';
import { ToastrService } from 'ngx-toastr';
import { ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-attendance',
  templateUrl: './attendance.component.html',
  styleUrls: ['./attendance.component.scss'],
})
export class AttendanceComponent implements OnInit {
  private trigger = new Subject<any>();
  public webcamImage!: WebcamImage;
  private nextWebcam = new Subject<any>();
  captureImage = '';
  blankImg = '';
  public onLoadData: any;
  public currentDateTime = new Date();
  public errors: WebcamInitError[] = [];
  public loadAllComponant = false;
  public loggedInUserName: string = '';
  constructor(
    public rest: RestService,
    private toaster: ToastrService,
    private ngxService: NgxUiLoaderService
  ) {}
  ngOnInit(): void {
    this.getUserData();
    this.checkInTimeData();
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        if (mediaDevices.length == 1 && mediaDevices[0].deviceId === '') {
          Swal.fire({
            title: 'Please turn on camera!',
            icon: 'warning',
            iconColor:"#ea556f",
            showConfirmButton: true,
            allowOutsideClick: false,
            allowEscapeKey: false,
            confirmButtonText: 'Recheck',
          }).then((result) => {
            if (result.isConfirmed) {
              window.location.reload();
            }
          });
        }
      }
    );
  }
  public triggerSnapshot(typ = ''): void {
    this.trigger.next('any');
    WebcamUtil.getAvailableVideoInputs().then(
      (mediaDevices: MediaDeviceInfo[]) => {
        if (mediaDevices.length >= 1 && mediaDevices[0].deviceId !== '') {
          if (typ == '') {
            this.attendance();
          } else {
            Swal.fire({
              title: 'Are you sure?',
              icon: 'warning',
              showCancelButton: true,
              confirmButtonColor: '#3085d6',
              cancelButtonColor: '#ea556f',
              iconColor:"#ea556f",
              confirmButtonText: 'Yes',
            }).then((result) => {
              if (result.isConfirmed) {
                this.attendance();
              }
            });
          }
        } else {
          this.toaster.error('Please on camera!!!');
        }
      }
    );
  }
  public handleImage(webcamImage: WebcamImage): void {
    this.webcamImage = webcamImage;
    this.captureImage = webcamImage!.imageAsDataUrl;
  }
  public get triggerObservable(): Observable<any> {
    return this.trigger.asObservable();
  }
  public get nextWebcamObservable(): Observable<any> {
    return this.nextWebcam.asObservable();
  }
  /* Api call for checking has intime or not */
  checkInTimeData() {
    this.ngxService.start();
    this.rest.getData({}, 'checkintimeattend').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.onLoadData = data.data;
        } else {
          this.onLoadData = data.data;
          if (
            this.onLoadData.isShowPunchIn === false &&
            this.onLoadData.isShowPunchOut === false
          ) {
            this.toaster.error(data.message);
          }
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        this.toaster.error('Something went wrong');
      },
    });
  }
  /* Puch in and punch out attendance of user */
  attendance() {
    if (this.captureImage.length > 3543) {
      this.ngxService.start();
      this.rest
        .getData({ img_data: this.captureImage }, 'addattendance')
        .subscribe({
          next: (data) => {
            this.ngxService.stop();
            if (data.state == '200') {
              this.onLoadData = data.data;
              this.checkInTimeData();
              this.toaster.success(data.message);
            } else {
              this.toaster.error(data.message);
            }
          },
          error: (e) => {
            console.log(e);
            this.toaster.error('Something went wrong');
            this.ngxService.stop();
          },
        });
    }else{
      this.toaster.error('Please on camera!!!');
    }
  }

  getUserData() {
      this.rest.getData({}, 'getloginuser').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.loggedInUserName = data.data.name;
          }else{
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.toaster.error("Something went wrong!!");
        },
      });
  }

  ngOnDestroy() {    
    Swal.close();
  }
}
