import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-leave-employee',
  templateUrl: './leave-employee.component.html',
  styleUrls: ['./leave-employee.component.scss']
})
export class LeaveEmployeeComponent implements OnInit {
  
  public tabval = 'apply_leave';

  constructor() { }

  ngOnInit(): void {
  }
  changeTab(tab: any) {
    if (tab == 'applied_leave') {
      this.tabval = 'applied_leave';
    } else if (tab == 'approved_leave') {
      this.tabval = 'approved_leave';
    } else if (tab == 'rejected_leave') {
      this.tabval = 'rejected_leave';
    }else if (tab == 'apply_leave') {
      this.tabval = 'apply_leave';
    }
  }

}
