import { Component, OnInit ,HostListener} from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-module',
  templateUrl: './module.component.html',
  styleUrls: ['./module.component.scss'],
})
export class ModuleComponent implements OnInit {
  public showloader: boolean = false;
  public moduleList= <any>[];
  public error = false;
  public filter_status = false;
  public searchKey = '';
  public selectedfield = '';
  public nameSearch: any;
  public typeSearch: any;
  public statusSearch: any;
  public projectSearch: any;
  public nameFilterList = <any>[];
  public typeFilterList = <any>[];
  public statusFilterList = <any>[];
  public projectFilterList = <any>[];
  public objKey: any;
  public multiSearch: string = '';
  public loadAllComponant = false;
  public scrolling: any;
  public paginateData: any;
  public showEmp = true;
  public lastPage = true;
  public offset = <any>0;
  public limit = <any>100;
  public addDataToArray: any;
  public isTrue = true;
  public scrollList = true;

  constructor(
    public rest: RestService,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.getModuleUser();
  }
  /* This function used to fetch module list */
  getModuleUser(key = '') {
    let key1 = '';
    if(key !== '' || key !== undefined){
      key1 = key;
    }
    this.rest.getData({offset: this.offset, limit: this.limit,keywords:key1}, 'getallmodule2').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.moduleList = [...this.moduleList,...data.data];
          this.createFilterData();
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.loadAllComponant = true;
        //this.toaster.error('Something went wrong');
      },
    });
  }

  onScroll(){
    if(this.scrollList == true){  
    this.limit = 100;
    this.offset += 100;
    console.log("==",this.offset);
    this.getModuleUser();
    }
  }

  filter_status_btn() {
    this.filter_status = !this.filter_status;
  }

  deSelect(event: any, filedType: string) {
    this.selectedfield = filedType;
    if (filedType === 'm_name') {
      this.typeSearch    = undefined;
      this.statusSearch  = undefined;
      this.projectSearch = undefined;
      this.searchKey = this.nameSearch;
    } else if (filedType === 'm_type') {
      this.nameSearch    = undefined;
      this.statusSearch  = undefined;
      this.projectSearch = undefined;
      this.searchKey     = this.typeSearch;
    } else if (filedType === 'm_status') {
      this.typeSearch    = undefined;
      this.nameSearch    = undefined;
      this.projectSearch = undefined;
      this.searchKey = this.statusSearch;
    } else if (filedType === 'p_name') {
      this.statusSearch  = undefined;
      this.typeSearch    = undefined;
      this.nameSearch    = undefined;
      this.searchKey     = this.projectSearch;
      console.log("key",this.searchKey);
    }
  }

  createFilterData() {
    let mNameList    = [];
    let mTypeList    = [];
    let mStatusList  = [];
    let mProjectList = [];

    for (let module of this.moduleList) {
      mNameList.push(module.m_name);
      mTypeList.push(module.m_type);
      mStatusList.push(module.m_status);
      mProjectList.push(module.p_name);
    }
    this.nameFilterList    = [...new Set(mNameList)];
    this.typeFilterList    = [...new Set(mTypeList)];
    this.statusFilterList  = [...new Set(mStatusList)];
    this.projectFilterList = [...new Set(mProjectList)];
  }

  searchType(event: any) {
    let searchkey = event.target.value;
    if (searchkey.length > 3) {
      this.scrollList = false;
      this.findModuleUser(searchkey);
    } else if (searchkey.length == 0) {
      this.scrollList = true;
      this.moduleList = [];
      this.getModuleUser();
    }
  }

  findModuleUser(key = '') {
    let key1 = '';
    if(key !== '' || key !== undefined){
      key1 = key;
    }
    this.rest.getData({keywords:key1}, 'getallmodule2').subscribe({
      next: (data) => {;
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.moduleList = [];
          this.moduleList = data.data;
          this.createFilterData();
        } else {
          console.log(data.message);
          //this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.loadAllComponant = true;
        //this.toaster.error('Something went wrong');
      },
    });
  }

  encodeURIParam(param:any){
    return param.replace('/','_');
  }
}
