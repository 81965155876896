<div class="card">
    <div id="basic1" class="card-header d-flex align-center justify-content-between">
        <div class="card_title_left">
          <h4>Attachments</h4>
        </div>
        <div class="card_right_ d-flex align-items-center justify-content-end">
            <div class="filter_contetn">
                <div class="filter_drop_down ">
                    <div class="input-group">
                        <div class="form-outline">
                            <input type="text" class="form-control" (keyup)="searchType($event)" placeholder="Search"
                                aria-label="Search" [(ngModel)]="multiSearch" id="searchdata">
                        </div>
                    </div>
                    <button class="custom-btn btn-8 text-center" (click)="filter_status_btn()"><span><i
                                class="las la-filter"></i> Filters</span></button>
                </div>
                <div class="drop_down_filter_list d-flex justify-content-end" [ngClass]="filter_status?'on':''">
                    <div class="input-group mb-3">
                        <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true"
                            [(ngModel)]="nameSearch" id="name" (change)="deSelect($event,'name')" placeholder="Name">
                            <ng-option *ngFor="let name of nameFilterList" [value]="name">
                                {{name}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
                <div class="drop_down_filter_list d-flex justify-content-end" [ngClass]="filter_status?'on':''">
                    <div class="input-group mb-3">
                        <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true"
                            [(ngModel)]="dateSearch" id="created_at" (change)="deSelect($event,'created_at')"
                            placeholder="Date">
                            <ng-option *ngFor="let created_at of dateFilterList" [value]="created_at">
                                {{created_at}}
                            </ng-option>
                        </ng-select>
                    </div>
                </div>
            </div>
            <div class="flex-shrink-0">
                <input type="file" class="d-none" id="upload-input" (change)="uploadCsv($event)">
                <button type="button" class="btn btn-success text-white btn-sm" *ngIf="this.isShow; showBtn"
                    (click)="upload()">
                    <i class="las la-file-upload"></i> Upload</button>
                <ng-template #showBtn>
                    <button type="button" class="btn btn-success text-white btn-sm" disabled>
                        <i class="las la-file-upload"></i> Upload</button>
                </ng-template>
            </div>
        </div>
    </div>
    <div class="card-body">
     
        <div class="table-back employee-office-table">
            <div class="table-responsive">
                <table class="table custom-table table-hover table-hover" id="empolyeeId">
                    <thead>
                        <tr>
                            <th>Id</th>
                            <th>Emp Code</th>
                            <th>name</th>
                            <th>In time</th>
                            <th>Out time</th>
                            <th>timeZone</th>
                            <th>Date</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngIf="attendanceList==undefined || attendanceList==''; else dataTable">
                            <tr>
                                <td class="text-center" colspan="6">No Data Found!!</td>
                            </tr>
                        </ng-container>
                        <ng-template #dataTable>
                            <tr
                                *ngFor="let attendance of attendanceList | tableFilter:searchKey : selectedfield | tableSearch:objKey; index as i">
                                <td>{{i+1}}</td>
                                <td>{{attendance.user_id}}</td>
                                <td>{{attendance.emp_name}}</td>
                                <td>{{attendance.in_time}}</td>
                                <td>{{attendance.out_time}}</td>
                                <td>{{attendance.timeZone}}</td>
                                <td>{{attendance.date}}</td>
                            </tr>
                        </ng-template>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>