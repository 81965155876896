import { Component, OnInit ,HostListener} from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { ToastrService } from 'ngx-toastr';
@Component({
  selector: 'app-recurring-task',
  templateUrl: './recurring-task.component.html',
  styleUrls: ['./recurring-task.component.scss']
})
export class RecurringTaskComponent implements OnInit {
  
  public showloader:boolean = false;
  public taskList=<any>[];
  public nextPage = <any>[];
  public error = false;
  public filter_status = false;
  public projectSearch:string = '';
  public filterData:any;
  public searchKey = ''; 
  public nameSearch:any;
  public typeSearch:any;
  public statusSearch:any;   
  public selectedfield=''; 
  public nameFilterList = <any>[];
  public typeFilterList = <any>[];
  public statusFilterList = <any>[];
  public objKey: any;
  public multiSearch: string = '';
  public recurringTaskListObj: any = []
  public recurringTaskList: any = []
  public loadAllComponant = false;
  public scrolling: any;
  public paginateData: any;
  public showEmp = true;
  public lastPage = true;
  public offset = <any>0;
  public limit = <any>15;
  public addDataToArray: any;
  public isTrue = true;
  public currentPosition = window.pageYOffset;

  constructor(
    public rest: RestService,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.getRecurringTasks();
  }

  /* This function used to fetch recurring task list */
  getRecurringTasks() {
    //this.ngxService.start();
    this.rest.getData({offset: this.offset, limit: this.limit}, "getrecurringtasklist").subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.recurringTaskList = [...this.recurringTaskList,...data.data];
          this.createFilterData();
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        console.log(e);
        this.toaster.error('Something went wrong');
      },
    })
  }

  createFilterData() {
    let tnameFilterList = [];
    let ttypeFilterList = [];
    let tstatusFilterList = [];
    for (let task of this.recurringTaskList) {
      tnameFilterList.push(task.task_name);
      ttypeFilterList.push(task.task_type);
      tstatusFilterList.push(task.task_status);
    }
    this.nameFilterList   = [...new Set(tnameFilterList)];
    this.typeFilterList   = [...new Set(ttypeFilterList)];
    this.statusFilterList = [...new Set(tstatusFilterList)];
  }

  onScroll(){
    console.log("calling");
    this.limit = 15;
    this.offset += 15;
    console.log("==",this.offset);
    this.getRecurringTasks();
  }

  filter_status_btn(){
    this.filter_status = !this.filter_status;
  }

  searchType(event: any) {
    let searchkey = event.target.value;
    if (searchkey.length > 2) {
      this.objKey = searchkey
    } else if (searchkey.length == 0) {
      this.objKey = searchkey;
    }
  }

  deSelect(event:any,filedType:string){
    this.selectedfield = filedType;
    if(filedType==='task_name'){
      this.typeSearch=undefined;
      this.statusSearch=undefined;
      this.searchKey = this.nameSearch;
    }else if(filedType==='task_type'){
      this.nameSearch=undefined;
      this.statusSearch=undefined;
      this.searchKey = this.typeSearch;     
    }else if(filedType==='task_status'){
      this.nameSearch=undefined;
      this.typeSearch=undefined;
      this.searchKey = this.statusSearch;
    }    
  }

}
