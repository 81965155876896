<div class="main-content" *ngIf="loadAllComponant">
  <div class="quicklink-sidebar-menu ctm-border-radius  bg-white  grow">
    <div class="ctm-border-radius  grow card" *ngIf="taskList != undefined || taskList != null || taskList !='' ">
      <div id="basic1" class="card-header d-flex align-center justify-content-between">
        <div class="card_title_left">
          <h4 class="card-title  mb-0 mt-2" *ngIf="taskList.length > 0;else title"> <b><i
            class="las la-info-circle"></i>{{taskList.length}} Tasks Available </b></h4>
      <ng-template #title>
        <h4 class="card-title  mb-0 mt-2"> <b><i class="las la-info-circle"></i> No Project Available Right Now</b></h4>
      </ng-template>
        </div>
        <div class="card_right_ d-flex align-items-center justify-content-end">
          <div class="filter_contetn">
            <div class="filter_drop_down align-center justify-content-between ">
              <div class="form-outline col-md-6">
                <input type="text" class="form-control" (keyup)="searchType($event)" placeholder="Search"
                  aria-label="Search" [(ngModel)]="multiSearch" id="searchdata">
              </div>
              <button class="custom-btn btn-8 text-center" (click)="filter_status_btn()"><span><i class="las la-filter"></i>
                  Filters</span></button>
            </div>
            <div class="drop_down_filter_list d-flex justify-content-end" [ngClass]="filter_status?'on':''">
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="nameSearch"
                  (change)="deSelect($event,'task_name')" placeholder="Task Name">
                  <ng-option *ngFor="let task_name of nameFilterList" [value]="task_name">
                    {{task_name}}
                  </ng-option>
                </ng-select>
              </div>
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="typeSearch"
                  (change)="deSelect($event,'task_type')" placeholder="Task Type">
                  <ng-option *ngFor="let task_type of typeFilterList" [value]="task_type">
                    {{task_type}}
                  </ng-option>
                </ng-select>
              </div>
              <div class="input-group mb-3">
                <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="statusSearch"
                  (change)="deSelect($event,'task_status')" placeholder="Task Status">
                  <ng-option *ngFor="let task_status of statusFilterList" [value]="task_status">
                    {{task_status}}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
              <a routerLink="/create-task" class="custom-btn btn-8 text-center d-flex">
                <span>
                  <i class="las la-plus"></i>Add Task
                </span>
              </a>
        </div>
    </div>
    
      <div class="card-body">
        <div class="table-back employee-office-table">
          <div class="table-responsive" infiniteScroll
          [infiniteScrollDistance]="2"
          [infiniteScrollThrottle]="500"
          (scrolled)="onScroll()"
          [scrollWindow]="false">
            <table class="table custom-table table-hover table-hover" id="empolyeeId">
              <thead>
                <tr>
                  <th>SL_No</th>
                  <th>Task Name</th>
                  <th>Project Name</th>
                  <th>Module Name</th>
                  <th>Task Type</th>
                  <th class="text-center">Status</th>
                  <th>Updated At</th>
                  <th>Assigned By</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngIf="taskList?.length < 1">
                  <td></td>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td colspan="5">No Data Found!</td>
                </tr>
                <tr *ngFor="let taskLists of taskList | tableFilter:searchKey : selectedfield | tableSearch : objKey; index as i">
                  <td>{{i+1}}</td>
                  <td class="text-wrap w-50"><a class="" [routerLink]='["/manage-task/",encodeURIParam(taskLists.t_id)]'>
                    {{taskLists.task_name|titlecase}}
                  </a></td>
                  <td>{{taskLists.project_name|titlecase}}</td>
                  <td>{{taskLists.module_name|titlecase}}</td>
                  <td>{{taskLists.task_type|titlecase}}</td>
                  <td class="text-center">
                    <span class="badge alert-success"
                      *ngIf="taskLists.task_status=='finish'">{{taskLists.task_status|titlecase}}</span>
                    <span class="badge alert-primary"
                      *ngIf="taskLists.task_status=='active'">{{taskLists.task_status|titlecase}}</span>
                    <span class="badge alert-danger"
                      *ngIf="taskLists.task_status=='inactive'">{{taskLists.task_status|titlecase}}</span>
                    <span class="badge alert-warning text-dark"
                      *ngIf="taskLists.task_status=='stop'">{{taskLists.task_status|titlecase}}</span>
                    <span class="badge alert-warning text-dark"
                      *ngIf="taskLists.task_status=='unassigned'">{{taskLists.task_status|titlecase}}</span>
                  </td>
                  <td>{{taskLists.updated_at | date}}</td>
                  <td>{{taskLists.assign_by|titlecase}}</td>
                  <td>
                    <a [routerLink]='["/manage-task/",encodeURIParam(taskLists.t_id)]'>
                      <span class=" edit text-success text-center"><i class="las la-edit"></i></span>
                    </a>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>