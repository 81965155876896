<div class="quicklink-sidebar-menu ctm-border-radius  bg-white card grow">
  <div class="card-body d-flex justify-content-between align-items-center">
    <ul class="list-group list-group-horizontal-lg">
      <li class="list-group-item text-center  button-5"><a routerLink="/my-dashbroad" class="">My
          Dashboard</a></li>
      <li class="list-group-item text-center  button-5"><a routerLink="/task-dashbroad" class="">Task Dashboard</a></li>
      <li class="list-group-item text-center active button-6"><a class="" routerLink="/project-dashboard">Project
          Dashboard</a></li>
      <li class="list-group-item text-center button-6"><a class="" routerLink="/employee-dashboard">Employees
          Dashboard</a></li>
    </ul>
  </div>
</div>
<div class="drop_n_drag">
  <div class="row">
    <div class="col-xl-7 col-12">
      <div class="row mt-3">
        <div class="col-xl-4 col-lg-6 col-12">
          <div class="box">
            <div class="box-body">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <div class="bg-primary mb-20 box_in rounded10 text-center l-h-50">
                    <i class="fs-18 la la-file"></i>
                  </div>
                  <h4 class="">Overall Projects</h4>
                  <p class="text-mute mb-0">124 Total</p>
                </div>
                <div class="carts">
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-12">
          <div class="box">
            <div class="box-body">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <div class="bg-info mb-20 box_in rounded10 text-center l-h-50">
                    <i class="fs-18 la la-cogs"></i>
                  </div>
                  <h4 class=" text-nowrap">On Going</h4>
                  <p class="text-mute mb-0">32 Total</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-12">
          <div class="box">
            <div class="box-body">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <div class="bg-success mb-20 box_in rounded10 text-center l-h-50">
                    <i class="fs-18 la la-trophy"></i>
                  </div>
                  <h4 class="">Complete </h4>
                  <p class="text-mute mb-0">102 Total</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-xl-4 col-lg-6 col-12">
          <div class="box">
            <div class="box-body">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <div class="bg-primary mb-20 box_in rounded10 text-center l-h-50">
                    <i class="fs-18 la la-file"></i>
                  </div>
                  <h4 class="">Task</h4>
                  <p class="text-mute mb-0">124 Total</p>
                </div>
                <div class="carts">

                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-lg-6 col-12">
          <div class="box">
            <div class="box-body">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <div class="bg-info mb-20 box_in rounded10 text-center l-h-50">
                    <i class="fs-18 la la-cogs"></i>
                  </div>
                  <h4 class=" text-nowrap">On Task</h4>
                  <p class="text-mute mb-0">32 Total</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xl-4 col-12">
          <div class="box">
            <div class="box-body">
              <div class="d-flex align-items-center justify-content-between">
                <div>
                  <div class="bg-success mb-20 box_in rounded10 text-center l-h-50">
                    <i class="fs-18 la la-trophy"></i>
                  </div>
                  <h4 class="">Completed  Task</h4>
                  <p class="text-mute mb-0">102 Total</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-xl-5">
      <div class="box">
        <div class="box-header">
          <h4 class="box-title">
            Recent Activity
          </h4>
        </div>
        <div class="box-body">
          <div class="slimScrollDiv">
            <div class="activity-div">
              <lib-ngx-vertical-timeline [items]="items"></lib-ngx-vertical-timeline>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="col-lg-4">
      <div class="card connectedSortable ui-sortable">
        
        <div class="box box-solid box-primary">
          <div class="box-header  ui-sortable-handle" style="cursor: move;">
            <h4 class="box-title">MY Task</h4>
          </div>
          <div class="box-body p-0">
            <ul class="todo-list ui-sortable">
              <li *ngFor="let data of myTask">
                <span class="handle ui-sortable-handle">
                <i class="las la-ellipsis-v"></i>
                <i class="las la-ellipsis-v"></i>
                </span>
                <span class="text-line">{{data.task_name}}</span>
                <small class="badge bg-success"><i class="la la-clock-o"></i> {{data.effort | number: '1.0-0'}} Hours</small>
                <small class="badge bg-danger"><i class="la la-clock-o"></i> {{data.coneffort | number: '1.0-0'}} Hours</small>
                <div class="tools">
                  <i class="las la-eye"></i>
                 
                </div>
              </li>
     
            </ul>
          </div>
        </div>
      </div>
    </div> -->
    <div class="col-lg-4">

    </div>
    <div class="col-lg-4">

    </div>
  </div>
  <div class="row">
    <div class="col-md-12 col-lg-12 col-xl-4 d-flex">
      <div class="card flex-fill dash-statistics">
        <div class="card-body">
          <div class="d-flex justify-content-between align-items-center mb-3"  >
            <h5 class="card-title">Task OverView</h5>
            <div class="input-group selectbox">
              <div class="input-group-text"><i class="las la-user-secret"></i>
              </div>
              <select class="form-control select select2-hidden-accessible">
                <option value="">Select Employees </option>
                <option value="active"> Debasish</option>
                <option value="inactive">Debanku</option>
                <option value="stop"> Amit</option>
                <option value="finish">Ramiz</option>
              </select>
            </div>
          </div>
          <div class="stats-list">
            <div class=" box-widget widget-user-2">
              <div class="widget-user-header bg-yellow">
                <div class="widget-user-image"> <img class="img-circle" src="http://placehold.jp/150x150.png" alt="User Avatar"> </div>
                <h3 class="widget-user-username">Amit Biswas</h3>
                <h5 class="widget-user-desc">Web Designer</h5>
              </div>
              <div class="box-footer no-padding">
                <ul class="nav nav-stacked">
                  <li><a href="#">Overall Projects<span class="badge bg-primary">15</span></a></li>
                  <li><a href="#">Tasks <span class=" badge bg-secondary">25</span></a></li>
                  <li><a href="#">Completed Projects <span class=" badge bg-danger">30</span></a></li>
                  <li><a href="#">Completed Task <span class=" badge bg-info">30</span></a></li>
                  <li><a href="#">On Going Tasks <span class=" badge bg-dark">25</span></a></li>
                  <li><a href="#">Pending Task <span class=" badge bg-primary">120</span></a></li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-4 d-flex">
      <div class="card flex-fill">
        <div class="card-body">
          <h4 class="card-title">Task Statistics</h4>
          <div class="statistics">
            <div class="row">
              <div class="col-md-6 col-6 text-center">
                <div class="stats-box mb-4">
                  <p>Total Tasks</p>
                  <h3>385</h3>
                </div>
              </div>
              <div class="col-md-6 col-6 text-center">
                <div class="stats-box mb-4">
                  <p>Overdue Tasks</p>
                  <h3>19</h3>
                </div>
              </div>
            </div>
          </div>
          <div class="progress mb-4">
            <div class="progress-bar bg-purple" role="progressbar" style="width: 30%" aria-valuenow="30"
              aria-valuemin="0" aria-valuemax="100">30%</div>
            <div class="progress-bar bg-warning" role="progressbar" style="width: 22%" aria-valuenow="18"
              aria-valuemin="0" aria-valuemax="100">22%</div>
            <div class="progress-bar bg-success" role="progressbar" style="width: 24%" aria-valuenow="12"
              aria-valuemin="0" aria-valuemax="100">24%</div>
            <div class="progress-bar bg-danger" role="progressbar" style="width: 26%" aria-valuenow="14"
              aria-valuemin="0" aria-valuemax="100">21%</div>
            <div class="progress-bar bg-info" role="progressbar" style="width: 10%" aria-valuenow="14" aria-valuemin="0"
              aria-valuemax="100">10%</div>
          </div>
          <div>
            <p><i class="la la-dot-circle-o text-purple me-2"></i>Completed Tasks <span class="float-end">166</span></p>
            <p><i class="la la-dot-circle-o text-warning me-2"></i>Task In Progress <span class="float-end">115</span>
            </p>
            <p><i class="la la-dot-circle-o text-success me-2"></i>Held-Up Task  <span class="float-end">31</span></p>
            <p><i class="la la-dot-circle-o text-danger me-2"></i>Pending Tasks <span class="float-end">47</span></p>
            <p class="mb-0"><i class="la la-dot-circle-o text-info me-2"></i>Task Reviewed <span
                class="float-end">5</span></p>
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-12 col-lg-6 col-xl-4 d-flex">
      <div class="card flex-fill">
        <div class="card-body">
          <h4 class="card-title">Today Absent <span class="badge bg-danger ms-2">5</span></h4>
          <div class="leave-info-box">
            <div class="media d-flex align-items-center">
              <a routerLink="profile" class="avatar"><img alt=""
                  src="http://placehold.jp/150x150.png"></a>
              <div class="media-body flex-grow-1">
                <div class="text-sm my-0">Debasish dey</div>
              </div>
            </div>
            <div class="row align-items-center mt-3">
              <div class="col-6">
                <h6 class="mb-0">4 Sep 2022</h6>
                <span class="text-sm text-muted">Leave Date</span>
              </div>
              <div class="col-6 text-end">
                <span class="badge bg-inverse-danger">Pending</span>
              </div>
            </div>
          </div>
          <div class="leave-info-box">
            <div class="media d-flex align-items-center">
              <a routerLink="profile" class="avatar"><img alt=""
                  src="http://placehold.jp/150x150.png"></a>
              <div class="media-body flex-grow-1">
                <div class="text-sm my-0">Debanku Dey</div>
              </div>
            </div>
            <div class="row align-items-center mt-3">
              <div class="col-6">
                <h6 class="mb-0">4 Sep 2022</h6>
                <span class="text-sm text-muted">Leave Date</span>
              </div>
              <div class="col-6 text-end">
                <span class="badge bg-inverse-success">Approved</span>
              </div>
            </div>
          </div>
          <div class="load-more text-center">
            <a class="text-dark" href="javascript:void(0);">Load More</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
<ngx-ui-loader></ngx-ui-loader>
<!-- <ngx-ui-loader [fgsType]="SPINNER"></ngx-ui-loader> -->