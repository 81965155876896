import { Pipe, PipeTransform } from '@angular/core';
import {
  CalendarEvent
} from 'angular-calendar';

@Pipe({
  name: 'tmdateFilter'
})
export class TmdateFilterPipe implements PipeTransform {

  transform(events: CalendarEvent[], viewDate: Date ): CalendarEvent[] {
    //console.log(events);
    //console.log(viewDate);
    return events.filter((val) => {
    
      //console.log(val.start.getDate().toString());
      //console.log( viewDate.getDate().toString());
      if(val.start.getDate().toString() === viewDate.getDate().toString()){
        return true;
      }else{
        return false;
      }
    
    });
  }

}
