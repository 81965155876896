import { Component, OnInit,ViewChild,TemplateRef } from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import {
  ActivatedRoute,
  NavigationStart,
  NavigationExtras,
  Router,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PagesService } from '../pages.service';
import { LocalStorageService } from 'ngx-webstorage';
import Swal from 'sweetalert2';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import {
  ChartComponent,
  /* ApexAxisChartSeries */
  ApexNonAxisChartSeries,
  ApexChart,
  ApexResponsive
} from 'ng-apexcharts';
import { Console } from 'console';

export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};


@Component({
  selector: 'app-task-manage',
  templateUrl: './task-manage.component.html',
  styleUrls: ['./task-manage.component.scss'],
})
export class TaskManageComponent implements OnInit {
  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  public showChart=false;
  public Math = Math;
  public memberName :any;
  public taskTypeList = <any>[];
  public objKey : any;
  public loadDataInfomationStatus = false;
  public tabval = 'task_details';
  public tagList=<any>[];
  public addTagBtn=false;
  public getTaskStageApiCall = 0;
  public getTimeSheetDataApiCall = 0;
  public userForm!: FormGroup;
  public getTaskPDApiCall = 0;
  public taskID: string = '';
  public error: boolean = false;
  public selectedTagList:any;
  public payload: any;
  public taskData: any;
  public demoModal :any;
  public addMemberStatus = false;
  public taskList = <any>[];
  public projectList = <any>[];
  public moduleList = <any>[];
  public userList = <any>[];
  public t_id: string = '';
  public taskTimeList = <any>[];
  public userIdlist = <any>[];
  public taskStage = <any>[];
  public pdDetails = <any>[];
  public userlist: any;
  public itemList = <any>[];
  public progressBarValue = 0;
  public userApiCall = 1;
  public taskRowId = 0;
  public docStatus: any;
  public subtabval = 'task_pd';
  public tabshowhideinMobile = false;
  public selectedUserList: any = [];
  public timeSheetEffInfo = <any>[];
  public comments = '';
  public getAttchmentList: any = [];
  public getMessage: any = [];
  public isShow = true;
  public total_effort = 0;
  public lsData: any;
  public taskForm!: FormGroup;
  public userId = 0;
  public getCmts: any;
  public editStatus = false;
  public multiSearch : string = '';
  public isShowBtn = true;
  public message: String = '';
  public hsl: any;
  public formData = new FormData();
  public attechList = <any>[];
  public empList = <any>[];
  public selected="";
  public tagArr=<any>[];
  public loadAllComponant = false;
  public memberDropdwon = ``;
  public openModel = true;
  public mentionKey:any ='';
  public tagForm!: FormGroup;
  public taggedUsers : any = [];
  public selectedMembers:any = [];
  public selectedIds:any = [];
  public openTagModal = true;
  public taskName = '';
  public selectedIdsForMsgSave:any = [];
  public fileType = [
    'image/jpeg', //image
    'image/png', //image
    'image/gif', //image
    'text/csv', //CSV
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //XLSX
    'application/vnd.ms-excel', //XLS
    'application/x-zip-compressed', //zip
    'application/pdf', //PDF
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //DOCX
  ];
  constructor(
    private modalService: NgbModal,
    public rest: RestService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public ngxService: NgxUiLoaderService,
    private router: Router,
    private toaster: ToastrService,
    public pageService: PagesService,
    private localSt: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.getTaskType();
    this.taskID = this.route.snapshot.params['id'].replace('_','/');
    this.getEditTaskData(this.taskID);
    /* This is object set for adding validation on selected fileds */
    this.taskForm = this.formBuilder.group({
      task_name: [null, [Validators.required]],
      task_estimated_pd: [null, [Validators.required]],
      task_status: ['', [Validators.required]],
      task_type: ['', [Validators.required]],
      task_description: [null, [Validators.required]],
      t_id: [0, [Validators.required]],
      p_name: [],
      m_name: [],
    });
    this.lsData = this.localSt.retrieve('user').data.roles;
    this.userId = this.localSt.retrieve('user').data.empid;
  }
  /* This function is used get task details by task_id*/
  getEditTaskData(id: string) {
    this.itemList = [];
    if (id != '') {
      this.payload = {
        taskID: id,
      };
      this.ngxService.start();
      this.rest.getData(this.payload, 'getsingletask').subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.loadAllComponant = true;
          if (data.state == '200') {
            this.taskData = data.data;
            this.taskName = data.data.task_name;
            this.empList = this.taskData.asigned_data;
            console.log(this.taskData.asigned_data);
            this.t_id = this.taskData.task_id;
            this.taskRowId = this.taskData.taskRowId;
            this.getAttchment(this.taskRowId);
            this.getComments(this.taskRowId);
            this.taskStage = this.taskData.stage;
            this.taskData.projectUsers.forEach((element: any) => {
              let tmpObj = { id: element.id, name: element.name };
              this.itemList.push(tmpObj);
            });
            if (
              this.taskData.progressBarValue != undefined &&
              this.taskData.progressBarValue !== null
            ) {
              this.progressBarValue = this.taskData.progressBarValue;
            } else {
              this.progressBarValue = 0;
            }
            this.taskData.tags.forEach((el: any) => {
              this.tagArr.push(el.id);
            });
            this.getTagList(this.tagArr);
            this.setFormValue();
          } else {
            this.ngxService.stop();
            this.router.navigate(['task']);
            setTimeout(() => {
              this.toaster.warning(data.message);
            }, 800);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.loadAllComponant = true;
          this.toaster.warning('Something went wrong');
        },
      });
    } else {
      this.toaster.warning('Invalid task id unable to fetch data');
    }
  }
  /* This function is used patch value to selected filed*/
  setFormValue() {
    this.taskForm.patchValue({
      task_name: this.taskData.task_name,
      task_estimated_pd: this.taskData.estimated_hours,
      task_status: this.taskData.task_status,
      task_type: this.taskData.task_type,
      task_description: this.taskData.task_description,
      p_name: this.taskData.project_name,
      m_name: this.taskData.module_name,
    });
  }

  ngOnDestroy() {
    clearInterval(this.getCmts);
  }
  
  changeTab(tab: any) {
    this.tabval = tab;
    if (tab == 'get_timedata' || tab=='reportTimesheet') {
      this.getTimeSheetData(this.t_id);
    } else if (tab == 'logs') {
      this.changeSubTab(this.subtabval);
    }
  }

  changeSubTab(tab: any) {
    this.subtabval = tab;
    if (tab == 'task_stage') {
      this.getTaskStage(this.t_id);
    } else if (tab == 'task_pd') {
      this.getTaskPD(this.t_id);
    }
  }
  /* This function is used to update task details*/
  onSubmit(form: FormGroup) {
    Swal.fire({
      title: 'Save Changes?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        if (form.invalid) {
          return;
        }
        this.ngxService.start();
        this.isShow = false;
        let payload = { ...form.value };
        payload.t_id = this.t_id;
        this.rest.getData(payload, 'updatetask').subscribe({
          next: (data) => {
            this.ngxService.stop();
            this.isShow = true;
            if (data.state == '200') {
              this.getTaskStageApiCall = 0;
              this.getTimeSheetDataApiCall = 0;
              this.getTaskPDApiCall = 0;
              this.toaster.success(data.message);
              this.getTaskType();
              this.taskID = this.route.snapshot.params['id'].replace('_','/');
              this.getEditTaskData(this.taskID);
              this.docAction(0);
            } else {
              this.toaster.warning(data.message);
            }
          },
          error: (e) => {
            this.isShow = true;
            this.ngxService.stop();
            this.toaster.warning('Something went wrong');
          },
        });
      }
    });
  }
  /* This function is used to delete task */
  deleteTask(id: string) {
    Swal.fire({
      title: 'This task will be permanently deleted. Do you wish to continue?',
      // text: "You won't be able to revert this!",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        if (id != '' && id != undefined && id != null) {
          this.ngxService.start();
          this.rest.getData({ t_id: id }, 'deletetask').subscribe({
            next: (data) => {
              this.ngxService.stop();
              if (data.state == '200') {
                this.toaster.success(data.message);
                setTimeout(() => {
                  this.router.navigate(['task']);
                }, 1500);
              } else {
                this.toaster.warning(data.message);
              }
            },
            error: (e) => {
              console.log(e);
              this.toaster.warning('Something went wrong');
            },
          });
        } else {
          console.log('Empty');
        }
      }
    });
  }
  /* This function is used to fetch time details of task
  ex: assign time, spand time, and user how much time woking in this project  */
  getTimeSheetData(id: string) {
    if (
      id != '' &&
      id != undefined &&
      id != null &&
      this.getTimeSheetDataApiCall == 0
    ) {
      this.ngxService.start();
      this.rest.getData({ t_id: id }, 'getsingletimedetails').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.getTimeSheetDataApiCall = 1;
            this.taskTimeList = data.data.timeSheet;
            this.timeSheetEffInfo = data.data.effortData;
            this.total_effort = data.data.total_effort;
            this.setChartData(this.timeSheetEffInfo);
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }
  /* This function is used to fetch task stage log*/
  getTaskStage(id: any) {
    if (id !== '' && this.getTaskStageApiCall == 0) {
      this.ngxService.start();
      this.rest.getData({ t_id: id }, 'gettaskstage').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.getTaskStageApiCall = 1;
            this.taskStage = data.data;
            console.log(this.taskStage);
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }
  /* This function is used to fetch task pd log*/
  getTaskPD(id: any) {
    if (id !== '' && this.getTaskPDApiCall == 0) {
      this.ngxService.start();
      this.rest.getData({ t_id: id }, 'remainingtaskpd').subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.getTaskPDApiCall = 1;
          if (data.state == '200') {
            this.pdDetails = data.data;
            console.log(this.pdDetails);
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }
  /* This function is used to send data to service for cloning task*/
  cloneTask(data: any) {
    console.log("--",data);
    this.pageService.setData(data);
    this.router.navigate(['/create-task'], { queryParams: { clone: true } });
  }
  /* Api call call to add user */
  addUsers() {
    this.ngxService.start();
    let apiPayload = { t_id: 0, users: [] };
    apiPayload.t_id = this.taskRowId;
    apiPayload.users = this.selectedUserList;
    console.log("tt",apiPayload);
    this.rest.getData(apiPayload, 'addtaskmember').subscribe({
      next: (data) => {
        this.ngxService.stop();
        if (data.state == '200') {
          this.toaster.success(data.message);
          this.selectedUserList = [];
          this.userApiCall = 0;
          this.getEditTaskData(this.t_id);
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.toaster.warning('Something went wrong');
      },
    });
  }

  /* Api call call to delete user */
  deleteUser(id: any,name:any) {
    Swal.fire({
      title: 'Remove '+name+' ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        if (id !== '' && this.taskRowId > 0) {
          this.ngxService.start();
          this.rest
            .getData({ users: id, t_id: this.taskRowId }, 'removetaskuser')
            .subscribe({
              next: (data) => {
                this.ngxService.stop();
                if (data.state == '200') {
                  this.toaster.success(data.message);
                  this.getEditTaskData(this.t_id);
                  this.selectedUserList = [];
                  this.userApiCall = 0;
                } else {
                  this.toaster.warning(data.message);
                }
              },
              error: (e) => {
                this.ngxService.stop();
                this.toaster.warning('Something went wrong');
              },
            });
        }
      }
    });
  }

  select_user(ev: any,name:any) {
    
    Swal.fire({
      title: 'Add '+name+' ?',
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText: 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.isConfirmed) {
        this.selectedUserList.push(ev);
        if (this.selectedUserList.length > 0) this.addUsers();
      }
    });
  }
  /* This function is used to fetch task attachment list*/
  getAttchment(id: number) {
    if (id > 0 ) {
      this.ngxService.start();
      this.rest.getData({ t_id: id }, 'getattchment').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.getAttchmentList = data.data;
            this.attechList = this.pageService.loadMore(this.getAttchmentList);
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }
 
  showMore() {
    this.isShow = false;
    setTimeout(() => {
      this.isShow = true;
      this.attechList = this.pageService.loadMore(
        this.getAttchmentList,
        this.attechList.length
      );
    }, 1000);
  }

  showEmp() {
    this.isShowBtn = false;
    setTimeout(() => {
      this.isShowBtn = true;
      this.empList = this.pageService.loadMore(
        this.taskData.asigned_data,
        this.empList.length
      );
    }, 1000);
  }

  /* This function is used to fetch all comments*/
  getComments(id: number) {
    if (id > 0) {
      this.rest.getData({ id }, 'getcomments').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            let prevLen = this.getMessage.length;
            if (prevLen !== 0 && prevLen !== data.data.length) {
              let checkNtify = data.data[data.data.length - 1];
              console.log(checkNtify);
              if (this.userId !== checkNtify.member_id) {
                this.pageService.notifyMe('Task', checkNtify.message);
              }
            }
            this.getMessage = data.data;
          }
        },
        error: (e) => {
          this.ngxService.stop();
          //this.toaster.warning('Something went wrong');
        },
      });
    }
  }

  docAction(ev: any) {
    if (this.docStatus == ev) {
      this.docStatus = 0;
    } else {
      this.docStatus = ev;
    }
  }

  showSelect(open:boolean){
    if(this.addTagBtn){
      this.addTagBtn=false;
    }else{
      this.addTagBtn=open;
    }
  }
  /* This function is used to save attachment*/
  onFileChange(event: any) {
    this.ngxService.start();
    this.isShow = false;
    if (event.target.files.length > 0) {
      for (let i = 0; i < event.target.files.length; i++) {
        let size = (event.target.files[i].size / 1024 / 1024) | 0;
        if (this.fileType.includes(event.target.files[i].type) && size <= 4) {
          this.formData.append('files[]', event.target.files[i]);
        }
      }
      this.formData.append('id', this.taskRowId.toString());
      if (this.formData.getAll('files[]').length < 1) {
        return;
      }
      this.rest.getData(this.formData, 'uploadattchment', true).subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.isShow = true;
          if (data.state == '200') {
            this.getAttchmentList.length = 0;
            this.getAttchment(this.taskRowId);
            this.toaster.success(data.message);
            this.formData = new FormData();
            if (data.data.saved.length > 0) {
              this.pageService.exportExcel(data.data.saved,"upload_result");
            }
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.isShow = true;
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }

  upload() {
    document.getElementById('upload-input')?.click();
  }
  /* This function is used to delete attachment*/
  deleteAttechment(id: number) {
    if (id > 0) {
      this.ngxService.start();
      this.rest.getData({ id }, 'deleteattechment').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.toaster.success(data.message);
            this.getAttchmentList.length = 0;
            this.getAttchment(this.taskRowId);
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.warning('Something went wrong');
        },
      });
    }
  }

  convertToLetter(letter:any) {
    this.convertColor(letter);
    return this.pageService.getFirstLetters(letter);
  }
  convertColor(string:any) {
    this.hsl = this.pageService.convertNameToColor(string);
  }
  /* This function is used to fetch taglist*/
  getTagList(tagArr=[]) {
    this.rest.getData({tag_id:tagArr}, 'gettaglist').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.tagList=data.data;
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning('Something went wrong!!');
      },
    });
  }
  /* This function is used to save tag*/
  addTag(){
    if(this.selectedTagList.length>0){
      this.rest.getData({tag_name:this.selectedTagList,task_id:this.taskRowId}, 'addtag').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.toaster.success(data.message);
            this.selectedTagList=[];
            this.getEditTaskData(this.taskID);
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.toaster.warning('Something went wrong!!');
        },
      });
    }
  }
  /* This function is used to remove tag*/
  removeTag(tag_id:number){
    if(tag_id>0){
      this.rest.getData({tag_id,task_id:this.taskRowId}, 'removetag').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.toaster.success(data.message);
            this.getEditTaskData(this.taskID);
          } else {
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.toaster.warning('Something went wrong!!');
        },
      });
    }
  }
  
  addNewTag=(term: string)=> {
    this.selected = term;
    return term;
  }

  setChartData(data: any) {
    let seriesArr=<any>[];
    let labelsArr=<any>[];
    if(data.length>0){
      for (let iterator of data) {
        labelsArr.push(iterator.name+' ('+this.pageService.formatSeconds(iterator.total_effort)+')' );
        seriesArr.push(parseInt(iterator.total_effort))
      }
      console.log(seriesArr)
      this.showChart=true;
      this.chartOptions = {
        series: seriesArr,
        chart: {
          type: "donut",
          width: '450px'
        },
        labels: labelsArr,
        responsive: [
          {
            breakpoint: 200,
            options: {
              chart: {
                width: '150px'
              },
              legend: {
                position: "bottom"
              }
            }
          }
        ]
      };
    }
  }

  getTaskType() {
    this.rest.getData({},'gettasktypes').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.taskTypeList = data.data;
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        console.log(e);
        this.toaster.warning("Something went wrong!");
      },
    });
  }

  searchType(event: any) {
    let searchkey = event.target.value;
    if (searchkey.length > 1) {
      this.objKey = searchkey;
    } else if (searchkey.length == 0) {
      this.objKey = searchkey;
    }
  }

  mentionEmp(u: any) {
    this.mentionKey = u.target.value;
    if (this.empList?.length > 2) {
      if (this.mentionKey.includes(" @") && this.openModel) {
        if (this.openTagModal) {
          this.openModal();
        }
        this.tagForm = this.formBuilder.group({
          tagged_user: [null],
        });
      }
    }
  }

  @ViewChild('editModal') public editModal : |TemplateRef<any>|undefined;
  openModal(){
    this.modalService.open(this.editModal);
  }

  closeModal() {
    this.modalService.dismissAll();
  }

  tagMembers(form: FormGroup) {
    if (form.invalid) {
      return;
    }
    this.isShow = false;
    let apiPayload = {tagged_user: []};
    apiPayload.tagged_user = this.selectedIds;
    this.rest.getData(apiPayload, 'taggedmembers').subscribe({
      next: (data) => {
        this.isShow = true;
        if (data.state == '200') {
          this.openTagModal = !this.openTagModal;
          let parentChecked = data.data;
          parentChecked.forEach((element: any) => {
            this.selectedMembers.push(element.name);
            this.selectedIdsForMsgSave.push(element.id);
          });
          let prevComments = this.comments;
          this.comments = prevComments + this.selectedMembers.toString();
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.isShow = true;
        this.toaster.warning('Something went wrong');
      },
    });
  }

    /* This function is used to save comment*/
  saveComments() {
    if (this.comments != '' && this.taskRowId > 0) {
      this.rest.getData({ t_id: this.taskRowId, message: this.comments, tagged_users:this.selectedIdsForMsgSave},'savecomments').subscribe({
          next: (data) => {
            if (data.state == '200') {
              this.selectedIdsForMsgSave = [];
              this.openTagModal = !this.openTagModal;
              this.getComments(this.taskRowId);
              this.toaster.success("Sent!");
              this.sendMailToTaggdedUsers(this.selectedIds,this.comments);
              this.selectedIds = [];
              this.comments = '';
            } else {
              this.toaster.warning(data.message);
            }
          },
          error: (e) => {
            this.toaster.warning('Something went wrong');
          },
        });
    }
  }

  sendMailToTaggdedUsers(ids:any=[],t_message:any='') {
    let taskUrl = "https://erp.bookyup.com/manage-task/"+this.route.snapshot.params['id'];
    let taskname = this.taskData.task_name;
    let message = t_message;
    let taggUserPayload = {
      tagged_user:ids,
      task_name:taskname,
      t_link:taskUrl,
      t_msg:message
    }
    this.rest.getData(taggUserPayload,'sendmailtotaggedmembers').subscribe({
      next: (data) => {},
      error: (e) => {
        console.log(e);
      },
    });
  }

  encodeURIParam(taskUrl: any) {
    if(taskUrl !== '' || taskUrl !== null)
    {
      return taskUrl.replace('/', '_');
    }
  }

}
