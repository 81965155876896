<div class="main-content">
  <div class="accordion add-employee" id="accordion-details">
    <div class="card  grow ctm-border-radius">
      <div class="card-header d-flex align-items-center justify-content-between" id="basic1">
        <h6 class="mb-0">Announcement List</h6>
        <a type="button" (click)="open(demoModal)" class="custom-btn btn-8 text-center"><span> Add</span> </a>
      </div>

      <div class="card-body">
        <div class="table-back employee-office-table">
          <div class="table-responsive">
            <table class="table custom-table table-hover table-hover">
              <thead>
                <tr>
                  <th>Sl No.</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>Description</th>
                  <th>Status</th>
                  <th>Created On</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                <ng-container *ngIf="announcementList==undefined || announcementList==''; else dataTable">
                  <tr>
                    <td class="text-center" colspan="9">No Data Found!!</td>
                  </tr>
                </ng-container>
                <ng-template #dataTable>
                  <tr *ngFor="let announce of announcementList; index as i">
                    <td>{{i+1}}</td>
                    <td>{{announce.type}}</td>
                    <td>{{announce.date | date }}</td>
                    <td>{{announce.description}}</td>
                    <td>{{announce.status}}</td>
                    <td>{{announce.created_at  | date }}</td>
                    <td>
                      <a href="javascript:void(0)" (click)="removeAnnouncement(announce.id)">
                        <span class=" edit text-success text-center"><i class="las la-trash"></i></span>
                      </a>
                    </td>
                  </tr>
                </ng-template>
              </tbody>
            </table>
          </div>
        </div>
      </div>

    </div>
  </div>
</div>

<ng-template #demoModal let-modal>
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Announcement Form</h4>
    <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="modal.dismiss('Cross click')"></button>
  </div>
  <div class="modal-body">
    <form [formGroup]="announcementForm" (ngSubmit)="submitAnnouncementForm(announcementForm)" #fd="ngForm" [ngxFvControl]="fd">
      <div class="row">
            
        <div class="col-lg-6 form-group">
          <ngx-fv-validate [control]="announcementForm.controls['announcement_type']">
            <label #ngxFvName>
              Announcement Type
            </label>
            <div class="input-group mb-3">
              <div class="input-group-text"><i class="las la-user-secret"></i>
              </div>
              <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true" #ngxFvFormControl formControlName="announcement_type">
                <option value="">Type</option>
                <option value="event"> Event</option>
                <option value="notice">Notice</option>
                <option value="desclaimer">Desclaimer</option>
                <option value="festival"> Festival</option>
                <option value="occasion">Occasion</option>
                <option value="emergenecy">Emergenecy</option>
                <option value="warning">Warning</option>
              </select>
            </div>
            <ngx-fv-error></ngx-fv-error>
          </ngx-fv-validate>
        </div>
        
        <div class="col-lg-6 form-group">
          <ngx-fv-validate [control]="announcementForm.controls['date']">
            <label #ngxFvName>
              Date
            </label>
            <div class="input-group mb-3">
              <div class="input-group-text"><i class="las la-calendar-alt"></i>
              </div>
              <input type="text" class="form-control" ngbDatepicker #dpStart="ngbDatepicker" id="date" placeholder="YYYY-MM-DD" name="dpStart" (click)="dpStart.toggle();" #ngxFvFormControl formControlName="date" [minDate]="minDate">
            </div>
            <ngx-fv-error></ngx-fv-error>
          </ngx-fv-validate>
        </div>
            <div class="col-lg-12 form-group">
              <ngx-fv-validate [control]="announcementForm.controls['description']">
                <label #ngxFvName>
                  Description
                </label>
                <div class="input-group mb-3">
                  <textarea class="form-control" minlength="15" placeholder="description "
                    #ngxFvFormControl formControlName="description"> </textarea>
                </div>
                <ngx-fv-error></ngx-fv-error>
              </ngx-fv-validate>
            </div>
          <div class="col-lg-12 form-group">
            <div class="input-group mb-3">
              <button  class="btn custom-btn btn-8" (click)="modal.dismiss('Cross click')"><span>Submit Now</span></button>
            </div>
          </div>
      </div>
    </form>
  </div>
  <!-- <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
  </div> -->
</ng-template>

<ngx-ui-loader></ngx-ui-loader>
