import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loader-two',
  templateUrl: './loader-two.component.html',
  styleUrls: ['./loader-two.component.scss']
})
export class LoaderTwoComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
