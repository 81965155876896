<div class="quicklink-sidebar-menu ctm-border-radius  bg-white card grow">
  <div class="card-body">
    <div class="cardTitle d-flex justify-content-between align-items-center">
     <h5 class="p-0 m-0">Leave Employee List</h5>
     <button class="btn btn-primary bg-custom border-0 " ><i class="lar la-caret-square-left 48px"></i></button>
    </div>
  </div>
</div>
<div class="user_match_data">
  <div class="row">
    <div class="col-lg-12">
      <div class="d-flex justify-content-between">
        <ul class="nav nav-tabs border-0">
          <li class="nav-item">
            <a class="nav-link " aria-current="page" href="javascript:void(0)" (click)="changeTab('apply_leave')">Apply For Leave</a>
          </li>
          <li class="nav-item">
            <a class="nav-link " aria-current="page" href="javascript:void(0)" (click)="changeTab('applied_leave')">Applied</a>
          </li>
          <li class="nav-item">
            <a class="nav-link " href="javascript:void(0)" (click)="changeTab('approved_leave')">Approved</a>
          </li>
          <li class="nav-item">
            <a class="nav-link " href="javascript:void(0)" (click)="changeTab('rejected_leave')">Rejected</a>
          </li>
        </ul>
        </div>
    </div>
  </div>
</div>
<!-- Apply -->
<div class="accordion add-employee mt-2" id="accordion-details" *ngIf="tabval=='apply_leave'">
  <div class="card  grow ctm-border-radius">
    <div class="card-header" id="basic1">
      <h4 class="cursor-pointer mb-0">
        <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
          data-target="#basic-one" aria-expanded="true">
          Leave Details
        </a>
      </h4>
    </div>
    <div class="card-body p-0">
      <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
        <form>
          <div class="row">
            
            <div class="col-lg-6 form-group">
              <div class="input-group mb-3">
                <div class="input-group-text"><i class="lar la-id-card"></i>
                </div>
                <input type="text" class="form-control" id="Name" placeholder="Leave Type ">
              </div>
            </div>
            <div class="col-lg-6 form-group">
              <div class="input-group mb-3">
                <div class="input-group-text"><i class="las la-file-signature"></i>
                </div>
                <input type="text" class="form-control" id="Name" placeholder="Remaining Leaves (10)" disabled>
              </div>
            </div>
            <div class="col-lg-6 form-group">
              <div class="input-group mb-3">
                <div class="input-group-text"><i class="las la-globe"></i>
                </div>
                <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                  <option selected="">Employee Country</option>
                  <option value="1">India</option>
                  <option value="2">Bangaldesh</option>
                </select>
              </div>
            </div>
            <div class="col-lg-6 form-group">
              <div class="input-group mb-3">
                <div class="input-group-text"><i class="las la-calendar-alt"></i>
                </div>
                <input type="text" class="form-control"    id="username" placeholder="Number of Day ">
              </div>
            </div>
            <div class="col-lg-6 form-group">
              <div class="input-group mb-3">
                <div class="input-group-text"><i class="las la-calendar-alt"></i>
                </div>
                <input type="text" class="form-control"  ngbDatepicker  #dpStart="ngbDatepicker" id="username" placeholder="Leave Start" name="dpStart" (click)="dpStart.toggle()">
              </div>
            </div>
          
            <div class="col-lg-6 form-group">
              <div class="input-group mb-3">
                <div class="input-group-text"><i class="las la-calendar-alt"></i>
                </div>
                <input type="text" class="form-control" ngbDatepicker  #joinStart="ngbDatepicker" id="username" placeholder="Leave End" (click)="joinStart.toggle()">
              </div>
            </div>
            <div class="input-group mb-3">
              <div class="input-group-text"><i class="las la-globe"></i>
              </div>
              <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                <option selected="">Days</option>
                <option value="1">Half Day</option>
                <option value="2">Full Day</option>
              </select>
            </div>
            <div class="col-lg-12 form-group">
              <p class="mb-2">Reason</p>
              <div class="input-group mb-3">
                <textarea class="form-control" placeholder=""> </textarea>
              </div>
            </div>
            <div class="col-lg-12 form-group">
              <div class="input-group mb-3">
                <button class="btn custom-btn btn-8"><span>Submit Now</span></button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>    
  </div>
</div>
<!-- Applied list -->
<div class="accordion add-employee mt-2" id="accordion-details" *ngIf="tabval=='applied_leave'">
  <div class="card  grow ctm-border-radius">
    <div class="card-header" id="basic1">
      <h4 class="cursor-pointer mb-0">
        <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
          data-target="#basic-one" aria-expanded="true">
          Timesheet Details
        </a>
      </h4>
    </div>
    <div class="card-body p-0">
      <div class="table-back employee-office-table">
        <div class="table-responsive">
          <table class="table custom-table table-hover table-hover">
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Employee Name</th>
                <th>Role</th>
                <th>Applied date</th>
                <th>From</th>
                <th>Till</th>
                <th>Reason</th>
                <th>Acttion</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Applied</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ngx-ui-loader></ngx-ui-loader>
  </div>
</div>
<!-- Approved list -->
<div class="accordion add-employee mt-2" id="accordion-details" *ngIf="tabval=='approved_leave'">
  <div class="card  grow ctm-border-radius">
    <div class="card-header" id="basic1">
      <h4 class="cursor-pointer mb-0">
        <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
          data-target="#basic-one" aria-expanded="true">
          Timesheet Details
        </a>
      </h4>
    </div>
    <div class="card-body p-0">
      <div class="table-back employee-office-table">
        <div class="table-responsive">
          <table class="table custom-table table-hover table-hover">
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Employee Name</th>
                <th>Role</th>
                <th>Applied date</th>
                <th>From</th>
                <th>Till</th>
                <th>Reason</th>
                <th>Acttion</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Approved</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ngx-ui-loader></ngx-ui-loader>
  </div>
</div>
<!-- Rejected list -->
<div class="accordion add-employee mt-2" id="accordion-details" *ngIf="tabval=='rejected_leave'">
  <div class="card  grow ctm-border-radius">
    <div class="card-header" id="basic1">
      <h4 class="cursor-pointer mb-0">
        <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
          data-target="#basic-one" aria-expanded="true">
          Timesheet Details
        </a>
      </h4>
    </div>
    <div class="card-body p-0">
      <div class="table-back employee-office-table">
        <div class="table-responsive">
          <table class="table custom-table table-hover table-hover">
            <thead>
              <tr>
                <th>Sl No.</th>
                <th>Employee Name</th>
                <th>Role</th>
                <th>Applied date</th>
                <th>From</th>
                <th>Till</th>
                <th>Reason</th>
                <th>Acttion</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Rejected</td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <ngx-ui-loader></ngx-ui-loader>
  </div>
</div>
