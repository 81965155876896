<div class="main-content" *ngIf="loadAllComponant">
    <div class="accordion add-employee " id="accordion-details">
        <div class="card  grow ctm-border-radius">
            <div class="card-header" id="basic1">
                <div class="d-flex justify-content-between align-items-center">
                    <ul class="nav nav-tabs border-0">
                        <li class="nav-item">
                            <a class="nav-link" href="javascript:void(0)">Reminder Logs</a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="card-body p-0">
                <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                    data-parent="#accordion-details">
                    <div class="accordion add-employee " id="accordion-details">
                        <div class="card  grow ctm-border-radius">
                            <div class="card-header" id="basic1">
                                <h4 class="cursor-pointer mb-0">
                                    <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
                                        data-target="#basic-one" aria-expanded="true">
                                        Task Stage
                                    </a>
                                </h4>
                            </div>
                            <div class="card-body p-0">
                                <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                                    data-parent="#accordion-details">
                                    <div class="row">
                                        <table class="table custom-table table-hover table-hover" id="empolyeeId">
                                            <thead>
                                                <tr>
                                                    <th>Sl_no</th>
                                                    <th>Name</th>
                                                    <th>Title</th>
                                                    <th>Description</th>
                                                    <th>Priority</th>
                                                    <th>Status</th>
                                                    <th>Created On</th>
                                                    <th>Date</th>
                                                </tr>
                                            </thead>
                                            <tbody  *ngFor="let remData of getReminders; index as i ">
                                                <tr>
                                                    <td>{{i+1}}</td>
                                                    <td>{{remData.name}}</td>
                                                    <td>{{remData.title}}</td>
                                                    <td>{{remData.description}}</td>
                                                    <td *ngIf="remData.priority == 'h'">High</td>
                                                    <td *ngIf="remData.priority == 'm'">Medium</td>
                                                    <td *ngIf="remData.priority == 'l'">Low</td>
                                                    <td *ngIf="remData.status == '0'">Upcoming</td>
                                                    <td class="text-success" *ngIf="remData.status == '1'">Completed</td>
                                                    <td class="text-danger" *ngIf="remData.status == '2'">Expired</td>
                                                    <td>{{remData.reminder_date | date}}</td>
                                                    <td>{{remData.created_at | date}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>