<div class="main-content" *ngIf="isDataLoaded;else showLoader">

    <div class="ctm-border-radius  grow card">
        <div id="basic1" class="card-header d-flex align-center justify-content-between">
            <div class="card_title_left">
                <h4 class="card-title  mb-0 mt-0">Edit Attendance List</h4>
            </div>
            <div class="card_right_ d-flex align-items-center justify-content-end">
                <div class="filter_contetn">
                    <div class="filter_drop_down ">
                        <div class="input-group">
                            <div class="form-outline">
                                <input type="text" class="form-control" placeholder="Search" aria-label="Search"
                                    id="searchdata">
                            </div>
                        </div>
                        <button class="custom-btn btn-8 text-center mx-2" *ngIf="isShowReset" (click)="resetForm()"><span><i
                            class="las la-filter"></i>
                        Reset</span></button>
                        <button class="custom-btn btn-8 text-center" (click)="filter_status_btn()"><span><i
                                    class="las la-filter"></i>
                                Filters</span></button>
                    </div>
                    <div class="drop_down_filter_list d-flex justify-content-end" [ngClass]="filter_status?'on':''">
                        <div class="input-group mb-3">
                            <input type="text" ngbDatepicker #dpStart="ngbDatepicker" name="dpStart" (click)="dpStart.toggle()"
                                class="form-control" placeholder="Select date" (dateSelect)="onDateSelect($event)"
                                [maxDate]="maxObject">
                        </div>
                        <div class="input-group mb-3">
                            <ng-select [multiple]="true" [selectableGroup]="true" [(ngModel)]="selectUser"
                                [closeOnSelect]="false" (change)="select_user($event)"  placeholder="Select Users...">
                                <ng-option *ngFor="let usr of userlist" [value]="usr.u_id">{{usr.name}}</ng-option>
                            </ng-select>
                        </div>
                        <div class="input-group mb-3">
                            <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                                <option value="active"> India</option>
                                <option value="inactive">Bangladesh</option>
                                <option value="stop"> UK</option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="card-body">
         
            <div class="table-back employee-office-table">
                <div class="table-responsive">
                    <table class="table custom-table table-hover table-hover" id="empolyeeId">
                        <thead>
                            <tr>
                                <th>Employee Name</th>
                                <th>Country </th>
                                <th>Date</th>
                                <th>In Time</th>
                                <th>Out-time</th>
                                <th>Active</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="attendanceList.length>0 && attendanceList!=null;else noDataAvailable">
                            <ng-container *ngFor="let list of attendanceList">
                                <tr>
                                    <td>{{list.name}}</td>
                                    <td>{{list.country}}</td>
                                    <td>{{list.date}}</td>
                                    <td>
                                        <input type="time" class="form-control" name="inTime" [(ngModel)]="list.inTime"
                                            [disabled]="list.isEdit">
                                    </td>
                                    <td>
                                        <input type="time" class="form-control" name="outTime" [(ngModel)]="list.outTime"
                                            [disabled]="list.isEdit">
                                    </td>
                                    <td>
                                        <button class="btn btn-success" (click)="edit_att(list)"
                                            *ngIf="list.isEdit">Edit</button>
                                        <button class="btn btn-success" (click)="save(list)"
                                            *ngIf="!list.isEdit">Save</button>
                                    </td>
                                </tr>
                            </ng-container>
                        </tbody>
                        <ng-template #noDataAvailable>
                            <tr class="text-center">
                                <td colspan="6">No Data Available!!</td>
                            </tr>
                        </ng-template>
                    </table>
                </div>
            </div>
        </div>
    </div>
</div>
<ng-template #showLoader>
    <ngx-ui-loader></ngx-ui-loader>
</ng-template>