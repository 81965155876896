import { Component, OnInit,ViewChild } from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { PagesService } from '../pages.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';
import {
  ChartComponent,
  ApexAxisChartSeries,
  ApexNonAxisChartSeries,
  ApexChart,
  ApexPlotOptions,
  ApexResponsive,
  ApexDataLabels,
  ApexXAxis,
  ApexFill,
  ApexYAxis,
  ApexTooltip,
  ApexTitleSubtitle,
  ApexLegend,
  ApexStroke,
} from 'ng-apexcharts';
export type ChartOptions = {
  series: ApexNonAxisChartSeries;
  chart: ApexChart;
  responsive: ApexResponsive[];
  labels: any;
};
export type ChartOptionsTask = {
  series: ApexAxisChartSeries;
  chart: ApexChart;
  xaxis: ApexXAxis;
  yaxis: ApexYAxis | ApexYAxis[];
  title: ApexTitleSubtitle;
  plotOptions: ApexPlotOptions;
  labels: string[];
  colors: string[];
  stroke: ApexStroke; // ApexStroke;
  dataLabels: ApexDataLabels; // ApexDataLabels;
  fill: ApexFill;
  tooltip: ApexTooltip;
  subtitle: ApexTitleSubtitle;
};

@Component({
  selector: 'app-manage-module',
  templateUrl: './manage-module.component.html',
  styleUrls: ['./manage-module.component.scss'],
})
export class ManageModuleComponent implements OnInit {
  @ViewChild('chart') chart!: ChartComponent;
  public chartOptions: Partial<ChartOptions> | any;
  public chartOptionsTask: Partial<ChartOptionsTask> | any;
  public showChart=false;
  public moduleID: any = '';
  public tabval = 'taskList';
  public error: boolean = false;
  public payload: any;
  public docStatus:any;
  public moduleTaskData:any;
  public showTaskChart=false;
  public projectList = <any>[];
  public moduleForm!: FormGroup;
  public moduleData = <any>[];
  public moduleStage = <any>[];
  public moduleRename = <any>[];
  public estimeted_effort=<any>[];
  public estimetedTimeRepost=<any>[];
  public getModuleStageApiCall=0;
  public getModuleRenameApiCall = 0;
  public getEstimetedEffortLogsApiCall=0;
  public menuToggle = '';
  public subtabval='module_stage';
  public moduleRowID=0;
  public minDate: any;
  public loadAllComponant = false;
  public ModuleTypeList = <any>[];
  isShow=true;
  constructor(
    public rest: RestService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    private router: Router,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService,
    public pageService: PagesService,
  ) {}

  ngOnInit(): void {
    this.moduleID = this.route.snapshot.params['id'].replace('_','/');
    /* This is object set for adding validation on selected fileds */
    this.moduleForm = this.formBuilder.group({
      m_description: [null, [Validators.required]],
      m_status: ['', [Validators.required]],
      m_type: ['', [Validators.required]],
      m_estimated_effort: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      risk: ['', [Validators.required]],
      challenges: [null],
      dependency: [null],
      m_estimated_end_date:[null, [Validators.required]],
      p_name:[],
      m_name:[]
    });
    this.getsinglemodule(this.moduleID);
  }

  changeTab(tab: any) {
    this.tabval=tab;
    if (tab == 'logs') {
      this.changeSubTab(this.subtabval);
    }else if(tab=='taskReport'){
      this.estimatedTimeReport(this.moduleRowID);
    }else if(tab=='taskModReport'){
      this.reportTask(this.moduleID);
    }
    this.toogleMobile();
  }

  changeSubTab(tab: any){
    this.subtabval=tab;
    console.log(this.subtabval);
    if (tab == 'module_stage') {
      this.getModuleStage(this.moduleID,'stage');
    }else if(tab=='estimeted_effort'){
      this.getEstimetedEffortLogs(this.moduleID);
    }else if(tab=='task_mod_rename'){
      this.getModuleRename(this.moduleID,'rename');
    }else{
      this.estimatedTimeReport(this.moduleRowID);
    }
  }

  toogleMobile(){
    if(this.menuToggle == "on"){
      this.menuToggle = "";
    }else{
      this.menuToggle = "on";
    }
  }
  /* This function is used to fetch single module */
  getsinglemodule(id: string) {
    if (id != '') {
      this.rest.getData({ module_id: id }, 'getsinglemodule').subscribe({
        next: (data) => {
          this.loadAllComponant = true;
          if(data.state == '200'){
            this.moduleData = data.data;
            this.moduleRowID=data.data.id;
            this.setFormValue();
          } else {
            this.router.navigate(['module']);
            setTimeout(() => {
              this.toaster.warning(data.message);
            }, 1000);
          }
        },
        error: (e) => {
          this.loadAllComponant = true;
          this.toaster.error("Something weng wrong!!!");
        },
      });
    }
  }
  /* This function is used patch value to selected filed*/
  setFormValue(){
    console.log(this.moduleData.m_estimated_end_date);
    let mestimetedenddate = this.moduleData.m_estimated_end_date.split('-');
    const dateModule = new NgbDate(
      parseInt(mestimetedenddate[0]),
      parseInt(mestimetedenddate[1]),
      parseInt(mestimetedenddate[2])
    );
    this.moduleForm.patchValue({
      m_description: this.moduleData.module_description,
      m_status: this.moduleData.module_status,
      m_type: this.moduleData.module_type,
      m_estimated_effort: this.moduleData.module_estimated_effort,
      risk: this.moduleData.risk,
      status: this.moduleData.module_status,
      challenges: this.moduleData.challenges,
      dependency: this.moduleData.dependency,
      p_name:this.moduleData.project_name,
      m_name:this.moduleData.module_name,
      m_estimated_end_date:dateModule
    });
  }
  /* This function is used to convert date object to php date format */
  dateConvert(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    const convertDate = year + '-' + month + '-' + day;
    return convertDate;
  }
  /* This function is used to update module details*/
  onSubmit(form: FormGroup) {
    if (form.invalid) {
      return;
    }
    let payload = { ...form.value };
    payload.m_id = this.moduleID;
    payload.m_estimated_end_date = this.dateConvert(payload.m_estimated_end_date);
    this.isShow=false;
    this.rest.getData(payload, 'updatemodule').subscribe({
      next: (data) => {
        this.isShow=true;
        if(data.state=='200'){
          this.getModuleStageApiCall=0;
          this.getEstimetedEffortLogsApiCall=0;
          this.getModuleRenameApiCall=0;
          this.getEstimetedEffortLogs(this.moduleID);
          this.getsinglemodule(this.moduleID);
          this.toaster.success(data.message);
        }else{
          this.docStatus = !this.docStatus;
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.isShow=true;
        this.toaster.error('Something went wrong');
      },
    });
  }
  /* This function is used to delete module */
  deleteModule(id: string) {
    Swal.fire({
      title: 'Are you sure?',
      text: "You won't be able to revert this, and all unassigned tasks will be deleted.",
      icon: 'warning',
      showCancelButton: true,
      cancelButtonText : 'No',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      confirmButtonText: 'Yes',
      width: 400
    }).then((result) => {
      if (result.isConfirmed) {
        if (id !== '') {
          this.ngxService.start();
          this.rest.getData({ module_id: id }, 'deletemodule').subscribe({
            next: (data) => {
              this.ngxService.stop();
              if (data.state == '200') {
                this.toaster.success(data.message);
                setTimeout(() => {
                  this.router.navigate(['module']);
                }, 1500);
              }else{
                this.toaster.error(data.message);
              }
            },
            error: (e) => {
              this.ngxService.stop();
              this.toaster.error('Something went wrong');
            },
          });
        }
      }
    })
  }

  /* This function is used to fetch module stage log*/
  getModuleStage(id:any,type:any){
    if (id !== '' && type!== '' && this.getModuleStageApiCall==0)  {
      console.log("--",type);
      this.ngxService.start();
      this.rest.getData({ module_id: id,type:type}, 'getmodulestage').subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.getModuleStageApiCall=1;
          if (data.state == '200') {
            this.moduleStage=data.data;
          }else{
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }

  getModuleRename(id:any,type:any){
    if (id !== '' && type!== '' && this.getModuleRenameApiCall==0)  {
      console.log("--",type);
      this.ngxService.start();
      this.rest.getData({ module_id: id,type:type}, 'getmodulestage').subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.getModuleRenameApiCall=1;
          if (data.state == '200') {
            this.moduleRename=data.data;
            console.log(this.moduleRename);
          }else{
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }

  /* This function is used to fetch module estimeted effort logs */
  getEstimetedEffortLogs(id:any){
    if (id !== '' && this.getEstimetedEffortLogsApiCall==0) {
      this.ngxService.start();
      this.rest.getData({ module_id: id,type:'effort' }, 'getmodulestage').subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.getEstimetedEffortLogsApiCall=1;
          if (data.state == '200') {
            this.estimeted_effort=data.data;
          }else{
            console.log(data.message);
            //this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }
  /* This function is used to fetch all tasklist of this module */
  estimatedTimeReport(mId:number){
    if(mId>0){
      this.ngxService.start();
      this.rest.getData({ module_id: mId}, 'estimatedtimereport').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.estimetedTimeRepost=data.data;
            this.setChartData(this.estimetedTimeRepost.taskEffort);
          }else{
            this.toaster.warning(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }
  /* This function is used to convert integer time to readable format */
  timeConvert(min:any,type="h"){
    return type=="h"?this.pageService.timeConvert(min):this.pageService.formatSeconds(min);
  }

  openCloseTab(st:string,val:number){
    this.getModuleType();
    return this.pageService.openCloseTab(st,val)
  }

  setChartData(data: any) {
    let seriesArr=<any>[];
    let labelsArr=<any>[];
    if(data.length>0){
      for (let iterator of data) {
        labelsArr.push(iterator.task_name);
        seriesArr.push(iterator.spend_pd);
      }
      this.showChart=true;
      this.chartOptions = this.pageService.showDonutChart({seriesArr,labelsArr});
    }
  }
  /* This function is used to get task report of this project */
  reportTask(mId: string) {
    if (mId !== '') {
      this.ngxService.start();
      let payload = {
        m_id: mId,        
      };
      this.rest.getData(payload, 'gettasksbymodule').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.moduleTaskData = data.data;
            this.getTaskRepo("top",this.moduleTaskData);
          } else {
            this.moduleTaskData = [];
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }

  getTaskRepo(type="top",data:any){
    if(data[type].length>0)
      this.setChartDataTask(data[type]);
    else
      this.showTaskChart=false;
  }
  /* render graph */
  setChartDataTask(data: any) {
    console.log(data);
    let colorArr = <any>[];
    let spendTimeArr = <any>[];
    let labelsArr = <any>[];
    if (data.length > 0 || data !== '') {
      for (let iterator of data) {
        labelsArr.push(iterator.tsk_name);
        colorArr.push(iterator.color);
        spendTimeArr.push(parseInt(iterator.effort));
      }
      this.showTaskChart = true;
      this.chartOptionsTask = {
        series: [
          {
            data: spendTimeArr,
          },
        ],
        chart: {
          type: 'bar',
          height: '300',
        },
        plotOptions: {
          bar: {
            barHeight: '100%',
            distributed: true,
            horizontal: true,
            dataLabels: {
              position: 'bottom',
            },
          },
        },
        fill: {
          colors: colorArr,          
        },
        dataLabels: {
          enabled: true,
          textAnchor: 'start',
          style: {
            colors: ['#000'],
          },
          formatter: function (val: any, opt: any) {
            let rhours = Math.floor(val / 60);
            let rminutes = Math.round((val / 60 - rhours) * 60);
            let timeStr =
              rhours > 0 ? (rhours == 1 ? rhours + ' h ' : rhours + ' h ') : '';
            timeStr += rminutes == 0 ? '' : rminutes + ' m';
            //return timeStr;
            return  timeStr;
          },
          offsetX: 0,
          dropShadow: {
            enabled: false,
          },
        },
        stroke: {
          width: 1,
          colors: colorArr,
        },
        xaxis: {
          categories: labelsArr,
        },
        yaxis: {
          labels: {
            show: true,
          },
        },
        title: {
          text: 'Task Progress Status',
          align: 'center',
          floating: true,
        },
        tooltip: {
          theme: 'dark',
          x: {
            show: true,
          },
          y: {
            title: {
              formatter: function () {
                return '';
              },
            },
          },
        },
      };
    }
  }

  getModuleType() {
    this.rest.getData({}, 'getmoduletypes').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.ModuleTypeList = data.data;
        } else {
          this.docStatus = !this.docStatus;
          this.toaster.warning(data.message);
        }
      },
      error: (e) => {
        this.docStatus = !this.docStatus;
        console.log(e);
        this.toaster.error("Something went wrong!");
      },
    });
  }
}
