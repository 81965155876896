import { Component, OnInit,ViewChild } from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import { PagesService } from '../../pages.service';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-employees-dashboard',
  templateUrl: './employees-dashboard.component.html',
  styleUrls: ['./employees-dashboard.component.scss']
})
export class EmployeesDashboardComponent implements OnInit {

  public empData             : any;
  public absentEmp           : any;
  public displayData         : any;
  public empWeekOff          : any;
  public empLeave            : any;
  public empPresent          : any;
  public taskListModal       : any;
  public objKey              : any;
  public hsl                 : any;
  public modal               = false;
  public menuBoxStatus       = false;
  public module              = false;
  public task                = false;
  public isDisable: boolean  = true;
  public loadAllComponant    = false;
  public totalEmpList:any    = []; 
  public empDetails:any      = []; 
  public empTasks:any        = []; 
  public reminders:any       = []; 
  public attendance:any      = [];
  public timesheets:any      = []; 
  public teamMembers:any     = [];
  public empReportArr:any    = [];
  public projects:any        = [];
  public taskTimeList        = <any>[];
  public empInfo             = <any>[];
  public empReport:number    = 0;
  public currentDay          = new Date().toLocaleDateString('en-EN', { weekday: 'long' });
  public today:any           = new Date().toISOString().slice(0, 10);
  public id                  = '';
  public empDayStatus        = '';
  public t_id: string        = '';
  public multiSearch: string = '';
  public emp_id              = '';
  public tsTitle             = 'Last 30 Days';
  public Math                = Math;
  constructor(
    public rest        : RestService,
    private formBuilder: FormBuilder,
    private route      : ActivatedRoute,
    private router     : Router,
    public ngxService  : NgxUiLoaderService,
    private toaster    : ToastrService,
    public pageService : PagesService,
  ) {}

  ngOnInit(): void {
    this.getDataByEmp(this.id,'month');
    this.employeeGeneralTab();
  }
  /* api call for fetching employee info */
  employeeGeneralTab(){
    let defaultDate = new Date().toISOString().slice(0, 10);
    this.ngxService.start();
    this.loadAllComponant = true;
    this.rest.getData({}, 'getemployees').subscribe({
      next:(data)=>{        
        this.ngxService.stop();
        if(data.state == '200'){
          this.empData = data.data.filter((obj:any) => obj.status === 1);
          // this.absentEmp  = this.empData.filter((data:any) => data.status === 'absent');
          // this.empWeekOff = this.empData.filter((data:any) => data.status === 'week off');
          // this.empLeave   = this.empData.filter((data:any) => data.status === 'leave');
          // this.empPresent = this.empData.filter((data:any) => data.status === 'present');
        }else{
          this.loadAllComponant = true;
          this.toaster.error(data.message);
        }
      },
      error:(e)=>{
        this.ngxService.stop();
        this.toaster.error('Something went wrong!');
      },
    });
  }
 
  getDataByEmp(id:any,range=''){
    if(id !== undefined || id !== ''){
      this.emp_id = id;
      let e_id    = id;
      this.empReport = 0;
      this.ngxService.start();
      this.loadAllComponant = true;
      this.rest.getData({u_id:e_id,range:range}, 'getemployeedata').subscribe({
        next:(data)=>{        
          this.ngxService.stop();
          if(data.state == '200'){
            this.empDetails   = data.data;
            this.empTasks     = data.data.tasks;
            this.reminders    = data.data.reminders;
            this.attendance   = data.data.attendanceCount;
            this.timesheets   = data.data.timesheets;
            this.empReport    = data.data.working_hours;
            this.teamMembers  = data.data.team_members;
            this.empInfo      = data.data.employee_info;
            this.projects     = data.data.projects;
            this.getEmpWorkingReport(this.empInfo.u_id,'month');
            this.empDayStatus = (this.empDetails.week_off === this.currentDay)? 'Today':this.empDetails.week_off;
          }else{
            this.loadAllComponant = true;
            this.empReport = 0;
            this.toaster.error(data.message);
          }
        },
        error:(e)=>{
          this.ngxService.stop();
          this.toaster.error('Something went wrong!');
        },
      });
    }
  }

  getEmpWorkingReport(id:any, range:any) {
    this.empReport = 0;
    this.rest.getData({ id: id, range: range }, 'empworkinghours').subscribe({
      next: (data) => {
        if (data.state == '200') {
          for(let h of data.data){
            this.empReport += h.effort
          }
          console.log("hhh",this.empReport);
          this.empReportArr = data.data;
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.toaster.error('Something went wrong!');
      },
    });
  }
  
  encodeURIParam(param:any){
    return param.replace('/','_');
  }
  project(){
    this.module = !this.module;
  }
  menuBox(){
    this.menuBoxStatus != this.menuBoxStatus;
  }
  moduleData(){
    this.task = !this.task;
  }
  modalCtrl(data:any = []) {
    this.modal = !this.modal;
    if(data !== null || data !== undefined){
      this.taskListModal = data.t_details;
    }
  }

  searchType(event: any) {
      let searchkey = event.target.value;
      if (searchkey.length > 2) {
        this.objKey = searchkey;
      } else if (searchkey.length == 0) {
        this.objKey = searchkey;
        this.id = '';
        this.getDataByEmp(this.id,'month');
      }
  }

  getTimeSheetData(id: string,ts_name:string) {
    if (id != '' && id != undefined && id != null ) {
      let range = 'month';
      let u_id = this.empInfo.e_id;
      this.rest.getData({ task_id: id,user_id:u_id,range:range }, 'gettimesheetsbytaskid').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.tsTitle = ts_name;
            this.timesheets = data.data.timesheets;
            console.log(this.timesheets);
          } else {
            this.timesheets = [];
            this.tsTitle = 'Last 30 Days';
            console.log(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }

  //service functions

  convertColor(string:any) {
    this.hsl = this.pageService.convertNameToColor(string);
  }
  convertToLetter(letter:any) {
    this.convertColor(letter);
    return this.pageService.getFirstLetters(letter);
  }

  humanize(str:any) {
    str = str.replace(/[$@%-/_/]/g, ' ');
    return str.toUpperCase();
  }
  timeConvert(totalMinutes:any) {
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);
    let h = this.padTo2Digits(hours);
    let m = this.padTo2Digits(minutes);
    return this.empDetails.workingDay*8+'/'+h+'.'+m;
  }
  
  padTo2Digits(num:any) {
    return num.toString().padStart(2, '0');
  }
  minTohr(a:any){
    let hours   = Math.trunc(a/60);
    let minutes = a % 60;
    return hours +"."+ minutes+" H";
  }
}
