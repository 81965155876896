

<div class="main-content">
  <div class="ctm-border-radius  grow card">
    <div id="basic1" class="card-header d-flex align-center justify-content-between">
      <div class="card_title_left">
        <h4 class="card-title  mb-0 mt-2"> <b><i class="las la-info-circle"></i>Create Rosters </b></h4>
      </div>
      <a routerLink="/roster-list" class="custom-btn btn-8 text-center">
        <span>
          <i class="las la-plus"></i>Roster List
        </span>
      </a>
    </div>

    <div class="card-body">
      <div class="timetable-container">
        <div class="row">
          <div class="col-lg-6 form-group">
            <label for="shift-name">Roster Name</label>
            <div class="input-group mb-3">
              <div class="input-group-text"><i class="las la-globe"></i></div>
              <input type="text" id="shift-name" class="form-control" placeholder="Enter Roster Name"
                [(ngModel)]="shiftName" required aria-label="Shift Name" />
            </div>
          </div>

          <div class="col-lg-6 form-group">
            <label for="user" (click)="changeCountry()">Employee {{country=='Bangaldesh'?'BD':'IND'}}</label>
            <div class="input-group mb-3">
              <select id="user" class="form-control" [(ngModel)]="selectedUser" required
                aria-label="Employee Selection">
                <option value="">Select Employee</option>
                <ng-container *ngFor="let emp of employeeList">
                  <option [value]="emp.empid">
                    {{ emp.name }} / {{ getCountryCode(emp.country) }}
                  </option>
                </ng-container>
              </select>
            </div>
          </div>

          <div class="col-lg-6 form-group">
            <label for="startDate">Start Date:</label>
            <div class="input-group mb-3">
              <input type="date" class="form-control" id="startDate" [(ngModel)]="startDate" />
            </div>
          </div>

          <div class="col-lg-6 form-group">
            <label for="endDate">End Date:</label>
            <div class="input-group mb-3">
              <input type="date" class="form-control" id="endDate" [(ngModel)]="endDate" />
            </div>
          </div>
          <!-- Create Button -->
          <div class="col-12 col-lg-12 form-group">
            <button (click)="toggleContent()" type="button" class="btn btn-primary w-100" aria-label="Create Timetable">
              Create
            </button>
          </div>

        </div>

        <!-- Timetable Form -->
        <div *ngIf="isContentVisible" class="collapsible-content mt-4">
          <form #timetableForm="ngForm" (ngSubmit)="openModal()">
            <h3>Credit {{ shiftName | titlecase }} Timetable</h3>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Day</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Week Off</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let entry of timetableData.data; let i = index">
                    <td>{{ entry.date }}</td>
                    <td>{{ entry.day }}</td>
                    <td>
                      <input type="time" [(ngModel)]="entry.startTime" name="startTime-{{ i }}" required />
                    </td>
                    <td>
                      <input type="time" [(ngModel)]="entry.endTime" name="endTime-{{ i }}" required />
                    </td>
                    <td>
                      <input type="checkbox" [(ngModel)]="entry.weekoff" name="weekOff-{{ i }}"
                        class="form-check-input roster_days_checkbox" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button type="submit" class="btn btn-success" aria-label="Review Timetable">
              Review
            </button>
          </form>
        </div>

        <!-- Modal for Review -->
        <div class="modal" [ngClass]="{ 'show': showModal }" role="dialog" aria-labelledby="review-modal-title"
          aria-hidden="!showModal">
          <div class="modal-content">
            <h3 id="review-modal-title">Review and Edit {{ shiftName | titlecase }} Timetable</h3>
            <div class="table-responsive">
              <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>Date</th>
                    <th>Day</th>
                    <th>Start Time</th>
                    <th>End Time</th>
                    <th>Week Off</th>
                  </tr>
                </thead>
                <tbody>
                  <tr *ngFor="let entry of timetableData.data; let i = index">
                    <td>{{ entry.date }}</td>
                    <td>{{ entry.day }}</td>
                    <td>
                      <input type="time" (ngModelChange)="autoFillTimes(i, 'startTime', $event)"
                        [(ngModel)]="entry.startTime" name="modalStartTime-{{ i }}" />
                    </td>
                    <td>
                      <input type="time" (ngModelChange)="autoFillTimes(i, 'endTime', $event)"
                        [(ngModel)]="entry.endTime" name="modalEndTime-{{ i }}" />
                    </td>
                    <td>
                      <input type="checkbox" [(ngModel)]="entry.weekoff" (change)="onWeekOffChange(i, $event)"
                        name="modalWeekOff-{{ i }}" class="form-check-input roster_days_checkbox" />
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <button (click)="finalSubmit()" class="btn btn-primary" aria-label="Submit Final Timetable">
              Submit
            </button>
            <button (click)="closeModal()" class="btn btn-secondary" aria-label="Close Modal">
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
