import { Component, OnInit } from '@angular/core';
import { RestService } from '../../rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import {SessionStorageService,LocalStorageService} from 'ngx-webstorage';
import { AuthServiceService } from '../../auth-service.service';
import { Router} from '@angular/router';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  myStyle: object = {};
  myParams: object = {};
  width: number = 100;
  height: number = 100;
  loginForm!: FormGroup;
  error=false;

  constructor(public rest:RestService,private formBuilder: FormBuilder , private ls: LocalStorageService , private auth:AuthServiceService, private route:Router) { }

  ngOnInit(): void {
    this.myStyle = {
      'position': 'absolute',
      'width': '100%',
      'height': '100%',
      'z-index': 5,
      'top': 0,
      'left': 0,
      'right': 0,
      'bottom': 0,
  };

this.myParams = {
      particles: {
          number: {
              value: 200,
          },
          color: {
              value: '#ff0000'
          },
          shape: {
              type: 'triangle',
          },
  }
};
    this.loginForm = this.formBuilder.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
   });
  }

  onSubmit(form: FormGroup){

    this.error=false;
    
    if (form.invalid) {
      return;
    }

    //console.log(JSON.stringify(form.value));

    this.rest.getData(form.value,"login").subscribe({
    next: (data)=>{

       console.log(data);
       this.saveSession(data);
    },
    error: (e)=>{

      console.log(e);
      this.error=true;
    }
   })
  }

  saveSession(data:any){

    this.ls.store("user",data);
    this.auth.setSecureToken(data.data.session);
    this.route.navigate(['/my-dashboard']);
  }

}
