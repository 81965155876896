import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService, SessionStorageService } from 'ngx-webstorage';
import {Md5} from 'ts-md5'

@Injectable({
  providedIn: 'root',
})
export class AuthServiceService {
  constructor(private router: Router, private localSt: LocalStorageService) {}

  setSecureToken(secure_token: string) {
    this.localSt.store('loghash', secure_token);

    console.log(secure_token);
  }

  // Set User Secure Token
  getSecureToken() {
    return this.localSt.retrieve('loghash');
  }

  // Check User is LoggedIn or not!
  isLoggednIn() {
    return this.getSecureToken() !== null;
  }

  // Logout method
  logout() {
    this.localSt.clear();
    //this.router.navigate(['']);
    this.router.navigate(['login']);
  }

  calllogin() {
    this.localSt.store('calllogin', 'trueval');
  }

  checkUrl(url: string) {
    if (url.includes('?')) {
      url = url.split('?')[0];
    }
    console.log(url);
    url = Md5.hashStr(url);
    let usr = this.localSt.retrieve('user');
    const pathArray = usr.data.paths;
    if (pathArray.indexOf(url) != -1) {
      return true;
    } else {
      return false;
    }
  }

  checkParameterizedUrl(url: string,param:any) {
    let newUrl='';
    param.forEach((element:any) => {
      let tempUrl=url.split('/')[element];
      newUrl = newUrl+"/"+tempUrl;
    });
    url = Md5.hashStr(newUrl);
    let usr = this.localSt.retrieve('user');
    const pathArray = usr.data.paths;
    if (pathArray.indexOf(url) != -1) {
      return true;
    } else {
      return false;
    }
  }
}
