import { ChangeDetectorRef, OnInit,Input, } from '@angular/core';
import { ViewportScroller } from "@angular/common";
import { Component,ChangeDetectionStrategy,ViewChild,TemplateRef} from '@angular/core';
import {startOfDay,endOfDay,subDays,addDays,endOfMonth,isSameDay,isSameMonth,addHours} from 'date-fns';
import { Subject } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import {CalendarEvent,CalendarEventAction,CalendarEventTimesChangedEvent,CalendarView} from 'angular-calendar';
import { RestService } from '../../rest.service';
import { formatDate } from '@angular/common';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { ToastrService } from 'ngx-toastr';
import { NgForm } from '@angular/forms';
import Swal from 'sweetalert2';
import { PagesService } from '../pages.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Console } from 'console';
const colors: any = {
  red: {
    primary: '#ad2121',
    secondary: '#FAE3E3',
  },
  blue: {
    primary: '#1e90ff',
    secondary: '#D1E8FF',
  },
  yellow: {
    primary: '#e3bc08',
    secondary: '#FDF1BA',
  },
  green: {
    primary: '#2bad0a',
    secondary: '#9ff788',
  },
};

@Component({
  selector: 'app-timesheet',
  changeDetection: ChangeDetectionStrategy.OnPush,
  templateUrl: './timesheet.component.html',
  styleUrls: ['./timesheet.component.scss'],
})
export class TimesheetComponent implements OnInit {
  @Input() user_id: any;
  @ViewChild('modalContent', { static: true }) modalContent:
    | TemplateRef<any>
    | undefined;
  @ViewChild('timesheetdata') timesheetdata: any;

  view: CalendarView = CalendarView.Month;

  public CalendarView = CalendarView;
  public viewDate: Date = new Date();
  public viewAdEventDate: Date = new Date;
  public now: any;
  public reminderCard = false;
  public prevDay = true;
  public nxtDay = false;
  public getReminders = <any>[];
  public getToday: any = new Date();
  public remList = <any>[];
  public assigneTask: any = [];
  public switchStatus = 'task';
  public plusSign = '+';
  public showloader: boolean = false;
  public startDate: any;
  public isAdmin = false;
  public isShown = false;
  public u_id:any;
  public hsl           : any;
  public username:string="";
  public minDateLimit: any;
  public isOverflow = false;
  public overFlowReason: any;
  public overFlowSheetId: any;
  public exceedTime: any;
  public resetSelection:any;
  public isShow = false;
  public timesheetInfoData = <any>[];
  // public timesheetButton = "Submit";
  public timesheetId = '';
  public isNewEntry:boolean = true;
  public disableTask = false;
  public timesheetFormTitle = "Add Timesheet"
  modalData:
    | {
      action: string;
      event: CalendarEvent;
    }
    | undefined;
  public timeSheetData = false;
  actions: CalendarEventAction[] = [
    {
      label: '',
      a11yLabel: 'Edit',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.handleEvent('Edited', event);
      },
    },
    {
      label: '',
      a11yLabel: 'Delete',
      onClick: ({ event }: { event: CalendarEvent }): void => {
        this.events = this.events.filter((iEvent) => iEvent !== event);
        this.handleEvent('Deleted', event);
      },
    },
  ];

  refresh = new Subject<void>();

  events: CalendarEvent[] = [];

  activeDayIsOpen: boolean = false;

  public isShowBLoadBtn = true;
  public remimderForm!: FormGroup;

  timeSheetToSave = {
    start      : <any>'',
    end        : <any>'',
    date       : <any>'',
    task_id    : '',
    description: '',
    reason     : ''
  };

  constructor(
    private modalService  : NgbModal,
    public rest           : RestService,
    private cd            : ChangeDetectorRef,
    public ngxService     : NgxUiLoaderService,
    public pageService    : PagesService,
    private formBuilder   : FormBuilder,
    private toaster       : ToastrService,
    private activatedroute: ActivatedRoute,
    private scroller      : ViewportScroller,
  ) { }

  dayClicked({ date, events }: { date: Date; events: CalendarEvent[] }): void {
    if (isSameMonth(date, this.viewDate)) {
      this.viewDate = date;
      if (
        (isSameDay(this.viewDate, date) && this.activeDayIsOpen === true) ||
        events.length === 0
      ) {
        //this.activeDayIsOpen = true;
        if (this.verifydate()) {
          // this.activeDayIsOpen = true;
          //this.timeSheetData = true;
        } else {
          //this.timeSheetData = false;
        }
      } else {
        // this.activeDayIsOpen = true;
        //this.timeSheetData = false;
      }
    }
    this.getMyReminderList(this.viewDate);
    this.scroller.scrollToAnchor("taskView");

  }
  dateChangePop(ev: any) {
    if (ev == "prev") {
      this.viewAdEventDate = new Date(this.viewAdEventDate.setDate(this.viewAdEventDate.getDate() - 1));
      this.prevDay         = false;
      this.nxtDay          = true;
    } else if (ev == "next") {
      this.prevDay         = true;
      this.nxtDay          = false;
      this.viewAdEventDate = new Date(this.viewAdEventDate.setDate(this.viewAdEventDate.getDate() + 1));

    }
  }
  eventTimesChanged({
    event,
    newStart,
    newEnd,
  }: CalendarEventTimesChangedEvent): void {
    this.events = this.events.map((iEvent) => {
      if (iEvent === event) {
        return {
          ...event,
          start: newStart,
          end: newEnd,
        };
      }
      return iEvent;
    });
    this.handleEvent('Dropped or resized', event);
  }

  handleEvent(action: string, event: CalendarEvent): void {
    this.modalData = { event, action };
    //this.modal.open(this.modalContent, { size: 'lg' });
  }

  addEvent(): void {
    this.events = [
      ...this.events,
      {
        title: 'New Task',
        start: startOfDay(this.viewAdEventDate),
        end: endOfDay(this.viewAdEventDate),
        color: colors.yellow,
        meta: 'taskname',
      },
    ];
  }

  deleteEvent(eventToDelete: CalendarEvent) {
    this.timeSheetData = false;
    Swal.fire({
      title: 'warning!',
      text: 'Do you want to delete this entry?',
      icon: 'warning',
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#ea556f',
      iconColor:"#ea556f",
      cancelButtonText: 'No',
      showCancelButton: true,
      confirmButtonText: 'Yes',
    }).then((result) => {
      if (result.value) {
        this.ngxService.start();
        this.events = this.events.filter((event) => event !== eventToDelete);

        let DeletePayload = {
          entry_date: formatDate(eventToDelete.start, 'yyyy-MM-dd', 'en-US'),
          start_time: eventToDelete.start.toLocaleTimeString([], {
            hour12: false,
          }),
          end_time: eventToDelete.end?.toLocaleTimeString([], {
            hour12: false,
          }),
        };

       // console.log(DeletePayload);

        this.rest.getData(DeletePayload, 'deltimesheetself').subscribe({
          next: (data) => {
           // console.log(data);
            this.toaster.success('Deleted Successfully');
            this.ngxService.stop();
            this.getTimesheet();
            this.timeSheetData = true;
          },
          error: (e) => {
            this.toaster.error('something Went Wrong');
            console.log(e.message);
            this.ngxService.stop();
            //this.error=true;
          },
        });
      }
    });
  }
  // saveEvent(eventToSave: CalendarEvent) {
  //   this.ngxService.start();
  //   let SavePayload = {
  //     start_time: eventToSave.start.toLocaleTimeString([], { hour12: false }),
  //     end_time: eventToSave.end?.toLocaleTimeString([], { hour12: false }),
  //     entry_date: formatDate(this.viewAdEventDate, 'yyyy-MM-dd', 'en-US'),
  //     task_id: eventToSave.meta,
  //     comment: eventToSave.title,
  //   };
  //   this.rest.getData(SavePayload, 'addtimesheetself').subscribe({
  //     next: (data) => {
  //       this.ngxService.stop();
  //       //this.saveSession(data);
  //       //  this.ngxService.stop();
  //       this.toaster.success('Task Sucessfully Submitted');
  //       this.getTimesheet();
  //     },
  //     error: (e) => {
  //       //console.log(e.message);
  //       //  this.ngxService.stop();
  //       this.ngxService.stop();
  //       //this.error=true;
  //     },
  //   });
  // }

  saveTimesheet() {
    let valid = this.timesheetdata.valid;
    if (valid) {
      let SavePayload = {
        start_time: this.timeSheetToSave.start.toLocaleTimeString([], {
          hour12  : false,
        }),
        end_time  : this.timeSheetToSave.end?.toLocaleTimeString([], {
          hour12  : false,
        }),
        entry_date: formatDate(this.viewAdEventDate, 'yyyy-MM-dd', 'en-US'),
        task_id   : parseInt(this.timeSheetToSave.task_id),
        comment   : this.timeSheetToSave.description,
        reason    : (this.isOverflow == true)?this.timeSheetToSave.reason:"",
        overflow  : (this.isOverflow == true)?true:false
      };
      // if(this.isOverflow == true){
      //   if(this.timeSheetToSave.reason.replace(/ /g,'').length < 25){
      //     this.toaster.error('Minimum characters length should be 25 characters!');
      //     return;
      //   }
      //   SavePayload.reason   = this.timeSheetToSave.reason;
      //   SavePayload.overflow = true;
      // }
      if(SavePayload.overflow == true){
        if(SavePayload.reason.replace(/ /g,'').length < 15){
          this.toaster.error('Reason must be described with minimum 15 characters!', 'Error', { timeOut: 2000 });
          return;
        }
      }
      // this.ngxService.start();
      this.rest.getData(SavePayload, 'addtimesheetself').subscribe({
        next: (data) => {
          // this.ngxService.stop();
          if (data.state == '200' || data.state == 200) {
            this.toaster.success('Task Sucessfully Submitted');
            this.getTimesheet();
            this.isShow = false;
            this.resetForm();
          } else {
            this.toaster.error('Something Went Wrong!');
          }
        },
        error: (e) => {
          console.log(e.error.message);
          // this.ngxService.stop();
          this.toaster.error(e.error.message);
          //this.error=true;
        },
      });
    } else {
      // this.ngxService.stop();
      this.toaster.error('***All Fields are Required!');
    }
  }

  resetForm() {
    let lastTime                     = this.timeSheetToSave.end;
    this.timeSheetToSave.description = '';
    this.timeSheetToSave.task_id     = '-1';
    this.timeSheetToSave.reason     = '';
    this.timeSheetToSave.start       = new Date(
      this.timeSheetToSave.end.getTime() + 5 * 60000
    );
    this.timeSheetToSave.end         = new Date(
      this.timeSheetToSave.end.getTime() + 20 * 60000
    );
  }

  setView(view: CalendarView) {
    this.view = view;
  }

  closeOpenMonthViewDay() {
    this.getTimesheet();
    this.activeDayIsOpen = false;
  }
  ngOnInit(): void {
    console.log(this.timeSheetToSave);
   // this.u_id = this.activatedroute.snapshot.queryParamMap.get('u_id');
   this.u_id=this.user_id
    if(this.u_id!==null && this.u_id!==undefined){
      this.isAdmin=true;
    }
    this.getTimesheet();
    this.remimderForm = this.formBuilder.group({
      title        : [null, [Validators.required]],
      description  : [null, [Validators.required]],
      reminder_date: [null, [Validators.required]],
      r_id         : [null],
      memberlist   : [null],
      reminder_time: [null],
      priority     : [null, [Validators.required]]
    });
    this.setMinMaxDate();
  }

  getTimesheet() {
    let month = this.viewDate.getMonth() + 1;
    let year  = this.viewDate.getFullYear();

    let payload:any = {
      entry_month: month,
      entry_year: year
    };
    if(this.u_id!==null && this.u_id!==undefined){
      payload.u_id=this.u_id
    }

    this.rest.getData(payload, 'gettimesheetdayself').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.ngxService.stop();
          this.createCalaendarData(data.data.timesheet);
          this.username=data.username;
          this.assigneTask = data.data.task;
        }
        // creating calander data
        this.ngxService.stop();
        this.cd.detectChanges();
        this.getMyReminderList(new Date());
      },
      error: (e) => {
        console.log(e);
      },
    });
  }

  getMyReminderList(date: any) {
    if (date != '' && date != undefined) {
      let remDate = formatDate(date, 'yyyy-MM-dd', 'en');
      let payload:any = {
        r_date: remDate,
      };
      if(this.u_id!==null && this.u_id!==undefined){
        payload.u_id=this.u_id
      }
      this.rest.getData(payload, 'getreminderbydate').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.getReminders = data.data;
            this.remList = this.pageService.loadMore(this.getReminders);
            this.reminderCard = true;
          } else {
            this.getReminders = [];
            this.reminderCard = false;
          }
          this.cd.detectChanges();
        }
      })
    }
  }

  showMoreRem() {
    this.isShowBLoadBtn = false;
    setTimeout(() => {
      this.isShowBLoadBtn = true;
      this.remList = this.pageService.loadMore(this.getReminders, this.remList.length);
      this.cd.detectChanges();
    }, 1000);

  }

  createCalaendarData(data: any) {
    this.events = [];
    for (let dt of data) {
      let obj = {
        start  : new Date(dt.entry_date + ' ' + this.converTime(dt.start_time)),
        end    : new Date(dt.entry_date + ' ' + this.converTime(dt.end_time)),
        title  : dt.comment,
        color  : colors.green,
        actions: this.actions,
        meta   : {
          task_name: dt.task_name,
          p_name   : dt.p_name,
          p_id     : dt.p_id,
          m_name   : dt.m_name,
          m_id     : dt.m_id,
          t_id     : dt.t_id,
          overflow : dt.overflow
        },        
      };
      this.events.push(obj);
      this.refresh.next();
    }
   console.log(this.events);
  }
  
  verifydate() {
    let curdate  = new Date();
    let prevdate = new Date();
    prevdate.setDate(curdate.getDate() - 1);
    if (
      this.viewDate.toLocaleDateString() == curdate.toLocaleDateString() ||
      this.viewDate.toLocaleDateString() == prevdate.toLocaleDateString()
    ) {
      return true;
    } else {
      return false;
    }
  }
  close_timeSheetData() {
    this.timeSheetData   = false;
    this.activeDayIsOpen = false;
  }
  addEventBtn() {
    this.timeSheetData   = !this.timeSheetData;
    this.viewAdEventDate = new Date();
    this.nxtDay = false;
    this.prevDay = true;
  }
  switch(ev: any) {
      this.switchStatus = ev;
  
  }

  addEditReminder(form: FormGroup) {
    if (form.invalid) {
      return;
    } else {
      let apiPayload = { ...form.value };
      apiPayload.reminder_date = this.dateConvert(apiPayload.reminder_date)
      this.rest.getData(apiPayload, 'createreminder').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.toaster.success(data.message);
            this.getMyReminderList(apiPayload.reminder_date);
            form.reset();
          } else {
            this.toaster.success(data.message)
          }
        }
      })
    }
  }

  dateConvert(event: any) {
    let year  = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day   = event.day <= 9 ? '0' + event.day : event.day;
    const convertDate = year + '-' + month + '-' + day;
    return convertDate;
  }

  onDateSelect(event: any) {
    this.isShown   = true;
    let year       = event.year;
    let month      = event.month <= 9 ? '0' + event.month : event.month;;
    let day        = event.day <= 9 ? '0' + event.day : event.day;;
    this.startDate = year + "-" + month + "-" + day;
  }

  converTime(time:string){
    let splitTime:any = time.split(':');
    let stime         = splitTime[0]=='24'?'00':splitTime[0];
    return stime+':'+splitTime[1]+':'+splitTime[2];
  }

  setMinMaxDate() {
    let today        = new Date();
    let tomorrowDate = today.getDate();
    today.setDate(tomorrowDate);
    this.minDateLimit = {
      year : parseInt(formatDate(today, 'yyyy', 'en')),
      month: parseInt(formatDate(today, 'MM', 'en')),
      day  : parseInt(formatDate(today, 'dd', 'en'))
    }
  }

  encodeURIParam(param:any){
    return param.replace('/','_');
  }

  searchInObj(nameKey:number, myArray:any){
    for (let i=0; i < myArray.length; i++) {
        if (myArray[i].id == nameKey) {
            return myArray[i];
        }
    }
  }
 
  isOverFlowTask(data:number){
    if(data !== undefined){
      let task          = this.searchInObj(data, this.assigneTask);
      let estimatedTime = task.estimated_pd;
      let takenTime     = task.taken_hours;
      this.exceedTime   = task.result_hour;
      if(takenTime > estimatedTime){
        this.isShow     = true;
        this.isOverflow = true;
      }else{
        this.isShow     = false;
        this.isOverflow = false;
      }
    }
  }

  convertColor(string:any) {
    this.hsl = this.pageService.convertNameToColor(string);
  }

  convertToLetter(letter:any) {
    this.convertColor(letter);
    return this.pageService.getFirstLetters(letter);
  }

  open(tsArray:any=[]): void {
    console.log(tsArray);
  }


}
