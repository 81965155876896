import { Component, OnInit } from '@angular/core';
import * as XLSX from 'xlsx';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { RestService } from 'src/app/rest.service';
import { ToastrService } from 'ngx-toastr';
import { LocalStorageService } from 'ngx-webstorage';
@Component({
  selector: 'app-holiday',
  templateUrl: './holiday.component.html',
  styleUrls: ['./holiday.component.scss'],
})
export class HolidayComponent implements OnInit {
  file: File = <any>[];
  arrayBuffer: any;
  filelist: any;
  public holidayList = <any>[];
  public userRoles: any = [];
  public filter_status = false;
  public finalDate: any;
  public loadAllComponant = false;
  public loggedInUserName: string = '';
  constructor(
    public ngxService: NgxUiLoaderService,
    public rest: RestService,
    private toaster: ToastrService,
    public ls: LocalStorageService
  ) {}

  ngOnInit(): void {
    this.getUserData();
    this.userRoles = this.ls.retrieve('user').data.roles;
    this.getHoliday();
  }

  getUserData() {
    this.rest.getData({}, 'getloginuser').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.loggedInUserName = data.data.name;
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning("Authorization error!!");
      },
    });
  }

  filter_status_btn() {
    this.filter_status = !this.filter_status;
  }
  onDateSelect(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.finalDate = year + '-' + month + '-' + day;
    this.getHoliday();
  }
  /* extract excel file data */
  uploadFile(ev: any) {
    if (ev.target.files.length == 1) {
      this.file = ev.target.files[0];
      let fileReader = new FileReader();
      fileReader.readAsArrayBuffer(this.file);
      fileReader.onload = (e) => {
        this.arrayBuffer = fileReader.result;
        let data = new Uint8Array(this.arrayBuffer);
        let arr = new Array();
        for (let i = 0; i != data.length; ++i)
          arr[i] = String.fromCharCode(data[i]);
        let bstr = arr.join('');
        let workbook = XLSX.read(bstr, { type: 'binary' });
        let first_sheet_name = workbook.SheetNames[0];
        let worksheet = workbook.Sheets[first_sheet_name];
        this.filelist = XLSX.utils.sheet_to_json(worksheet, {
          raw: false,
          dateNF: 'yyyy-mm-dd',
        });
        console.log(this.filelist);
        this.uploadHoliday();
      };
    }
  }
  upload() {
    document.getElementById('upload-input')?.click();
  }
  /* add holiday list */
  uploadHoliday() {
    if (this.filelist.length > 0) {
      this.ngxService.start();
      this.rest.getData({ list: this.filelist }, 'uploadholidays').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.getHoliday();
          } else {
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }
  /* This function is used to fetching holiday list */
  getHoliday() {
    if (this.holidayList.length == 0) {
      this.ngxService.start();
      this.rest.getData({ list: this.filelist }, 'getholidaylist').subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.loadAllComponant = true;
          if (data.state == '200') {
            this.holidayList = data.data;
          } else {
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.loadAllComponant = true;
          this.toaster.error('Something went wrong');
        },
      });
    }
  }
}
