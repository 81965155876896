import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AlertService {

  private subject = new Subject<any>();

  public alertBox = {

     "alertdata" : "",
     "alerttype" : "info"
  }

  alertWatcher(){

    return this.subject.asObservable();
  }

  openAlert(data:string,type:string){

    this.alertBox.alertdata=data;
    this.alertBox.alerttype=type;
    this.subject.next(this.alertBox);
  }



  constructor() { }
}
