import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import {
  ActivatedRoute,
  NavigationStart,
  NavigationExtras,
  Router,
} from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PagesService } from '../../pages.service';
import { LocalStorageService } from 'ngx-webstorage';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-upload-attendance',
  templateUrl: './upload-attendance.component.html',
  styleUrls: ['./upload-attendance.component.scss']
})
export class UploadAttendanceComponent implements OnInit {
  public fileType = [
    'image/jpeg', //image
    'image/png', //image
    'image/gif', //image
    'text/csv', //CSV
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //XLSX
    'application/vnd.ms-excel', //XLS
    'application/x-zip-compressed', //zip
    'application/pdf', //PDF
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //DOCX
  ];

  public formData = new FormData();
  public file: any;
  public isShowBtn = true;
  public isShow = true;
  public attendanceList: any;
  public nameSearch: any;
  public dateSearch: any;
  public selectedfield = '';
  public searchKey = '';
  public filter_status = false;
  public nameFilterList = <any>[];
  public dateFilterList = <any>[];
  public objKey: any;
  public multiSearch: string = '';

  constructor(
    public rest: RestService,
    private formBuilder: FormBuilder,
    private route: ActivatedRoute,
    public ngxService: NgxUiLoaderService,
    private router: Router,
    private toaster: ToastrService,
    public pageService: PagesService,
    private localSt: LocalStorageService
  ) { }

  ngOnInit(): void {
    //this.getAttendanceList();
  }
  /* upload attendance list and fetch attendance list */
  uploadCsv(event: any) {
    this.ngxService.start();
    this.isShow = false;
    if (event.target.files.length > 0) {
      this.formData.append('files', event.target.files[0]);
      console.log(this.formData.get('files'))
      this.rest.getData(this.formData, 'uploadattendancecsv', true).subscribe({
        next: (data) => {
          console.log(data);
          if (data.state == '200') {
            this.toaster.success(data.message);
            if(data.data.length>0){
              this.attendanceList=data.data;
            }
            if(data.errorData.length>0){
              this.pageService.exportExcel(data.errorData);
            }
          } else {
            this.toaster.error(data.message);
          }
          this.ngxService.stop();
        },
        error: (e) => {
          this.ngxService.stop();
          this.isShow = true;
          this.toaster.error('Something went wrong');
        },
      });
    }
  }

  upload() {
    document.getElementById('upload-input')?.click();
  }

 /*  getAttendanceList(){
    this.ngxService.start();
    this.rest.getData({}, 'getattendancelist').subscribe({
      next: (data) => {
        this.ngxService.stop();
        console.log(data);
        if (data.state == '200') {
          this.attendanceList = data.data;
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.toaster.error('Something went wrong!!');
      },
    });
  } */

  
  deSelect(event: any, filedType: string) {
    this.selectedfield = filedType;
    if (filedType === 'name') {
      this.dateSearch = undefined;
      this.searchKey = this.nameSearch;
    } else if (filedType === 'created_at') {
      this.nameSearch = undefined;
      this.searchKey = this.dateSearch;
    }
  }
  filter_status_btn() {
    this.filter_status = !this.filter_status;
  }

  createFilterData() {
    let nFilterList = [];
    let dFilterList = [];
    for (let attendance of this.attendanceList) {
      nFilterList.push(attendance.name);
      dFilterList.push(attendance.created_at);
    }
    // Making all array unique
    this.nameFilterList = [...new Set(nFilterList)];
    this.dateFilterList = [...new Set(dFilterList)];
  }

  searchType(event: any) {
    let searchkey = event.target.value;
    if (searchkey.length > 2) {
      this.objKey = searchkey;
    } else if (searchkey.length == 0) {
      this.objKey = searchkey;
    }
  }

}
