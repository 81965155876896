import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TableSearchPipe} from './table-search.pipe';



@NgModule({
  declarations: [
    TableSearchPipe
  ],
  imports: [
    CommonModule
  ],
  exports:[
    TableSearchPipe
  ]
})
export class SharedSearchModule { }
