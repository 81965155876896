<div class="main_content" *ngIf="loadAllComponant">
  <div class="all_info_cont">
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="bg-soft-warning">
            <div class="card-body pb-0 px-4">
              <div class="row mb-3">
                <div class="col-md">
                  <div class="row align-items-center g-3">
                    <div class="col-md-auto">
                      <div class="avatar-md">
                        <div class="avatar-title bg-white rounded-circle">
                          <img src="https://eu.ui-avatars.com/api/?name={{moduleData.module_name}}&size=80" alt=""
                            class="avatar-xs rounded-circle">
                        </div>
                      </div>
                    </div>
                    <div class="col-md">
                      <div>
                        <h4 class="fw-bold" *ngIf="moduleData!=undefined && moduleData.module_name!=undefined">
                          {{moduleData.module_name | titlecase}} </h4>
                        <div class="hstack gap-3 flex-wrap">
                          <div><i class="ri-building-line align-bottom me-1"></i> {{moduleData.project_name | titlecase}}</div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-md-auto button_card">
                  <div class="hstack gap-1 flex-wrap">
                    
                    <a (click)="docStatus=openCloseTab(docStatus,2)" class="custom-btn btn-8 text-center"><i class="las la-edit"></i>Edit
                    </a>
                    <a (click)="deleteModule(moduleData.id)" class="custom-btn btn-8 text-center"><i class="las la-trash-alt"></i>Delete
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
     
        <div class="card mb-3">
          <div class="card-body">
            <div class="table_card">
              <div class="table-responsive">
                <table class="table">
                  <tbody>
                    <!-- <tr>
                      <td>
                        <span class="w-50">Module Name</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span class="font-weight-semibold">{{moduleData.module_name}}</span>
                      </td>
                    </tr> -->
                    <tr>
                      <td>
                        <span class="w-50">Module Type</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span class="font-weight-semibold text-success">{{moduleData.module_type}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="w-50">Estimated End Date</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span class="font-weight-semibold">{{moduleData.m_estimated_end_date|date}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="w-50">Estimeted effort</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span
                          class="font-weight-semibold">{{timeConvert((moduleData.module_estimated_effort*8)*60,"d")}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="w-50">Priority</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span class="badge bg-danger" *ngIf="moduleData.risk == 'High'">High</span>
                        <span class="badge bg-success" *ngIf="moduleData.risk == 'Low'">Low</span>
                        <span class="badge bg-warning" *ngIf="moduleData.risk == 'Modarate'">Modarate</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="w-50">Module Status</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span class="badge bg-primary">{{moduleData.module_status}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="w-50">Description</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span class="font-weight-semibold">{{moduleData.module_description}}</span>
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <span class="w-50">Created By</span>
                      </td>
                      <td>:</td>
                      <td>
                        <span class="font-weight-semibold">{{moduleData.created_by}}</span>
                      </td>
                    </tr>
                  </tbody>
                </table>
                <!-- <div class="p-3">
                  <div class="d-flex align-items-end justify-content-between">
                    <h6 class="fs-12"> Module Status</h6>
                    <h6 class="fs-12">{{moduleData.progressBarValue}}%</h6>
                  </div>
                  <div class="progress">
                    <div class="progress-bar" role="progressbar" [style.width]="moduleData.progressBarValue+'%'"
                      aria-valuemin="0" aria-valuemax="100">{{moduleData.progressBarValue}}%</div>
                  </div>
                </div> -->
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-8">
        <div class="card">
          <div class="card-body">
            <div class="text-muted" *ngIf="moduleData!=undefined && moduleData.module_description!==undefined">
              <h6 class="mb-3 text-uppercase">Description</h6>
              {{moduleData.module_description|titlecase}}
            </div>
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <div>
              <ul class="nav nav-tabs-custom rounded card-header-tabs border-bottom-0">
                <li class="nav-item" role="presentation">
                  <a class="nav-link " [ngClass]="tabval=='taskList'?'active':''" href="javascript:void(0)"
                    (click)="changeTab('taskList')" aria-selected="true">
                    Task List
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" [ngClass]="tabval=='logs'?'active':''" href="javascript:void(0)"
                    (click)="changeTab('logs')">
                    Logs
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" [ngClass]="tabval=='taskReport'?'active':''" href="javascript:void(0)"
                    (click)="changeTab('taskReport')">
                    Report
                  </a>
                </li>
                <li class="nav-item" role="presentation">
                  <a class="nav-link" [ngClass]="tabval=='taskModReport'?'active':''" href="javascript:void(0)"
                    (click)="changeTab('taskModReport')">
                    Task Report
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div class="card-body">
            <div class="tab-content" *ngIf="tabval=='taskList'">
              <div class="table-responsive">
                <table class="table custom-table table-hover table-hover" *ngIf="moduleData.taskList?.length>0;else img">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Task Name</th>
                      <th>Task Type</th>
                      <th>Status</th>
                      <!-- <th>Updated At</th> -->
                      <!-- <th>Assigned By</th> -->
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr *ngFor="let taskLists of moduleData.taskList;index as i">
                      <td>{{i+1}}</td>
                      <td>{{taskLists.task_name|titlecase}}</td>
                      <td>{{taskLists.task_type|titlecase}}</td>
                      <td>{{taskLists.task_status|titlecase}}</td>
                      <!-- <td>{{taskLists.updated_at | date:'medium'}}</td> -->
                      <!-- <td>{{taskLists.task_assigned_by.name}}[{{taskLists.task_assigned_by.email}} ]</td> -->
                      <td>
                        <a class="btn alert-success text-success text-center mx-1"
                          [routerLink]='["/manage-task/",taskLists.t_id]'><span><i class="las la-eye"></i></span>
                        </a>
  
                      </td>
                    </tr>
                  </tbody>
                </table>
                <ng-template #img>
                  <div class="text-center"><img src="../../../assets/data-not-av.jpg" alt="" class="img-fullwidth w-50">
                  </div>
                </ng-template>
  
              </div>
            </div>
  
            <div class="tab-content" *ngIf="tabval=='logs'">
              <div class="d-flex justify-content-between align-items-center">
                <ul class="nav nav-tabs border-0 subtabInfo">
                  <li class="nav-item">
                    <a class="nav-link " href="javascript:void(0)" [ngClass]="subtabval=='module_stage'?'active':''"
                      (click)="changeSubTab('module_stage')">Stage Log</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link " href="javascript:void(0)" [ngClass]="subtabval=='estimeted_effort'?'active':''"
                      (click)="changeSubTab('estimeted_effort')">Time Logs</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link " href="javascript:void(0)"
                      [ngClass]="subtabval=='estimatedTimeReport'?'active':''"
                      (click)="changeSubTab('estimatedTimeReport')">Time Report</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link " href="javascript:void(0)"
                      [ngClass]="subtabval=='task_mod_rename'?'active':''"
                      (click)="changeSubTab('task_mod_rename')">Module Rename</a>
                  </li>
                </ul>
              </div>
              <div class="card-body p-0">
                <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                  data-parent="#accordion-details">
                  <div class="accordion add-employee " id="accordion-details" *ngIf="subtabval=='module_stage'">
                    <div class="card  grow ctm-border-radius">
                      <div class="card-header" id="basic1">
                        <h4 class="cursor-pointer mb-0">
                          <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
                            data-target="#basic-one" aria-expanded="true">
                            Module Stage
                          </a>
                        </h4>
                      </div>
                      <div class="card-body p-0">
                        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                          data-parent="#accordion-details">
                          <div class="row">
                            <table class="table custom-table table-hover table-hover" id="empolyeeId">
                              <thead>
                                <tr>
                                  <th>Sl_no</th>
                                  <th>Modified By</th>
                                  <th>Before Stage</th>
                                  <th>After Stage</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody *ngIf="moduleStage!==undefined && moduleStage!==null && moduleStage!==''">
                                <tr *ngFor="let stage of moduleStage; index as i ">
                                  <td>{{i+1}}</td>
                                  <td>{{stage.action_by}}</td>
                                  <td> <span class="badge bg-primary">{{stage.before_stage}}</span></td>
                                  <td> <span class="badge bg-primary">{{stage.after_stage}}</span></td>
                                  <td>{{stage.update_on.toLocaleString()|date:'short'}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="accordion add-employee " id="accordion-details" *ngIf="subtabval=='task_mod_rename'">
                    <div class="card  grow ctm-border-radius">
                      <div class="card-header" id="basic1">
                        <h4 class="cursor-pointer mb-0">
                          <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
                            data-target="#basic-one" aria-expanded="true">
                            Module Rename
                          </a>
                        </h4>
                      </div>
                      <div class="card-body p-0">
                        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                          data-parent="#accordion-details">
                          <div class="row">
                            <table class="table custom-table table-hover table-hover" id="empolyeeId">
                              <thead>
                                <tr>
                                  <th>Sl_no</th>
                                  <th>Modified By</th>
                                  <th>Before Name</th>
                                  <th>After Name</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody *ngIf="moduleRename!==undefined && moduleRename!==null && moduleRename!==''">
                                <tr *ngFor="let rename of moduleRename; index as i ">
                                  <td>{{i+1}}</td>
                                  <td>{{rename.action_by}}</td>
                                  <td>{{rename.before_name}}</td>
                                  <td>{{rename.after_name}}</td>
                                  <td>{{rename.update_on|date:'short'}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
  
                  <div class="accordion add-employee " id="accordion-details" *ngIf="subtabval=='estimeted_effort'">
                    <div class="card  grow ctm-border-radius">
                      <div class="card-header" id="basic1">
                        <h4 class="cursor-pointer mb-0">
                          <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
                            data-target="#basic-one" aria-expanded="true">
                            Module Estimated Time Status
                          </a>
                        </h4>
                      </div>
                      <div class="card-body p-0">
                        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                          data-parent="#accordion-details">
                          <div class="row">
                            <table class="table custom-table table-hover table-hover" id="empolyeeId">
                              <thead>
                                <tr>
                                  <th>Sl_no</th>
                                  <th>Modified By</th>
                                  <th>Before PD</th>
                                  <th>After PD</th>
                                  <th>Date</th>
                                </tr>
                              </thead>
                              <tbody *ngIf="estimeted_effort!==undefined && estimeted_effort!==null && estimeted_effort!==''">
                                <tr *ngFor="let pd of estimeted_effort; index as i ">
                                  <td>{{i+1}}</td>
                                  <td>{{pd.action_by}}</td>
                                  <td>{{pd.before_est_effort}} PD</td>
                                  <td>{{pd.after_est_effort}} PD</td>
                                  <td>{{pd.update_on.toLocaleString()}}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="accordion add-employee " id="accordion-details" *ngIf="subtabval=='estimatedTimeReport'">
                    <div class="  grow ctm-border-radius">
                    
                      <div class="card-body p-0">
                        <div id="basic-one" class="ctm-padding collapse show p-0" aria-labelledby="basic1"
                          data-parent="#accordion-details">
                          <div class="hd-header d-flex justify-content-between alert alert-primary">
                            <h6 class="m-0">Assigned : {{timeConvert(moduleData.module_estimated_effort*8*60,"d")}}
                            </h6>
                            <h6 class="m-0"
                              *ngIf="estimetedTimeRepost.totalEffort!=null && estimetedTimeRepost.totalEffort!=undefined">
                              Remaining:
                              {{timeConvert(((moduleData.module_estimated_effort*8*60)-estimetedTimeRepost.totalEffort),"d")}}
                            </h6>
                          </div>
                          <div class="table-responsive">
                            <table class="table custom-table table-hover table-hover" id="empolyeeId">
                              <thead>
                                <tr>
                                  <th>Task Name</th>
                                  <th>Assigned</th>
                                  <th>Spend</th>
                                  <th>Remaining</th>
                                  <th>Percentage</th>
                                  <th>View</th>
                                </tr>
                              </thead>
                              <tbody
                                *ngIf="estimetedTimeRepost.taskEffort!==undefined && estimetedTimeRepost.taskEffort!==null">
                                <tr *ngFor="let time of estimetedTimeRepost.taskEffort">
                                  <td>{{time.task_name}}</td>
                                  <td> {{timeConvert(time.assigned_pd,"d")}}</td>
                                  <td>{{timeConvert(time.spend_pd,"d")}}</td>
                                  <td>{{timeConvert(time.remaining_pd,"d")}}</td>
                                  <td> {{ time.percentage }}%</td>
                                  <td><a  class="btn alert-success text-success text-center mx-1" [routerLink]="['/manage-task',time.t_id]"><span ><i  class="las la-eye"></i></span></a></td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="tab-content" *ngIf="tabval=='taskReport'">
              <!-- Chart View -->
              <div class="table-responsive h-200" *ngIf="showChart;else noChartData">
              <apx-chart 
                      [series]="chartOptions.series" 
                      [chart]="chartOptions.chart" 
                      [xaxis]="chartOptions.xaxis"
                      [title]="chartOptions.title"
                      [labels]="chartOptions.labels">
              </apx-chart>
            </div>
            <ng-template #noChartData>
              <div class="alert alert-danger">No Data Available!!!</div>
            </ng-template>
            </div>
            <div  class="tab-content" *ngIf="tabval=='taskModReport'">
              <div id="basic-one" class="ctm-padding collapse show p-0" aria-labelledby="basic1"
                data-parent="#accordion-details">
                <div class="hd-header d-flex justify-content-between alert alert-primary">
                  <h6 class="m-0">Task Report</h6>
                </div>
                <div class="table-responsive h-200" >
                  <ul class="nav nav-tabs border-0 subtabInfo">
                    <li class="nav-item">
                      <a class="nav-link " (click)="getTaskRepo('top',moduleTaskData)">Top 5</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link " (click)="getTaskRepo('least',moduleTaskData)">Least 5</a>
                    </li>
                  </ul>
                  <apx-chart [series]="chartOptionsTask.series" [chart]="chartOptionsTask.chart"
                    [xaxis]="chartOptionsTask.xaxis" [yaxis]="chartOptionsTask.yaxis" [title]="chartOptionsTask.title"
                    [labels]="chartOptionsTask.labels" [stroke]="chartOptionsTask.stroke"
                    [dataLabels]="chartOptionsTask.dataLabels" [fill]="chartOptionsTask.fill"
                    [tooltip]="chartOptionsTask.tooltip" [plotOptions]="chartOptionsTask.plotOptions"></apx-chart>
                </div>
                
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  <div class="accordion add-employee " id="taskedit" [ngClass]="docStatus==2?'on':''">
    <div class="card  grow ctm-border-radius shadow-none">
      <div class="modal-header p-3 ps-4 bg-soft-success">
        <h5 class="modal-title" id="inviteMembersModalLabel">Edit Module</h5>
        <button type="button" class="btn-close" (click)="docStatus=openCloseTab(docStatus,0)"></button>
      </div>
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
          <form [formGroup]="moduleForm" (ngSubmit)="onSubmit(moduleForm)" #fd="ngForm" [ngxFvControl]="fd">
            <div class="row">
  
              <div class="col-lg-6 form-group">
                <label>
                  Project Name
                </label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="las la-rocket"></i>
                  </div>
                  <input type="text" value="bookyup project" class="form-control" #ngxFvFormControl
                    formControlName="p_name" readonly>
                </div>
              </div>
              <div class="col-lg-6 form-group">
                <label>
                  Module Name
                </label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="lab la-git-alt"></i>
                  </div>
                  <input type="text" class="form-control" #ngxFvFormControl formControlName="m_name">
                </div>
              </div>
              <div class="col-lg-12 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_description']">
                  <label #ngxFvName>
                    Module Description
                  </label>
                  <div class="input-group mb-3">
                    <textarea type="text" name="cStart" class="form-control" id="username"
                      placeholder="Module Description " ngxFvFormControl formControlName="m_description"></textarea>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_status']">
                  <label #ngxFvName>
                    Module Status
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-asterisk"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      ngxFvFormControl formControlName="m_status">
                      <option value="">Status </option>
                      <option value="Inpogress">Inpogress</option>
                      <option value="Planned">Planned</option>
                      <option value="Completed">Completed</option>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['risk']">
                  <label #ngxFvName>
                    Risk
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-asterisk"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      ngxFvFormControl formControlName="risk">
                      <option value="">Risk </option>
                      <option value="Low">Low </option>
                      <option value="High">High </option>
                      <option value="Modarate">Modarate </option>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_type']">
                  <label #ngxFvName>
                    Module Type
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-random"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"  ngxFvFormControl formControlName="m_type">
                      <option value="">Select Type</option>
                      <ng-container *ngFor="let mod of ModuleTypeList">
                        <option value="{{mod.type_name}}">{{mod.type_name}}</option>
                      </ng-container>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_estimated_effort']">
                  <label #ngxFvName>
                    Estimeted effort
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="lab la-git-alt"></i>
                    </div>
                    <input type="text" class="form-control" id="username" placeholder=" Estimeted effort"
                      #ngxFvFormControl formControlName="m_estimated_effort" />
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>            
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_estimated_end_date']">
                  <label #ngxFvName>
                    <p class="mb-2">Estimated End Date</p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-calendar"></i>
                    </div>
                    <input type="text" ngbDatepicker #dpStart="ngbDatepicker" (click)="dpStart.toggle()" class="form-control" #ngxFvFormControl formControlName="m_estimated_end_date" name="m_estimated_end_date" id="m_estimated_end_date">
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <div class="input-group mb-3">
  
                </div>
              </div>
  
              <div class="col-lg-12 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['challenges']">
                  <label #ngxFvName>
                    Challenge
                  </label>
  
                  <div class="input-group mb-3">
                    <textarea type="text" name="cStart" class="form-control" placeholder="Challenge " #ngxFvFormControl
                      formControlName="challenges"></textarea>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-12 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['challenges']">
                  <label #ngxFvName>
                    Dependancy
                  </label>
                  <div class="input-group mb-3">
                    <textarea type="text" name="cStart" class="form-control" placeholder="Dependancy  " #ngxFvFormControl
                      formControlName="dependency"></textarea>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-12 form-group">
                <div class="input-group mb-3" *ngIf="isShow">
                  <button class="btn custom-btn btn-8 updbtn" type="submit"><span>Update</span></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
  </div>
  <ngx-ui-loader></ngx-ui-loader>