<!-- <div class="quicklink-sidebar-menu ctm-border-radius  bg-white card grow">
    <div class="card-body">
        <div class="cardTitle d-flex justify-content-between align-items-center">
            <h5 class="p-0 m-0">Bookyup App Development </h5>
            <button class="btn btn-primary bg-custom border-0 " href="/view-attendance"><i
                    class="lar la-caret-square-left 48px"></i></button>
        </div>
    </div>
</div> -->
<div class="main-content" *ngIf="loadAllComponant">
    <div class="accordion add-employee mt-2" id="accordion-details">
        <div class="card  grow ctm-border-radius">
            <div class="card-header" id="basic1">
                <ul class="nav nav-tabs border-0" [ngClass]="menuToggle?'on':''">
                    <li class="nav-item" [ngClass]="tabval=='empReport'?'active':''">
                        <a class="nav-link " aria-current="page" href="javascript:void(0)"
                            (click)="changeTab('empReport')">Employee Wise Report</a>
                    </li>
                    <li class="nav-item" [ngClass]="tabval=='dateReport'?'active':''">
                        <a class="nav-link " href="javascript:void(0)" (click)="changeTab('dateReport')">Date Wise
                            Report</a>
                    </li>
                </ul>
            </div>
            <div class="card-body p-0 datepickcad" *ngIf="tabval=='empReport'">
                <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                    data-parent="#accordion-details">
                    <div class="row">
                        <div class="prodetail">
                            <div class="row align-items-end">
                                <div class="col-lg-6">
                                    <p class="mb-2">Select Start Date</p>
                                    <div class="input-group">
                                        <div class="input-group-text"><i class="las la-user-secret"></i>
                                        </div>
                                        <input type="text" class="form-control" ngbDatepicker [(ngModel)]="startDate"
                                            #joinStart="ngbDatepicker" id="start_date" placeholder="Start Date"
                                            (click)="joinStart.toggle()" name="sdate" [maxDate]="maxObjectStart">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <p class="mb-2">Select End Date</p>
                                    <div class="input-group">
                                        <div class="input-group-text"><i class="las la-user-secret"></i>
                                        </div>
                                        <input type="text" class="form-control" [(ngModel)]="endDate" ngbDatepicker
                                            #dpStart="ngbDatepicker" id="end_date" placeholder="End Date" name="edate"
                                            (click)="dpStart.toggle()" [maxDate]="maxObjectEnd">
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <p class="mb-2 mt-2">Select Employee Name</p>
                                    <div class="input-group w-100">
                                        <div class="input-group-text"><i class="las la-user-secret"></i>
                                        </div>
                                        <ng-select class="w-100" [multiple]="true" [selectableGroup]="true"
                                            [closeOnSelect]="false" [(ngModel)]="selectedUserList"
                                            (change)="select_user($event)">
                                            <ng-option *ngFor="let usr of itemList" [value]="usr.u_id">{{usr.name}}
                                            </ng-option>
                                        </ng-select>
                                    </div>
                                </div>
                                <div class="col-lg-12 mt-2">
                                    <div class="input-group  text-end" *ngIf="selectedUserList.length>0">
                                        <button class="btn custom-btn btn-8" (click)="getEmpReport()"
                                            type="submit"><span>View
                                                Report</span></button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- view emp report -->
                <div *ngIf="isVisibleEmp">
                    <div class="table-back employee-office-table "
                        *ngIf="getEmpReportList!==undefined && getEmpReportList!==null">
                        <div class="table-responsive" *ngFor="let reportemp of getEmpReportList">
                            <div class="quicklink-sidebar-menu ctm-border-radius  bg-white card grow">
                                <div class="card-body ">
                                    <div class="mycard d-flex justify-content-between align-items-center">
                                        <h5 class="p-0 m-0">{{reportemp.name}}</h5>
                                        <button class="btn btn-success border-0"
                                            (click)="downloadReport(reportemp.attendance,reportemp.name)">
                                            <i class="las la-download"></i>
                                        </button>
                                    </div>
                                    <div class="table-responsive p-3">
                                        <table class="table custom-table table-hover table-hover" id="empolyeeId">
                                            <thead>
                                                <tr>
                                                    <th>Date</th>
                                                    <th>Shift Intime</th>
                                                    <th>Shift Outtime</th>
                                                    <th>In time</th>
                                                    <th>Out time</th>
                                                    <th>Status</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <ng-container
                                                    *ngIf="reportemp.attendance==undefined || reportemp.attendance==''; else dataTable">
                                                    <tr>
                                                        <td class="text-center" colspan="6">No Data Found!!</td>
                                                    </tr>
                                                </ng-container>
                                                <ng-template #dataTable>
                                                    <tr *ngFor="let report of reportemp.attendance">
                                                        <td>{{report.date}}</td>
                                                        <td>{{report.shiftInTime}}</td>
                                                        <td>{{report.shiftOutTime}}</td>
                                                        <td>{{report.inTime}}</td>
                                                        <td>{{report.outTime}}</td>
                                                        <td>{{report.status}}</td>
                                                    </tr>
                                                </ng-template>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- end view emp report-->
            </div>
            <div class="card-body p-0" *ngIf="tabval=='dateReport'">
                <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                    data-parent="#accordion-details">
                    <div class="row">
                        <div class="prodetail">
                            <div class="row">
                                <div class="col-lg-6">
                                    <p class="mb-2">Select Start Date</p>
                                    <div class="input-group">
                                        <div class="input-group-text"><i class="las la-user-secret"></i>
                                        </div>
                                        <input type="text" class="form-control" ngbDatepicker [(ngModel)]="startDate"
                                            #joinStart="ngbDatepicker" id="start_date" placeholder="Start Date"
                                            (click)="joinStart.toggle()" (dateSelect)="onDateSelect($event,'start')"
                                            name="sdate" [maxDate]="maxObjectStart" [minDate]="validateMinStart">
                                    </div>
                                </div>
                                <div class="col-lg-6">
                                    <p class="mb-2">Select End Date</p>
                                    <div class="input-group">
                                        <div class="input-group-text"><i class="las la-user-secret"></i>
                                        </div>
                                        <input type="text" class="form-control" [(ngModel)]="endDate" ngbDatepicker
                                            #dpStart="ngbDatepicker" id="end_date" placeholder="End Date" name="edate"
                                            (dateSelect)="onDateSelect($event,'end')" (click)="dpStart.toggle()"
                                            [maxDate]="maxObjectEnd" [minDate]="validateMinEnd">
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-4 mt-2">
                                <div class="input-group mb-3 text-end">
                                    <button class="btn custom-btn btn-8" (click)="getDateReport()"
                                        type="submit"><span>View
                                            Report</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- start view date report table -->
                <div *ngIf="isVisibleDate">
                    <div class="table-back employee-office-table">
                        <div class="card-body">
                            <div class="mycard d-flex justify-content-between align-items-center">
                                <h4>Reports</h4>
                                <button class="btn btn-success border-0" (click)="downloadReport(getDateReportList)">
                                    <i class="las la-download"></i>
                                </button>
                            </div>
                            <div class="table-responsive">
                                <table class="table custom-table table-hover table-hover" id="empolyeeId">
                                    <thead>
                                        <tr>
                                            <th *ngFor="let heading of dateReportHeadings">{{heading}}</th>
                                        </tr>
                                    </thead>
                                    <tbody *ngIf="getDateReportList!==undefined && getDateReportList!==null">
                                        <tr *ngFor="let report of getDateReportList">
                                            <td *ngFor="let dates of dateReportHeadings">{{report[dates]}}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>