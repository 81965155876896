<div class="main-content" *ngIf="loadAllComponant">
  <div class="accordion add-employee" id="accordion-details">
    <div class="card  grow ctm-border-radius">
      <div class="card-header" id="basic1">
        <h4 class="cursor-pointer mb-0">
          <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
            data-target="#basic-one" aria-expanded="true">
            Create Automated Task
          </a>
        </h4>
      </div>
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
          <form [formGroup]="taskForm" (ngSubmit)="onSubmit(taskForm)" #fd="ngForm" [ngxFvControl]="fd" >
            <div class="row">
              <div class="task_extra_information ">
                <div class="row">
                  <div class="col-lg-6 form-group">  
                    <ngx-fv-validate [control]="taskForm.controls['module_type']">
                      <label #ngxFvName>
                        Module Type
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-user-secret"></i>
                        </div>
                        <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"  ngxFvFormControl formControlName="module_type">
                          <ng-container *ngFor="let mod of ModuleTypeList">
                            <option value="{{mod.type_name}}">{{mod.type_name}}</option>
                          </ng-container>
                        </select>
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['task_name']">
                      <label #ngxFvName>
                        Task Name
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="lab la-git-alt"></i>
                        </div>
                        <input type="text" class="form-control" id="task_name" placeholder="Task Name" #ngxFvFormControl formControlName="task_name">
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">  
                    <ngx-fv-validate [control]="taskForm.controls['task_type']">
                      <label #ngxFvName>
                        Task Type
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-user-secret"></i>
                        </div>
                        <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"  ngxFvFormControl formControlName="task_type">
                          <option value="">Select Type</option>
                          <ng-container *ngFor="let task of taskTypeList">
                            <option value="{{task.type_name}}">{{task.type_name}}</option>
                          </ng-container>
                        </select>
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['t_description']">
                      <label #ngxFvName>
                        Task Description
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="lab la-git-alt"></i>
                        </div>
                        <input type="text" class="form-control" id="t_description" placeholder="Task Description" #ngxFvFormControl formControlName="t_description">
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['task_estimated_pd']">
                      <label #ngxFvName>
                        Estimated Hour
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-business-time"></i>
                        </div>
                        <input type="number" min="1" max="20" class="form-control" id="task_estimated_pd" placeholder="Task Estimated Hours" #ngxFvFormControl formControlName="task_estimated_pd">  
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['t_status']">
                      <label #ngxFvName>
                        Task Status
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-user-secret"></i>
                        </div>
                        <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                          #ngxFvFormControl formControlName="t_status">
                          <option value="">Task Status </option>
                          <option value="1"> Active</option>
                          <option value="0">Inactive</option>
                        </select>
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                </div>
                <div class="col-lg-12 form-group">
                  <div class="input-group mb-3" *ngIf="isShow">
                    <button class="btn custom-btn btn-8"><span>Add</span></button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>

<ngx-ui-loader></ngx-ui-loader>