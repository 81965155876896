import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { CreateModuleComponent } from './pages/create-module/create-module.component';
import { CreateProjectComponent } from './pages/create-project/create-project.component';
import { CreateTaskComponent } from './pages/create-task/create-task.component';
import { DashboardComponent } from './pages/dashboard/dashboard.component';
import { LoginComponent } from './pages/login/login.component';
import { ModuleComponent } from './pages/module/module.component';
import { ManageModuleComponent } from './pages/manage-module/manage-module.component';
import { ProjectComponent } from './pages/project/project.component';
import { TaskComponent } from './pages/task/task.component';
import { TimesheetComponent } from './pages/timesheet/timesheet.component';
import { LoaderOneComponent } from './layout/loader/loader-one/loader-one.component';
import { LoaderTwoComponent } from './layout/loader/loader-two/loader-two.component';
import { EmployeesListComponent } from './pages/employees/employees-list/employees-list.component';
import { AddEmployeeComponent } from './pages/employees/add-employee/add-employee.component';
import { LeaveEmployeeComponent } from './pages/employees/leave-employee/leave-employee.component'
import { LeaveRosterComponent } from './pages/employees/leave-roster/leave-roster.component';
import { ForbiddenComponent } from './forbidden/forbidden.component';
import { NotfoundComponent } from './notfound/notfound.component';
import { AuthGuard } from "./auth.guard";
import { ProjectDashboardComponent } from './pages/project-dashboard/project-dashboard.component';
import { ManageEmployeeComponent } from './pages/manage-employee/manage-employee.component';
import { TaskManageComponent } from './pages/task-manage/task-manage.component';
import { ManageProjectComponent } from './pages/manage-project/manage-project.component';
import { AddAttendanceComponent } from './pages/employees/add-attendance/add-attendance.component';
import { CreateShiftComponent } from './pages/roster/create-shift/create-shift.component';
import { ShiftListComponent } from './pages/roster/shift-list/shift-list.component';
import { AttendanceReportComponent } from './pages/employees/attendance-report/attendance-report.component';
import { RecurringTaskComponent } from './pages/recurring-task/recurring-task.component';
import { ReminderHistoryComponent } from './pages/reminder-history/reminder-history.component';
import { HolidayComponent } from './pages/employees/holiday/holiday.component';
import { UploadAttendanceComponent } from './pages/employees/upload-attendance/upload-attendance.component';
import { AttendanceDetailsComponent } from './pages/employees/attendance-details/attendance-details.component';
import { TagsComponent } from './pages/tags/tags.component';
import { WorkReportsComponent } from './pages/work-reports/work-reports.component';
import { MyDashboardComponent } from './pages/employees/my-dashboard/my-dashboard.component';
import { EmployeesDashboardComponent } from './pages/employees/employees-dashboard/employees-dashboard.component';
import { AutomatedTaskComponent } from './pages/automated-task/automated-task.component';
import { CreateAutomatedTaskComponent } from './pages/create-automated-task/create-automated-task.component';
import { TaskDashboardComponent } from './pages/task-dashboard/task-dashboard.component';
import { AnnouncementsComponent } from './pages/announcements/announcements.component';
import { ReportsComponent } from './pages/reports/reports.component';
import { RosterShiftsComponent } from './pages/roster-shifts/roster-shifts.component';
import { RosterListComponent } from './pages/roster-list/roster-list.component';
import { title } from 'process';
// import { ManageTimesheetComponent } from './pages/manage-timesheet/manage-timesheet.component';




const routes: Routes = [
  { path: '', redirectTo: '/dashboard',  pathMatch: 'full' },
  { path: 'login', component: LoginComponent, data: { title: 'Login' } },
  { path: 'dashboard', component: MyDashboardComponent, data: { title: 'Dash board' }, canActivate: [AuthGuard] },
  { path: 'project', component: ProjectComponent, data: { title: 'Projects' }, canActivate: [AuthGuard]},
  { path: 'employee', component: EmployeesListComponent, data: { title: 'Employees' }, canActivate: [AuthGuard] },
  { path: 'task', component: TaskComponent, data: { title: 'task' }, canActivate: [AuthGuard] },
  { path: 'module', component: ModuleComponent, data: { title: 'module' }, canActivate: [AuthGuard] },
  { path: 'manage-module/:id', component: ManageModuleComponent, data: { title: 'ManageModuleComponent',data:[1] },  },
  { path: 'add-employee', component: AddEmployeeComponent, data: { title: 'Add Employee' }, canActivate: [AuthGuard] },
  { path: 'create-project', component: CreateProjectComponent, data: { title: 'Create Project Component' }, canActivate: [AuthGuard] },
  { path: 'create-module', component: CreateModuleComponent, data: { title: 'Create Module Component' }, canActivate: [AuthGuard] },
  { path: 'create-task', component: CreateTaskComponent, data: { title: 'Create task Component' }, canActivate: [AuthGuard] },
  { path: 'time-sheet', component: TimesheetComponent, data: { title: 'time sheet' },canActivate: [AuthGuard] },
  { path: 'leave-employee', component: LeaveEmployeeComponent, data: { title: 'leave employee' } },
  { path: 'loader-one', component: LoaderOneComponent, data: { title: 'loader one' }, canActivate: [AuthGuard] },
  { path: 'loader-two', component: LoaderTwoComponent, data: { title: 'loader tow' }, canActivate: [AuthGuard] },
  { path: 'leave-roster', component: LeaveRosterComponent, data: { title: 'leave roster' }, canActivate: [AuthGuard] },
  { path: '403', component: ForbiddenComponent, data: { title: 'ForbiddenComponent' } },
  { path: 'notfound', component: NotfoundComponent, data: { title: 'ForbiddenComponent' } },
  { path: 'project-dashboard', component: ProjectDashboardComponent, data: { title: 'ProjectDashboardComponent' ,data:[1]},canActivate: [AuthGuard]},
  { path: 'manage-employee/:id', component: ManageEmployeeComponent, data: { title: 'ManageEmployeeComponent',data:[1] },canActivate: [AuthGuard]},
  { path: 'manage-task/:id', component: TaskManageComponent, data: { title: 'TaskManageComponent' ,data:[1]},canActivate: [AuthGuard]  },
  { path: 'manage-project/:id', component: ManageProjectComponent, data: { title: 'ManageProjectComponent' ,data:[1]},canActivate: [AuthGuard] },
  { path:'add-attendance' , component:AddAttendanceComponent,data:{title:'AddAttendanceComponent'},canActivate: [AuthGuard] },
  { path:'add-shift' , component:CreateShiftComponent,data:{title:'CreateShiftComponent'} ,canActivate: [AuthGuard]},
  { path:'shift-list' , component:ShiftListComponent,data:{title:'ShiftListComponent'},canActivate: [AuthGuard] },
  { path: 'recurring-task' , component:RecurringTaskComponent,data:{title:'RecurringTaskComponent'},canActivate: [AuthGuard]},
  { path:'attendance-report' , component: AttendanceReportComponent,data:{title:'AttendanceReportComponent'},canActivate: [AuthGuard] },
  { path:'upload-attendance' , component: UploadAttendanceComponent,data:{title:'UploadAttendanceComponent'},canActivate: [AuthGuard]},
  { path:'attendance-details/:id' , component: AttendanceDetailsComponent,data:{title:'AttendanceDetailsComponent',data:[1]},canActivate: [AuthGuard]},
  { path:'holiday-list' , component: HolidayComponent,data:{title:'HolidayComponent'},canActivate: [AuthGuard] },
  { path:'my-dashboard' , component: MyDashboardComponent,data:{title:'MyDashboardComponent'},canActivate:[AuthGuard]},
  { path:'employee-dashboard' , component: EmployeesDashboardComponent,data:{title:'EmployeesDashboardComponent'},canActivate: [AuthGuard] },
  { path:'tags' , component: TagsComponent,data:{title:'TagsComponent'},canActivate: [AuthGuard] },
  { path: 'automated-task', component: AutomatedTaskComponent, data: { title: 'Automated task Component' }, canActivate: [AuthGuard] },
  { path: 'create-automated-task', component: CreateAutomatedTaskComponent, data: { title: 'Create Automated task Component' }, canActivate: [AuthGuard] },
  { path: 'task-dashboard', component: TaskDashboardComponent, data: { title: 'Task Dashboard Component' }, canActivate: [AuthGuard] },
  {
    path: 'leave',
    loadChildren: () => import('./pages/leave/leave.module').then(m => m.LeaveModule),
  },

  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule)
  },
  { path: 'reminder-history', component:ReminderHistoryComponent,data:{title:'ReminderHistoryComponent'}},
  { path: 'work-reports', component:WorkReportsComponent,data:{title:'WorkReportsComponent'} },
  {path:'announcements', component:AnnouncementsComponent,data:{title:'AnnouncementsComponent'}},
  {path:'reports', component:ReportsComponent,data:{title:'ReportsComponent'}},
  {path:'roster-shifts', component:RosterShiftsComponent,data:{title:'RosterShiftsComponent'}},
  {path:'roster-list', component:RosterListComponent,data:{title:'RosterListComponent'}}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
