import { Component, OnInit,ViewChild,ElementRef, TemplateRef } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from 'src/app/rest.service';
import { ToastrService } from 'ngx-toastr';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';



@Component({
  selector: 'app-edit-attendance',
  templateUrl: './edit-attendance.component.html',
  styleUrls: ['./edit-attendance.component.scss'],
})
export class EditAttendanceComponent implements OnInit {
 
  public userlist: any;
  public finalDate = '';
  public selectUser: any;
  public attendanceList: any;
  public isShowReset=false;
  public maxObject:any;
  public isDataLoaded=false;
  dropdownSettings = {};
  public filter_status = false;
  constructor(
    public rest: RestService,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    let d = new Date();
    this.maxObject = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select Employee',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: 'myclass custom-class',
    };
    this.getEditAttendanceList();
    this.getUser();
  }

  onDateSelect(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.finalDate = year + '-' + month + '-' + day;
    console.log(this.finalDate);
    this.getEditAttendanceList();
    this.isShowReset=true;
  }

  select_user(ev: any) {
    this.selectUser = ev;
  }
  filter_status_btn(){
    this.filter_status = !this.filter_status;
  }
  /* get all user list*/
  getUser() {
    this.ngxService.start();
    this.rest.getData({}, 'getemployees').subscribe({
      next: (data) => {
        this.ngxService.stop();
        if ((data.state = '200')) {
          this.userlist = data.data;
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.toaster.error('Somethiong Went wrong');
      },
    });
  }
/* Api call for getting current date attendance list */
  getEditAttendanceList() {
    this.ngxService.start();
    this.rest
      .getData(
        { u_id: this.selectUser, date: this.finalDate,type:'edit' },
        'geteditattendancelist'
      )
      .subscribe({
        next: (data) => {
          this.ngxService.stop();
          this.isDataLoaded=true;
          if ((data.state = '200')) {
            console.log(data.data);
            this.attendanceList = data.data;
          }else{
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.isDataLoaded=true;
          this.toaster.error('Somethiong Went wrong');
        },
      });
  }

  edit_att(obj: any) {
    obj.isEdit = false;
  }
/* update attendance data */
  save(obj: any) {
    this.ngxService.start();
    this.rest
      .getData({ attendance_id:obj.u_id, inTime:obj.inTime, outTime:obj.outTime }, 'updateattendance')
      .subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            obj.isEdit = true;
            this.toaster.success(data.message);
          } else {
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Somethiong Went wrong');
        },
      });
  }

  resetForm(){
    this.selectUser=[];
    this.finalDate ="";
    this.getEditAttendanceList();
  }
}
