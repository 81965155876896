import { Component, OnInit } from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-roster-shifts',
  templateUrl: './roster-shifts.component.html',
  styleUrls: ['./roster-shifts.component.scss']
})
export class RosterShiftsComponent implements OnInit {
  shiftForm!: FormGroup;
  employeeList = <any>[];
  shiftName: string = '';
  selectedUser: string = '';
  startDate!: string;
  endDate!: string;
  weekdays: string[] = [];
  public searchKey = '';
  public objKey: any;
  dayNames:any[] = [];
  timetableData: { user_id: any; shift_name: string; data: any[] } = {
    user_id: '',
    shift_name: '',
    data: []
  };
  country:any = 'Bangaldesh';
  public selectedfield = '';
  showModal: boolean = false;
  isContentVisible: boolean = false;
  days: any[] = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

  constructor(
    public rest: RestService,
    public ngxService: NgxUiLoaderService,
    private route: Router,
    private toaster: ToastrService
  ) { }

  ngOnInit(): void {
    this.getEmployees();
  }
  
  openModal() {
    this.showModal = true;
  }

  closeModal() {
    this.showModal = false;
  }

  changeCountry(): void {
    this.country = this.country === 'Bangaldesh' ? 'India' : 'Bangaldesh';
    console.log(this.country);
    this.getEmployees();
  }

  getWeekdays() {
    if (!this.startDate || !this.endDate) {
      this.toaster.warning('Please select both start and end dates.');
      return;
    }
    let start = new Date(this.startDate);
    let end = new Date(this.endDate);
    if (start > end) {
      this.toaster.warning('Start date must be before or equal to end date.');
      return;
    }
    this.weekdays = [];
    let currentDate = start;
    while (currentDate <= end) {
      this.weekdays.push(this.days[currentDate.getDay()]);
      currentDate.setDate(currentDate.getDate() + 1);
    }
  }

  getEmployees() {
    this.rest.getData({country:this.country},'getbdemployess').subscribe({
      next: (data) => {
        if (data.state === '200') {
          this.employeeList = data.data;
        } else {
          this.toaster.warning(data.message);
        }
      },
      error: () => this.toaster.error('Something went wrong')
    });
  }

  toggleContent() {
    let start = new Date(this.startDate);
    let end = new Date(this.endDate);
    if (start > end) {
      this.toaster.warning('Start date must be before or equal to end date.');
      return;
    }
    if (!this.shiftName || !this.selectedUser) {
      this.toaster.warning('Please provide both Shift Name and Employee.');
      return;
    }
    this.timetableData = {
      shift_name: this.shiftName,
      user_id: this.selectedUser,
      data: []
    };
  
    let currentDate = start;
    while (currentDate <= end) {
      let dayOfWeek = currentDate.getDay();
      
      let formattedDate = currentDate.toISOString().split('T')[0]; 
  
      this.timetableData.data.push({
        date: formattedDate,
        day: this.days[dayOfWeek],
        shiftName: this.shiftName,
        user_id: this.selectedUser,
        startTime: '',
        endTime: '',
        weekoff: false
      });
      currentDate.setDate(currentDate.getDate() + 1);
    }
    this.showModal = true;
  }

  finalSubmit() {
    console.log('Payload: ', this.timetableData);
    this.rest.getData(this.timetableData, 'createrostershift').subscribe({
      next: (data) => {
        if(data.state=='200'){
          console.log(data);
          this.closeModal();
          this.toaster.success(data.message);
        }else{
          this.toaster.error(data.message);
        }
      },error: (e) => {
        this.toaster.error('Something went wrong');
      },
    });
  }

  getCountryCode(country: string): string {
    switch (country) {
      case 'India': return 'IND';
      case 'Bangladesh': return 'BD';
      default: return country;
    }
  }

  autoFillTimes(index: number, field: 'startTime' | 'endTime', value: string): void {
    if (index === 0) {
      // Auto-fill only if it's the first row
      for (let i = 1; i < this.timetableData.data.length; i++) {
        this.timetableData.data[i][field] = value;
      }
    }
  }

  onWeekOffChange(index: number, event: Event): void {
    const checkbox = event.target as HTMLInputElement;
    const isChecked = checkbox.checked;
  
    if (isChecked) {
      this.timetableData.data[index].startTime = null;
      this.timetableData.data[index].endTime = null;
    }
  }
  
  
}
