import { Component, OnInit,HostListener } from '@angular/core';
import { RestService } from 'src/app/rest.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { PagesService } from '../pages.service';

@Component({
  selector: 'app-project-dashboard',
  templateUrl: './project-dashboard.component.html',
  styleUrls: ['./project-dashboard.component.scss']
})
export class ProjectDashboardComponent implements OnInit {
  public projectListArr   : any = [];
  public moduleList       : any = [];
  public taskList         : any = [];
  public timesheetList    : any = [];
  public projectData      : any = [];
  public overView         : any = [];
  public taskOverviewData : any = [];
  public assignedEmp      : any = [];
  public project_id       : any = '';
  public module_id        : any = '';
  public task_id          : any = '';
  public projectArr       : any;
  public activeProjects   : any;
  public inActiveProjects : any;
  public finishedProjects : any;
  public stopProjects     : any;
  public srcWidth         : any;
  public srcWidthVal      : any;
  public projectList      : any;
  public hsl              : any;
  public menuBoxStatus    = false;
  public loadAllComponant = false;
  public p_load           = true;
  public m_load           = true;
  public t_load           = true;
  public s_load           = true;
  public showProjectArrow = true;
  public showModuleArrow  = true;
  public sidePanel        = false;
  public selectedItem     = false;
  public limit            = 10;
  public offset           = 0;
  public mLimit           = 10;
  public mOffset          = 0;
  public tLimit           = 10;
  public tOffset          = 0;
  public sLimit           = 10;
  public sOffset          = 0;
  public p_id             = '';
  public m_id             = '';
  public t_id             = '';
  public loadType         = '';
  public Math             = Math;

  constructor(
    public  rest        : RestService,
    private toaster     : ToastrService,
    public  pageService : PagesService,
    public  ngxService  : NgxUiLoaderService
    
  ) { }

  ngOnInit(): void {
    this.projectTab();
    this.getScreenSize();
    this.getProjectData(this.p_id);
  }
  /* This function used to fetch project dashboard information */
  
  getScreenSize() {
    if(window.innerWidth <= 900){
      this.srcWidth =1;
      this.srcWidthVal =1;
    }else{
      this.srcWidth =3;
      this.srcWidthVal =2;
    }
  }


  projectTab(){
    let payload = {
      'limit' : this.limit,
      'offset' : this.offset
    }
    this.rest.getData(payload,'projectdashboardtab').subscribe({
      next:(data)=>{
        this.loadAllComponant = true;
        if(data.state == '200'){
          this.projectList      = data.data;
          if(this.projectList.length > 0){
            this.projectListArr = this.projectListArr.concat(this.projectList);
            if(this.projectListArr?.length < 3){
              this.showProjectArrow = false;
            }else{
              this.showProjectArrow = true;
            }
          }else{
            this.toaster.warning("No more projects!");
            this.p_load = false
            this.offset = 0;
            this.limit = 0;
          }
        }else{
          this.toaster.error(data.message)
        }
      },
      error:(e)=>{
        //this.ngxService.stop();
        this.toaster.error('Something went wrong!');
      },
    })
  }
  getProjectId(id:any){
    this.getProjectData(id);
    this.project_id = id;
  }

  loadAllProject() {
    this.offset += 10;
    this.limit = 10;
    if(this.p_load){
      this.projectTab()
    }
  }

  loadAllModule(type:any='') {
    this.loadType = type;
    console.log(this.loadType);
    if(this.m_load){
      this.loadMoreData(this.loadType,this.mOffset,this.mLimit);
    }
    this.mOffset += 10;
    this.mLimit = 10;
  }

  loadAllTasks(type:any='') {
    this.tOffset += 10;
    this.tLimit = 10;
    this.loadType = type;
    if(this.t_load){
      this.loadMoreData(this.loadType,this.tOffset,this.tOffset);
    }
  }

  loadAllTimesheets(type:any='') {
    this.sOffset += 10;
    this.sLimit = 10;
    this.loadType = type;
    if(this.s_load){
      this.loadMoreData(this.loadType,this.sOffset,this.sLimit);
    }
  }
  loadMoreData(type:any,o:any,l:any){
    let payload = {
      loadtype : type,
      limit    : l,
      offset   : o
    }
    this.rest.getData(payload, 'paginatedata').subscribe({
      next: (data) => {
        console.log("api data --",data);
        if (data.state == '200') {
          if(data.data.modules?.length > 0){
            this.moduleList.concat(data.data.modules);
          }else{
            this.m_load = false;
          }
          if(data.data.tasks?.length > 0){
            this.taskList      = this.taskList.concat(data.data.tasks);
          }else{
            this.t_load = false;
          }
          if(data.data.timesheets){
            this.timesheetList = this.timesheetList.concat(data.data.timesheets);
          }else{
            this.s_load = false;
          }
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
      },
    });
  }
   /* This function used to fetch module list */
  getProjectData(id:any) {
    if(id == '' || id == undefined){
      this.p_id = '';
    }else{
      this.p_id = id;
    }
    let projectPayload = {
      project_id:this.p_id,
      loadtype:this.loadType,
      module_id : this.m_id
    }
    this.rest.getData(projectPayload, 'projectdashboarddata').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.projectData = data.data;
          if( data.data.modules?.length < 3){
            this.showModuleArrow = false;
          }else{
            this.showModuleArrow = true;
          }
          this.moduleList = data.data.modules;
          this.taskList = data.data.tasks;
          this.timesheetList = data.data.timesheets;
          console.log(this.moduleList);
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
      },
    });
  }

  getModuleId(id:any){
    this.getModuleData(id);
    this.m_id = id;
  }

  getModuleData(id:any) {
    if(id !== '' || id !== undefined){
      this.m_id = id;
    }else{
      this.toaster.error("Module not selected!")
    }
    let modulePayload = {
      loadtype:this.loadType,
      module_id : this.m_id
    }
    this.rest.getData(modulePayload, 'projectdashboardmoduledata').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.taskList = data.data.tasks;
          this.timesheetList = data.data.timesheets;
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
      },
    });
  }

  getTimesheetId(id:any){
    this.getTaskData(id);
    this.t_id = id;
  }

  getTaskData(id:any) {
    if(id !== '' || id !== undefined){
      this.t_id = id;
    }else{
      this.toaster.error("Task not selected!")
    }
    let timesheetPayload = {
      loadtype:this.loadType,
      task_id : this.t_id
    }
    console.log("h---",timesheetPayload);
    this.rest.getData(timesheetPayload, 'projectdashboardtsData').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.timesheetList = data.data.timesheets;
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
      },
    });
  }

  menuBox() {
    this.menuBoxStatus = !this.menuBoxStatus;
  }

  encodeURIParam(param:any){
    return param.replace('/','_');
  }

  convertColor(string:any) {
    this.hsl = this.pageService.convertNameToColor(string);
  }

  convertToLetter(letter:any) {
    this.convertColor(letter);
    return this.pageService.getFirstLetters(letter);
  }

  userIconTrimmer(num:any){
    return num - 3;
  }

  openSidePanel(id: any) {
    if (id !== '' || id !== undefined) {
      this.getTaskOverview(id);
      this.sidePanel = !this.sidePanel;
    } else {
      this.toaster.warning("Id not found!");
    }
  }

  getTaskOverview(id: string) {
    if (id == '' || id == undefined || id == null) {
      return;
    } else {
      let taskPayload = {
        taskID: id,
      };
      this.rest.getData(taskPayload, 'getsingletask').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.taskOverviewData = data.data;
            console.log("api data---",this.taskOverviewData);
            let tempArr = [];
            for(let user of this.taskOverviewData.asigned_data){
              tempArr.push(user.name);
            }
            this.assignedEmp = tempArr;
          } else {
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.loadAllComponant = true;
          this.toaster.error('Something went wrong');
        },
      });
    }
  }

  timeConvert(time: any = Number) {
    let num = time;
    let hours = (num / 60);
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);
    return rhours + " hr " + rminutes + " min";
}
}
