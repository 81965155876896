<div class="main-content app-content boxfin">
  <div class="row">
    <div class="col-xxl-12">
      <div class="row">
        <div class="col-xxl-3">
          <div class="card custom-card userInfo"> 
            <div class="d-sm-flex align-items-top p-4 border-bottom border-block-end-dashed main-profile-cover nd">
              <div> 
                <span class="avatar avatar-xxl avatar-rounded online me-3">
                  <div class="userBox1">
                    <img src="../../../assets/avatar.png" alt="Avatar" class="avatar">
                  </div>
                </span> 
              </div>
              <div class="flex-fill main-profile-info ">
                <h3 class="mb-1 text-muted text-fixed-white op-7" [ngClass]="{'inactive_emp': empInfo.status == 0}">{{empInfo.name| uppercase}} </h3>{{(empInfo.status == '0')?'Inactive':'Active'}}
                <p class="fs-12 text-fixed-white op-5 m-0">
                  <span class="me-3"><i class="ri-building-line me-1 align-middle badge alert-success"></i>{{empInfo.designation?.length>0?humanize(empInfo.designation) :''}}</span>
                </p>
              </div>
            </div>
            <div class="card-header justify-content-between">
              <div class="card-title"><h2><i class="las la-search"></i></h2></div>
                <div class="form-outline col-md-10">
                  <input type="text" class="form-control" (keyup)="searchType($event)" placeholder="Search" aria-label="Search" [(ngModel)]="multiSearch" id="searchdata">
                </div>
            </div>
            <div class="card-body userc scollDry">
              <ul class="list-unstyled daily-task-card my-2" *ngFor="let emp of empData | tableSearch : objKey;">
                <li>
                  <div class="card border border-primary border-opacity-25 shadow-none custom-card mb-0"  (click)="getDataByEmp(emp.u_id)">
                    <div class="card-body p-4 main-profile-cover" [ngClass]="{'e_select': emp_id === emp.u_id}">
                      <div class="d-flex align-center">
                        <div class="flex-fill main-profile-info my-auto">
                          <div class="userBox">
                            <h5 class="fw-semibold mb-1 userShape-Sm" [ngStyle]="{'background-color':hsl}">{{convertToLetter(emp.name)}}</h5>
                            <p class="ml-2 mb-0">{{emp.name }}</p>
                            <div [ngClass]="{'online-indicator': emp.updated_at === today}">
                              <span  [ngClass]="{'blink': emp.updated_at === today}"></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="col-xxl-3">
          <div class="row">
            <div class="col-sm-12">
              <div class="card custom-card">
                <div class="card-body d-flex justify-content-between align-items-center">
                  <div>
                    <p class="mb-2">Leaves</p>
                    <h4 class="mb-0 fw-semibold mb-2">24 / {{empDetails.leaves}}</h4>
                  </div>
                  <div>
                    <span class="avatar avatar-md bg-success p-2">
                      <i class="las la-check-square fs-20 op-7"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-12">
              <div class="card custom-card gg">
                <div class="card-header justify-content-between">
                  <div class="card-title"><i class="las la-tasks"></i> Tasks </div>
                  <div class="dropdown"> 
                    <a href="javascript:void(0);" class="p-2 fs-12 text-muted mb-0" data-bs-toggle="dropdown" aria-expanded="false"> {{empTasks?.length}}<i class="ri-arrow-down-s-line align-middle ms-1 d-inline-block"></i>
                    </a>
                    <ul class="dropdown-menu" role="menu">
                      <li>
                        <a class="dropdown-item" href="javascript:void(0);"></a>
                      </li>
                    </ul>
                  </div>
                </div>

                <div class="card-body customTask scollDry">
                  <ul class="list-unstyled projects-maintask-card" *ngIf="empTasks?.length < 1">
                    <li>
                      <div class="d-flex align-items-top">
                        <div class="d-flex align-items-top flex-fill">
                          <div class="flex-fill"> <span>No Task</span>
                          </div>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul class="list-unstyled projects-maintask-card"   *ngFor="let etasks of empTasks">
                    <li>
                      <div class="d-flex align-items-top" (click)="getTimeSheetData(etasks.t_id,etasks.task_name)">
                        <div class="d-flex align-items-top flex-fill">
                          <span class="me-3">
                            <input class="form-check-input" type="checkbox" id="checkboxNoLabel1" value="" aria-label="...">
                          </span>
                          <div class="flex-fill">
                            <h5>{{etasks.project_name}} > {{etasks.module_name}}</h5>
                            <span>Task : {{etasks.task_name}}</span> 
                            <p class="mb-0">{{etasks.updated_at | date}} | <i class="la las-hours"></i>{{etasks.effort+" H"}}
                            </p>
                          </div>
                        </div>
                        <div> <span class="badge alert-success">{{etasks.task_status}} </span> </div>
                      </div>
                      <hr>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="col-xxl-3">
          <div class="row">
            <div class="col-sm-6 d-none">
              <div class="card custom-card">
                <div class="card-body d-flex justify-content-between align-items-center">
                  <div>
                    <p class="mb-2"><i class="las la-hourglass-half"></i> Working Hours</p>
                    <h4 class="mb-0 fw-semibold mb-2">{{(empDetails.workingTime > 0)?timeConvert(empDetails.workingTime):0}}</h4>
                  </div>
                  <div>
                    <span class="avatar avatar-md bg-success p-2">
                      <i class="las la-images fs-20 op-7"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            
            <div class="col-sm-12">
              <div class="card custom-card">
                <div class="card-body d-flex justify-content-between align-items-center">
                  <div>
                    <p class="mb-2"><i class="las la-user-alt-slash"></i> Week Off</p>
                    <h4 class="mb-0 fw-semibold mb-2"></h4>
                    <span class="badge alert-danger bnc">{{(empDayStatus !== '')?empDayStatus:'Not Assigned'}} 
                      <i class="ti ti-trending-down"></i>
                    </span>
                  </div>
                  <div>
                    <span class="avatar avatar-md bg-secondary p-2">
                      <i class="las la-business-time fs-20 op-7"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xl-12">
              <div class="card custom-card gg">
                <div class="card-header justify-content-between">
                  <div class="card-title"><i class="las la-calendar-check"></i> {{tsTitle}} {{(timesheets?.length > 0)?'('+timesheets?.length+')':''}}</div>
                  <div class="dropdown">
                  </div>
                </div>
                <div class="card-body userc scollDry">
                  <ul class="list-unstyled project-transactions-card" *ngIf="timesheets?.length < 1">
                    <li>
                      <div class="d-flex align-items-top">
                        <div class="me-3"> <span class="avatar avatar-rounded fw-bold avatar-md bg-primary-transparent"> 
                          </span> </div>
                        <div class="flex-fill">
                          <span class="d-block fw-semibold">No Timesheets Entry!</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul class="list-unstyled projects-maintask-card" *ngFor="let tm of timesheets">
                    <li>
                      <div class="d-flex align-items-top">
                        <div class="d-flex align-items-top flex-fill">
                          <span class="me-3"> 
                          <input class="form-check-input" type="checkbox" id="checkboxNoLabel1" value="" aria-label="...">
                          </span>
                          <div class="flex-fill"> <span>{{tm.comment}} </span> 
                            <p class="mb-0"><i class="las la-calendar"></i> {{ tm.entry_date | date}} | <i class="las la-clock"></i> {{minTohr(tm.effort)}} </p>
                          </div>
                        </div>
                      </div>
                      <hr>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="col-xxl-3">
          <div class="row">
            <div class="col-sm-12">
              <div class="card custom-card">
                <div class="card-body p-2 pb-0 justify-content-between align-items-center tempcl">
                  <div class="text-center">
                    <p class="mb-1">Work Report</p>
                  </div>
                  <div class="control-container">
                    <div class="states-control three-states radiobuttons">
                      <input type="radio" id="three-option1" (click)="getEmpWorkingReport(empInfo.u_id,'month')" class="option1" name="three-options" value="month" checked="checked" />
                      <input type="radio" id="three-option2" (click)="getEmpWorkingReport(empInfo.u_id,'week')" class="option2" name="three-options" value="week" />
                      <input type="radio" id="three-option3" (click)="getEmpWorkingReport(empInfo.u_id,'year')" class="option3" name="three-options" value="year" />
                      <div class="display">
                        <label class="option1" for="three-option1">Month</label>
                        <label class="option2" for="three-option2">Week</label>
                        <label class="option3" for="three-option3">Year</label>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="col-xxl-12">
              <div class="card custom-card">
                <div class="card-header justify-content-between">
                  <div class="card-title"><i class="las la-bell"></i> Today's Reminder </div>
                  <div class="dropdown">
                    <a href="javascript:void(0);" class="p-2 fs-12 text-muted" data-bs-toggle="dropdown" aria-expanded="false"><i class="ri-arrow-down-s-line align-middle ms-1 d-inline-block"></i>
                    </a>
                  </div>
                </div>
                <div class="card-body">
                  <ul class="list-unstyled project-transactions-card" *ngIf="reminders?.length < 1">
                    <li>
                      <div class="d-flex align-items-top">
                        <div class="me-3"> <span class="avatar avatar-rounded fw-bold avatar-md bg-primary-transparent"> 
                          </span> </div>
                        <div class="flex-fill">
                          <span class="d-block fw-semibold">No Reminder!</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <ul class="list-unstyled project-transactions-card" *ngFor="let reminder of reminders">
                    <li>
                      <div class="d-flex align-items-top">
                        <div class="me-3">
                          <span class="avatar avatar-rounded fw-bold avatar-md bg-primary-transparent">
                          </span>
                        </div>
                        <div>
                          <h6 class="fw-semibold">{{reminder.title}}</h6>
                        </div>
                        <div class="flex-fill">
                          <span class="d-block fw-semibold">{{reminder.description}}</span>
                          <span class="d-block text-muted fs-11">{{reminder.reminder_date | date}} - {{reminder.reminder_time}}
                          </span>
                        </div>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div> -->
    
            <div class="col-xxl-12">
              <div class="card custom-card">

                <div class="card-header justify-content-between">
                  <div class="card-title"><i class="las la-hourglass-half"></i> Working Hour Report</div>
                </div>
                <div class="card-body">
                  <div class="flex-fill">
                    <ul class="list-unstyled project-transactions-card scroll">
                      <li>
                        <details>
                          <summary>Spent Time in Modules - {{empReport | number: '1.0'}} H</summary>
                          <div>
                            <ul *ngIf="empReportArr?.length < 1">
                              <li>No Modules</li>
                            </ul>
                            <ul *ngFor="let m of empReportArr">
                              <li><a href="javascript: void(0);" [routerLink]='["/manage-module/",encodeURIParam(m.m_id)]'>{{m.m_name}} - </a>{{m.est_effort}} / {{m.effort + " Hr"}}</li>
                            </ul>
                          </div>
                        </details>
                      </li>
                      <br>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-xxl-12">
              <div class="card custom-card tas">
                <div class="card-header justify-content-between">
                  <div class="card-title"><i class="las la-exclamation-triangle"></i> Working Projects</div>
                </div>
                <div class="card-body">
                  <ul class="list-unstyled project-transactions-card">
                    <li>
                      <details>
                        <summary>Projects</summary>
                        <div>
                          <ul *ngIf="projects?.length < 1">
                            <li>No Projects Assigned!</li>
                          </ul>
                          <ul *ngFor="let p of projects">
                            <li><a href="javascript: void(0);" [routerLink]='["/manage-project/",encodeURIParam(p.p_id)]'>{{p.p_name}} - {{ p.est_end_date|date}}</a></li>
                          </ul>
                        </div>
                      </details>
                    </li>
                    <br>
                  </ul>
                </div>
              </div>
            </div>
      
            <div class="col-xxl-12">
              <div class="card custom-card tas">
                <div class="card-header justify-content-between">
                  <div class="card-title"><i class="las la-exclamation-triangle"></i> Not Available Today</div>
                </div>
                <div class="card-body">
                  <ul class="list-unstyled project-transactions-card">
                    <li>
                      <details>
                        <summary>Week Off
                           ({{empDetails.on_weekoff?.length}})</summary>
                        <div>
                          <ul *ngIf="empDetails.on_weekoff?.length < 1">
                            <li>No Week Off Today</li>
                          </ul>
                          <ul *ngFor="let w of empDetails.on_weekoff">
                            <li><a href="javascript: void(0);">{{w.name}}</a></li>
                          </ul>
                        </div>
                      </details>
                    </li>
                    <br>
                    <li>
                      <details>
                        <summary>On Leave ({{empDetails.on_leaves?.length}})</summary>
                        <div>
                          <ul *ngIf="empDetails.on_leaves?.length < 1">
                            <li>No Leave Today</li>
                          </ul>
                          <ul *ngFor="let l of empDetails.on_leaves">
                            <li><a href="javascript: void(0);">{{l.name}}</a></li>
                          </ul>
                        </div>
                      </details>
                    </li>
                    <br>
                    <!-- <li>
                      <details>
                        <summary>Timesheet Defaulters ({{empDetails.defaulters?.length}})</summary>
                        <div>
                          <ul *ngIf="empDetails.defaulters?.length < 1">
                            <li>No Defaulters Today</li>
                          </ul>
                          <ul *ngFor="let t of empDetails.defaulters">
                            <li><a href="javascript: void(0);">{{t.name}}</a></li>
                          </ul>
                        </div>
                      </details>
                    </li> -->
                  </ul>
                </div>
              </div>
            </div>
            <!-- ADisclaimer -->
          <div class="card-header justify-content-between sugg">
            <div class="containerad">
              <div class="centerad">
                <div class="titleb">
                  <h5><p class="text-dark"><i class="las la-exclamation-triangle"></i> Looking for something else ?? </p></h5>
                  <h6><a href="javascript: void(0);" [routerLink]='["/manage-employee/",encodeURIParam(empInfo.e_id)]'><i class="las la-dot-circle"></i> Know more about {{empInfo.name}} </a></h6>
                  <h6><a href="javascript: void(0);" [routerLink]='["/task/"]'><i class="las la-dot-circle"></i> See Task list ?</a></h6>
                  <h6><a href="javascript: void(0);" [routerLink]='["/leave/leave-requests/"]'><i class="las la-dot-circle"></i> Check leave requests ?</a></h6>
                </div>
                </div>
              </div>
            </div>
        </div>
      </div>
    </div>
  </div> 
</div>