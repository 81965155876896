import { Component, OnInit, HostListener } from '@angular/core';
import { RestService } from '../../rest.service';
import { NgxUiLoaderService } from 'ngx-ui-loader'; // Import NgxUiLoaderService
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-reports',
  templateUrl: './reports.component.html',
  styleUrls: ['./reports.component.scss']
})
export class ReportsComponent implements OnInit {

  public buttonOne:any   = 'Send';
  public buttonTwo:any   = 'Send';
  public buttonThree:any = 'Send';
  public buttonFour:any = 'Send';
  public unAssignTasks : any[] = [];
  public minDate       : any;
  public dailyReportDay:any     = 'today'
  public underWorkReportDay:any = 'today'
  public defaulterReportDay:any = 'today'

  constructor(
    public rest: RestService,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService
  ) {
    let d = new Date();
    this.minDate = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
  }

  ngOnInit(): void {
  }

  sendReport(key: string) {
    if (key == 'timesheet-defaulter') {
      this.buttonOne = 'Sending...'
      this.rest.getData({report_date:this.defaulterReportDay}, 'defaultermail').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.buttonOne = 'Sent!'
            this.toaster.success(data.message);
          } else {
            this.toaster.warning(data.message);
            this.buttonOne = 'Failed!'
          }
        },
        error: (e) => {
          this.toaster.error('Something went wrong');
        },
      });
    } else if (key == 'under-working') {
      this.buttonTwo = 'Sending...'
      this.rest.getData({report_date:this.underWorkReportDay }, 'underworkingemployees').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.buttonTwo = 'Sent!'
            this.toaster.success(data.message);
          } else {
            this.toaster.warning(data.message);
            this.buttonTwo = 'Failed!'
          }
        },
        error: (e) => {
          this.toaster.error('Something went wrong');
        },
      });
    } else if (key == 'working-report') {
      this.buttonThree = 'Sending...'
      this.rest.getData({report_date:this.dailyReportDay}, 'empworkinghoursreportmail').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.buttonThree = 'Sent!'
            this.toaster.success(data.message);
          } else {
            this.toaster.warning(data.message);
            this.buttonThree = 'Failed!'
          }
        },
        error: (e) => {
          this.toaster.error('Something went wrong');
        },
      });
    } else if (key == 'reminder') {
      this.buttonFour = 'Sending...'
      this.rest.getData({report_date:this.dailyReportDay}, 'empremindermail').subscribe({
        next: (data) => {
          if (data.state == '200') {
            this.buttonFour = 'Sent!'
            this.toaster.success(data.message);
          } else {
            this.toaster.warning(data.message);
            this.buttonFour = 'Failed!'
          }
        },
        error: (e) => {
          this.toaster.error('Something went wrong');
        },
      });
    }
  }

  reportDay(type:any,day: any){
    if(type == 'daily_work'){
      this.dailyReportDay = day.value
    }else if(type == 'under_work'){
      this.underWorkReportDay = day.value;
    }else if(type == 'defaulter'){
      this.defaulterReportDay = day.value;
    }
    console.log(this.dailyReportDay);
  }
}
