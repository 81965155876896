import { Injectable, ɵConsole } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Observable, Subject, ReplaySubject, from, of, range } from "rxjs";
import { map, filter, retry } from "rxjs/operators";
//import { LoginpopupService } from "./loginpopup.service";
//import { AuthServiceService } from "./auth-service.service";
import { LocalStorageService, SessionStorageService } from "ngx-webstorage";
import { environment } from '../environments/environment';
//import { DeviceDetectorService } from 'ngx-device-detector';

@Injectable({
  providedIn: "root"
})
export class RestService {
  /* main server api url */
  // apiUrl = 'https://worksheet.bookyup.com/';
  /* testing server api url */
  // apiUrl = 'https://worksheet.bookyep.com/';
  /* Local server url */
  // apiUrl = 'http://localhost/worksheet/public/';
  apiUrl = environment.apiUrl;
  //apiUrl = environment.apiUrl;
  deviceInfo:any;
  constructor(
    public http   : HttpClient,
    public localSt: LocalStorageService,
    public sesnSt : SessionStorageService
  ) {}

  getData(data:any,fnc:any,fileData:boolean=false): Observable<any> {
    // We do not subscribe here! We let the resolver take care of that...
    const user  = this.localSt.retrieve("user");
    if( user   != undefined && user != null  && user != '' ){
      data.session=user.data.session;
    }
    let headers = new HttpHeaders({'Content-Type': 'application/json', 'Accept': 'application/json'});
    if(fileData){
      if( user != undefined && user != null  && user != '' ){
        data.append('session',user.data.session);
      }
      return this.http.post(this.apiUrl + fnc, data);
    }else{
      return this.http.post(this.apiUrl + fnc, JSON.stringify(data),{headers: headers});
    }
  }
}
