import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ManageProfileComponent } from './manage-profile/manage-profile.component';
import { EmployeesDashboardComponent } from './employees-dashboard/employees-dashboard.component';
import { AttendanceComponent } from './attendance/attendance.component';
import { ViewAttendanceComponent } from './view-attendance/view-attendance.component';
import { EditAttendanceComponent } from './edit-attendance/edit-attendance.component';
import { MyDashboardComponent } from './my-dashboard/my-dashboard.component';
import { AuthGuard } from 'src/app/auth.guard';

const routes: Routes = [
  {
    path: 'manage-profile',
    component: ManageProfileComponent,
    //canActivate: [AuthGuard]
  },
  {
    path: 'attendance',
    component: AttendanceComponent,
    //canActivate: [AuthGuard]
  },
  {
    path: 'employee-dashboard',
    component: EmployeesDashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'view-attendance',
    component: ViewAttendanceComponent,
    //canActivate: [AuthGuard]
  },
  {
    path: 'edit-attendance',
    component: EditAttendanceComponent,
    //canActivate: [AuthGuard]
  },
  {
    path: 'my-dashbroad',
    component: MyDashboardComponent,
    //canActivate: [AuthGuard]
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class EmployeesRoutingModule { }
