<div class="main-content" *ngIf="loadAllComponant">
<div class="row justify-content-center align-items-center">
  <div class="col-lg-5">
    <div class="card mt-5">
      <div class="card-body text-center">
        <!-- <div class="emp">
          Employee Id : E5544
        </div> -->
        <h2 class="display-6">{{loggedInUserName}}</h2>
        <h6>{{ currentDateTime | date:'shortTime' }} | {{ currentDateTime | date:'longDate' }}</h6>
        <webcam [trigger]="triggerObservable"  (imageCapture)="handleImage($event)"></webcam>
        <!-- <div class="live-cam">
              <h2>No Camera Available</h2>
          </div> -->
        <h5 class="mt-3">To give your attendance, click the button below.</h5>
        <div>
        
          <ng-container *ngIf="onLoadData!=undefined && onLoadData.isShowPunchIn">
            <button class="btn btn-primary bg-custom border-0 w-50 mb-2" (click)="triggerSnapshot();"> <i
                class="las la-fingerprint"></i> Punch In </button>
          </ng-container>
          <ng-container *ngIf="onLoadData!=undefined && onLoadData.inTime!=''">
            <button class="btn btn-primary bg-custom border-0 w-50 mb-2" disabled> <i
                class="las la-fingerprint"></i> In Time: {{onLoadData.inTime}}</button>
          </ng-container>
          <ng-container *ngIf="onLoadData!=undefined && onLoadData.isShowPunchOut && onLoadData.outTime==''">
            <button class="btn btn-danger  border-0 w-50 mb-2" (click)="triggerSnapshot('PunchOut');"> <i class="las la-fingerprint"></i> Punch Out </button>
          </ng-container>
          <ng-container *ngIf="onLoadData!=undefined && onLoadData.outTime!=''">
            <button class="btn btn-primary bg-custom border-0 w-50 mb-2" disabled> <i class="las la-fingerprint"></i> Out Time: {{onLoadData.outTime}}</button>
          </ng-container>        
      </div>
        <!-- <img src="{{ captureImage }}" height="300px"> -->
      </div>
    </div>
  </div>
</div>
</div>
<ngx-ui-loader></ngx-ui-loader>