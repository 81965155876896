import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from 'src/app/rest.service';
import { ToastrService } from 'ngx-toastr';
import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-attendance',
  templateUrl: './add-attendance.component.html',
  styleUrls: ['./add-attendance.component.scss']
})
export class AddAttendanceComponent implements OnInit {
  public userlist: any;
  public finalDate = '';
  public selectUser: any;
  public attendanceList: any;
  public isShowReset=false;
  public maxObject:any;
  public dropdownSettings = {};
  public filter_status = false;
  public loadAllComponant = false;
  public loggedInUserName: string = '';
  constructor(
    public rest: RestService,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.getUserData();
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select Employee',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: 'myclass custom-class',
    };
    this.getUser();
    this.getEditAttendanceList();
    let d = new Date();
    this.maxObject = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
  }

  getUserData() {
    this.rest.getData({}, 'getloginuser').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.loggedInUserName = data.data.name;
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning("Authorization error!!");
      },
    });
  }

  onDateSelect(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.finalDate = year + '-' + month + '-' + day;
    console.log(this.finalDate);
    this.getEditAttendanceList();
    this.isShowReset=true;
  }

  select_user(ev: any) {
    this.selectUser = ev;
  }
  filter_status_btn(){
    this.filter_status = !this.filter_status;
  }
  /* Api call for fetching all user list */
  getUser() {
    this.ngxService.start();
    this.rest.getData({}, 'getemployees').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        if ((data.state = '200')) {
          this.userlist = data.data;
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        this.toaster.error('Somethiong Went wrong');
      },
    });
  }
  /* Api call for getting current date attendance list */
  getEditAttendanceList() {
    this.ngxService.start();
    this.rest.getData({ u_id: this.selectUser, date: this.finalDate,type:'add' },'geteditattendancelist').subscribe({
      next: (data) => {
        this.ngxService.stop();
        if ((data.state = '200')) {
          console.log(data.data);
          this.attendanceList = data.data;
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.toaster.error('Somethiong Went wrong');
      },
    });
  }

  edit_att(obj: any) {
    obj.isEdit = false;
  }
  /* update attendance data */
  save(obj: any) {
    let { u_id,country,date,inTime, outTime } = obj;
    if(inTime!=undefined && inTime!=null && inTime!=""){
      this.ngxService.start();
      this.rest
      .getData({ u_id,country,date,inTime, outTime }, 'saveattendance')
      .subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            obj.isEdit = true;
            this.toaster.success(data.message);
            this.getEditAttendanceList();
          } else {
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Somethiong Went wrong');
        },
      });
    }else{
      this.toaster.error('In-time is required');
    }
    
  }
  /* reset form data */
  resetForm(){
    this.selectUser=[];
    this.finalDate ="";
    this.getEditAttendanceList();
  }

}
