import { Component, OnInit } from '@angular/core';
import { RestService } from '../../rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { AuthServiceService } from '../../auth-service.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { PagesService } from '../pages.service';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-create-automated-task',
  templateUrl: './create-automated-task.component.html',
  styleUrls: ['./create-automated-task.component.scss']
})
export class CreateAutomatedTaskComponent implements OnInit {

  taskTypeList = <any>[];
  public loadDataInfomationStatus = false;
  public loadAllComponant         = true;
  public ModuleTypeList           = <any>[];
  public taskForm!                : FormGroup;
  public taskData                 = <any>[];
  public isShow                   = true;
  public formData                 = new FormData();
  public loggedInUserName: string = '';

  constructor(
    public rest: RestService,
    private formBuilder: FormBuilder,
    private ls: LocalStorageService,
    private auth: AuthServiceService,
    private route: Router,
    private toaster: ToastrService,
    private pageService: PagesService,
    private activatedroute: ActivatedRoute,
    private ngxService: NgxUiLoaderService
  ) { }

  ngOnInit(): void {
    this.getUserData();
    this.getTaskType();
    this.getModuleType();
    this.taskForm = this.formBuilder.group({
      module_type       : ['',   [Validators.required]],
      task_name         : [null, [Validators.required]],
      task_estimated_pd : [null, [Validators.required]],
      t_status       : ['',   [Validators.required]],
      task_type         : ['',   [Validators.required]],
      t_description     : ['',   [Validators.required]],
    });
  }  
  getUserData() {
    this.rest.getData({}, 'getloginuser').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.loggedInUserName = data.data.name;
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning("Authorization error!!");
      },
    });
  }

  getModuleType() {
    this.rest.getData({}, 'getmoduletypes').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.ModuleTypeList = data.data;
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        console.log(e);
        this.toaster.error("Something went wrong!");
      },
    });
  }

  onSubmit(form: FormGroup) {
    //console.log(form.value);return;
    if (form.invalid) {
      return;
    }
    let payload = {
      m_type        : form.value.module_type,
      t_name        : form.value.task_name,
      t_status      : form.value.t_status,
      t_effort      : form.value.task_estimated_pd,
      t_type        : form.value.task_type,
      t_description : form.value.t_description
    }
    this.rest.getData(payload, 'addautomatedtask').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.toaster.success(data.message);
          setTimeout(() => {
            this.route.navigate(['automated-task']);
          }, 1500);
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.toaster.error('Something went wrong');
      },
    });
  }

  getTaskType() {
    this.rest.getData({},'gettasktypes').subscribe({
      next: (data) => {
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.taskTypeList = data.data;
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        console.log(e);
        this.toaster.error("Something went wrong!");
      },
    });
  }

}
