<div class="quicklink-sidebar-menu ctm-border-radius  bg-white card grow">
  <div class="card-body">
    <div class="cardTitle d-flex justify-content-between align-items-center">
      <h5 class="p-0 m-0">Leave Roster </h5>
      <button class="btn btn-primary bg-custom border-0 "><i class="lar la-caret-square-left 48px"></i></button>
    </div>
  </div>
</div>
<div class="accordion add-employee" id="accordion-details">
  <div class="card  grow ctm-border-radius">
    <div class="card-header" id="basic1">
      <h4 class="cursor-pointer mb-0">
        <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
          data-target="#basic-one" aria-expanded="true">
          Roster Details
        </a>
      </h4>
    </div>
    <div class="card-body p-0">
      <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details"
        >
        <form>
          <div class="row">
            <div class="col-lg-12">
              <div class="customer_track d-flex justify-content-between">
                <h5 class=""><b>Name :</b> Debasish Dey</h5> <h4><b>Id :</b> 87878</h4>
              </div>
            </div>
            <div class="col-lg-6 form-group">
              <div class="input-group mb-3">
                <div class="input-group-text"><i class="las la-globe"></i>
                </div>
                <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                  <option selected="">Employee Country</option>
                  <option value="1">India</option>
                  <option value="2">Bangaldesh</option>
                </select>
              </div>
            </div>
            <div class="col-lg-6">
              <div class="input-group mb-3">
                <div class="input-group-text"><i class="las la-globe"></i>
                </div>
                <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true" (change)="roaster()">
                  <option selected="">Weekly Roster</option>
                  <option value="1">1</option>
                  <option value="2">2</option>
                </select>
              </div>
            </div>
            <div class="col-lg-12" *ngIf="status">
              <div class="weeklyRost d-flex mb-3">
                <div class="form-check p-0">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckDefault">
                  <label class="form-check-label" for="flexCheckDefault">
                    Wedness Day
                  </label>
                </div>
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" value="" id="flexCheckChecked" checked>
                  <label class="form-check-label" for="flexCheckChecked">
                    Friday
                  </label>
                </div>
              </div>
            </div>
            <div class="col-lg-12 form-group">
              <div class="input-group mb-3">
                <button class="btn custom-btn btn-8"><span>Submit Now</span></button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>
