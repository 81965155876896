import { Component, OnInit } from '@angular/core';
import { RestService } from '../../rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SessionStorageService, LocalStorageService } from 'ngx-webstorage';
import { AuthServiceService } from '../../auth-service.service';
import { Router } from '@angular/router';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-create-project',
  templateUrl: './create-project.component.html',
  styleUrls: ['./create-project.component.scss'],
})
export class CreateProjectComponent implements OnInit {
  projectForm!: FormGroup;
  error = false;
  isShow = true;
  finalEstDate = '';
  finalStartDate = '';
  finalEndDate = '';
  userlist = <any>[];
  itemList: any;
  dropdownSettings = {};
  public selectedItems: any = [];
  public minDate: any;
  public loadAllComponant = false;
  public loggedInUserName: string = '';
  constructor(
    private ngxService: NgxUiLoaderService,
    public rest: RestService,
    private formBuilder: FormBuilder,
    private ls: LocalStorageService,
    private auth: AuthServiceService,
    private route: Router,
    private toaster: ToastrService
  ) {
    let d = new Date();
    this.minDate = {
      year: d.getFullYear(),
      month: d.getMonth() + 1,
      day: d.getDate(),
    };
  }

  ngOnInit(): void {
    this.itemList = [];
    this.dropdownSettings = {
      singleSelection: false,
      text: 'Select Employee',
      selectAllText: 'Select All',
      unSelectAllText: 'UnSelect All',
      enableSearchFilter: false,
      classes: 'myclass custom-class',
    };
    /* This is object set for adding validation on selected fileds */
    this.projectForm = this.formBuilder.group({
      pname: [null, [Validators.required]],
      ptype: ['SM', [Validators.required]],
      pstage: [null, [Validators.required]],
      pestimetedeffort: [
        null,
        [Validators.required, Validators.pattern(/^[0-9]\d*$/)],
      ],
      pestimetedenddate: [null, [Validators.required]],
      p_start_date: [null, [Validators.required]],
      p_end_date: [null, [Validators.required]],
      pdescription: [null, [Validators.required]],
      pdomain: ['Development', [Validators.required]],
      users: [null, [Validators.required]],
    });

    this.getUser();
  }

  getUserData() {
    this.getUserData();
    this.rest.getData({}, 'getloginuser').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.loggedInUserName = data.data.name;
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning("Authorization error!!");
      },
    });
  }

  /* This function is used to save new project */
  onSubmit(form: FormGroup) {
    this.error = false;
    if (form.invalid) {
      return;
    }
    this.isShow = false;
    this.ngxService.start();
    let apiPayload = { ...form.value };
    apiPayload.pestimetedenddate = this.finalEstDate;
    apiPayload.p_start_date = this.finalStartDate;
    apiPayload.p_end_date = this.finalEndDate;
    let userpayload = <any>[];
    form.value.users.forEach((element: any) => {
      let tmpObj = { id: element, role: 'member' };
      userpayload.push(tmpObj);
    });
    apiPayload.users = userpayload;
    this.rest.getData(apiPayload, 'addproject').subscribe({
      next: (data) => {
        this.isShow = true;
        this.ngxService.stop();
        if (data.state == '200') {
          this.toaster.success(data.message);
          setTimeout(() => {
            this.route.navigate(['project']);
          }, 500);
        } else {
          this.isShow = true;
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        console.log(e);
        this.error = true;
        this.isShow = true;
        this.toaster.error('Something went wrong');
      },
    });
  }
  /* This function is used to convert date object to php date format */
  onEstDateSelect(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.finalEstDate = year + '-' + month + '-' + day;
    console.log(this.finalEstDate);
  }
  /* This function is used to convert date object to php date format */
  onStartDateSelect(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.finalStartDate = year + '-' + month + '-' + day;
    console.log(this.finalStartDate);
  }
  /* This function is used to convert date object to php date format */
  onEndDateSelect(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.finalEndDate = year + '-' + month + '-' + day;
    console.log(this.finalEndDate);
  }
  /* This function is used to fetch all user list */
  getUser() {
    //this.ngxService.start();
    this.rest.getData({}, 'getemployees').subscribe({
      next: (data) => {
        //this.ngxService.stop();
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.userlist = data.data;
          this.userlist.forEach((element: any) => {
            let tmpObj = { id: element.u_id, name: element.name };
            this.itemList.push(tmpObj);
          });
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        //this.ngxService.stop();
        this.loadAllComponant = true;
        this.toaster.error('Something went wrong!');
      },
    });
  }
}
