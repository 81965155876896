<div class="four-zero-three">
  <div class="inner">
    <svg xmlns="http://www.w3.org/2000/svg" version="1.1" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:svgjs="http://svgjs.com/svgjs" width="512" height="512" x="0" y="0" viewBox="0 0 512 512" style="enable-background:new 0 0 512 512" xml:space="preserve" class=""><g>
      <g xmlns="http://www.w3.org/2000/svg">
        <path d="M34.74,155.984v279.191H477.26V155.984c0-13.655-11.069-24.724-24.724-24.724H59.465   C45.81,131.26,34.74,142.329,34.74,155.984z" fill="#3872b7" data-original="#3872b7" class=""></path>
        <path d="M63.636,435.175V189.811c0-9.103,7.38-16.483,16.483-16.483h351.763   c9.103,0,16.483,7.38,16.483,16.483v245.364H63.636z" fill="#7cccf2" data-original="#7cccf2" class=""></path>
        <path d="M0,463.071v-19.654c0-4.552,3.69-8.241,8.241-8.241h495.517c4.552,0,8.241,3.69,8.241,8.241v19.654   c0,13.655-11.069,24.724-24.724,24.724H24.724C11.069,487.795,0,476.725,0,463.071z" fill="#ebeef2" data-original="#ebeef2" class=""></path>
        <path d="M503.759,435.175h-24.724c4.552,0,8.241,3.69,8.241,8.241v19.654   c0,13.655-11.069,24.724-24.724,24.724h24.724c13.655,0,24.724-11.069,24.724-24.724v-19.654   C512,438.865,508.31,435.175,503.759,435.175z" fill="#c9cdd3" data-original="#c9cdd3" class=""></path>
        <path d="M202.774,448.602v-13.427h106.452v13.427c0,5.69-4.612,10.302-10.302,10.302h-85.848   C207.386,458.904,202.774,454.291,202.774,448.602z" fill="#c9cdd3" data-original="#c9cdd3" class=""></path>
        <g>
          <path d="M370.103,74.909c-1.977,0-3.955-0.755-5.464-2.263c-3.017-3.018-3.017-7.909,0-10.928    l15.295-15.295c3.018-3.016,7.909-3.016,10.928,0c3.017,3.018,3.017,7.909,0,10.928l-15.295,15.295    C374.057,74.155,372.079,74.909,370.103,74.909z" fill="#243b5d" data-original="#243b5d" class=""></path>
          <path d="M343.357,63.561c-4.268,0-7.726-3.459-7.726-7.726V31.931c0-4.268,3.459-7.726,7.726-7.726    s7.726,3.459,7.726,7.726v23.903C351.083,60.102,347.625,63.561,343.357,63.561z" fill="#243b5d" data-original="#243b5d" class=""></path>
          <path d="M405.355,101.655h-23.903c-4.268,0-7.726-3.459-7.726-7.726c0-4.268,3.459-7.726,7.726-7.726    h23.903c4.268,0,7.726,3.459,7.726,7.726C413.081,98.196,409.623,101.655,405.355,101.655z" fill="#243b5d" data-original="#243b5d" class=""></path>
        </g>
        <path d="M199.162,360.685c-13.575,0-26.224-7.303-33.011-19.06l-56.838-98.445   c-6.786-11.757-6.786-26.36-0.001-38.115l56.84-98.449c6.784-11.755,19.433-19.058,33.01-19.058h113.676   c13.577,0,26.226,7.304,33.012,19.062l56.837,98.443c6.786,11.757,6.786,26.36,0.001,38.115l-56.84,98.449   c-6.786,11.756-19.435,19.058-33.01,19.058H199.162z" fill="#ebeef2" data-original="#ebeef2" class=""></path>
        <path d="M402.687,205.064l-56.837-98.443c-6.786-11.759-19.435-19.062-33.012-19.062h-31.385   c11.535,0,22.281,7.304,28.046,19.062l48.286,98.443c5.765,11.757,5.765,26.36,0.001,38.115l-48.289,98.449   c-5.765,11.756-16.511,19.058-28.044,19.058h31.385c13.575,0,26.224-7.303,33.01-19.058l56.84-98.449   C409.473,231.424,409.473,216.82,402.687,205.064z" fill="#c9cdd3" data-original="#c9cdd3" class=""></path>
        <path d="M383.059,216.396l-56.838-98.447c-2.76-4.781-7.862-7.726-13.382-7.726H199.162   c-5.521,0-10.622,2.945-13.382,7.726l-56.838,98.447c-2.76,4.781-2.76,10.672,0,15.453l56.838,98.447   c2.76,4.781,7.862,7.726,13.382,7.726h113.677c5.521,0,10.622-2.945,13.382-7.726l56.838-98.447   C385.819,227.067,385.819,221.177,383.059,216.396z" fill="#f72878" data-original="#ec6b59" class=""></path>
        <path d="M383.059,216.396l-56.838-98.447c-2.76-4.781-7.862-7.726-13.382-7.726h-1.573l46.52,94.841   c5.765,11.757,5.765,26.36,0.001,38.115l-46.52,94.843h1.573c5.521,0,10.622-2.945,13.382-7.726l56.838-98.447   C385.819,227.067,385.819,221.177,383.059,216.396z" fill="#d8256a" data-original="#bc4142" class=""></path>
        <g>
          <path d="M219.772,225.152h-2.919v-5.666c0-4.268-3.459-7.726-7.726-7.726s-7.726,3.459-7.726,7.726v5.666    h-10.104l14.399-29.394c1.877-3.833,0.293-8.461-3.539-10.338c-3.836-1.881-8.462-0.292-10.338,3.539l-19.849,40.52    c-1.173,2.395-1.028,5.225,0.382,7.488c1.412,2.263,3.889,3.638,6.556,3.638H201.4v15.282c0,4.268,3.459,7.727,7.726,7.727    s7.726-3.459,7.726-7.727v-15.282h2.919c4.268,0,7.726-3.458,7.726-7.726C227.498,228.61,224.039,225.152,219.772,225.152z" fill="#ebeef2" data-original="#ebeef2" class=""></path>
          <path d="M333.092,225.152h-2.918v-5.666c0-4.268-3.459-7.726-7.726-7.726c-4.268,0-7.726,3.459-7.726,7.726    v5.666h-10.104l14.399-29.394c1.877-3.833,0.293-8.461-3.539-10.338c-3.837-1.881-8.463-0.292-10.338,3.539l-19.849,40.52    c-1.173,2.395-1.028,5.225,0.382,7.488c1.411,2.263,3.889,3.638,6.556,3.638h22.492v15.282c0,4.268,3.459,7.727,7.726,7.727    c4.268,0,7.726-3.459,7.726-7.727v-15.282h2.918c4.268,0,7.726-3.458,7.726-7.726C340.818,228.61,337.359,225.152,333.092,225.152    z" fill="#ebeef2" data-original="#ebeef2" class=""></path>
          <path d="M256,188.257c-13.346,0-24.204,10.857-24.204,24.203v23.323c0,13.346,10.858,24.203,24.204,24.203    s24.204-10.857,24.204-24.203v-23.323C280.204,199.115,269.346,188.257,256,188.257z M264.751,235.784    c0,4.825-3.926,8.75-8.751,8.75s-8.751-3.926-8.751-8.75v-23.323c0-4.825,3.926-8.75,8.751-8.75s8.751,3.926,8.751,8.75V235.784z" fill="#ebeef2" data-original="#ebeef2" class=""></path>
        </g>
        <rect x="237.336" y="360.685" width="37.328" height="74.49" fill="#52668d" data-original="#52668d"></rect>
        <rect x="237.336" y="360.685" width="37.328" height="24.724" fill="#243b5d" data-original="#243b5d" class=""></rect>
      </g>
      </g></svg>
    <div class="desc">Page Not Found</div>
    <div class="content">The page you're looking for can't be found.</div>
  </div>
</div>
