import { Component, OnInit } from '@angular/core';
import { RestService } from '../../../rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LocalStorageService } from 'ngx-webstorage';
import { AuthServiceService } from '../../../auth-service.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { formatDate } from '@angular/common';
@Component({
  selector: 'app-add-employee',
  templateUrl: './add-employee.component.html',
  styleUrls: ['./add-employee.component.scss'],
})
export class AddEmployeeComponent implements OnInit {
  public userForm!: FormGroup;
  public error = false;
  public finalDate = '';
  public dobDate = '';
  public formatJoin='';
  public today: any = new Date()
  public selectedRoles = <any>[];
  public maxDateLimit: any;
  public minDateLimit: any;
  public minJoinDate : any;
  public loggedInUserName: string = '';
  public isShow=true;
  public permissionData: Array<any> = [
    { name: 'Admin', value: 1 },
    { name: 'Manager', value: 2 },
    { name: 'Team Lead', value: 3 },
    { name: 'Team Member', value: 4 },
  ];
  constructor(
    public rest: RestService,
    private formBuilder: FormBuilder,
    private ls: LocalStorageService,
    private auth: AuthServiceService,
    private route: Router,
    private toaster: ToastrService,
    private ngxService: NgxUiLoaderService,
  ) {}

  ngOnInit(): void {
    this.getUserData();
    /* This is object set for adding validation on selected fileds */
    this.userForm = this.formBuilder.group({
      name: [null, [Validators.required]],
      designation: [null, [Validators.required]],
      type: ['', [Validators.required]],
      email: [null, [Validators.required, Validators.email]],
      mobile: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      country: [null, [Validators.required]],
      dob: [null, [Validators.required]],
      join_date: [null, [Validators.required]],
    },{validator: this.dateLessThan('join_date', 'dob')});
    //this.getDateTimeLimit();
  }

  getUserData() {
    this.rest.getData({}, 'getloginuser').subscribe({
      next: (data) => {
        if (data.state == '200') {
          this.loggedInUserName = data.data.name;
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.toaster.warning("Authorization error!!");
      },
    });
  }
  /* Create new employee */
  onSubmit(form: FormGroup) {
    this.error = false;
    if (form.invalid) {
      return;
    }
    this.ngxService.start();
    this.isShow=false;
    let apiPayload = { ...form.value };
    apiPayload.dob = this.dateConvert(apiPayload.dob);
    apiPayload.join_date = this.dateConvert(apiPayload.join_date);
    apiPayload.roles = this.selectedRoles;
    this.rest.getData(apiPayload, 'adduser').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.isShow=true;
        if(data.state=='200'){
          this.toaster.success(data.message);
          setTimeout(() => {
            this.route.navigate(['employee']);
          }, 500);
        }else{
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.isShow=true;
        this.ngxService.stop();
        this.error = true;
        this.toaster.error('Something went wrong', 'danger');
      },
    });
  }
  /*
    this function used to push role array 
  */
  permission_toggle(ch: any, e: any) {
    console.log(ch);
    console.log(e.target.checked);
    if (e.target.checked) {
      this.selectedRoles.push(ch);
    } else {
      for (var i = 0; i < this.selectedRoles.length; i++) {
        if (this.selectedRoles[i] === ch) {
          this.selectedRoles.splice(i, 1);
        }
      }
    }
    console.log(this.selectedRoles);
  }

  dateConvert(event: any) {
    if(event==null) return;
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    return year + '-' + month + '-' + day;
  }
  /* Validation of dob and join date */
  dateLessThan(join_date: string, dob: string){
    return (group: FormGroup): {[key: string]: any} => {
      let dJoin = this.dateConvert(group.controls[join_date].value);
      let dDob = this.dateConvert(group.controls[dob].value);
      if(dJoin==null || dDob==null) return {};
      if (dDob >= dJoin) {
        this.toaster.error("Date of birth must be less than join date");
        return {
          dates: "Date of birth must be less than join date"
        };
      }
      return {};
    }
  }

  onDobDateSelect(event: any) {
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.dobDate = year + "-" + month + "-" + day;
    this.getDiff(this.dobDate,this.formatJoin);
  }

  onJoinDateSelect(event: any){
    let year = event.year;
    let month = event.month <= 9 ? '0' + event.month : event.month;;
    let day = event.day <= 9 ? '0' + event.day : event.day;
    this.formatJoin = year + "-" + month + "-" + day;
    this.getDiff(this.dobDate,this.formatJoin);
  }
  /* Validation of dob and join date */
  getDiff(sdate:string,edate:string) {
    if(edate=='' || sdate==''){
      return;
    }
    let startDay = new Date(sdate);
    let dob = new Date(edate);
    let diff = (startDay.getTime() - dob.getTime()) / 1000;
    diff /= (60 * 60 * 24);
    let currentAge = Math.abs(Math.round(diff / 365.25));
    console.log(currentAge);
    if (currentAge < 18) {
      this.toaster.error("Minimum age should be 18 years!");
      this.isShow = false;
    }else{
      this.isShow = true;
    } 
  }

 /*  getDateTimeLimit() {
    this.rest.getData({}, 'calculateuserage').subscribe({
      next: (data) => {
        console.log(data);
        if (data.state == '200') {
          // this.maxDateLimit = {
          //   year: parseInt(formatDate(data.data.maxDate, 'yyyy', 'en')),
          //   month: parseInt(formatDate(data.data.maxDate, 'MM', 'en')),
          //   day: parseInt(formatDate(data.data.maxDate, 'dd', 'en'))
          // };minDateLimit
          this.minDateLimit = {
            year: parseInt(formatDate(data.data.min, 'yyyy', 'en')),
            month: parseInt(formatDate(data.data.min, 'MM', 'en')),
            day: parseInt(formatDate(data.data.min, 'dd', 'en'))
          },
          this.maxDateLimit = {
            year: parseInt(formatDate(data.data.max, 'yyyy', 'en')),
            month: parseInt(formatDate(data.data.max, 'MM', 'en')),
            day: parseInt(formatDate(data.data.max, 'dd', 'en'))
          },
          this.minJoinDate = {
            year: parseInt(formatDate(data.data.minJoin, 'yyyy', 'en')),
            month: parseInt(formatDate(data.data.minJoin, 'MM', 'en')),
            day: parseInt(formatDate(data.data.minJoin, 'dd', 'en'))
          }
          console.log(this.maxDateLimit);
          console.log(this.minDateLimit);
        }
      }
    })
  } */
}