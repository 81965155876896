<div class="main-content" *ngIf="loadAllComponant">
  <!-- <div class="quicklink-sidebar-menu ctm-border-radius  bg-white card grow">
    <div class="card-body">
      <div class="cardTitle d-flex justify-content-between align-items-center">
        <h5 class="p-0 m-0"></h5>
      </div>
    </div>
  </div> -->
  <div class="accordion add-employee" id="accordion-details">
    <div class="card  grow ctm-border-radius">
      <div class="card-header" id="basic1">
        <h4 class="cursor-pointer mb-0">
          <a class="coll-arrow d-block text-dark" routerLink="javascript:void(0)" data-toggle="collapse"
            data-target="#basic-one" aria-expanded="true">
            Create New Module
          </a>
        </h4>
      </div>
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
          <form [formGroup]="moduleForm" (ngSubmit)="onSubmit(moduleForm)" #fd="ngForm" [ngxFvControl]="fd">
            <div class="row">
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['project_id']">
                  <label #ngxFvName>
                    Project Name
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-rocket"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      #ngxFvFormControl formControlName="project_id">
                      <option value="">Project Name</option>
                      <ng-container *ngFor="let proj of projectList">
                        <option value="{{proj.id}}">{{proj.p_name}} /{{proj.p_id}}</option>
                      </ng-container>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_name']">
                  <label #ngxFvName>
                    Module Name
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="lab la-git-alt"></i>
                    </div>
                    <input type="text" (keyup)="moduleName()" [(ngModel)]="mKeyword"  class="form-control" id="username" placeholder="Module Name" #ngxFvFormControl formControlName="m_name">
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-12 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_description']">
                  <label #ngxFvName>
                    Module Description
                  </label>
                  <div class="input-group mb-3">
                    <textarea type="text" name="cStart" class="form-control" id="username"
                      placeholder="Module Description " ngxFvFormControl formControlName="m_description"></textarea>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_status']">
                  <label #ngxFvName>
                    Module Status
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-asterisk"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      ngxFvFormControl formControlName="m_status">
                      <option value="">Status </option>
                      <option value="Inpogress">Inpogress</option>
                      <option value="Planned">Planned</option>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_estimated_end_date']">
                  <label #ngxFvName>
                    Est End Date
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-calendar-alt"></i>
                    </div>
                    <input type="text" class="form-control" readonly ngbDatepicker #endStart="ngbDatepicker" id="username"
                      placeholder="YYYY-MM-DD" (click)="endStart.toggle()" ngxFvFormControl
                      formControlName="m_estimated_end_date" (dateSelect)="onDateSelect($event)" [minDate]="minDate">
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['risk']">
                  <label #ngxFvName>
                    Risk
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-asterisk"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true" ngxFvFormControl formControlName="risk">
                      <option value="">Risk </option>
                      <option value="Low">Low </option>
                      <option value="High">High </option>
                      <option value="Modarate">Modarate </option>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_type']">
                  <label #ngxFvName >
                    Module Type
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-random"></i>
                    </div>
                    <!-- <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"  ngxFvFormControl formControlName="m_type">
                      <option value="">Module Type </option>
                      <option value="Feature Development">Feature Development</option>
                      <option value="Content Writing">Content Writing</option>
                      <option value="Menu Creation">Menu Creation</option>
                      <option value="Rework">Rework</option>
                      <option value="Production Bug Fix">Production Bug Fix</option>
                      <option value="HTML development">HTML development</option>
                      <option value="Support">Support</option>
                      <option value="App Publish">App Publish</option>
                      <option value="UX development">UX development</option>
                      <option value="Creative Design">Creative Design</option>
                      <option value="Testing">Testing</option>
                    </select> -->
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"  ngxFvFormControl formControlName="m_type" (change)="addModuleType($event)">
                      <option value="">Select Type</option>
                      <ng-container *ngFor="let mod of ModuleTypeList">
                        <option value="{{mod.type_name}}">{{mod.type_name}}</option>
                      </ng-container>
                      <option value="Add New">-- Add New --</option>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_estimated_effort']">
                  <label #ngxFvName>
                    Estimeted effort <small >(1 PD = 8 Hours or 1 working day)</small>
                  </label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="lab la-git-alt"></i>
                  </div>
                 <input type="text" class="form-control" id="username" placeholder=" Estimeted effort" #ngxFvFormControl formControlName="m_estimated_effort"/>
                </div>
                <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['challenges']">
                <label #ngxFvName>
                  Challenge
                </label>
                  <div class="input-group mb-3">
                    <textarea type="text" name="cStart" class="form-control" placeholder="Challenge " #ngxFvFormControl
                      formControlName="challenges"></textarea>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-12 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['challenges']">
                  <label #ngxFvName>
                    Dependancy
                  </label>
                  <div class="input-group mb-3">
                    <textarea type="text" name="cStart" class="form-control" placeholder="Dependancy  " #ngxFvFormControl
                      formControlName="dependency"></textarea>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-12 form-group">
                <div class="input-group mb-3" *ngIf="isShow">
                  <button class="btn custom-btn btn-8" type="submit"><span>Submit Now</span></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      
<!-- Auto task modal -->
  <ng-template #editModal let-modal>
    <div class="modal-header">
      <h3>Automated Task List</h3>
    </div>
    <div class="modal-body">
      <div class="wrap">

          <div class="input-group mb-3">
            <input type="checkbox" [(ngModel)]="masterSelected" id="m1" name="list_name" value="m1" (change)="checkUncheckAll()"/>
            <label class="form-check-label" for="m1">
              {{masterSelected?'Uncheck All':'Check All'}}
            </label>
          </div>

          <div class="input-group mb-3" *ngFor="let task of checklist; index as i;">
            <div class="form-check ">
              <input class="form-check-input" type="checkbox" [(ngModel)]="task.isSelected" name="list_name" value="{{task.t_id}}" id="{{task.t_id}}" (change)="isAllSelected()"/>
              <label class="form-check-label" for={{task.t_id}}>
                {{task.t_name}}
              </label>
            </div>
          </div>

          <h3 *ngIf="automatedTask?.length > 0">Inactive Automated Tasks</h3>
          <div class="input-group mb-3">
            <div class="form-check "  *ngFor="let task1 of automatedTask; index as i;">
              <label class="form-check-label">
                {{i+1}} . {{ task1.t_name}} <button class="custom-btn btn-8 text-center" (click)="activeInsctiveAutoTask(task1.t_id)">Active now ?</button>
              </label>
            </div>
          </div>

      </div>
    </div>
    <div class="modal-footer">
      <button type="button" class="custom-btn btn-8 text-center" (click)="clearTaskSelection()">Cancel</button>
      <button type="button" class="custom-btn btn-8 text-center" (click)="modal.close()" *ngIf="showButton">Save</button>
    </div>
  </ng-template>
<!-- End auto task modal -->

<ngx-ui-loader></ngx-ui-loader>