import { Component, OnInit } from '@angular/core';
import { RestService } from '../../../rest.service';
import { ToastrService } from 'ngx-toastr';
import { NgxUiLoaderService } from 'ngx-ui-loader';

@Component({
  selector: 'app-shift-list',
  templateUrl: './shift-list.component.html',
  styleUrls: ['./shift-list.component.scss'],
})
export class ShiftListComponent implements OnInit {
  public shiftList: any;
  public loadAllComponant = false;
  constructor(
    public rest: RestService,
    public ngxService: NgxUiLoaderService,
    private toaster: ToastrService
  ) {}

  ngOnInit(): void {
    this.getShiftList();
  }
  /* This function is used to fetch shift list */
  getShiftList() {
    this.ngxService.start();
    this.rest.getData({}, 'getshiftlist').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.shiftList = data.data;
          console.log(this.shiftList);
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        this.toaster.error('Something went wrong');
      },
    });
  }
  /* This function is used to delete shift */
  deleteShift(id: number) {
    if (id > 0) {
      this.ngxService.start();
      this.rest.getData({shift_id:id}, 'deleteshift').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.toaster.success(data.message);
            this.getShiftList();
          } else {
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.stop();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }
}
