<div class="main-content" *ngIf="loadAllComponant">
  <div class="quicklink-sidebar-menu ctm-border-radius  bg-white card grow">
    <div class="card-body">
      <div class="cardTitle d-flex justify-content-between align-items-center">
        <h5 class="p-0 m-0">Add Tag </h5>
        <button class="btn btn-primary bg-custom border-0 " routerLink="/"><i
            class="lar la-caret-square-left 48px"></i></button>
      </div>
    </div>
  </div>
  <div class="accordion add-employee" id="accordion-details">
    <div class="card  grow ctm-border-radius">
      <div class="card-header" id="basic1">
        <h4 class="cursor-pointer mb-0">
          <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
            data-target="#basic-one" aria-expanded="true">
            Tag 
          </a>
        </h4>
      </div>
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
          <form>
            <div class="row">
              <div class="col-lg-12">
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="las la-globe"></i>
                  </div>
                  <input type="text" [(ngModel)]="tagData.tagName" name="addTag" class="form-control">
                </div>
              </div>
              <div class="col-md-12 form-group">
                <div class="input-group mb-3">
                    <input type="hidden" [(ngModel)]="tagData.tagId" name="tagid" class="form-control">
                  <button class="btn custom-btn btn-8" (click)="saveTag()" *ngIf="isShow"><span>Submit</span></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
  
    </div>
  </div>
  
  <div class="card  grow ctm-border-radius" *ngIf=" tagData.tags!==undefined ">
    <div class="card-body align-center d-flex justify-content-between">
      <h4 class="card-title  mb-0 mt-2"> <b><i class="las la-info-circle"></i> Tag Available</b></h4>
      <ng-template #title>
        <h4 class="card-title  mb-0 mt-2"> <b><i class="las la-info-circle"></i>No Tag Data Available</b></h4>
      </ng-template>
    </div>
  </div>
  <div class="card">
    <div class="card-body">
      <div class="filter_contetn">
        <!-- <div class="filter_drop_down align-center justify-content-between ">
          <div class="form-outline col-md-6">
            <input type="text" class="form-control" (keyup)="searchType($event)" placeholder="Search" aria-label="Search"
              [(ngModel)]="multiSearch" id="searchdata">
          </div>
          <button class="custom-btn btn-8 text-center" (click)="filter_status_btn()"><span><i class="las la-filter"></i>
              Filters</span></button>
        </div> -->
        <!-- <div class="drop_down_filter_list d-flex justify-content-end" [ngClass]="filter_status?'on':''">
          <div class="input-group mb-3">
            <ng-select [multiple]="false" [selectableGroup]="true" [closeOnSelect]="true" [(ngModel)]="roleSearch"
              (change)="deSelect($event,'roles')" placeholder="Roles">
              <ng-option *ngFor="let roles of roleFilterList" [value]="roles">
                {{roles}}
              </ng-option>
            </ng-select>
          </div>
        </div> -->
      </div>
      <div class="table-back employee-office-table">
        <div class="table-responsive">
          <table class="table custom-table table-hover table-hover" id="empolyeeId">
            <thead>
              <tr>
                <th>Id</th>
                <th>Tag</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let tag of tagData.tags;index as i">
                <td>{{i+1}}</td>
                <td>{{tag.name}}</td>
                <td> <a class="custom-btn btn-8 text-center btn-sm mx-2" *ngIf="isDelShow" (click)="del(tag.id)">
                    <span><i class="las la-trash-alt"></i></span></a>
                    
                    <a class="custom-btn btn-8 text-center btn-sm" (click)="edit(tag)">
                        <span><i class="las la-edit"></i></span></a>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</div>
  <ngx-ui-loader></ngx-ui-loader>