<ng-container *ngIf="currentUrl!='login' else loginDiv">
  <div class="appContainer" [ngClass]="switchToggle ?'on':''">
    <!-- header -->
    <header class="header">
      <div class="top-header-section position-relative d-flex align-items-center">
        <button class="nav-toggle" (click)="navToggle()" [ngClass]="switchToggle ?'on':''">
          <svg [ngClass]="switchToggle ?'d-none':''" xmlns="http://www.w3.org/2000/svg" width="16" height="16"
            fill="currentColor" class="bi bi-list" viewBox="0 0 16 16">
            <path fill-rule="evenodd"
              d="M2.5 12a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5zm0-4a.5.5 0 0 1 .5-.5h10a.5.5 0 0 1 0 1H3a.5.5 0 0 1-.5-.5z" />
          </svg>
          <svg [ngClass]="switchToggle ?'d-block':'d-none'" class="svg-icon"
            style="width: 1em; height: 2em;vertical-align: middle;fill: #ea556f;overflow: hidden;"
            viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M810.65984 170.65984q18.3296 0 30.49472 12.16512t12.16512 30.49472q0 18.00192-12.32896 30.33088l-268.67712 268.32896 268.67712 268.32896q12.32896 12.32896 12.32896 30.33088 0 18.3296-12.16512 30.49472t-30.49472 12.16512q-18.00192 0-30.33088-12.32896l-268.32896-268.67712-268.32896 268.67712q-12.32896 12.32896-30.33088 12.32896-18.3296 0-30.49472-12.16512t-12.16512-30.49472q0-18.00192 12.32896-30.33088l268.67712-268.32896-268.67712-268.32896q-12.32896-12.32896-12.32896-30.33088 0-18.3296 12.16512-30.49472t30.49472-12.16512q18.00192 0 30.33088 12.32896l268.32896 268.67712 268.32896-268.67712q12.32896-12.32896 30.33088-12.32896z" />
          </svg>
        </button>

        <div class="dropdown topbar-head-dropdown ms-1 header-item newBtn">
          <select class="form-select navp" aria-label="Default select example" (change)="routePage($event)">
            <option selected>{{currentUrl == 'task-dashboard'?'Tasks ( Last 7 days )':humanize(currentUrl) | titlecase
              }}</option>
            <option value="task"><a routerLink="/task-dashboard" [ngClass]="currentUrl=='task-dashboard' ?'active':''">
                Workspace
              </a></option>
            <option value="project"><a routerLink="/project-dashboard"
                [ngClass]="currentUrl=='project-dashboard' ?'active':''">
                Project Dashboard
              </a></option>
            <option value="employee"><a routerLink="/employee-dashboard"
                [ngClass]="currentUrl=='employee-dashboard' ?'active':''">
                Employee Dashboard
              </a></option>
          </select>
        </div>

        <div class="container-fluid">
          <div class="row align-items-center">
            <div class="col-lg-3 col-md-3 col-sm-3 col-6">
            </div>
            <div class="col-lg-9 col-md-9 col-sm-9 col-6 text-end">
              <div class="user-block  d-lg-block">
                <div class="row align-items-center">
                  <div class="col-lg-12 col-md-12 col-sm-12">
                    <div class="card_not_new d-flex align-items-center justify-content-end">

                      <div class="user-info align-right dropdown d-inline-block header-dropdown">
                        <a href="javascript:void(0)" data-toggle="dropdown" class=" menu-style dropdown-toggle"
                          (click)="profileToggle()">
                          <div class="user-avatar d-inline-block">
                            <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                              class="bi bi-person-circle" viewBox="0 0 16 16">
                              <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                              <path fill-rule="evenodd"
                                d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                            </svg>
                          </div>
                          <span>{{uname}}</span>
                        </a>
                        <div class="dropdown-menu notification-dropdown-menu shadow-lg border-0 m-0 dropdown-menu-right"
                          [ngClass]="profile_setting?'on':''">
                          <a class="dropdown-item p-2" routerLink="/manage-profile" (click)="profileToggle()">
                            <span class="media align-items-center">
                              <span class="lnr lnr-user mr-3"></span>
                              <span class="media-body text-truncate">
                                <span class="text-truncate">
                                  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor"
                                    class="bi bi-person-circle" viewBox="0 0 16 16">
                                    <path d="M11 6a3 3 0 1 1-6 0 3 3 0 0 1 6 0z" />
                                    <path fill-rule="evenodd"
                                      d="M0 8a8 8 0 1 1 16 0A8 8 0 0 1 0 8zm8-7a7 7 0 0 0-5.468 11.37C3.242 11.226 4.805 10 8 10s4.757 1.225 5.468 2.37A7 7 0 0 0 8 1z" />
                                  </svg>
                                  Profile</span>
                              </span>
                            </span>
                          </a>
                          <a class="dropdown-item p-2" routerLink="/settings" (click)="profileToggle()">
                            <span class="media align-items-center">
                              <span class="lnr lnr-cog mr-3"></span>
                              <span class="media-body text-truncate">
                                <span class="text-truncate"> <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" fill="currentColor" class="bi bi-gear" viewBox="0 0 16 16">
                                    <path
                                      d="M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z" />
                                    <path
                                      d="M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z" />
                                  </svg> Settings</span>
                              </span>
                            </span>
                          </a>
                          <a class="dropdown-item p-2" (click)="userlogout()">
                            <span class="media align-items-center">
                              <span class="lnr lnr-power-switch mr-3"></span>
                              <span class="media-body text-truncate">
                                <span class="text-truncate"> <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                    height="16" fill="currentColor" class="bi bi-box-arrow-left" viewBox="0 0 16 16">
                                    <path fill-rule="evenodd"
                                      d="M6 12.5a.5.5 0 0 0 .5.5h8a.5.5 0 0 0 .5-.5v-9a.5.5 0 0 0-.5-.5h-8a.5.5 0 0 0-.5.5v2a.5.5 0 0 1-1 0v-2A1.5 1.5 0 0 1 6.5 2h8A1.5 1.5 0 0 1 16 3.5v9a1.5 1.5 0 0 1-1.5 1.5h-8A1.5 1.5 0 0 1 5 12.5v-2a.5.5 0 0 1 1 0v2z" />
                                    <path fill-rule="evenodd"
                                      d="M.146 8.354a.5.5 0 0 1 0-.708l3-3a.5.5 0 1 1 .708.708L1.707 7.5H10.5a.5.5 0 0 1 0 1H1.707l2.147 2.146a.5.5 0 0 1-.708.708l-3-3z" />
                                  </svg> Logout</span>
                              </span>
                            </span>
                          </a>
                        </div>
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </header>
    <!--end  header -->

    <div class="page-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-xl-3 col-lg-4 col-md-12 theiaStickySidebar">
            <div class="dbIcon">
              <img src="../assets/logo.png" alt="">
            </div>
            <aside class="sidebar sidebar-user">
              <button class="nav-toggles mobile_close_tab" (click)="navToggle()" [ngClass]="switchToggle ?'on':''">
                <i class="las la-angle-left"></i>
              </button>
              <div class="sidebar-wrapper">
                <div class="card  shadow-none ctm-border-radius  border-none grow p-0">
                  <div class="card-body p-0">
                    <div class="row g-0">
                      <div class="col-12 align-items-center text-center">
                        <a routerLink="/{{dashboardLink}}" class="first-slider-btn  ctm-border-top"
                          [ngClass]="currentUrl=='my-dashbroad'?'active':''">
                          <i class="las la-home"></i>
                          <span class="">Dashboard</span>
                          <span class="shape1"></span>
                          <span class="shape2"></span>
                        </a>
                      </div>
                      <div class="col-12 align-items-center shadow-none text-center">
                        <a routerLink="/task-dashboard"
                          class="text-dark  second-slider-btn ctm-border-right ctm-border-top"
                          [ngClass]="currentUrl=='task-dashboard'?'active':''">
                          <i class="las la-chart-pie"></i>
                          <span class="">Workspace</span>
                        </a>
                      </div>

                      <div class="col-12 align-items-center shadow-none text-center">
                        <a routerLink="/work-reports"
                          class="text-dark  second-slider-btn ctm-border-right ctm-border-top"
                          [ngClass]="currentUrl=='work-reports'?'active':''">
                          <i class="las la-calendar-week"></i>
                          <span class="">Timesheets</span>
                        </a>
                      </div>

                      <!-- dsdsd -->
                      <ng-container
                        *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager') || userRoles.includes('Team Lead'))">
                        <div class="col-12 align-items-center shadow-none text-center submenu-group">
                          <a class="text-dark"
                            [ngClass]="currentUrl=='add-employee' || currentUrl=='employee' ?'active':''"
                            (click)="dropDownMenu('employee')">
                            <i class="las la-users-cog"></i>
                            <span class="">Employees </span>
                            <span class="shape1"></span>
                            <span class="shape2"></span>
                          </a>
                          <ul class="dropdown dropDownMenu list-style-none"
                            [ngClass]="dropDownMenuStatus=='employee' ||currentUrl=='add-employee' || currentUrl=='employee' ?'on':''">
                            <li><a routerLink="/add-employee" [ngClass]=" currentUrl=='add-employee'?'active':''"
                                class="text-dark  ctm-border-left">
                                <i class="las la-dot-circle"></i>
                                <span class="">Add Employee</span></a>
                            </li>
                            <li><a routerLink="/employee" class="text-dark  ctm-border-left"
                                [ngClass]=" currentUrl=='employee'?'active':''">
                                <i class="las la-dot-circle"></i>
                                <span class=""> Employee List </span></a>
                            </li>
                          </ul>
                        </div>
                      </ng-container>
                      <!-- dsdsd -->

                      <ng-container
                        *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager') || userRoles.includes('Team Lead'))">
                        <div class="col-12 align-items-center shadow-none text-center submenu-group">
                          <a class="text-dark"
                            [ngClass]="currentUrl=='create-project' || currentUrl=='project' ?'active':''"
                            (click)="dropDownMenu('project')">
                            <i class="lab la-git"></i>
                            <span class="">Projects </span>
                            <span class="shape1"></span>
                            <span class="shape2"></span>
                          </a>
                          <ul class="dropdown dropDownMenu list-style-none"
                            [ngClass]="dropDownMenuStatus=='project' ||currentUrl=='create-project' || currentUrl=='project' ?'on':''">
                            <li><a routerLink="/create-project" [ngClass]=" currentUrl=='create-project'?'active':''"
                                class="text-dark  ctm-border-left">
                                <i class="las la-dot-circle"></i>
                                <span class="">Create Project</span></a>
                            </li>
                            <li><a routerLink="/project" class="text-dark  ctm-border-left"
                                [ngClass]=" currentUrl=='project'?'active':''">
                                <i class="las la-dot-circle"></i>
                                <span class=""> Project List </span></a>
                            </li>
                          </ul>
                        </div>
                      </ng-container>
                      <div class="col-12 align-items-center shadow-none text-center submenu-group">
                        <a class="text-dark "
                          [ngClass]="currentUrl=='create-module' || currentUrl=='module' ?'active':''"
                          (click)="dropDownMenu('module')">
                          <i class="las la-recycle"></i>
                          <span class="">Modules </span>
                          <span class="shape1"></span>
                          <span class="shape2"></span>
                        </a>
                        <ul class="dropdown dropDownMenu list-style-none"
                          [ngClass]="dropDownMenuStatus=='module' ||currentUrl=='create-module' || currentUrl=='module' ?'on':''">
                          <ng-container
                            *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager') || userRoles.includes('Team Lead'))">
                            <li>
                              <a routerLink="/create-module" [ngClass]=" currentUrl=='create-module'?'active':''"
                                class="text-dark  ctm-border-left">
                                <i class="las la-dot-circle"></i>
                                <span class="">Create Module</span>
                              </a>
                            </li>
                          </ng-container>
                          <li><a routerLink="/module" class="text-dark  ctm-border-left"
                              [ngClass]=" currentUrl=='module'?'active':''">
                              <i class="las la-dot-circle"></i>
                              <span class=""> Module List </span></a>
                          </li>
                        </ul>
                      </div>

                      <div class="col-12 align-items-center shadow-none text-center submenu-group">
                        <a class="text-dark "
                          [ngClass]="currentUrl=='create-task' || currentUrl=='task' ||  currentUrl=='recurring-task' ?'active':''"
                          (click)="dropDownMenu('task')">
                          <i class="las la-list-alt"></i>
                          <span class="">Tasks </span>
                          <span class="shape1"></span>
                          <span class="shape2"></span>
                        </a>
                        <ul class="dropdown dropDownMenu list-style-none"
                          [ngClass]="dropDownMenuStatus=='task' ||currentUrl=='create-task' || currentUrl=='task' ?'on':''">
                          <li><a routerLink="/create-task" [ngClass]=" currentUrl=='create-task'?'active':''"
                              class="text-dark  ctm-border-left">
                              <i class="las la-dot-circle"></i>
                              <span class="">Create Task</span></a>
                          </li>
                          <li><a routerLink="/task" class="text-dark  ctm-border-left"
                              [ngClass]=" currentUrl=='task'  ?'active':''">
                              <i class="las la-dot-circle"></i>
                              <span class=""
                                *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager') || userRoles.includes('Team Lead')); else member">
                                Task List</span></a>
                            <ng-template #member>
                              <span>My Tasks</span>
                            </ng-template>
                          </li>
                          <li><a routerLink="/recurring-task" class="text-dark  ctm-border-left"
                              [ngClass]=" currentUrl=='recurring-task'?'active':''">
                              <i class="las la-dot-circle"></i>
                              <span class=""> Recurring Tasks</span></a>
                          </li>
                          <ng-container
                            *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager') || userRoles.includes('Team Lead'))">
                            <li><a routerLink="/automated-task" [ngClass]=" currentUrl=='automated-task'?'active':''"
                                class="text-dark  ctm-border-left">
                                <i class="las la-dot-circle"></i>
                                <span class="">Automated Tasks</span></a>
                            </li>
                          </ng-container>
                        </ul>
                      </div>

                      <ng-container
                        *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager')); else attendMember;">
                        <div class="col-12 align-items-center shadow-none text-center submenu-group">
                          <a class="text-dark "
                            [ngClass]="currentUrl=='attendance' || currentUrl=='add-attendance' || currentUrl=='edit-attendance' || currentUrl=='view-attendance' ?'active':''"
                            (click)="dropDownMenu('Attendent')">
                            <i class="las la-user-check"></i>
                            <span class="">Attendences </span>
                            <span class="shape1"></span>
                            <span class="shape2"></span>
                          </a>
                          <ul class="dropdown dropDownMenu list-style-none"
                            [ngClass]="dropDownMenuStatus=='Attendent' ||currentUrl=='add-attendance' || currentUrl=='attendance' || currentUrl=='edit-attendance' || currentUrl=='view-attendance' ?'on':''">
                            <li><a routerLink="attendance" class="text-dark  ctm-border-left"
                                [ngClass]=" currentUrl=='attendance'?'active':''">
                                <i class="las la-dot-circle"></i>
                                <span class="">Attendance </span></a>
                            </li>
                            <li><a routerLink="/add-attendance" [ngClass]=" currentUrl=='add-attendance'?'active':''"
                                class="text-dark  ctm-border-left">
                                <i class="las la-dot-circle"></i>
                                <span class="">Add Attendance </span></a>
                            </li>
                            <li><a routerLink="/edit-attendance" [ngClass]=" currentUrl=='edit-attendance'?'active':''"
                                class="text-dark  ctm-border-left">
                                <i class="las la-dot-circle"></i>
                                <span class="">Edit Attendance</span></a>
                            </li>
                            <li><a routerLink="/view-attendance" [ngClass]=" currentUrl=='view-attendance'?'active':''"
                                class="text-dark  ctm-border-left">
                                <i class="las la-dot-circle"></i>

                                <span class="">Attendance List</span></a>
                            </li>
                            <li><a routerLink="/upload-attendance"
                                [ngClass]=" currentUrl=='upload-attendance'?'active':''"
                                class="text-dark  ctm-border-left">
                                <i class="las la-dot-circle"></i>

                                <span class="">Upload Attendance</span></a>
                            </li>
                            <li><a routerLink="/attendance-report"
                                [ngClass]=" currentUrl=='attendance-report'?'active':''"
                                class="text-dark  ctm-border-left">
                                <i class="las la-dot-circle"></i>
                                <span class="">Attendance Report</span></a>
                            </li>
                          </ul>
                        </div>
                      </ng-container>

                      <ng-template #attendMember>
                        <div class="col-12 align-items-center shadow-none text-center">
                          <a routerLink="/attendance"
                            class="text-dark  second-slider-btn ctm-border-right ctm-border-top"
                            [ngClass]="currentUrl=='attendance'?'active':''">
                            <i class="las la-user-check"></i>
                            <span class="shape1"></span>
                            <span class="shape2"></span>
                            <span class="">Attendance</span></a>
                        </div>
                      </ng-template>

                      <ng-container
                        *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager'))">
                        <div class="col-12 align-items-center shadow-none text-center">
                          <a routerLink="/shift-list" class="text-dark  ctm-border-left"
                            [ngClass]="currentUrl=='shift-list'?'active':''">
                            <i class="las la-calendar-alt"></i>
                            <span class="shape1"></span>
                            <span class="shape2"></span>
                            <span class="">Shifts</span></a>
                        </div>
                      </ng-container>

                      <ng-container
                        *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager'))">
                        <div class="col-12 align-items-center shadow-none text-center submenu-group">
                          <a class="text-dark"
                            [ngClass]="currentUrl=='roster-shifts' || currentUrl=='settings/url-permission' ?'active':''"
                            (click)="dropDownMenu('roster')">
                            <i class="las la-calendar-alt"></i>
                            <span class="">Roster</span>
                            <span class="shape1"></span>
                            <span class="shape2"></span>
                          </a>
                          <ul class="dropdown dropDownMenu list-style-none"
                            [ngClass]="dropDownMenuStatus=='roster' ||currentUrl=='roster-shifts' || currentUrl=='settings/url-permission' ?'on':''">
                            <li>
                              <a routerLink="/roster-shifts" [ngClass]=" currentUrl=='roster-shifts'?'active':''"
                                class="text-dark  ctm-border-left">
                                <i class="las la-dot-circle"></i>
                                <span class="">Create Roster Shift</span></a>
                            </li>
                            <li>
                              <a routerLink="/roster-list" class="text-dark  ctm-border-left"
                                [ngClass]=" currentUrl=='roster-list'?'active':''">
                                <i class="las la-dot-circle"></i>
                                <span class=""> Roster Shift List </span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </ng-container>

                      <div class="col-12 align-items-center shadow-none text-center submenu-group">
                        <a class="text-dark " (click)="dropDownMenu('leave')"
                          [ngClass]="(currentUrl=='leave/leave-requests' || currentUrl=='leave/leave-list' || currentUrl=='leave/add-leave')?'active':''">
                          <i class="las la-user-injured"></i>
                          <span class="shape1"></span>
                          <span class="shape2"></span>
                          <span class="">Leave Applications</span></a>
                        <ul class="dropdown dropDownMenu list-style-none"
                          [ngClass]="(dropDownMenuStatus=='leave' || currentUrl=='leave/leave-requests'||currentUrl=='leave/leave-list' || currentUrl=='leave/add-leave')?'on':''">
                          <li><a routerLink="/leave/add-leave" class="text-dark  ctm-border-left"
                              [ngClass]="currentUrl=='leave/add-leave'?'active':''">
                              <i class="las la-dot-circle"></i>
                              <span class="">Apply For Leave</span></a>
                          </li>
                          <li><a routerLink="/leave/leave-list" class="text-dark  ctm-border-left"
                              [ngClass]="currentUrl=='leave/leave-list'?'active':''">
                              <i class="las la-dot-circle"></i>
                              <span class="">My Leave Lists</span>
                            </a>
                          </li>
                          <ng-container
                            *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager'))">
                            <li><a routerLink="/leave/leave-requests" class="text-dark  ctm-border-left"
                                [ngClass]="currentUrl=='leave/leave-requests'?'active':''">
                                <i class="las la-dot-circle"></i>
                                <span class="">All Leave Requests</span>
                              </a>
                            </li>
                          </ng-container>
                        </ul>
                      </div>
                      <ng-container
                        *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager'));">

                        <div class="col-12 align-items-center text-center">
                          <a routerLink="/announcements" class="first-slider-btn  ctm-border-top"
                            [ngClass]="currentUrl=='/announcements'?'active':''">
                            <i class="las la-bullhorn"></i>
                            <span class="">Announcements</span>
                          </a>
                        </div>

                      </ng-container>

                      <ng-container
                        *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager'));">

                        <div class="col-12 align-items-center text-center">
                          <a routerLink="/reports" class="first-slider-btn  ctm-border-top"
                            [ngClass]="currentUrl=='/reports'?'active':''">
                            <i class="las la-file"></i>
                            <span class="">Send Report</span>
                          </a>
                        </div>

                      </ng-container>

                      <div class="col-12 align-items-center shadow-none text-center d-none">
                        <a routerLink="reports.html" class="text-dark">
                          <i class="las la-bug"></i>
                          <span class="">Reports</span>
                          <span class="shape1"></span>
                          <span class="shape2"></span>
                        </a>
                      </div>
                      <div class="col-12 align-items-center shadow-none text-center d-none">
                        <a routerLink="manage.html" class="text-dark  ctm-border-right">
                          <i class="lab la-creative-commons-by"></i>
                          <span class="">Manage</span>
                          <span class="shape1"></span>
                          <span class="shape2"></span>
                        </a>
                      </div>
                      <div class="col-12 align-items-center shadow-none text-center">
                        <a routerLink="/holiday-list" class="text-dark  ctm-border-right"
                          [ngClass]="currentUrl=='holiday-list'?'active':''">
                          <i class="lab la-creative-commons-by"></i>
                          <span class="">Holiday List</span>
                          <span class="shape1"></span>
                          <span class="shape2"></span>
                        </a>
                      </div>
                      <div class="col-12 align-items-center shadow-none text-center">
                        <a [routerLink]="['/manage-profile']" class="text-dark  last-slider-btn ctm-border-right"
                          [ngClass]="currentUrl=='manage-profile' ?'active':''"><i class="las la-user-edit"></i><span
                            class="">Profile</span>
                          <span class="shape1"></span>
                          <span class="shape2"></span>
                        </a>
                      </div>
                      <ng-container
                        *ngIf="userRoles!== undefined && (userRoles.includes('Admin') || userRoles.includes('Manager'))">
                        <div class="col-12 align-items-center shadow-none text-center submenu-group">
                          <a class="text-dark"
                            [ngClass]="currentUrl=='settings' || currentUrl=='settings/add-api' || currentUrl=='settings/add-url' || currentUrl=='settings/api-permission' || currentUrl=='settings/url-permission' ?'active':''"
                            (click)="dropDownMenu('settings')">
                            <i class="las la-sliders-h"></i>
                            <span class="">Settings </span>
                            <span class="shape1"></span>
                            <span class="shape2"></span>
                          </a>
                          <ul class="dropdown dropDownMenu list-style-none"
                            [ngClass]="dropDownMenuStatus=='settings' ||currentUrl=='settings/add-api' || currentUrl=='settings/add-url' || currentUrl=='settings/api-permission' || currentUrl=='settings/url-permission' ?'on':''">
                            <li>
                              <a routerLink="/settings/add-api" [ngClass]=" currentUrl=='settings/add-api'?'active':''"
                                class="text-dark  ctm-border-left">
                                <i class="las la-dot-circle"></i>
                                <span class="">Add Api</span></a>
                            </li>
                            <li>
                              <a routerLink="/settings/add-url" class="text-dark  ctm-border-left"
                                [ngClass]=" currentUrl=='settings/add-url'?'active':''">
                                <i class="las la-dot-circle"></i>
                                <span class=""> Add Url </span>
                              </a>
                            </li>
                            <li>
                              <a routerLink="/settings/api-permission" class="text-dark  ctm-border-left"
                                [ngClass]=" currentUrl=='settings/api-permission'?'active':''">
                                <i class="las la-dot-circle"></i>
                                <span class=""> Api Permission </span>
                              </a>
                            </li>
                            <li>
                              <a routerLink="/settings/url-permission" class="text-dark  ctm-border-left"
                                [ngClass]=" currentUrl=='settings/url-permission'?'active':''">
                                <i class="las la-dot-circle"></i>
                                <span class=""> Url Permission</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </ng-container>
                    </div>
                  </div>
                </div>
              </div>
              <div class="footersidebar">
                <a routerLink="javascript:void(0)" (click)="userlogout()"
                  class="text-dark  last-slider-btn ctm-border-right"><i class="las la-sign-out-alt"></i><span
                    class="">Logout</span>
                  <span class="shape1"></span>
                  <span class="shape2"></span>
                </a>
              </div>
            </aside>
          </div>
          <div class="col-xl-12 col-lg-12  col-md-12">
            <router-outlet></router-outlet>
            <div class="common-alert-div-wrapper" *ngIf="alerttext != ''">
              <ngb-alert [type]="alerttype">{{alerttext}}</ngb-alert>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <app-loader-one *ngIf="showLoader"></app-loader-one>
</ng-container>
<ng-template #loginDiv>
  <router-outlet></router-outlet>
</ng-template>

<!--floating quick toggle shortcuts-->
<div class="floatingButtonWrap" *ngIf="currentUrl != 'login'">
  <div class="floatingButtonInner">
    <a href="javascript:void(0)" (click)="fabOpener()" class="floatingButton" [ngClass]="fabOpenerTab?'open':''">
      <i class="las la-plus icon-default"></i>
    </a>
    <ul class="floatingMenu quickListItems" [ngClass]="fabOpenerTab?'open':''">
      <li>
        <a href="javascript:void(0)" (click)="quickAdd('task')">Create Task</a>
      </li>
      <li *ngIf="userRoles.includes('Admin') || userRoles.includes('Manager')">
        <a href="javascript:void(0)" (click)="quickAdd('module')">Create Module</a>
      </li>
      <li *ngIf="userRoles.includes('Admin') || userRoles.includes('Manager')">
        <a href="javascript:void(0)" (click)="quickAdd('project')">Create Project</a>
      </li>
      <li>
        <a routerLink="/time-sheet" (click)="dismissPanel()">Timesheet</a>
      </li>
    </ul>
  </div>
</div>

<div class="card timesheet appQuickPanel scrollcls" [ngClass]="sidePanel?'on':''" *ngIf="currentUrl != 'login'">
  <div class="card-body">
    <div class="modal-header quick-task-header">
      <div class="pos_header">
        <h2 class="text-lg icont">{{quickPanelTitle}}</h2>
        <a href="javascript:void(0)" (click)="dismissPanel()" class="close"></a>
      </div>
    </div>

    <!-- Add task form -->
    <div class="table-responsive sidebar_tab" *ngIf="addTask">
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
          <form [formGroup]="taskForm" (ngSubmit)="taskForm.valid && addUpdateTask(taskForm)" #fd="ngForm"
            [ngxFvControl]="fd">
            <div class="row">
              <div class="col-lg-6 form-group">
                <label>
                  1. Choose Project
                </label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="las la-user-secret"></i>
                  </div>
                  <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                    (change)="loadDataModule($event)">
                    <option>Select project first</option>
                    <ng-container *ngFor="let proj of projectList; index as i">
                      <option value="{{proj.id}}" [selected]="proj.id == cloneProjectId">{{i+1}}. {{proj.p_name|
                        titlecase}} /{{proj.p_id}}
                      </option>
                    </ng-container>
                  </select>
                </div>
              </div>

              <div class="col-lg-6 form-group">
                <label #ngxFvName>
                  2. Module
                </label>
                <div class="input-group mb-3">
                  <div class="input-group-text">
                    <i class="las la-user-secret"></i>
                  </div>
                  <ng-select class="w-100" (change)="loadDataInfomation($event)" #ngxFvFormControl
                    formControlName="module_id" [clearable]="items?.length > 0?true:false">
                    <option value="Select Module">Select Module</option>
                    <ng-option value="{{item.id}}" *ngFor="let item of items; index as i">
                      {{i+1}}. {{item.m_name| titlecase}} / {{item.m_id}}
                    </ng-option>
                  </ng-select>
                  <span class="text-danger" *ngIf="!taskForm.controls['module_id'].valid && 
                    taskForm.controls['module_id']?.touched"> 'Module' field is required</span>
                </div>
              </div>

              <!-- existing task -->
              <div class="col-lg-12 form-group" *ngIf="isDisable">
                <ngx-fv-validate [control]="taskForm.controls['tasks']">
                  <label #ngxFvName>
                    3. Re-open Previous Task (Optional)
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-random"></i>
                    </div>
                    <select (change)="pickTask()" class="form-control select select2-hidden-accessible" tabindex="-1"
                      aria-hidden="true" ngxFvFormControl formControlName="tasks" [(ngModel)]="selectedTaskId">
                      <option value="new_task">--------------------------------------------- New
                        ---------------------------------------------</option>
                      <ng-container *ngFor="let task of existTaskList; index as i">
                        <option [ngValue]="task.t_id" [value]="task.t_id" [disabled]="task.t_status == 'active'">
                          {{i+1}}. {{task.t_name| titlecase}} <strong>({{task.t_status| titlecase}})</strong>
                          {{task.t_status == 'active'?'**Not Selectable**':''}}</option>
                      </ng-container>
                    </select>

                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>

              <div class="task_extra_information ">
                <div class="row">
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['task_name']">
                      <label #ngxFvName>
                        4. Task Name
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="lab la-git-alt"></i>
                        </div>
                        <input type="text" class="form-control" id="task_name" #ngxFvFormControl
                          formControlName="task_name" [readonly]="readOnlyTaskForm" placeholder="Enter task name..">
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['task_type']">
                      <label #ngxFvName>
                        5. Type
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-user-secret"></i>
                        </div>
                        <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                          ngxFvFormControl formControlName="task_type">
                          <ng-container *ngFor="let task of taskTypeList">
                            <option value="{{task.type_name}}">{{task.type_name | titlecase}}</option>
                          </ng-container>
                          <!-- <option><i class="las la-plus-circle"></i> Add New</option> -->
                        </select>
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['task_estimated_pd']">
                      <label #ngxFvName>
                        6. Estimated Time (hour)
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-business-time"></i>
                        </div>
                        <input type="number" [min]="minTaskTime" [max]="maxTaskTime" class="form-control"
                          id="task_estimated_pd" #ngxFvFormControl formControlName="task_estimated_pd" step="1"
                          placeholder="Ex: 4">
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                  <div class="col-lg-6 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['task_status']">
                      <label #ngxFvName>
                        7. Status
                      </label>
                      <div class="input-group mb-3">
                        <div class="input-group-text"><i class="las la-user-secret"></i>
                        </div>
                        <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                          #ngxFvFormControl formControlName="task_status" (change)="removeUnassign()">
                          <option value="active"> Active</option>
                          <option value="inactive">Inactive</option>
                          <option value="stop"> Stop</option>
                          <option value="finish">Finish</option>
                          <option value="unassigned" *ngIf="t_id == ''">Unassigned</option>
                        </select>
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>

                  <div class="col-lg-12 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['task_description']">
                      <label #ngxFvName>
                        8. Describe Task
                      </label>
                      <div class="input-group mb-3">
                        <textarea class="form-control" minlength="30" maxlength="500" #ngxFvFormControl
                          formControlName="task_description" [readonly]="readOnlyTaskForm"
                          placeholder="Describe your task in detail..."> </textarea>
                      </div>
                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>

                  <div class="col-lg-12 form-group">
                    <ngx-fv-validate [control]="taskForm.controls['users']">
                      <label #ngxFvName>
                        9. Assign Member
                      </label>
                      <div class="form-control input-group-text"><i class="las la-user-secret"></i>
                      </div>
                      <ng-select formControlName="users" [multiple]="true" [readonly]="readOnlyTaskForm"
                        (click)="identifyUser()">
                        <ng-option *ngFor="let user of userList" [value]="user.id">{{user.name ===
                          uname?'Myself':user.name}}</ng-option>
                      </ng-select>

                      <ngx-fv-error></ngx-fv-error>
                    </ngx-fv-validate>
                  </div>
                </div>
                <br>

                <div class="col-lg-12 form-group">
                  <div class="input-group mb-3" *ngIf="isShow">
                    <button class="btn custom-btn btn-8" [disabled]="taskForm.invalid"><span>{{t_id ==
                        ''?"Create":"Re-open"}}</span></button>
                  </div>
                </div>

              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- End add task form -->

    <!-- Add module form -->
    <div class="table-responsive sidebar_tab" *ngIf="addModule">
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
          <form [formGroup]="moduleForm" (ngSubmit)="moduleForm.valid && onModuleSubmit(moduleForm)" #fd="ngForm"
            [ngxFvControl]="fd">
            <div class="row">
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['project_id']">
                  <label #ngxFvName>
                    Choose Project
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-rocket"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      #ngxFvFormControl formControlName="project_id">
                      <option value="">Select </option>
                      <ng-container *ngFor="let proj of projectList">
                        <option value="{{proj.id}}">{{proj.p_name}} /{{proj.p_id}}</option>
                      </ng-container>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_name']">
                  <label #ngxFvName>
                    Module Name
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="lab la-git-alt"></i>
                    </div>
                    <input type="text" class="form-control" id="username" placeholder="Module Name" #ngxFvFormControl
                      formControlName="m_name">
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-12 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_description']">
                  <label #ngxFvName>
                    Module Description
                  </label>
                  <div class="input-group mb-3">
                    <textarea type="text" name="cStart" class="form-control" id="username"
                      placeholder="Module Description " ngxFvFormControl formControlName="m_description"></textarea>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_status']">
                  <label #ngxFvName>
                    Module Status
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-asterisk"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      ngxFvFormControl formControlName="m_status">
                      <option value="">Status </option>
                      <option value="Inpogress">Inpogress</option>
                      <option value="Planned">Planned</option>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_estimated_end_date']">
                  <label #ngxFvName>
                    Est End Date
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-calendar-alt"></i>
                    </div>
                    <input type="text" class="form-control" readonly ngbDatepicker #endStart="ngbDatepicker"
                      id="username" placeholder="YYYY-MM-DD" (click)="endStart.toggle()" ngxFvFormControl
                      formControlName="m_estimated_end_date" (dateSelect)="onDateSelect($event)" [minDate]="minDate">
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['risk']">
                  <label #ngxFvName>
                    Risk
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-asterisk"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      ngxFvFormControl formControlName="risk">
                      <option value="">Risk </option>
                      <option value="Low">Low </option>
                      <option value="High">High </option>
                      <option value="Modarate">Modarate </option>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_type']">
                  <label #ngxFvName>
                    Module Type
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-random"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      ngxFvFormControl formControlName="m_type" (change)="getAutoTasks($event)">
                      <option value="">Select Type</option>
                      <ng-container *ngFor="let mod of ModuleTypeList">
                        <option value="{{mod.type_name}}">{{mod.type_name}}</option>
                      </ng-container>
                      <!-- <option><i class="las la-plus-circle"></i> Add New</option> -->
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['m_estimated_effort']">
                  <label #ngxFvName>
                    Estimeted effort <small>(1 PD = 8 Hours or 1 working day)</small>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="lab la-git-alt"></i>
                    </div>
                    <input type="text" class="form-control" id="username" placeholder=" Estimeted effort"
                      #ngxFvFormControl formControlName="m_estimated_effort" />
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <div class="input-group mb-3">
                </div>
              </div>
              <div class="col-lg-12 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['challenges']">
                  <label #ngxFvName>
                    Challenge
                  </label>
                  <div class="input-group mb-3">
                    <textarea type="text" name="cStart" class="form-control" placeholder="Challenge " #ngxFvFormControl
                      formControlName="challenges"></textarea>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-12 form-group">
                <ngx-fv-validate [control]="moduleForm.controls['challenges']">
                  <label #ngxFvName>
                    Dependancy
                  </label>
                  <div class="input-group mb-3">
                    <textarea type="text" name="cStart" class="form-control" placeholder="Dependancy  "
                      #ngxFvFormControl formControlName="dependency"></textarea>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-12 form-group">
                <div class="input-group mb-3" *ngIf="isShow">
                  <button class="btn custom-btn btn-8" [disabled]="moduleForm.invalid"
                    type="submit"><span>Create</span></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>

      <!-- Auto task modal -->
      <ng-template #editModal let-modal>
        <div class="modal-header">
          <h3>Automated Task List</h3>
        </div>
        <div class="modal-body">
          <div class="wrap">

            <div class="input-group mb-3">
              <input type="checkbox" [(ngModel)]="masterSelected" id="m1" name="list_name" value="m1"
                (change)="checkUncheckAll()" />
              <label class="form-check-label" for="m1">
                {{masterSelected?'Uncheck All':'Check All'}}
              </label>
            </div>

            <div class="input-group mb-3" *ngFor="let task of checklist; index as i;">
              <div class="form-check ">
                <input class="form-check-input" type="checkbox" [(ngModel)]="task.isSelected" name="list_name"
                  value="{{task.t_id}}" id="{{task.t_id}}" (change)="isAllSelected()" />
                <label class="form-check-label" for={{task.t_id}}>
                  {{task.t_name}}
                </label>
              </div>
            </div>

            <h3 *ngIf="automatedTask?.length > 0">Inactive Automated Tasks</h3>
            <div class="input-group mb-3">
              <div class="form-check " *ngFor="let task1 of automatedTask; index as i;">
                <label class="form-check-label">
                  {{i+1}} . {{ task1.t_name}} <button class="btn btn-outline-success"
                    (click)="activeInsctiveAutoTask(task1.t_id)">Active now ?</button>
                </label>
              </div>
            </div>

          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-outline-dark" (click)="clearTaskSelection()">Cancel</button>
          <button type="button" class="btn btn-outline-dark" (click)="modal.close()" *ngIf="showButton">Save</button>
        </div>
      </ng-template>
      <!-- End auto task modal -->
    </div>
    <!-- End add module form -->

    <!-- Add project form -->
    <div class="table-responsive sidebar_tab" *ngIf="addProject">
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
          <form [formGroup]="projectForm" (ngSubmit)="projectForm.valid && onProjectSubmit(projectForm)" #fd="ngForm"
            [ngxFvControl]="fd">
            <div class="row">
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['pname']">
                  <label #ngxFvName>
                    <p class="mb-2">Project Name</p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="lab la-git-alt"></i>
                    </div>
                    <input type="text" class="form-control" id="pname" placeholder="Project Name" #ngxFvFormControl
                      formControlName="pname">
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['pdomain']">
                  <label #ngxFvName>
                    <p class="mb-2">Project Domain</p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-random"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      #ngxFvFormControl formControlName="pdomain">
                      <option value="">Select Domain </option>
                      <option value="Development">Development</option>
                      <option value="UX">UX</option>
                      <option value="Server">Server</option>
                      <option value="Sales">Sales</option>
                      <option value="Support">Back Office</option>
                      <option value="Official">Official</option>
                      <option value="DigitalMarketing">Digital Marketing</option>
                      <option value="Study">Study</option>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['ptype']">
                  <label #ngxFvName>
                    <p class="mb-2">Project Type</p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-layer-group"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      #ngxFvFormControl formControlName="ptype">
                      <option value="">Project Type </option>
                      <option value="SM">Support & Maintainence </option>
                      <option value="FD">Feature Development</option>
                      <option value="RD">R & D</option>
                      <option value="WF">Website & Facebook</option>
                      <option value="NB">Non Billable</option>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['pstage']">
                  <label #ngxFvName>
                    <p class="mb-2">Project Stage</p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-user-secret"></i>
                    </div>
                    <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                      #ngxFvFormControl formControlName="pstage">
                      <option selected="Planning">Planning</option>
                      <option selected="Designing">Designing</option>
                      <option selected="Execution">Execution</option>
                    </select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-12 form-group">
                <ngx-fv-validate [control]="projectForm.controls['pdescription']">
                  <label #ngxFvName>
                    <p class="mb-2">Project Description</p>
                  </label>
                  <div class="input-group mb-3">
                    <textarea class="form-control" placeholder="" #ngxFvFormControl
                      formControlName="pdescription"> </textarea>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['p_start_date']">
                  <label #ngxFvName>
                    <p class="mb-2">Project Start Date</p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-calendar"></i>
                    </div>
                    <input type="text" readonly ngbDatepicker #dpStart="ngbDatepicker" name="dpStart"
                      (click)="dpStart.toggle()" class="form-control" id="fff" placeholder="YY-MM-DD" #ngxFvFormControl
                      formControlName="p_start_date" (dateSelect)="onStartDateSelect($event)" [minDate]="minDate">
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['p_end_date']">
                  <label #ngxFvName>
                    <p class="mb-2">Project End Date</p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-calendar"></i>
                    </div>
                    <input type="text" readonly ngbDatepicker #dpEnd="ngbDatepicker" name="dpEnd"
                      (click)="dpEnd.toggle()" class="form-control" id="udfdsername" placeholder="YY-MM-DD"
                      #ngxFvFormControl formControlName="p_end_date" (dateSelect)="onEndDateSelect($event)"
                      [minDate]="minDate">
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['pestimetedenddate']">
                  <label #ngxFvName>
                    <p class="mb-2">Project Estimate End Date</p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-calendar"></i>
                    </div>
                    <input type="text" readonly ngbDatepicker #dpEsStart="ngbDatepicker" name="dpEsStart"
                      (click)="dpEsStart.toggle()" class="form-control" id="username" placeholder="YY-MM-DD"
                      #ngxFvFormControl formControlName="pestimetedenddate" (dateSelect)="onEstDateSelect($event)"
                      [minDate]="minDate">
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-6 form-group">
                <ngx-fv-validate [control]="projectForm.controls['pestimetedeffort']">
                  <label #ngxFvName>
                    <p class="mb-2">Project Effort <small>(1 PD = 8 Hours or 1 working day)</small></p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-business-time"></i>
                    </div>
                    <input type="Number" class="form-control" id="username" placeholder="Est End Duration in PD"
                      #ngxFvFormControl formControlName="pestimetedeffort">
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-12 form-group">
                <ngx-fv-validate [control]="projectForm.controls['users']">
                  <label #ngxFvName>
                    <p class="mb-2">Project Member</p>
                  </label>
                  <div class="input-group mb-3">
                    <div class="input-group-text"><i class="las la-user-secret"></i>
                    </div>
                    <ng-select formControlName="users" [multiple]="true" [selectableGroup]="true"
                      [closeOnSelect]="false">
                      <ng-option *ngFor="let usr of userlist" [value]="usr.u_id">{{usr.name}}</ng-option>
                    </ng-select>
                  </div>
                  <ngx-fv-error></ngx-fv-error>
                </ngx-fv-validate>
              </div>
              <div class="col-lg-12 form-group">
                <div class="input-group mb-3" *ngIf="isShow">
                  <button class="btn custom-btn btn-8" type="submit"
                    [disabled]="projectForm.invalid"><span>Create</span></button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
    <!-- End add project form -->

    <!-- Add notes form-->
    <div class="table-responsive sidebar_tab" *ngIf="myClipBoard">
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">
          <div class="sticky-note">
            <textarea>This is a sticky note you can type and edit.</textarea>

          </div>

          <div id="create">+</div>
        </div>
      </div>
      <!-- End reminder form -->
    </div>
  </div>
</div>