import { Component, OnInit } from '@angular/core';
import { RestService } from '../../rest.service';
import {LoaderService} from "../../loader.service";
import { NgxUiLoaderService } from "ngx-ui-loader"; // Import NgxUiLoaderService
import { TimelineItem } from 'ngx-vertical-timeline';


@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})

export class DashboardComponent implements OnInit {

  public myTask=<any>[];
  items: TimelineItem[] = [];
  externalVariable = 'hello';
  constructor( public rest:RestService,public loader:LoaderService,public ngxService: NgxUiLoaderService) { }
  ngOnInit(): void {
    this. getMyTask();
    const self = this;
    this.items.push({
      label: 'Amit',
      icon: '',
      styleClass: 'teste',
      content: `Project Management Design`,
      title: '10:00 - 11:00',
      command() {
        alert(`test: ${self.externalVariable}`);
      }
    });
 
    this.items.push({
      label: 'Mir',
      icon: '',
      styleClass: 'teste',
      content: `Responsive Design Fixed`,
      title: '10:00 - 11:00',
      command() {
        alert('Action!');
      }
    });
    this.items.push({
      label: 'debanku',
      icon: '',
      styleClass: 'teste',
      content: `backend  bugs  fixed`,
      title: '10:00 - 12:00',
      command() {
        alert('Action!');
      }
    });
  }
  /* This function use to fetch all task list of active user */
  getMyTask(){
    let payload = {}
     this.ngxService.start();
      //this.loader.startLoader();
      this.rest.getData(payload,"myalltask").subscribe({
        next: (data)=>{
          this.ngxService.stop();
          //this.loader.closeLoader();
           if(data.state == "200"){
            this.myTask = data.data;
           }
        },
        error: (e)=>{
          this.ngxService.stop();
          //this.loader.closeLoader();
          console.log(e);
        }
       })
    }

}
