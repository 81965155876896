import { Component, OnInit } from '@angular/core';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { RestService } from 'src/app/rest.service';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { PagesService } from '../../pages.service';

@Component({
  selector: 'app-manage-profile',
  templateUrl: './manage-profile.component.html',
  styleUrls: ['./manage-profile.component.scss'],
})
export class ManageProfileComponent implements OnInit {
  public edit_info = 'edit';
  public userData = <any>{};
  public userForm!: FormGroup;
  public getScheduleData: any;
  public shiftList = <any>[];
  public currentDate = new Date();
  public days = ['sunday', 'monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday'];
  isShow = true;
  docStatus = 0;
  public userName: any;
  public hsl: any;
  public loadAllComponant = false;
  constructor(
    private ngxService: NgxUiLoaderService,
    private rest: RestService,
    private formBuilder: FormBuilder,
    private toaster: ToastrService,
    public pagesService: PagesService
  ) { }

  ngOnInit(): void {
    this.getUserData();
    /* This is object set for adding validation on selected fileds */
    this.userForm = this.formBuilder.group({
      father_name: [null, [Validators.required]],
      address: ['', [Validators.required]],
      emergency_mobile_1: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      emergency_mobile_2: [null, [Validators.pattern(/^[0-9]\d*$/)]],
      marital_status: [null, [Validators.required]],
      mobile: [null, [Validators.required, Validators.pattern(/^[0-9]\d*$/)]],
      password: [null, [Validators.minLength(6)]],
      email: []
    });
  }
  toggle_edit_button(val: any) {
    this.edit_info = val;
    if (this.edit_info == 'schedule') {
      this.getShiftList();
      this.getSchedule()
    }
  }
  /* api call for getting logedin user data */
  getUserData() {
    this.ngxService.start();
    this.rest.getData({}, 'getloginuser').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        if (data.state == '200') {
          this.userData = data.data;
          this.userName = this.pagesService.getFirstLetters(this.userData.name);
          this.hsl = this.pagesService.convertNameToColor(this.userName);
          this.setFormValue();
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.ngxService.stop();
        this.loadAllComponant = true;
        this.toaster.error("Something went wrong!!!");
      },
    });
  }
  /* This function is used patch value to selected filed*/
  setFormValue() {
    this.userForm.patchValue({
      father_name: this.userData.father_name,
      address: this.userData.address,
      emergency_mobile_1: this.userData.emergency_mobile_1,
      emergency_mobile_2: this.userData.emergency_mobile_2,
      marital_status: this.userData.marital_status,
      mobile: this.userData.mobile,
      email: this.userData.email
    });
  }
  /* Update active user info */
  onSubmit(form: FormGroup) {
    if (form.invalid) {
      return;
    }
    this.ngxService.start();
    this.isShow = false;
    let apiPayload = { ...form.value };
    apiPayload.u_id = this.userData.u_id;
    this.rest.getData(apiPayload, 'updateprofile').subscribe({
      next: (data) => {
        this.ngxService.stop();
        this.isShow = true;
        if (data.state == '200') {
          this.toaster.success(data.message);
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        this.isShow = true;
        this.ngxService.stop();
        this.toaster.error("Something went wrong!!!");
      },
    });
  }
/* get schedule of current user */
  getSchedule() {
    // if(this.getScheduleData.length==0){
    this.rest.getData({}, 'getschedule').subscribe({
      next: (data) => {
        this.ngxService.stop();
        if (data.state == '200') {
          if (data.data.length > 0) {
            data.data.forEach((el: any) => {
              if (el.user_id == this.userData.id) {
                this.getScheduleData = el;
              }
            });
          }
        } else {
          this.toaster.error(data.message);
        }
      },
      error: (e) => {
        console.log(e);
        this.toaster.error('Something went wrong');
      },
    });
    // }else{
    //   this.setScheduleForm();
    // }
  }

  docAction(ev: any) {
    this.docStatus = ev;
  }
  /* fetching shift list */
  getShiftList() {
    if (this.shiftList.length == 0) {
      this.ngxService.start();
      this.rest.getData({}, 'getshiftlist').subscribe({
        next: (data) => {
          this.ngxService.stop();
          if (data.state == '200') {
            this.shiftList = data.data;
          } else {
            this.toaster.error(data.message);
          }
        },
        error: (e) => {
          this.ngxService.start();
          this.toaster.error('Something went wrong');
        },
      });
    }
  }

  humanize(str:any) {
    str = str.replace(/[$@%-/]/g, ' ');
    return str.toUpperCase();
  }
}
