<div class="quicklink-sidebar-menu ctm-border-radius shadow-sm bg-white card grow">
    <div class="card-body">
        <div class="cardTitle d-flex justify-content-between align-items-center">
            <h5 class="p-0 m-0">Create New Shift </h5>
            <button class="btn btn-primary bg-custom border-0 " routerLink="/shift-list"><i
                    class="lar la-caret-square-left 48px"></i></button>
        </div>
    </div>
</div>
<div class="accordion add-employee" id="accordion-details">
    <div class="card shadow-sm grow ctm-border-radius">
        <div class="card-header" id="basic1">
            <h4 class="cursor-pointer mb-0">
                <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
                    data-target="#basic-one" aria-expanded="true">
                    Shift Details
                </a>
            </h4>
        </div>
        <div class="card-body p-0">
            <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1"
                data-parent="#accordion-details">
                <form [formGroup]="shiftForm" (ngSubmit)="onSubmit(shiftForm)" #fd="ngForm" [ngxFvControl]="fd">
                    <div class="row">
                        <div class="shift_extra_information ">
                            <div class="row">
                                <div class="col-lg-6 form-group">
                                    <p>Shift Name</p>
                                    <div class="input-group mb-3">
                                        <div class="input-group-text"><i class="las la-globe"></i></div>
                                        <input type="text" name="" id="shift-name" class="form-control"
                                            formControlName="shift_name">
                                    </div>
                                </div>
                                <div class="col-lg-6 form-group">
                                    <p>Shift Country</p>
                                    <div class="input-group mb-3">
                                        <div class="input-group-text"><i class="las la-globe"></i></div>
                                        <select class="form-control select select2-hidden-accessible" tabindex="-1"
                                            aria-hidden="true" formControlName="shift_country">
                                            <option value="" selected>Select Country</option>
                                            <option value="india">India</option>
                                            <option value="bangaldesh">Bangaldesh</option>
                                            <option value="uk">UK</option>
                                        </select>
                                    </div>
                                </div>
                                <div class="col-lg-6 form-group">
                                    <p>Start Time</p>
                                    <div class="input-group mb-3">
                                        <div class="input-group-text"><i class="las la-globe"></i></div>
                                        <input type="time" name="" id="shift-name" class="form-control"
                                            formControlName="start_time">
                                    </div>
                                </div>
                                <div class="col-lg-6 form-group">
                                    <p>End Time</p>
                                    <div class="input-group mb-3">
                                        <div class="input-group-text"><i class="las la-globe"></i></div>
                                        <input type="time" name="" id="shift-name" class="form-control"
                                            formControlName="end_time">
                                    </div>
                                </div>
                                
                            </div>
                            <div class="col-lg-12 form-group">
                                <div class="input-group mb-3" *ngIf="isShow">
                                    <button class="btn custom-btn btn-8"><span>Create</span></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
<ngx-ui-loader></ngx-ui-loader>