
<form [formGroup]="userForm" (ngSubmit)="onSubmit(userForm)" #fd="ngForm" [ngxFvControl]="fd">
  <div class="accordion add-employee" id="accordion-details">
    <div class="card  grow ctm-border-radius">
      <div class="card-header" id="basic1">
        <h4 class="cursor-pointer mb-0">
          <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
            data-target="#basic-one" aria-expanded="true">
            Create New Employee
          </a>
        </h4>
      </div>
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">

          <div class="row">

            <div class="col-lg-6 form-group">
              <ngx-fv-validate [control]="userForm.controls['type']">
                <label #ngxFvName>
                  Type
                </label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="lar la-id-card"></i>
                  </div>
                  <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                    #ngxFvFormControl formControlName="type">
                    <option value="">Employee type</option>
                    <option value="Full time">Full time</option>
                    <option value="Part time">Part time</option>
                    <option value="Trainee">Trainee</option>
                  </select>
                </div>
                <ngx-fv-error></ngx-fv-error>
              </ngx-fv-validate>
            </div>
            <div class="col-lg-6 form-group">
              <ngx-fv-validate [control]="userForm.controls['name']">
                <label #ngxFvName>
                  Name
                </label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="las la-file-signature"></i>
                  </div>
                  <input type="text" class="form-control" id="Name" placeholder="Name" #ngxFvFormControl
                    formControlName="name">
                </div>
                <ngx-fv-error></ngx-fv-error>
              </ngx-fv-validate>
            </div>
            <div class="col-lg-6 form-group">
              <ngx-fv-validate [control]="userForm.controls['email']">
                <label #ngxFvName>
                  Email
                </label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="las la-envelope-open"></i>
                  </div>
                  <input type="email" class="form-control" id="Email" placeholder="Email" #ngxFvFormControl
                    formControlName="email">
                </div>
                <ngx-fv-error></ngx-fv-error>
              </ngx-fv-validate>
            </div>
            <div class="col-lg-6 form-group">
              <ngx-fv-validate [control]="userForm.controls['mobile']">
                <label #ngxFvName>
                  Mobile
                </label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="las la-mobile"></i>
                  </div>
                  <input type="email" class="form-control" id="mobile" placeholder="Mobile" #ngxFvFormControl
                    formControlName="mobile">
                </div>
                <ngx-fv-error></ngx-fv-error>
              </ngx-fv-validate>
            </div>
          </div>

        </div>
      </div>

    </div>
  </div>
  <div class="accordion add-employee" id="accordion-details">
    <div class="card  grow ctm-border-radius">
      <div class="card-header" id="basic1">
        <h4 class="cursor-pointer mb-0">
          <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
            data-target="#basic-one" aria-expanded="true">
            Employee Details
          </a>
        </h4>
      </div>
      <div class="card-body p-0">
        <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details">

          <div class="row">
            <div class="col-lg-6 form-group">
              <ngx-fv-validate [control]="userForm.controls['designation']">
                <label #ngxFvName>
                  Designation
                </label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="las la-globe"></i>
                  </div>
                  <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                    #ngxFvFormControl formControlName="designation">
                    <option selected="">Choose Designation</option>
                    <option value="software_developer">Software Developer</option>
                    <option value="php_developer">PHP Developer</option>
                    <option value="senior_php_developer">Senior PHP Developer</option>
                    <option value="junior_php_developer">Junior PHP Developer</option>
                    <option value="senior_software_developer">Senior Software Developer</option>
                    <option value="graphic_designer">Graphic Designer</option>
                    <option value="junior_graphic_designer">Junior Graphic Designer</option>
                    <option value="senior_graphic_designer">Senior Graphic Designer</option>
                    <option value="digital_marketing_and_content_writer">Digital Marketing & Content Writer</option>
                    <option value="junior_digital_marketing_and_content_writer">Junior Digital Marketing & Content Writer</option>
                    <option value="senior_digital_marketing_and_content_writer">Senior Digital Marketing & Content Writer</option>
                    <option value="manager">Manager</option>
                    <option value="executive">Executive</option>
                    <option value="back_office_executive">Back Office Executive</option>
                    <option value="senior_executive_hr_and_admin">Senior Executive HR and Admin</option>
                    <option value="web_developer">Web Developer</option>
                    <option value="junior_web_developer">Junior Web Developer</option>
                    <option value="senior_web_developer">Senior Web Developer</option>
                  </select>
                </div>
                <ngx-fv-error></ngx-fv-error>
              </ngx-fv-validate>
            </div>
            <div class="col-lg-6 form-group">
              <ngx-fv-validate [control]="userForm.controls['country']">
                <label #ngxFvName>
                  Country
                </label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="las la-globe"></i>
                  </div>
                  <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true"
                    #ngxFvFormControl formControlName="country">
                    <option selected="">Employee Country</option>
                    <option value="India">India</option>
                    <option value="Bangaldesh">Bangaldesh</option>
                    <option value="Uk">UK</option>
                  </select>
                </div>
                <ngx-fv-error></ngx-fv-error>
              </ngx-fv-validate>
            </div>
            <div class="col-lg-6 form-group">
              <ngx-fv-validate [control]="userForm.controls['dob']">
                <label #ngxFvName>
                  DOB
                </label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="las la-calendar-alt"></i>
                  </div>
                  <input type="text" class="form-control" readonly ngbDatepicker #dpStart="ngbDatepicker" id="username"
                    placeholder="YYYY-MM-DD" name="dpStart" (click)="dpStart.toggle()" [minDate]="minDateLimit"
                    (dateSelect)="onDobDateSelect($event)" [maxDate]="maxDateLimit" #ngxFvFormControl
                    formControlName="dob">
                </div>
                <ngx-fv-error></ngx-fv-error>
              </ngx-fv-validate>
            </div>
            <div class="col-lg-6 form-group">
              <ngx-fv-validate [control]="userForm.controls['join_date']">
                <label #ngxFvName>
                  Join Date
                </label>
                <div class="input-group mb-3">
                  <div class="input-group-text"><i class="las la-calendar-alt"></i>
                  </div>
                  <input type="text" class="form-control" readonly ngbDatepicker #joinStart="ngbDatepicker"
                    id="username" placeholder="YYYY-MM-DD" (click)="joinStart.toggle()" #ngxFvFormControl
                    formControlName="join_date" (dateSelect)="onJoinDateSelect($event)" [minDate]="minJoinDate" [maxDate]="{year: 2048, month: 12, day: 31}">
                </div>
                <ngx-fv-error></ngx-fv-error>
              </ngx-fv-validate>
            </div>
            <div class="col-md-12">
              <div class="grp mb-2">
                <p class="mb-2">Permission</p>
                <div class="form-check form-check-inline" *ngFor="let ch of permissionData">
                  <input class="form-check-input" type="checkbox" name="{{ch.name}}" id="{{ch.name}}"
                    value="{{ch.value}}" (change)="permission_toggle(ch.value,$event)">
                  <label class="form-check-label" for="{{ch.name}}">{{ch.name}}</label>
                </div>

              </div>
            </div>
            <div class="input-group mb-3" *ngIf="isShow">
              <button class="btn custom-btn btn-8" type="submit"><span>Submit Now</span></button>
            </div>
          </div>
          <!-- <label *ngIf="userForm.errors">{{ userForm.errors | json }}</label> -->
        </div>
      </div>

    </div>
  </div>

  <!--
<div class="accordion add-employee" id="accordion-details" >
  <div class="card  grow ctm-border-radius">
    <div class="card-header" id="basic1">
      <h4 class="cursor-pointer mb-0">
        <a class="coll-arrow d-block text-dark" href="javascript:void(0)" data-toggle="collapse"
          data-target="#basic-one" aria-expanded="true">
          Team & Work Details
        </a>
      </h4>
    </div>
    <div class="card-body p-0">
      <div id="basic-one" class="ctm-padding collapse show" aria-labelledby="basic1" data-parent="#accordion-details"
        style="">
        <form>
          <div class="row">
            <div class="col-lg-6 form-group">
              <div class="input-group mb-3">
                <div class="input-group-text"><i class="las la-code"></i>
                </div>
                <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                  <option selected="">Work Role</option>
                  <option value="1">designer</option>
                  <option value="2">Developer</option>
                  <option value="1">Marketing</option>
                  <option value="2">Monitoring</option>
                </select>
              </div>
            </div>
            <div class="col-lg-6 form-group">
              <div class="input-group mb-3">
                <div class="input-group-text"><i class="las la-tasks"></i>
                </div>
                <select class="form-control select select2-hidden-accessible" tabindex="-1" aria-hidden="true">
                  <option selected="">Manage By</option>
                  <option value="1">Super Admin</option>
                  <option value="2">Admin</option>
                </select>
              </div>
            </div>
            <div class="col-md-12">
              <div class="grp mb-2">
                <p class="mb-2">Permission</p>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio1"
                    value="option1">
                  <label class="form-check-label" for="inlineRadio1">Admin</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio2"
                    value="option2">
                  <label class="form-check-label" for="inlineRadio2">Super Admin</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio3"
                    value="option2">
                  <label class="form-check-label" for="inlineRadio3">Task Manage</label>
                </div>
                <div class="form-check form-check-inline">
                  <input class="form-check-input" type="checkbox" name="inlineRadioOptions" id="inlineRadio35"
                    value="option2">
                  <label class="form-check-label" for="inlineRadio35">Team Manager</label>
                </div>
              </div>
            </div>
            <div class="col-lg-12 form-group">
              <div class="input-group mb-3">
                <button class="btn custom-btn btn-8"><span>Submit Now</span></button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>

  </div>
</div>-->
</form>
<ngx-ui-loader></ngx-ui-loader>