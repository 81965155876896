import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'tableSearch'
})
export class TableSearchPipe implements PipeTransform {

  transform(
    value: any,
    searchTerm: any
  ): any {
    if (searchTerm != undefined && searchTerm != '') {
      return value.filter(function (search: any) {
        return Object.keys(search).some(function (key) {           
          console.log(search[key]);
          if (typeof (search[key]) == 'string') {
            let searchKey = search[key]
            return searchKey.toLowerCase().includes(searchTerm.toLowerCase());
          } else {
           
            if (search[key] !== null && search[key] !== undefined && search[key] !== '') {
              if (Array.isArray(search[key])) {
                for (let objectKey of search[key]) {
                  if(objectKey.toLowerCase().includes(searchTerm.toLowerCase())){
                    return objectKey.toLowerCase().includes(searchTerm.toLowerCase());
                  }
                }
              } else {
                for (let objectKey in search[key]) {
                  return objectKey.toLowerCase().includes(searchTerm.toLowerCase());
                }
              }
            }
          }
        })
      });
    } else {
      return value;
    }

  }
}
