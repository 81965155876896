<div class="main-content">
  <div class="card-kb">
    <div class="row">
      <div class="col-lg-8">
        <div class="proContent">
          <div class="d-flex justify-content-between align-items-center">
            <div class="dropdown topbar-head-dropdown ms-1 header-item newBtn">
              <div class="btn-group" (click)="menuBox()">
                <button type="button"  class="btn btn-icon btn-topbar btn-ghost-secondary badge text-dark">
                  Active Projects <b>{{projectListArr?.length}}</b></button>
                <button type="button"  class="dropdown-toggle">
                </button>
              </div>
              <div class="dropdown-menu  dropdown-menu-end main-menu-box"
                [ngClass]="menuBoxStatus?'on':''">
                <div class="bg-white">
                  <div class="menu-state-bg menu-extended">
                      <div class="menu-item">
                        <a routerLink="/dashboard" class="badge text-dark"> 
                          Active
                        </a>
                      </div>
                      <div class="menu-item ">
                        <a routerLink="/project-dashboard" class="badge text-dark">
                          Inacive
                        </a>
                      </div>
                      <div class="menu-item border-0" >
                        <a routerLink="/employee-dashboard" class="badge text-dark">
                          Finish
                        </a>
                      </div>
                  </div>
                </div>
              </div>
            </div>
            <a href="javascript: void(0);"><h5 class="badge  text-dark" (click)="loadAllProject()">Load More</h5></a>
          </div>
          <carousel [cellsToShow]=srcWidth [dots]="true" [arrows]="true" [height]=140>
            <div class="carousel-cell" *ngFor="let p of projectListArr">
              <div class="card" (click)="getProjectId(p.p_id)" [ngClass]="{'p_select': project_id === p.p_id}">
                <div class="p-2 bg-danger-subtle rounded-top">
                  <div class="d-flex align-items-center">
                    <div class="flex-grow-1">
                      <h5 class="mb-0 fs-14" tooltip={{p.p_name}}> {{p.p_name?.length>15?p.p_name.substr(0,15)+'...':p.p_name}}</h5>
                    </div>
                  </div>
                </div>
                <div class="card-body">
                  <div class="cardBoxx">
                    <div class="row gy-3">
                      <p class="text-muted mb-1" tooltip={{p.p_descp}}>Description : {{p.p_descp?.length>15?p.p_descp.substr(0,15)+'...':p.p_descp}}</p>
                    </div>
                    <div class="d-flex align-items-center">
                       <div class="userBox d-flex align-items-center">
                        <div class="flex-shrink-0 " *ngFor="let member of p.members | slice:0:3;">
                          <a href="javascript: void(0);" class="userShape-Sm" placement="top"
                            data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom"
                            aria-label="hello" [ngStyle]="{'background-color':hsl}" tooltip={{member}}>
                            {{convertToLetter(member)}}
                          </a>
                        </div>
                        <p class="m-0" tooltip={{p.members}} >
                          {{ p.members?.length > 3 ?' + '+userIconTrimmer(p.members?.length):''}}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div class="d-flex mb-2">
                    </div>
                    <div class="progress progress-sm animated-progress">
                      <div class="progress-bar bg-waring" role="progressbar" aria-valuenow="50" aria-valuemin="0"
                        aria-valuemax="100" style="width: 50%;">
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </carousel>
        </div>
        <div class="proContent tpm">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="badge  text-dark">Active Module <span class="badge alert-warning text-dark ">{{moduleList?.length}}</span></h5>
          </div>
          <div class="card team-box" *ngIf="moduleList?.length == 0">
            <div class="card-body px-4">
              <div class="row align-items-center team-row">
                <div class="col-6">
                  <div class="team-profile-img">
                    <div class="team-content">
                      <a href="javascript: void(0);" class="d-block">
                        <h5 class="fs-16 mb-1">No Module Created!!</h5>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="moduleCard">
            <carousel [cellsToShow]=srcWidthVal [dots]="true" [arrows]="showModuleArrow" [height]="110">
              <div class="carousel-cell"  *ngFor="let m of moduleList">
                <div class="card team-box" [ngClass]="{'m_select': m_id === m.id}" (click)="getModuleId(m.id)">
                  <div class="card-body px-4">
                    <div class="row align-items-center team-row">
                      <div class="col-6">
                        <div class="team-profile-img">
                          <div class="team-content">
                            <a href="javascript: void(0);" class="d-block">
                              <h5 class="fs-16 mb-1" tooltip={{m.m_name}}> {{m.m_name?.length>15?m.m_name.substr(0,15)+'...':m.m_name}}</h5>
                            </a>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 ">
                        <div class="row text-muted text-center">
                          <div class="col-12 border-end border-end-dashed">
                            <h5 class="mb-1">{{m.m_estimated_effort}}</h5>
                            <p class="text-muted mb-0">Effort</p>
                          </div>
                        </div>
                      </div>
                      <div class="col-3 ">
                        <div class="row text-muted text-center">
                          <div class="col-12">
                            <h5 class="mb-1">{{m.created_at | date}}</h5>
                            <p class="text-muted mb-0">Start</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </carousel>
          </div>
        </div>
        <div class="proContent taskTb ">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="badge  text-dark">Active Tasks <span class="badge alert-warning text-dark ">{{taskList?.length}}</span></h5>
          </div>
          <div class="moduleCard">
            <div class="card team-box" *ngIf="taskList?.length == 0">
              <div class="card-body px-4"  >
                <div class="row align-items-center team-row">
                  <div class="col-lg-4 col-12">
                    <div class="team-profile-img">
                      <div class="team-content">
                        <a href="#" class="d-block">
                          <h5 class="fs-16 mb-1"></h5>
                        </a>
                        <p class="text-muted mb-0">No task created!!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="card team-box" *ngFor="let t of taskList">
              <div class="card-body px-4" *ngIf="taskList?.length == 0" >
                <div class="row align-items-center team-row">
                  <div class="col-lg-4 col-12">
                    <div class="team-profile-img">
                      <div class="team-content">
                        <a href="#" class="d-block">
                          <h5 class="fs-16 mb-1"></h5>
                        </a>
                        <p class="text-muted mb-0">No task created!!</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="card-body px-4" [ngClass]="{'t_select': t_id === t.id}" (click)="getTimesheetId(t.id)">
                <div class="row align-items-center team-row">
                  <div class="col-lg-4 col-12">
                    <div class="team-profile-img">
                      <div class="team-content">
                        <a href="#" class="d-block">
                          <h5 class="fs-16 mb-1"></h5>
                        </a>
                        <p class="text-muted mb-0" tooltip={{t.task_name}}>{{t.task_name?.length>25?t.task_name.substr(0,25)+'...':t.task_name}}</p>
                      </div>
                    </div>
                  </div>

                  <div class="col-lg-2 col-3">
                    <div class="row text-muted text-center">
                      <div class="col-12 border-end border-end-dashed text-center">
                        <div class="team">
                          <a href="javascript:void(0)">
                            <p class="text-muted mb-0" (click)="openSidePanel(t.t_id)">Team</p>
                          </a>   
                          <div class="userBox d-flex">
                            <div class="flex-shrink-0 " *ngFor="let member of t.users">
                              <a href="javascript: void(0);" class="userShape-Sm" placement="top" data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom" [ngStyle]="{'background-color':hsl}" aria-label="hello" tooltip={{member.name}}>
                                {{convertToLetter(member.name)}}
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <!-- <div class="cardBoxx">
                    <div class="row gy-3">
                      <p class="text-muted mb-1" tooltip={{p.p_descp}}>Description : {{p.p_descp?.length>25?p.p_descp.substr(0,25)+'...':p.p_descp}}</p>
                    </div>
                    <div class="d-flex align-items-center">
                       <div class="userBox d-flex align-items-center">
                        <div class="flex-shrink-0 " *ngFor="let member of p.members | slice:0:3;">
                          <a href="javascript: void(0);" class="userShape-Sm" placement="top"
                            data-bs-toggle="tooltip" data-bs-trigger="hover" data-bs-placement="bottom"
                            aria-label="hello" [ngStyle]="{'background-color':hsl}" tooltip={{member}}>
                            {{convertToLetter(member)}}
                          </a>
                        </div>
                        <p class="m-0" tooltip={{p.members}} >
                          {{ p.members?.length > 3 ?' + '+userIconTrimmer(p.members?.length):''}}
                        </p>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-lg-2 col-3">
                    <div class="row text-muted text-center">
                      <div class="col-12 border-end border-end-dashed text-center">
                        <h5 class="mb-1">{{t.task_estimated_pd}}</h5>
                        <p class="text-muted mb-0">Est. Hours</p>
                      </div>
                    </div>
                  </div>
                  <!-- <div class="col-lg-2 col-3">
                    <div class="row text-muted text-center">
                      <div class="col-12 border-end border-end-dashed text-center">
                        <h5 class="mb-1">2H</h5>
                        <p class="text-muted mb-0">Remaining </p>
                      </div>
                    </div>
                  </div> -->
                  <div class="col-lg-2 col-3">
                    <div class="row text-muted text-center">
                      <div class="col-12 ">
                        <h5 class="mb-1">{{t.updated_at|date}}</h5>
                        <p class="text-muted mb-0">Last Activity</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="TimecardConcept mt-3 timeTb">
          <div class="d-flex justify-content-between align-items-center">
            <h5 class="badge  text-dark">TimeSheet <span class="badge alert-warning text-dark ">{{timesheetList?.length}}</span></h5>
            <!-- <h5 class="badge  text-dark" (click)="loadAllTimesheets('timesheet')">View All</h5> -->
          </div>
          <div class="card team-box" *ngIf="timesheetList?.length == 0">
            <div class="card-body px-4">
              <div class="row align-items-center team-row">
                <div class="col-lg-12 col-7">
                  <div class="team-profile-img">
                    <div class="team-content">
                      <a href="#" class="d-block">
                        <h5 class="fs-16 mb-1">No Timesheet entry!!</h5>
                      </a>
                      <!-- <p class="text-muted mb-0 badge alert-waring">{{ts.effort}}</p> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card team-box" *ngFor="let ts of timesheetList">
            <div class="card-body px-4">
              <div class="row align-items-center team-row">
                <div class="col-lg-12 col-7">
                  <div class="team-profile-img">
                    <div class="team-content">
                      <a href="javascript: void(0)" class="d-block">
                        <h5 class="fs-16 mb-1" tooltip={{ts.comment}}> {{ts.comment?.length>15?ts.comment.substr(0,25)+'...':ts.comment}}</h5>
                      </a>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-3">
                  <div class="row text-muted text-center">
                    <div class="col-12 border-end border-end-dashed text-center">
                      <p class="text-muted mb-0">Entry </p>
                      <h5 class="mb-1">{{ts.entry_date | date}}</h5>
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 col-2">
                  <div class="row text-muted text-center">
                    <div class="col-12 ">
                      <p class="text-muted mb-0">Used Time </p>
                      <h5 class="mb-1">{{timeConvert(ts.effort)}}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>    
    <div class="card timesheet" *ngIf="sidePanel">
      <div class="card-body">
        <div class="modal-header p-0 mb-0 align-items-center">
          <h6 class="display-3 fs-5 text-mute ">Overview</h6>
        </div>
        <div class="table-responsive sidebar_tab">
          <div class="timedataCard position-relative tabTb">
            <div class="container">
              <br>
              <div class="overview">
                <h4 class="text-muted"><strong>Task Name</strong> <span>:</span> <span><a [routerLink]=''>{{taskOverviewData.task_name}}</a></span></h4>
                <h4 class="text-muted"><strong>Description</strong> <span>   :</span> <span>{{taskOverviewData.task_description}}</span></h4>
                <h4 class="text-muted"><strong>Estimated Hour</strong> <span>:</span> <span>{{taskOverviewData.estimated_hours}}</span></h4>
                <h4 class="text-muted"><strong>Module Name</strong> <span>:</span> <span>{{taskOverviewData.module_name}}</span></h4>
                <h4 class="text-muted"><strong>Project Name</strong> <span>:</span> <span>{{taskOverviewData.project_name}}</span></h4>
                <h4 class="text-muted"><strong>Assign By</strong> <span>:</span> <span>{{taskOverviewData.assign_by_user}}</span></h4>
                <h4 class="text-muted"><strong>Assigned To</strong> <span>:</span> <span>{{assignedEmp.toString()}}</span></h4>
                <h4 class="text-muted"><strong>Created On</strong> <span>:</span> <span>{{taskOverviewData.created_at| date}}</span></h4>
                <h4 class="text-muted"><strong>Status </strong><span>:</span> <span>{{taskOverviewData.task_status}}</span></h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-ui-loader></ngx-ui-loader>